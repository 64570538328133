import { NetworkRequest } from "api/actions";
import RequestBuilder from "api/requestBuilder";
import { ConfigState } from "modules/config/models";

export interface XhrUploadHandlers {
    onLoad?: (e: any) => void;
    onError?: (e: any) => void;
    onAbort?: (e: any) => void;
    onProgress?: (percentageComplete: number) => void;
}
export interface XhrUploadResponse {
    status: number;
    json: () => Promise<any>;
}

export class XhrUpload<T> {
    private requestDetails: NetworkRequest;
    private config: ConfigState;
    private antiforgeryToken: string;

    public constructor(requestDetails: NetworkRequest, config: ConfigState) {
        this.requestDetails = requestDetails;
        this.config = config;

        return this;
    }

    public addAntiforgeryToken = (token: string): XhrUpload<T> => {
        this.antiforgeryToken = token;
        return this;
    };

    public upload = () => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.onload = e => {
                if (this.requestDetails.xhrHandlers.onLoad) {
                    this.requestDetails.xhrHandlers.onLoad(e);
                }
                resolve({
                    status: xhr.status,
                    json: () => {
                        return Promise.resolve(JSON.parse(xhr.responseText));
                    }
                } as XhrUploadResponse);
            };
            xhr.onerror = e => {
                if (this.requestDetails.xhrHandlers.onError) {
                    this.requestDetails.xhrHandlers.onError(e);
                }
                reject(xhr);
            };
            xhr.onabort = e => {
                if (this.requestDetails.xhrHandlers.onAbort) {
                    this.requestDetails.xhrHandlers.onAbort(e);
                }
                reject(e);
            };
            xhr.upload.onprogress = e => {
                if (this.requestDetails.xhrHandlers.onProgress) {
                    this.requestDetails.xhrHandlers.onProgress((e.loaded / e.total) * 100);
                }
            };

            const { url } = new RequestBuilder(this.requestDetails).setBaseUrl(this.config.appUrl || "").build();

            xhr.open(this.requestDetails.method, url);
            if (this.antiforgeryToken && this.antiforgeryToken !== "") {
                xhr.setRequestHeader("RequestVerificationToken", this.antiforgeryToken);
            }
            xhr.send(this.requestDetails.body);
        });
    };
}
