import * as React from "react";
import ReactMarkdown  from "react-markdown";

interface MarkdownProps {
    source: string;
}

export const Markdown = ({ source }: MarkdownProps) => {

    return (
        <div className="markdown-body">
            <ReactMarkdown>{source}</ReactMarkdown>
        </div>
    );
};
