import * as actions from "./actions";
import { UrlBuilder } from "./urlBuilder";

interface RequestProduct {
    init: any;
    url: string;
}

export default class RequestBuilder {
    private action: actions.NetworkRequest;
    private init: RequestInit = {};
    private url = "";
    private baseUrl = "";

    private headers: any = {};
    private params: any = {};
    private queryParams: any = {};
    private body: any = {};
    private skipEncodeURI = false;

    public constructor(action: actions.NetworkRequest) {
        this.action = action;
        this.url = this.action.url;
        this.headers = { ...action.headers };
        this.params = { ...this.action.params };
        this.queryParams = { ...this.action.queryParams };
        this.body = typeof this.action.body === "string" ? this.action.body : { ...this.action.body };
        this.skipEncodeURI = this.action.skipEncodeURI ? this.action.skipEncodeURI : this.skipEncodeURI;
        return this;
    }

    public setBaseUrl(baseURL: string) {
        this.baseUrl = baseURL;
        return this;
    }

    public addParam(key: string, value: string) {
        this.params[key] = value;
        return this;
    }

    public addAntiforgeryToken(token: string) {
        if (token && token !== "") {
            this.headers["RequestVerificationToken"] = token;
        }
        return this;
    }

    public build() {
        const urlBuilder = new UrlBuilder(this.baseUrl);
        urlBuilder.addPath(this.url);
        urlBuilder.mapParams(this.params || {});
        urlBuilder.addQueryParams(this.queryParams || {});

        const reqHeaders = {} as Headers;
        this.init = {
            method: this.action.method,
            mode: "cors"
        } as RequestInit;

        if (this.body && (typeof this.body === "string" || Object.keys(this.body).length > 0)) {
            this.init.body = typeof this.body === "string" ? this.body : JSON.stringify(this.body);
        }

        if (
            (this.init.method === "POST" || this.init.method === "PATCH") &&
            !this.headers.hasOwnProperty("Content-Type")
        ) {
            this.headers["Content-Type"] = "application/json; charset=UTF-8";
        }

        if (this.init.method === "GET") {
            urlBuilder.addQueryParams({ ts: Date.now().toString() });
        }

        Object.keys(this.headers || {}).forEach((value, index) => {
            if (this.headers[value] !== null) {
                reqHeaders[value] = this.headers[value];
            }
        });

        this.init.mode = "same-origin";
        this.init.credentials = "same-origin";

        if (this.action.mode) {
            this.init.mode = this.action.mode;
        }

        this.init.headers = reqHeaders;

        return { init: this.init, url: urlBuilder.getUrl(this.skipEncodeURI) };
    }
}
