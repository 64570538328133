import React, {useState, useEffect} from "react";

import { DialogTitle, DialogContent, DialogContentText, FormGroup, FormControl, TextField, FormHelperText, DialogActions, Button } from "@mui/material";

import { SubmitButton } from "modules/common/components/forms";
import { ResponsiveDialog } from "modules/common/components/layout";
import { Formatter } from "modules/utils";

interface SessionTimeoutDialogProps {
    isIdle: boolean;
    onLogInRequested: (password: string) => any;
    secondsAllottedForReauth: number;
    loginMessage: string;
    isFetching: boolean;
}

export const SessionTimeoutDialog = ({
    isIdle,
    onLogInRequested,
    secondsAllottedForReauth,
    loginMessage,
    isFetching,
  }: SessionTimeoutDialogProps) => {
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
      let timer;
  
      if (isIdle) {
        setOpen(true);
        setSecondsLeft(secondsAllottedForReauth);
        timer = setInterval(() => {
          setSecondsLeft((seconds) => {
            const newSecondsLeft = seconds > 0 ? seconds - 1 : 0;
  
            if (newSecondsLeft <= 0) {
              window.location.href = "/logout";
            }
  
            return newSecondsLeft;
          });
        }, 1000);
      } else {
        setPassword("");
        clearInterval(timer);
        setOpen(false);
      }
  
      return () => clearInterval(timer);
    }, [isIdle, secondsAllottedForReauth]);
  
    const onKeyPress = (e) => {
      if (e.key === "Enter" && !isFetching) {
        onLogInRequested(password);
        e.preventDefault();
      }
    };

      const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false);
        }
      };
  
    return (
      <ResponsiveDialog
        open={open}
        className="session-timer-dialog"
        onClose={handleClose}
      >
        <DialogTitle className="session-timer-dialog-title">
          <span className="title">Hi! Are you still there?</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You&apos;ve been inactive for awhile, so we locked your session to
            protect your information. If you want to continue, enter your
            password before the timer runs out. That way you won&apos;t lose any
            work in progress either.
          </DialogContentText>
          <br />
          <DialogContentText>
            You&apos;ll be completely logged out in{" "}
            <span className="countdown">
              {Formatter.formatSecondsToMinutes(secondsLeft)}
            </span>
          </DialogContentText>
          <form>
            <FormGroup>
              <FormControl className="form-row">
                <TextField
                  autoFocus
                  label="Password"
                  type="password"
                  onKeyPress={onKeyPress}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </FormControl>
              {loginMessage ? (
                <FormHelperText className="error-text">
                  {loginMessage}
                </FormHelperText>
              ) : null}
            </FormGroup>
          </form>
          <DialogActions>
            <Button href="/logout" color="secondary" variant="text">
              I&apos;m done, sign out
            </Button>
            <SubmitButton
              color="primary"
              variant="contained"
              isSubmitting={isFetching}
              submittingMessage="Signing in"
              onClick={() => onLogInRequested(password)}
            >
              Sign in
            </SubmitButton>
          </DialogActions>
        </DialogContent>
      </ResponsiveDialog>
    );
  };
