import * as React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Button } from "@mui/material";

import { Loading, LoadingIconSize } from "modules/common/components/layout";
import { Formatter } from "modules/utils";

export interface PaginationProps {
    firstPageUrl: string;
    nextPageUrl: string;
    previousPageUrl: string;
    lastPageUrl: string;

    totalCount: number;
    firstItemIndex: number;
    lastItemIndex: number;
    currentPageNumber: number;
    numberOfPages: number;

    className?: string;
    loading: boolean;
    hideFirstLastButtons?: boolean;
}

export class Pagination extends React.PureComponent<PaginationProps> {
    public render() {
        const {
            firstPageUrl,
            nextPageUrl,
            previousPageUrl,
            lastPageUrl,
            totalCount,
            firstItemIndex,
            lastItemIndex,
            currentPageNumber,
            numberOfPages,
            className,
            loading,
            hideFirstLastButtons
        } = this.props;

        const firstPageLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
            return <RouterLink {...props} innerRef={ref as any} />;
        });

        const previousPageLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
            return <RouterLink {...props} innerRef={ref as any} />;
        });

        const nextPageLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
            return <RouterLink {...props} innerRef={ref as any} />;
        });

        const lastPageLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
            return <RouterLink {...props} innerRef={ref as any} />;
        });

        return (
            <div className={"pagination-component " + (className ? className : "")}>
                {!hideFirstLastButtons ? (
                    <Button
                        sx={{ display: { xs: "none", sm: "inline-flex" } }}
                        className="button"
                        component={firstPageLink}
                        to={firstPageUrl}
                        disabled={currentPageNumber === 1}>
                        <i className="material-icons">first_page</i>
                    </Button>
                ) : null}
                <Button
                    className="button"
                    component={previousPageLink}
                    to={previousPageUrl}
                    disabled={currentPageNumber === 1}>
                    <i className="material-icons">navigate_before</i>
                </Button>
                <div className="stats page-range">
                    {loading ? (
                        <Loading loadingIconSize={LoadingIconSize.small} />
                    ) : (
                        <span>
                            <span className="bolder">
                                {Formatter.formatInt(firstItemIndex)} - {Formatter.formatInt(lastItemIndex)}{" "}
                            </span>
                            of
                            <span className="bolder"> {Formatter.formatInt(totalCount)}</span>
                        </span>
                    )}
                </div>
                <Button
                    className="button"
                    component={nextPageLink}
                    to={nextPageUrl}
                    disabled={currentPageNumber === numberOfPages}>
                    <i className="material-icons">navigate_next</i>
                </Button>
                {!hideFirstLastButtons ? (
                    <Button
                        sx={{ display: { xs: "none", sm: "inline-flex" } }}
                        className="button"
                        component={lastPageLink}
                        to={lastPageUrl}
                        disabled={currentPageNumber === numberOfPages}>
                        <i className="material-icons">last_page</i>
                    </Button>
                ) : null}
            </div>
        );
    }
}
