import { Reducer } from "redux";

import { BenefitCoverageDictionary } from "./models.benefitCoverage";
import { BenefitHistoryDictionary } from "./models.benefitCoverageHistory";
import { HandledAction } from "./actionCreator";
import { BenefitCoverageState, OverAgeDependantDeclaration } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<BenefitCoverageState> = (state: Readonly<BenefitCoverageState>, action: KnownAction) => {
    switch (action.type) {
        case "GET_MEMBER_BENEFITS_INIT": {
            return {
                ...state,
                isFetchingMemberBenefits: true
            };
        }
        case "GET_MEMBER_BENEFITS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetchingMemberBenefits: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetchingMemberBenefits: false,
                benefitCoverage: {
                    ...state.benefitCoverage,
                    [action.certificateNumber]: {
                        certificateNumber: action.certificateNumber,
                        membershipBenefits: action.benefits
                    }
                },
                errorMessage: null
            };
        }
        case "GET_DEPENDANT_BENEFITS_INIT": {
            return {
                ...state,
                isFetchingDependantBenefits: true
            };
        }
        case "GET_DEPENDANT_BENEFITS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetchingDependantBenefits: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetchingDependantBenefits: false,
                benefitCoverage: {
                    ...state.benefitCoverage,
                    [action.dependantCertificateNumber]: {
                        certificateNumber: action.dependantCertificateNumber,
                        membershipBenefits: action.benefits
                    }
                },
                errorMessage: null
            };
        }
        case "GET_MEMBER_BENEFIT_HISTORY_INIT": {
            return {
                ...state,
                isFetchingMemberBenefitHistory: true
            };
        }
        case "GET_MEMBER_BENEFIT_HISTORY_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetchingMemberBenefitHistory: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetchingMemberBenefitHistory: false,
                benefitCoverageHistory: {
                    ...state.benefitCoverageHistory,
                    [action.certificateNumber]: {
                        certificateNumber: action.certificateNumber,
                        membershipCoverageHistories: action.benefitHistories
                    }
                },
                errorMessage: null
            };
        }
        case "GET_DEPENDANT_BENEFIT_HISTORY_INIT": {
            return {
                ...state,
                isFetchingDependantBenefitHistory: true
            };
        }
        case "GET_DEPENDANT_BENEFIT_HISTORY_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetchingDependantBenefitHistory: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetchingDependantBenefitHistory: false,
                benefitCoverageHistory: {
                    ...state.benefitCoverageHistory,
                    [action.dependantCertificateNumber]: {
                        certificateNumber: action.dependantCertificateNumber,
                        membershipCoverageHistories: action.benefitHistories
                    }
                },
                errorMessage: null
            };
        }
        case "GET_OVER_AGE_DEPENDANT_DECLARATIONS_INIT": {
            return {
                ...state,
                overAgeDependantInfo: {
                    ...state.overAgeDependantInfo,
                    isFetching: true
                }
            };
        }
        case "GET_OVER_AGE_DEPENDANT_DECLARATIONS_COMPLETE": {
            return {
                ...state,
                overAgeDependantInfo: {
                    ...state.overAgeDependantInfo,
                    isFetching: false,
                    dependants: action.dependants
                }
            };
        }
        case "SAVE_OVER_AGE_DEPENDANT_DECLARATION_INIT": {
            return state;
        }
        case "SAVE_OVER_AGE_DEPENDANT_DECLARATION_COMPLETE": {
            if (action.succeeded) {
                const savedDeclaration = action.declaration;
                return {
                    ...state,
                    overAgeDependantInfo: {
                        ...state.overAgeDependantInfo,
                        dependants: state.overAgeDependantInfo.dependants.map(dependant => {
                            if (dependant.certificateNumber === savedDeclaration.dependantCertificateNumber) {
                                return {
                                    ...dependant,
                                    schoolYears: dependant.schoolYears.map(schoolYear => {
                                        if (schoolYear.id === savedDeclaration.schoolYearId) {
                                            const updatedDeclaration = {
                                                id: savedDeclaration.id,
                                                coveredMemberCertificateNumber: savedDeclaration.coveredMemberCertificateNumber,
                                                schoolName: savedDeclaration.schoolName,
                                                startDate: savedDeclaration.startDate,
                                                endDate: savedDeclaration.endDate
                                            } as OverAgeDependantDeclaration;

                                            return {
                                                ...schoolYear,
                                                declarations: schoolYear.declarations.length
                                                    ? schoolYear.declarations.map(currentDeclaration => {
                                                          return currentDeclaration.coveredMemberCertificateNumber === savedDeclaration.coveredMemberCertificateNumber ? updatedDeclaration : currentDeclaration;
                                                      })
                                                    : [updatedDeclaration]
                                            };
                                        } else {
                                            return {
                                                ...schoolYear
                                            };
                                        }
                                    })
                                };
                            } else {
                                return dependant;
                            }
                        })
                    }
                };
            } else {
                return state;
            }
        }
        case "GET_PRODUCT_MAXIMUM_GROUPS_INIT": {
            return {
                ...state,
                isFetchingProductMaximumGroups: true
            };
        }
        case "GET_PRODUCT_MAXIMUM_GROUPS_COMPLETE": {
            return {
                ...state,
                isFetchingProductMaximumGroups: false,
                productMaximumGroupFilterOptions: action.productMaximumGroups
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            isFetchingMemberBenefits: true, // default to true as this is must-have information and the page can start in a loading state
            isFetchingDependantBenefits: false,
            isFetchingMemberBenefitHistory: false,
            isFetchingDependantBenefitHistory: false,
            isFetchingProductMaximumGroups: false,
            benefitCoverage: {} as BenefitCoverageDictionary,
            benefitCoverageHistory: {} as BenefitHistoryDictionary,
            productMaximumGroupFilterOptions: null,
            overAgeDependantInfo: {
                isFetching: false,
                dependants: null
            }
        } as BenefitCoverageState)
    );
};
