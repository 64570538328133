import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ListItem, Icon, ListItemText, ListItemIcon } from "@mui/material";
import InboxBadge from "modules/inbox/components/common/inboxBadge";

import { SiteNode } from "routeProvider";
import { AppInsightsLogger } from "modules/logging/appInsightsLogger";
import { GoogleAnalyticsLogger } from "modules/logging/googleAnalyticsLogger";

export interface NavDrawerLinkProps {
    page: SiteNode;
    pathname: string;
    showIcon?: boolean;
    iconColor?: "primary" | "secondary";
    hasUnreadFeatureArticles?: boolean;
    showInboxBadge?: boolean;
    ignoreSelectedStyle?: boolean;
    toggleClicked: () => any;
}
export class NavDrawerLink extends React.Component<NavDrawerLinkProps, {}> {
    public render() {
        const { page, showIcon, iconColor, hasUnreadFeatureArticles, showInboxBadge, toggleClicked } = this.props;

        const pageSelected = !this.props.ignoreSelectedStyle && this.isPageSelected(page);
        return (
            <>
                {page && page.basePath ? (
                    <RouterLink key={page.basePath} to={page.basePath} onClick={toggleClicked}>
                        <ListItem
                            button
                            color="secondary"
                            className="subpage-item"
                            onClick={() => {
                                const area = "Inbox";
                                const event = "ViewInbox";
                                const action = `FeatureUsage-${area}-${event}-Clicked`;

                                AppInsightsLogger.trackEvent(
                                    { name: action },
                                    { name: event, area: area, action: action }
                                );
                                GoogleAnalyticsLogger.trackEvent(area, event, action);
                            }}>
                            {showIcon && page.iconName ? (
                                <ListItemIcon>
                                    {showInboxBadge ? (
                                        <InboxBadge>
                                            <Icon
                                                color={iconColor ? iconColor : "secondary"}
                                                className={hasUnreadFeatureArticles ? "ring" : ""}>
                                                {page.iconName}
                                            </Icon>
                                        </InboxBadge>
                                    ) : (
                                        <Icon
                                            color={iconColor ? iconColor : "secondary"}
                                            className={hasUnreadFeatureArticles ? "ring" : ""}>
                                            {page.iconName}
                                        </Icon>
                                    )}
                                </ListItemIcon>
                            ) : null}
                            <ListItemText
                                primary={page.displayName}
                                className={`nav-item-text ${pageSelected ? "selected" : ""}`}
                            />
                        </ListItem>
                    </RouterLink>
                ) : null}
            </>
        );
    }

    private isPageSelected = (page: SiteNode): boolean => {
        const pathContainsParameters = page && page.routingPath ? page.routingPath.indexOf(":") >= 0 : false;
        if (pathContainsParameters) {
            return this.props.pathname.indexOf(page.basePath) >= 0;
        }

        return page && page.basePath && page.basePath === this.props.pathname;
    };
}
