import * as React from "react";

export interface PageTitleProps {
    title?: string;
}

export interface PageTitleState {
    title: string;
}

export class PageTitle extends React.Component<PageTitleProps, PageTitleState> {
    public state: PageTitleState = { title: "My ASEBP" };

    public render() {
        const title = this.props.title ? `My ASEBP | ${this.props.title}` : "My ASEBP";

        if (typeof window !== "undefined" && document.title !== title) {
            document.title = title;
        }
        return null;
    }
}
