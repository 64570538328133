import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { Bank, BankAccount } from "../models";
import { ProfileBankAccountPlaceholder } from "./profileBankAccountPlaceholder";

export interface ProfileBankAccountViewProps {
    bankAccount: BankAccount;
    banks: Bank[];
    isLoading: boolean;
    isReadOnly: boolean;
}

const getBankName = (banks: Bank[], bankInstitutionNumber: string): string => {
    if (bankInstitutionNumber) {
        if ((banks || []).filter((c) => c.institutionNumber === bankInstitutionNumber).length) {
            return (banks || []).filter((c) => c.institutionNumber === bankInstitutionNumber)[0].name;
        }
    }
};

const getObfuscatedAccountNumber = (accountNumber: string): string => {
    const numberOfVisibleDigits = 4;

    if (accountNumber) {
        let visiblePart = accountNumber;
        if (accountNumber.length > numberOfVisibleDigits) {
            visiblePart = accountNumber.substring(accountNumber.length - numberOfVisibleDigits);
        }

        return "********" + visiblePart;
    }
};

export const ProfileBankAccountView = ({ bankAccount, banks, isLoading, isReadOnly }: ProfileBankAccountViewProps) => {
    if (isLoading) {
        return <ProfileBankAccountPlaceholder />;
    }

    if (!bankAccount && isReadOnly) {
        return null;
    }

    if (!bankAccount) {
        return (
            <div className="bank-account-view-not-set">
                <Typography variant="caption">Not set</Typography>
            </div>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="bank-account-view">
                <div className="section">
                    <Typography variant="subtitle1">{getBankName(banks, bankAccount.bankInstitutionNumber)}</Typography>
                </div>
                <div className="bank-numbers">
                    <div className="section">
                        <Typography variant="subtitle1">{bankAccount.branchTransitNumber}</Typography>
                        <Typography variant="caption">Transit</Typography>
                    </div>
                    <div className="section">
                        <Typography variant="subtitle1">{bankAccount.bankInstitutionNumber}</Typography>
                        <Typography variant="caption">Bank</Typography>
                    </div>
                    <div className="section">
                        <Typography variant="subtitle1">
                            {getObfuscatedAccountNumber(bankAccount.accountNumber)}
                        </Typography>
                        <Typography variant="caption">Account</Typography>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};
