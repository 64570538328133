import { NetworkRequest } from "./actions";

export const searchDrugsV2 = (name: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/drugs/v2/search?name={name}`,
        params: { name }
    };
};

export const getDrugV2 = (name: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/drugs/v2/drug?name={name}`,
        params: { name }
    };
};

export const searchDrugsV3 = (query: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/drugs/v3/search?query={query}`,
        params: { query }
    };
};

export const getDrugV3 = (cleansedDrugName: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/drugs/v3/drug?cleansedDrugName={cleansedDrugName}`,
        params: { cleansedDrugName }
    };
};

export const getDrugProductV3 = (din: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/drugs/v3/drugProduct?din={din}`,
        params: { din }
    };
};
