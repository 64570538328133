import { Reducer } from "redux";

import { HandledAction } from "./actionCreator";

import { SpendingAccountsState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<SpendingAccountsState> = (
    state: SpendingAccountsState,
    action: KnownAction
): SpendingAccountsState => {
    switch (action.type) {
        case "GET_COVERAGE_PERIODS_INIT": {
            return {
                ...state,
                coveragePeriodList: {
                    ...state.coveragePeriodList,
                    isFetching: true
                }
            };
        }
        case "GET_COVERAGE_PERIODS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    coveragePeriodList: {
                        ...state.coveragePeriodList,
                        isFetching: false,
                        membershipCoveragePeriods: null,
                        errorMessage: action.error
                    }
                };
            }

            return {
                ...state,
                coveragePeriodList: {
                    ...state.coveragePeriodList,
                    isFetching: false,
                    membershipCoveragePeriods: action.coveragePeriods,
                    errorMessage: null
                }
            };
        }
        case "GET_SPENDING_ACCOUNTS_INIT_V2": {
            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isFetching: true
                }
            };
        }
        case "GET_SPENDING_ACCOUNTS_COMPLETE_V2": {
            if (!action.succeeded) {
                return {
                    ...state,
                    spendingAccountList: {
                        ...state.spendingAccountList,
                        isFetching: false,
                        membershipSpendingAccounts: null,
                        errorMessage: action.error
                    }
                };
            }

            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isFetching: false,
                    membershipSpendingAccounts: action.spendingAccounts,
                    errorMessage: null
                }
            };
        }

        case "SUBMIT_ALLOCATION_INIT_V2": {
            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isSavingAllocation: true
                }
            };
        }
        case "SUBMIT_ALLOCATION_COMPLETE_V2": {
            if (!action.succeeded) {
                return {
                    ...state,
                    spendingAccountList: {
                        ...state.spendingAccountList,
                        isSavingAllocation: false,
                        isSpendingAccountAllocationDialogOpen: true,
                        errorMessage: action.error
                    }
                };
            }

            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isSavingAllocation: false,
                    isSpendingAccountAllocationDialogOpen: false,
                    errorMessage: null
                }
            };
        }

        case "OPEN_ALLOCATION_DIALOG": {
            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isSpendingAccountAllocationDialogOpen: true,
                    errorMessage: null
                }
            };
        }
        case "CLOSE_ALLOCATION_DIALOG": {
            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isSpendingAccountAllocationDialogOpen: false,
                    errorMessage: null
                }
            };
        }

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            spendingAccountList: {
                isSavingAllocation: false,
                membershipSpendingAccounts: null,
                isSpendingAccountAllocationDialogOpen: false,
                isFetching: true
            }
        } as SpendingAccountsState)
    );
};
