import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ListItem, Icon, ListItemText, Button, ListItemIcon } from "@mui/material";

interface MyProfileButtonProps {
    renderAsListItem?: boolean;
    onClick?: (event: any) => void;
}
export class MyProfileButton extends React.Component<MyProfileButtonProps, {}> {
    public render() {
        return this.props.renderAsListItem ? (
            <RouterLink to="/profile" onClick={this.props.onClick}>
                <ListItem button id="my-profile-link-button">
                    <ListItemIcon>
                        <Icon>account_circle</Icon>
                    </ListItemIcon>
                    <ListItemText primary="My Profile" />
                </ListItem>
            </RouterLink>
        ) : (
            <Button href={"/profile"}>My Profile</Button>
        );
    }
}
