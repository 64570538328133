import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import cloneDeep from "clone-deep";

import { ApplicationState } from "store";

import { FooterContent } from "./FooterContent";

// To be removed when cleaning up the feature toggle
// http://tfs-01p:8080/tfs/DefaultCollection/ASEBP/_workitems/edit/27526
import { FeatureToggles } from "modules/features/models";

class Footer extends React.PureComponent<ComponentProps> {
    public render() {
        return <div className="footer">{this.props.isAuthenticated ? <FooterContent pathname={this.props.pathname} featureToggles={cloneDeep(this.props.featureToggles)}/> : null}</div>;
    }
}

interface IStateToProps {
    isAuthenticated: boolean;
    pathname: string;
    featureToggles: FeatureToggles;
}

type ComponentProps = IStateToProps & Partial<RouteComponentProps<{}, {}, { location: { pathname: string } }>>;

export default withRouter(
    connect((state: ApplicationState, ownProps: RouteComponentProps<{}, {}, { location: { pathname: string } }>) => {
        return {
            isAuthenticated: !!state.auth.session.userId,
            pathname: ownProps.location ? ownProps.location.pathname : "",
            featureToggles: state.features.featureToggles,
        };
    })(Footer)
);
