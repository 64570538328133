import * as React from "react";
import { Typography } from "@mui/material";

import { isBefore, formatDistance, format } from "date-fns";

import { PlaceholderLine } from "modules/common/components/layout";
import { EmailUpdateConfirmationDetails } from "../models";

export interface ProfileEmailViewProps {
    email: string;
    isLoading: boolean;
    emailUpdateConfirmationDetails: EmailUpdateConfirmationDetails;
}

export class ProfileEmailView extends React.PureComponent<ProfileEmailViewProps> {
    public render() {
        return this.props.isLoading ? (
            <PlaceholderLine color="dark" thickness="thick" />
        ) : (
            <>
                <Typography id="email" variant="subtitle1" className="email">
                    {this.props.email}
                </Typography>
                {this.props.emailUpdateConfirmationDetails ? (
                    <div className="confirmation-status">
                        <div className="exclamation">!</div>
                        <div>
                            {isBefore(new Date().getTime(), new Date(this.props.emailUpdateConfirmationDetails.expirationDateUtc)) ? (
                                <>
                                    <Typography variant="h6">Heads up!</Typography>
                                    <div className="message">
                                        To confirm your email address update, click the link we sent to
                                        <strong> {this.props.emailUpdateConfirmationDetails.newEmailAddress} </strong>
                                        {formatDistance(new Date(this.props.emailUpdateConfirmationDetails.creationDateUtc), new Date().getTime())} ago. This link expires in{" "}
                                        {formatDistance(new Date(this.props.emailUpdateConfirmationDetails.expirationDateUtc), new Date().getTime())}.
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Typography variant="h6">Oops! Your email confirmation link expired</Typography>
                                    <div className="message">
                                        You tried to change your email address to <strong> {this.props.emailUpdateConfirmationDetails.newEmailAddress} </strong> on
                                        {format(new Date(this.props.emailUpdateConfirmationDetails.creationDateUtc), " MMMM do")}, but the link to confirm the change has expired. To try again, submit another email address update.
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}
