import * as React from "react";
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

import { ValidationState } from "modules/claims/claimSubmission/models";
import { FormPasswordField, FormTextField, SubmitButton } from "modules/common/components/forms";
import { UpdateEmailRequest } from "../models";
import { EmailValidator, nameof } from "modules/utils";
import { connect } from "react-redux";
import { ApplicationState } from "store";
import { actionCreators as EmailActionCreators } from "../actionCreator";
import { FeatureToggledElement } from "modules/common/components/misc/featureToggledElement";
import { KnownFeatureToggles, ToggleableFeature } from "modules/features/models";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";
import { ConfirmUnsavedChanges } from "modules/common/components/forms/confirmUnsavedChanges";


export interface EmailFormProps {
    profileEmail: string;
    isSaving: boolean;
    onSaveEmailRequested: (updateEmailRequest: UpdateEmailRequest) => any;
    onCancel: () => any;
}

export interface EmailFormState {
    formEmailRequest: UpdateEmailRequest;
    hasAttemptedToSubmit: boolean;
    validationState: ValidationState;
    showUpdateCancelConfirmation: boolean;
    isFormDirty: boolean;
    openEmployerEmailWarning: boolean;
}

export class EmailForm extends React.PureComponent<ComponentProps, EmailFormState> {
    public state: EmailFormState = {
        formEmailRequest: {
            newEmailAddress: "",
            confirmationPassword: ""
        } as UpdateEmailRequest,
        hasAttemptedToSubmit: false,
        validationState: {} as ValidationState,
        showUpdateCancelConfirmation: false,
        isFormDirty: false,
        openEmployerEmailWarning: false
    };

    public componentDidUpdate(prevProps: EmailFormProps) {
        if (this.props.profileEmail && prevProps.profileEmail !== this.props.profileEmail) {
            this.resetState();
        }
    }

    private resetState() {
        this.setState(
            s =>
                ({
                    formEmailRequest: {
                        newEmailAddress: "",
                        confirmationPassword: ""
                    } as UpdateEmailRequest,
                    hasAttemptedToSubmit: false,
                    validationState: {} as ValidationState,
                    isFormDirty: false,
                    openEmployerEmailWarning: false
                } as EmailFormState)
        );
    }

    public render() {
        const hasEmailError = this.state.hasAttemptedToSubmit && !!this.state.validationState.messages[nameof<UpdateEmailRequest>("newEmailAddress")];
        const hasPasswordError = this.state.hasAttemptedToSubmit && !!this.state.validationState.messages[nameof<UpdateEmailRequest>("confirmationPassword")];

        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={3} />
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2} className="profile-form">
                            <Grid item xs={12}>
                                <p>This is the email address you use to sign in to My ASEBP. We suggest you use your personal email, not your work email.</p>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormTextField
                                    title="New email address"
                                    value={this.state.formEmailRequest.newEmailAddress}
                                    className="profile-form-control"
                                    onChanged={this.onEmailChanged}
                                    errorText={hasEmailError ? this.state.validationState.messages[nameof<UpdateEmailRequest>("newEmailAddress")] : null}
                                    disabled={this.props.isSaving}
                                    fullWidth
                                    id="newEmailAddress"
                                />
                            </Grid>
                            <Grid item xs={12} md={8} />
                            <Grid item xs={12} md={4}>
                                <FormPasswordField
                                    title="My ASEBP password"
                                    autocomplete="new-password"
                                    className="profile-form-control"
                                    value={this.state.formEmailRequest.confirmationPassword}
                                    onChanged={this.onPasswordChanged}
                                    errorText={hasPasswordError ? this.state.validationState.messages["confirmationPassword"] : null}
                                    disabled={this.props.isSaving}
                                    fullWidth
                                    helperText={!hasPasswordError ? "For security reasons, please enter your My ASEBP password to change your email address." : null}
                                    id="myAsebpPassword"
                                />
                            </Grid>
                            <Grid item xs={12} className="form-actions gutter-top">
                                <SubmitButton
                                    submittingMessage="Updating"
                                    isSubmitting={this.props.isEmployerEmailCheckFetching || this.props.isSaving}
                                    className="secondary"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.onSubmit}
                                    disabled={!this.state.isFormDirty}>
                                    Update email
                                </SubmitButton>
                                <Button id="new-email-cancel-button" variant="text" color="secondary" disabled={this.props.isSaving} onClick={this.onCancel}>
                                    <span>Cancel</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={this.state.showUpdateCancelConfirmation} onClose={this.cancelCanceled}>
                    <DialogTitle id="new-email-cancelation-confirmation">Are you sure?</DialogTitle>
                    <DialogContent>Any changes you made will be lost.</DialogContent>
                    <DialogActions>
                        <Button id="edit--email-cancel-cancel-button" onClick={this.cancelCanceled}>
                            No, Keep It
                        </Button>
                        <Button id="edit-email-confirm-cancel-button" className="confirmation confirm-btn" onClick={this.cancelConfirmed}>
                            Yes, discard my changes
                        </Button>
                    </DialogActions>
                </Dialog>
                <FeatureToggledElement knownFeatureToggle={KnownFeatureToggles.PersonalInformationVerification}>
                    <Dialog open={this.state.hasAttemptedToSubmit && this.state.openEmployerEmailWarning}>
                        <DialogContent>Looks like you're using a work email. We suggest using your personal email so you can always access your account. Continue?</DialogContent>
                        <DialogActions>
                            <Button onClick={() => { 
                                this.submitEmailUpdate(); 
                                this.setState(state => ({ ...state, openEmployerEmailWarning: false })); 
                            }}>
                                Yes
                            </Button>
                            <Button className="confirmation confirm-btn" onClick={() => this.resetState()}>
                                No, I'll change to my personal email
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FeatureToggledElement>
                <ConfirmUnsavedChanges isDirty={this.state.isFormDirty} />
            </>
        );
    }

    private onEmailChanged = (evt: any) => {
        this.setUpdateEmailRequestValue({
            ...this.state.formEmailRequest,
            newEmailAddress: evt.target.value
        });
    };

    private onPasswordChanged = (evt: any) => {
        this.setUpdateEmailRequestValue({
            ...this.state.formEmailRequest,
            confirmationPassword: evt.target.value
        });
    };

    private setUpdateEmailRequestValue(formEmailRequest: UpdateEmailRequest) {
        this.setState(s => ({ ...s, formEmailRequest, isFormDirty: true }));
        this.validate(formEmailRequest);
    }

    private validate(updateEmailRequest: UpdateEmailRequest) {
        const validationResult = { isValid: true, messages: {} } as ValidationState;
        const requiredFieldMissingMessage = "This field is required";

        if (updateEmailRequest) {
            if (!updateEmailRequest.newEmailAddress) {
                validationResult.messages[nameof<UpdateEmailRequest>("newEmailAddress")] = requiredFieldMissingMessage;
            } else if (!EmailValidator.isEmailValid(updateEmailRequest.newEmailAddress)) {
                validationResult.messages[nameof<UpdateEmailRequest>("newEmailAddress")] = "Not a valid email address";
            } else if (updateEmailRequest.newEmailAddress === this.props.profileEmail) {
                validationResult.messages[nameof<UpdateEmailRequest>("newEmailAddress")] = "Email address must be different";
            }

            if (!updateEmailRequest.confirmationPassword) {
                validationResult.messages[nameof<UpdateEmailRequest>("confirmationPassword")] = "For security reasons, please enter your My ASEBP password";
            }

            validationResult.isValid = Object.keys(validationResult.messages).length === 0;
        }

        this.setState(s => ({ ...s, validationState: validationResult }));
    }

    private onSubmit = async () => {
        this.setState(s => ({ ...s, hasAttemptedToSubmit: true }));
        this.validate(this.state.formEmailRequest);

        if (this.state.validationState.isValid) {
            if (FeatureToggleChecker.isOn(this.props.personalInfoVerificationToggleFeature)) {
                const isEmployerEmail: boolean = await this.props.checkIsEmployerEmail(
                    this.state.formEmailRequest.newEmailAddress
                );
                if (!isEmployerEmail) {
                    this.submitEmailUpdate();
                } else {
                    this.setState((s) => ({ ...s, openEmployerEmailWarning: true }));
                }
            } else {
                this.submitEmailUpdate();
            }
        }
    };

    private submitEmailUpdate = () => {
        this.props.onSaveEmailRequested(this.state.formEmailRequest);
    }

    private onCancel = () => {
        if (this.state.isFormDirty) {
            this.setState(s => ({ ...s, showUpdateCancelConfirmation: true }));
        } else {
            this.cancelConfirmed();
        }
    };

    private cancelConfirmed = () => {
        this.resetState();
        this.props.onCancel();

        this.setState(s => ({ ...s, showUpdateCancelConfirmation: false }));
    };

    private cancelCanceled = () => {
        this.setState(s => ({ ...s, showUpdateCancelConfirmation: false }));
    };
}

interface IStateToProps {
    isEmployerEmailCheckFetching: boolean;
    personalInfoVerificationToggleFeature: ToggleableFeature;
}

interface IDispatchToProps {
    checkIsEmployerEmail: (email: string) => any;
}

type ComponentProps = EmailFormProps & IStateToProps & IDispatchToProps;

export default connect(
    (state: ApplicationState): IStateToProps => ({
        isEmployerEmailCheckFetching: state.profile.emailState.isEmployerEmailCheck?.isFetching,
        personalInfoVerificationToggleFeature: state.features.featureToggles.PersonalInformationVerification
    }),
    {
        checkIsEmployerEmail: EmailActionCreators.checkIsEmployerEmailAddress
    }
)(EmailForm);
