import * as React from "react";
import { Divider } from "@mui/material";

import { BenefitList } from "./benefitList";
import { PersonBenefits } from "modules/person/coveredMemberSummary.models";

export interface MyAsebpCardBackProps {
    benefits: PersonBenefits;
    show: boolean;
    hidePackageName: boolean;
}

export class MyAsebpCardBack extends React.PureComponent<MyAsebpCardBackProps> {
    public render() {
        const { benefits, show, hidePackageName } = this.props;
        return (
            <div>
                <Divider className="card-edge" />
                <div className={`card-face back ${show ? "show" : ""}`}>
                    <BenefitList personBenefits={benefits} hidePackageName={hidePackageName} />
                </div>
            </div>
        );
    }
}
