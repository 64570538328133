import API from "api";
import { NetworkRequest } from "api/actions";
import { SpendingAccountType } from "modules/benefits/spendingAccounts/models";
import { SpendingAccountClaimCategoryList, SpendingAccountClaimCategory } from "../models";
import { ApplicationState as AppState } from "store";
import * as actions from "./actions";
import { getCoveredMemberSummary } from "modules/person/coveredMemberSummary";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";

export type HandledAction =
    | actions.GetHsaSpendingAccountClaimCategoriesInitAction
    | actions.GetHsaSpendingAccountClaimCategoriesCompleteAction
    | actions.GetWsaSpendingAccountClaimCategoriesCompleteAction
    | actions.GetWsaSpendingAccountClaimCategoriesInitAction;

export type SpendingAccountClaimCategoryActions = HandledAction | NetworkRequest;

let hsaCategoryPromise = null;
let wsaCategoryPromise = null;

export const SpendingAccountClaimCategoryActionCreators = {
    getSpendingAccountClaimCategories: (resetPromises = false) => (dispatch, getState: () => AppState) => {
        const coveredMember = getCoveredMemberSummary(getState());

        dispatch(SpendingAccountClaimCategoryActionCreators.getHsaSpendingAccountClaimCategories(coveredMember, resetPromises));
        dispatch(SpendingAccountClaimCategoryActionCreators.getWsaSpendingAccountClaimCategories(coveredMember, resetPromises));
        return Promise.all([hsaCategoryPromise, wsaCategoryPromise]);
    },
    getHsaSpendingAccountClaimCategories: (coveredMember: CoveredMemberSummary, resetPromise = false) => (dispatch, getState: () => AppState) => {
        if (resetPromise) {
            hsaCategoryPromise = null;
        }
        if (hsaCategoryPromise == null) {
            dispatch({ type: "GET_HSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_INIT" });

            const currentHsaAccounts = coveredMember.submittableSpendingAccounts.filter(spendingAccount => {
                return spendingAccount.accountType == SpendingAccountType.HSA;
            });

            if (currentHsaAccounts.length) {
                const arrayOfSpendingAccountCategoryRetrivalPromises = currentHsaAccounts.map(spendingAccount => {
                    return dispatch(API.claims.getSpendingAccountClaimCategories(SpendingAccountType.HSA, spendingAccount.id))
                        .then(
                            response => {
                                if (response?.status === 200) {
                                    return response.json() as Promise<SpendingAccountClaimCategory>;
                                } else {
                                    return null;
                                }
                            },
                            () => null
                        )
                        .then(categories => {
                            if (categories) {
                                return {
                                    spendingAccountId: spendingAccount.id,
                                    categories: categories,
                                    succeeded: true
                                } as SpendingAccountClaimCategoryList;
                            } else {
                                return {
                                    succeeded: false
                                } as SpendingAccountClaimCategoryList;
                            }
                        });
                });

                hsaCategoryPromise = Promise.all(arrayOfSpendingAccountCategoryRetrivalPromises).then((spendingAccountCategories: SpendingAccountClaimCategoryList[]) => {
                    const anyErrors = spendingAccountCategories.some(spendingAccountCategory => {
                        return !spendingAccountCategory.succeeded;
                    });

                    if (anyErrors) {
                        dispatch({ type: "GET_HSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_COMPLETE", spendingAccountCategories: null, succeeded: false, err: "Failed to get HSA categories" });
                    } else {
                        dispatch({ type: "GET_HSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_COMPLETE", spendingAccountCategories, succeeded: true });
                    }
                });
            } else {
                dispatch({ type: "GET_HSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_COMPLETE", spendingAccountCategories: [], succeeded: true });
                hsaCategoryPromise = Promise.resolve();
            }
        }
        return hsaCategoryPromise;
    },
    getWsaSpendingAccountClaimCategories: (coveredMember: CoveredMemberSummary, resetPromise = false) => (dispatch, getState: () => AppState) => {
        if (resetPromise) {
            wsaCategoryPromise = null;
        }
        if (wsaCategoryPromise == null) {
            dispatch({ type: "GET_WSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_INIT" });

            const currentWsaAccounts = coveredMember.submittableSpendingAccounts.filter(spendingAccount => {
                return spendingAccount.accountType == SpendingAccountType.WSA && !spendingAccount.isPlaceholder;
            });

            if (currentWsaAccounts.length) {
                const arrayOfSpendingAccountCategoryRetrivalPromises = currentWsaAccounts.map(spendingAccount => {
                    return dispatch(API.claims.getSpendingAccountClaimCategories(SpendingAccountType.WSA, spendingAccount.id))
                        .then(
                            response => {
                                if (response?.status === 200) {
                                    return response.json() as Promise<SpendingAccountClaimCategory>;
                                } else {
                                    return null;
                                }
                            },
                            () => null
                        )
                        .then(categories => {
                            if (categories) {
                                return {
                                    spendingAccountId: spendingAccount.id,
                                    categories: categories,
                                    succeeded: true
                                } as SpendingAccountClaimCategoryList;
                            } else {
                                return {
                                    succeeded: false
                                } as SpendingAccountClaimCategoryList;
                            }
                        });
                });

                wsaCategoryPromise = Promise.all(arrayOfSpendingAccountCategoryRetrivalPromises).then((spendingAccountCategories: SpendingAccountClaimCategoryList[]) => {
                    const anyErrors = spendingAccountCategories.some(spendingAccountCategory => {
                        return !spendingAccountCategory.succeeded;
                    });

                    if (anyErrors) {
                        dispatch({ type: "GET_WSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_COMPLETE", spendingAccountCategories: null, succeeded: false, err: "Failed to get WSA categories" });
                    } else {
                        dispatch({ type: "GET_WSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_COMPLETE", spendingAccountCategories, succeeded: true });
                    }
                });
            } else {
                dispatch({ type: "GET_WSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_COMPLETE", spendingAccountCategories: [], succeeded: true });
                wsaCategoryPromise = Promise.resolve();
            }
        }
        return wsaCategoryPromise;
    }
};
