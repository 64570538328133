import { Reducer } from "redux";
import { NotificationsActions } from "./actionCreator";
import { NotificationsState } from "./models";

export const reducer: Reducer<NotificationsState> = (state: NotificationsState, action: NotificationsActions) => {
    switch (action.type) {
        case "ADD_NOTIFICATION": {
            return {
                ...state,
                message: action.message,
                style: action.style,
                autohide: action.autohide,
                canDismiss: action.canDismiss
            };
        }
        case "DISMISS_NOTIFICATION": {
            return {
                ...state,
                message: null
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return state || ({} as NotificationsState);
};
