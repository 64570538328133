import * as actions from "./actions";

import API from "api";
import { NetworkRequest } from "api/actions";
import { Alert } from "modules/alerts/models";
import { ApplicationState, AppThunkAction } from "store";

export type HandledAction = actions.GetAlertsInitAction | actions.GetAlertsCompleteAction;

export type KnownAction = HandledAction | NetworkRequest;

export const actionCreators = {
    getAlerts: (): AppThunkAction<KnownAction> => (dispatch, getState: () => ApplicationState) => {
        dispatch({ type: "GET_ALERTS_INIT" });

        return dispatch(API.alerts.getAlerts())
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<Alert[]>;
                }
                return null;
            })
            .then(alerts => {
                if (alerts) {
                    dispatch({ type: "GET_ALERTS_COMPLETE", alerts, succeeded: true });
                    return alerts;
                } else {
                    dispatch({ type: "GET_ALERTS_COMPLETE", alerts: [], succeeded: false });
                    return [];
                }
            });
    }
};
