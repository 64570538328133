import { HandledAction } from "modules/profile/address/actionCreator";
import { AddressState } from "modules/profile/address/models";
import { Reducer } from "redux";
export type KnownAction = HandledAction;
export const reducer: Reducer<AddressState> = (state: AddressState, action: KnownAction): AddressState => {
    switch (action.type) {
        case "GET_COUNTRIES_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_COUNTRIES_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetching: false,
                    countries: null,
                    errorMessage: action.err
                };
            }
            return {
                ...state,
                isFetching: false,
                countries: action.countries,
                errorMessage: null
            };
        }
        case "GET_ADDRESS_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_ADDRESS_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                isSaving: false,
                address: action.address,
                errorMessage: null
            };
        }
        case "SAVE_ADDRESS_INIT": {
            return {
                ...state,
                isSaving: true
            };
        }
        case "SAVE_ADDRESS_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    isSaving: false,
                    address: {
                        ...action.address
                    },
                    errorMessage: null
                };
            } else {
                return {
                    ...state,
                    isSaving: false,
                    errorMessage: action.err
                };
            }
        }
    }
    return (
        state ||
        ({
            isFetching: false,
            isSaving: false,
            countries: null,
            address: null,
            errorMessage: null
        } as AddressState)
    );
};
