import * as React from "react";
import { Grid, Typography } from "@mui/material";

import { ProfilePhoneNumbers } from "modules/profile/phoneNumbers/models";
import { Formatter } from "modules/utils";
import { ProfilePhoneNumbersPlaceholder } from "./profilePhoneNumbersPlaceholder";

export interface ProfilePhoneNumbersViewProps {
    phones: ProfilePhoneNumbers;
    isLoading: boolean;
}

export class ProfilePhoneNumbersView extends React.PureComponent<ProfilePhoneNumbersViewProps> {
    public render() {
        return (
            <>
                {this.props.isLoading ? (
                    <ProfilePhoneNumbersPlaceholder />
                ) : this.props.phones && this.props.phones.primaryPhoneNumber ? (
                    <Grid container spacing={2}>
                        {this.props.phones.primaryPhoneNumber && this.props.phones.primaryPhoneNumber.number ? (
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">
                                    <span id="primary-phone">{Formatter.formatPhoneNumber(this.props.phones.primaryPhoneNumber.number)}</span>
                                    {this.props.phones.primaryPhoneNumber.extension ? <span> ext: {this.props.phones.primaryPhoneNumber.extension}</span> : null}
                                </Typography>
                                <Typography variant="caption">Primary</Typography>
                            </Grid>
                        ) : null}
                        {this.props.phones.secondaryPhoneNumber && this.props.phones.secondaryPhoneNumber.number ? (
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">
                                    <span id="secondary-phone">{Formatter.formatPhoneNumber(this.props.phones.secondaryPhoneNumber.number)}</span>
                                    {this.props.phones.secondaryPhoneNumber.extension ? <span> ext: {this.props.phones.secondaryPhoneNumber.extension}</span> : null}
                                </Typography>
                                <Typography variant="caption">Secondary</Typography>
                            </Grid>
                        ) : null}
                    </Grid>
                ) : (
                    <Typography variant="caption">Not set</Typography>
                )}
            </>
        );
    }
}
