import API from "api";
import { NetworkRequest } from "api/actions";
import { ProductMaximumGroupFilterOption } from "modules/benefits/coverage/models";
import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import { ApplicationState as AppState, AppThunkAction } from "store";
import * as actions from "./actions";
import { HistoricalMembershipBenefitCoverage, MembershipBenefitCoverage, OverAgeDependantDeclarationSubmission, OverAgeDependantList, SettableOverAgeDependantDeclarationFields } from "./models";

export type HandledAction =
    | actions.GetMemberBenefitsInitAction
    | actions.GetMemberBenefitsCompleteAction
    | actions.GetDependantBenefitsInitAction
    | actions.GetDependantBenefitsCompleteAction
    | actions.GetMemberBenefitHistoryInitAction
    | actions.GetMemberBenefitHistoryCompleteAction
    | actions.GetDependantBenefitHistoryInitAction
    | actions.GetDependantBenefitHistoryCompleteAction
    | actions.GetOverAgeDependantDeclarationsInitAction
    | actions.GetOverAgeDependantDeclarationsCompleteAction
    | actions.SaveOverAgeDependantDeclarationActionInit
    | actions.SaveOverAgeDependantDeclarationActionComplete
    | actions.GetProductMaximumGroupsInitAction
    | actions.GetProductMaximumGroupsCompleteAction;

export type BenefitCoverageActions = HandledAction | AddNotificationAction | NetworkRequest;

const benefitsPromise = {} as { [certificateNumber: string]: Promise<MembershipBenefitCoverage[]> };
const benefitHistoryPromise = {} as { [certificateNumber: string]: Promise<HistoricalMembershipBenefitCoverage[]> };

let overAgeDependantsPromise = null;

export const BenefitCoverageActionCreators = {
    getMemberBenefits: (certificateNumber: string): AppThunkAction<BenefitCoverageActions> => (dispatch, getState: () => AppState) => {
        if (!!benefitsPromise[certificateNumber]) {
            return benefitsPromise[certificateNumber];
        } else {
            benefitsPromise[certificateNumber] = Promise.resolve()
                .then(() => dispatch({ type: "GET_MEMBER_BENEFITS_INIT" }))
                .then(() => {
                    return dispatch(API.benefits.getMemberBenefits(certificateNumber))
                        .then(
                            response => {
                                if (response?.status === 200) {
                                    return response.json() as Promise<MembershipBenefitCoverage[]>;
                                } else {
                                    dispatch({ type: "GET_MEMBER_BENEFITS_COMPLETE", benefits: null, certificateNumber, succeeded: false, err: "Failed to get benefits" });
                                    return null;
                                }
                            },
                            () => {
                                return null;
                            }
                        )
                        .then(
                            (benefits: MembershipBenefitCoverage[]) => {
                                if (benefits) {
                                    dispatch({ type: "GET_MEMBER_BENEFITS_COMPLETE", benefits, certificateNumber, succeeded: true });
                                    return benefits;
                                }
                                return null;
                            },
                            () => {
                                return null;
                            }
                        );
                });

            return benefitsPromise[certificateNumber];
        }
    },
    getDependantBenefits: (memberCertificateNumber: string, dependantCertificateNumber): AppThunkAction<BenefitCoverageActions> => (dispatch, getState: () => AppState) => {
        let task = Promise.resolve(null);
        const state = getState();
        if (!state.benefits.coverage.benefitCoverage[dependantCertificateNumber]) {
            dispatch({ type: "GET_DEPENDANT_BENEFITS_INIT" });

            task = dispatch(API.benefits.getDependantBenefits(memberCertificateNumber, dependantCertificateNumber))
                .then(
                    response => {
                        if (response?.status === 200) {
                            return response.json() as Promise<MembershipBenefitCoverage[]>;
                        } else {
                            dispatch({ type: "GET_DEPENDANT_BENEFITS_COMPLETE", benefits: null, memberCertificateNumber, dependantCertificateNumber, succeeded: false, err: "Failed to get benefits" });
                        }
                        return null;
                    },
                    () => null
                )
                .then((benefits: MembershipBenefitCoverage[]) => {
                    if (benefits) {
                        dispatch({ type: "GET_DEPENDANT_BENEFITS_COMPLETE", benefits, memberCertificateNumber, dependantCertificateNumber, succeeded: true });
                        return benefits;
                    }
                    return null;
                });
        }
        return task;
    },
    getOverAgeDependants: (certificateNumber: string): AppThunkAction<BenefitCoverageActions> => (dispatch, getState: () => AppState) => {
        if (!!overAgeDependantsPromise) {
            return overAgeDependantsPromise;
        } else {
            overAgeDependantsPromise = Promise.resolve()
                .then(() => dispatch({ type: "GET_OVER_AGE_DEPENDANT_DECLARATIONS_INIT", certificateNumber }))
                .then(() => dispatch(API.benefits.getOverAgeDependantDeclarations(certificateNumber)))
                .then(response => {
                    if (response?.status === 200) {
                        return response.json() as Promise<OverAgeDependantList>;
                    } else {
                        dispatch({ type: "GET_OVER_AGE_DEPENDANT_DECLARATIONS_COMPLETE", certificateNumber, dependants: null, succeeded: false, err: "Failed to get dependant information" });
                        return null;
                    }
                })
                .then(dependantList => {
                    if (dependantList) {
                        dispatch({ type: "GET_OVER_AGE_DEPENDANT_DECLARATIONS_COMPLETE", certificateNumber, dependants: dependantList.dependants, succeeded: true });
                        return dependantList.dependants;
                    }
                    return null;
                });
            return overAgeDependantsPromise;
        }
    },
    getBenefitHistory: (certificateNumber: string): AppThunkAction<BenefitCoverageActions> => (dispatch, getState: () => AppState) => {
        if (!certificateNumber) {
            return Promise.resolve([]);
        }
        if (!!benefitHistoryPromise[certificateNumber]) {
            return benefitHistoryPromise[certificateNumber];
        } else {
            const state = getState();
            const authenticatedMemberCertificateNumber = state.auth.session.userId;
            if (certificateNumber === authenticatedMemberCertificateNumber) {
                benefitHistoryPromise[certificateNumber] = Promise.resolve()
                    .then(() => dispatch({ type: "GET_MEMBER_BENEFIT_HISTORY_INIT" }))
                    .then(() => {
                        return dispatch(API.benefits.getMemberBenefitHistory(certificateNumber))
                            .then(
                                response => {
                                    if (response?.status === 200) {
                                        return response.json() as Promise<HistoricalMembershipBenefitCoverage[]>;
                                    } else {
                                        dispatch({ type: "GET_MEMBER_BENEFIT_HISTORY_COMPLETE", benefitHistories: null, certificateNumber, succeeded: false, err: "Failed to get benefit history" });
                                        return null;
                                    }
                                },
                                () => {
                                    return null;
                                }
                            )
                            .then(
                                (benefitHistories: HistoricalMembershipBenefitCoverage[]) => {
                                    if (benefitHistories) {
                                        dispatch({ type: "GET_MEMBER_BENEFIT_HISTORY_COMPLETE", benefitHistories, certificateNumber, succeeded: true });
                                        return benefitHistories;
                                    }
                                    return null;
                                },
                                () => {
                                    return null;
                                }
                            );
                    });
            } else {
                benefitHistoryPromise[certificateNumber] = Promise.resolve()
                    .then(() => dispatch({ type: "GET_DEPENDANT_BENEFIT_HISTORY_INIT" }))
                    .then(() => {
                        return dispatch(API.benefits.getDependantBenefitHistory(authenticatedMemberCertificateNumber, certificateNumber))
                            .then(
                                response => {
                                    if (response?.status === 200) {
                                        return response.json() as Promise<HistoricalMembershipBenefitCoverage[]>;
                                    } else {
                                        dispatch({
                                            type: "GET_DEPENDANT_BENEFIT_HISTORY_COMPLETE",
                                            benefitHistories: null,
                                            memberCertificateNumber: authenticatedMemberCertificateNumber,
                                            dependantCertificateNumber: certificateNumber,
                                            succeeded: false,
                                            err: "Failed to get benefit history"
                                        });
                                    }
                                },
                                () => {
                                    return null;
                                }
                            )
                            .then(
                                (benefitHistories: HistoricalMembershipBenefitCoverage[]) => {
                                    if (benefitHistories) {
                                        dispatch({
                                            type: "GET_DEPENDANT_BENEFIT_HISTORY_COMPLETE",
                                            benefitHistories,
                                            memberCertificateNumber: authenticatedMemberCertificateNumber,
                                            dependantCertificateNumber: certificateNumber,
                                            succeeded: true
                                        });
                                        return benefitHistories;
                                    }
                                    return null;
                                },
                                () => {
                                    return null;
                                }
                            );
                    });
            }
            return benefitHistoryPromise[certificateNumber];
        }
    },
    saveOverAgeDependantDeclaration: (dependantCertificateNumber: string, schoolYearId: number, declaration: SettableOverAgeDependantDeclarationFields): AppThunkAction<BenefitCoverageActions> => (dispatch, getState: () => AppState) => {
        const coveredMemberCertificateNumber = getState().auth.session.userId;

        const declarationSubmission = {
            ...declaration,
            coveredMemberCertificateNumber,
            dependantCertificateNumber,
            schoolYearId
        } as OverAgeDependantDeclarationSubmission;

        dispatch({ type: "SAVE_OVER_AGE_DEPENDANT_DECLARATION_INIT", declaration: declarationSubmission });

        const task = dispatch(API.benefits.saveOverAgeDependantDeclaration(declarationSubmission))
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<OverAgeDependantDeclarationSubmission>;
                } else {
                    dispatch({ type: "SAVE_OVER_AGE_DEPENDANT_DECLARATION_COMPLETE", declaration: declarationSubmission, succeeded: false, err: "Failed to save over age dependant declaration" });
                    dispatch({ type: "ADD_NOTIFICATION", message: "Oops. Couldn't save the over-age dependant information. Please try again.", style: NotificationStyle.Error, autohide: true, canDismiss: true });
                    return null;
                }
            })
            .then(savedDeclaration => {
                if (savedDeclaration) {
                    dispatch({ type: "SAVE_OVER_AGE_DEPENDANT_DECLARATION_COMPLETE", declaration: savedDeclaration, succeeded: true });
                    dispatch({ type: "ADD_NOTIFICATION", message: "Got it! Over-age dependant information updated.", style: NotificationStyle.Success, autohide: true, canDismiss: true });
                }
                return savedDeclaration;
            });
        return task;
    },
    getProductMaximumGroups: (certificateNumber: string) => (dispatch, getState: () => AppState) => {
        const state = getState();

        if (state.benefits && state.benefits.coverage && !state.benefits.coverage.productMaximumGroupFilterOptions) {
            dispatch({ type: "GET_PRODUCT_MAXIMUM_GROUPS_INIT", certificateNumber });
            dispatch(API.benefits.getProductMaximumGroups(certificateNumber))
                .then(
                    response => {
                        if (response?.status === 200) {
                            return response.json() as Promise<ProductMaximumGroupFilterOption[]>;
                        } else {
                            dispatch({ type: "GET_PRODUCT_MAXIMUM_GROUPS_COMPLETE", productMaximumGroups: null, succeeded: false, err: "Failed to get product maximum groups" });
                            return null;
                        }
                    },
                    (reason: any) => {
                        return null;
                    }
                )
                .then(
                    (productMaximumFilterGroups: ProductMaximumGroupFilterOption[]) => {
                        if (productMaximumFilterGroups) {
                            dispatch({ type: "GET_PRODUCT_MAXIMUM_GROUPS_COMPLETE", productMaximumGroups: productMaximumFilterGroups, succeeded: true });
                        }
                    },
                    () => null
                );
        }
    }
};
