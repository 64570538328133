import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import useAxios, { Options } from "axios-hooks";
import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { ApiValidationError, ApiValidationErrorMessage } from "api/models";

export interface AxiosHookRequestOptions<TIn> {
    axios?: Options;
    config?: AxiosRequestConfig<TIn>;
}

export interface AxioCallHookRequestOptions<TIn, TOut> extends AxiosHookRequestOptions<TIn> {
    onRequestCompleted?: CallCallback<TIn, TOut>;
}

export interface ApiResponseWithValidation<TOut> {
    isSuccess: boolean;
    axiosError: AxiosError<ApiValidationError> | null;
    response: AxiosResponse<TOut>;
    validationErrorMessages: ApiValidationErrorMessage[];
}

export type CallCallback<TIn, TOut> = (result: ApiResponseWithValidation<TOut>, input: TIn) => void;

const toApiResponseWithValidation = <TOut>(promise: Promise<AxiosResponse<TOut>>) =>
    promise
        .then((response) => {
            return {
                axiosError: null,
                isSuccess: true,
                response,
                validationErrorMessages: []
            } as ApiResponseWithValidation<TOut>;
        })
        .catch((axiosError: AxiosError<ApiValidationError>) => {
            const validationErrorMessages = axiosError.response?.data?.errors ?? [];
            if (isRequestCanceled(axiosError)) {
                return null;
            }
            return {
                axiosError,
                isSuccess: false,
                response: axiosError.response,
                validationErrorMessages
            } as ApiResponseWithValidation<TOut>;
        });

/** hook provides base headers for all requests */
const useBaseRequestHeaders = (contentType: string | null = "application/json") => {
    /// Retrieve the anti forgery token from redux store to be used in Axios calls
    /// This can of course be modified if redux is ever abandonded completely for axios.
    const { antiforgeryToken } = useAppSelector((state) => state.auth.session);

    const headers: any = {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "-1"
    };

    if (contentType) {
        headers["Accept"] = contentType;
        headers["Content-Type"] = contentType;
    }

    if (!!antiforgeryToken) {
        headers["RequestVerificationToken"] = antiforgeryToken;
    }

    return headers;
};

export const isRequestCanceled = (reason: AxiosError<any>) =>
    reason.code === "ERR_CANCELED" || reason.name === "CanceledError" || reason.message === "canceled";

/**
 * Base hook for GET requests
 * @param defaultUrl Optional. Provide the default URL for the request.
 * This URL can be overridden in the call() function via config.
 * @param requestOptions Optional. Provide request options.
 */
export function useBaseAxiosGetRequest<TEntity, TError = any, TBody = TEntity>(
    defaultUrl?: string | null,
    requestOptions?: AxiosHookRequestOptions<TBody>
) {
    const [isCanceled, setIsCanceled] = useState(false);
    const [hasBeenCalledManually, setHasBeenCalledManually] = useState(false);

    const axiosConfig = useMemo(
        () => ({ method: "GET", url: defaultUrl ?? undefined, ...requestOptions?.config } as AxiosRequestConfig<TBody>),
        [defaultUrl, requestOptions?.config]
    );
    const axiosOptions = useMemo(
        () => ({ useCache: false, manual: !defaultUrl, ...requestOptions?.axios } as Options),
        [defaultUrl, requestOptions?.axios]
    );

    const [{ data, loading: loadingRequest, error, response }, execute, cancelRequest] = useAxios<
        TEntity,
        TBody,
        TError
    >(axiosConfig, axiosOptions);

    const loading = loadingRequest && !isCanceled;

    const call = useCallback(
        (config: AxiosRequestConfig = {}, options?: Options) => {
            setIsCanceled(false);
            setHasBeenCalledManually(true);
            const url = config.url ?? defaultUrl;
            return execute({ url, ...config }, options)
                .then((response) => {
                    return response.data;
                })
                .catch((reason: AxiosError<any>) => {
                    if (isRequestCanceled(reason)) {
                        return null;
                    }
                    throw reason;
                }) as Promise<TEntity>;
        },
        [defaultUrl, execute]
    );

    const cancel = () => {
        setIsCanceled(true);
        cancelRequest();
    };

    return {
        // data should be null if the url is null and it's set to automatically load
        data: hasBeenCalledManually || !axiosOptions.manual ? data ?? null : null,
        loading,
        error,
        response,
        call,
        cancel
    };
}

export type AxiosHookRequestCall<TIn, TOut> = (
    input: TIn,
    config?: AxiosRequestConfig,
    options?: Options
) => Promise<ApiResponseWithValidation<TOut>>;

/**
 * Base hook for DELETE requests
 * @param requestUrl Required. The API Post URL, either a string or a function producting a string.
 * This URL can be overridden in the call() function via config.
 * @param requestOptions Optional.
 */
export function useBaseAxiosDeleteRequest<TId = number, TOut = void>(
    requestUrl: string | ((id: TId) => string),
    requestOptions?: AxioCallHookRequestOptions<TId, TOut>
) {
    const baseRequestHeaders = useBaseRequestHeaders(null);
    const [isCanceled, setIsCanceled] = useState(false);

    const [{ loading: loadingRequest, error }, execute, cancelRequest] = useAxios<TOut>(
        {
            method: "DELETE",
            ...(requestOptions?.config ?? {}),
            headers: { ...baseRequestHeaders, ...requestOptions?.config?.headers }
        },
        { manual: true, useCache: false, ...requestOptions?.axios }
    );
    const loading = loadingRequest && !isCanceled;

    const call: AxiosHookRequestCall<TId, TOut> = (id, config = {}, options) => {
        setIsCanceled(false);
        const url = typeof requestUrl === "string" ? requestUrl : requestUrl(id);
        return toApiResponseWithValidation(execute({ url, ...config }, options)).then((result) => {
            if (requestOptions?.onRequestCompleted) {
                requestOptions.onRequestCompleted(result, id);
            }
            return result;
        });
    };

    const cancel = () => {
        setIsCanceled(true);
        cancelRequest();
    };

    return { loading, error, call, cancel };
}

/**
 * Base hook for POST (Create) requests
 * @param requestUrl Required. The API Post URL, either a string or a function producting a string.
 * This URL can be overridden in the call() function via config.
 * @param requestOptions Optional.
 * @param contentType Optional.
 */
export function useBaseAxiosPostRequest<TIn, TOut = TIn>(
    requestUrl: string | ((payload: TIn) => string),
    requestOptions?: AxioCallHookRequestOptions<TIn, TOut>,
    contentType?: string
) {
    const baseRequestHeaders = useBaseRequestHeaders(contentType);
    const [isCanceled, setIsCanceled] = useState(false);

    const [{ loading: loadingRequest }, execute, cancelRequest] = useAxios<TOut, TIn>(
        {
            method: "POST",
            ...(requestOptions?.config ?? {}),
            headers: { ...baseRequestHeaders, ...requestOptions?.config?.headers }
        },
        { manual: true, useCache: false, ...requestOptions?.axios }
    );
    const loading = loadingRequest && !isCanceled;

    const call: AxiosHookRequestCall<TIn, TOut> = (payload, config = {}, options) => {
        setIsCanceled(false);
        const url = typeof requestUrl === "string" ? requestUrl : requestUrl(payload);
        return toApiResponseWithValidation(execute({ url, data: payload, ...config }, options)).then((result) => {
            if (requestOptions?.onRequestCompleted) {
                requestOptions.onRequestCompleted(result, payload);
            }
            return result;
        });
    };

    const cancel = () => {
        setIsCanceled(true);
        cancelRequest();
    };

    return { loading, call, cancel };
}

/**
 * Base hook for PUT (Update) requests
 * @param requestUrl Required. The API Post URL, either a string or a function producting a string.
 * This URL can be overridden in the call() function via config.
 * @param requestOptions Optional.
 */
export function useBaseAxiosPutRequest<TIn, TOut = void>(
    requestUrl: string | ((payload: TIn) => string),
    requestOptions?: AxioCallHookRequestOptions<TIn, TOut>
) {
    const baseRequestHeaders = useBaseRequestHeaders();
    const [isCanceled, setIsCanceled] = useState(false);

    const [{ loading: loadingRequest }, execute, cancelRequest] = useAxios<TOut, TIn>(
        {
            method: "PUT",
            ...(requestOptions?.config ?? {}),
            headers: { ...baseRequestHeaders, ...requestOptions?.config?.headers }
        },
        { manual: true, useCache: false, ...requestOptions?.axios }
    );
    const loading = loadingRequest && !isCanceled;

    const call: AxiosHookRequestCall<TIn, TOut> = (payload, config = {}, options) => {
        setIsCanceled(false);
        const url = typeof requestUrl === "string" ? requestUrl : requestUrl(payload);
        return toApiResponseWithValidation(execute({ url, data: payload, ...config }, options)).then((result) => {
            if (requestOptions?.onRequestCompleted) {
                requestOptions.onRequestCompleted(result, payload);
            }
            return result;
        });
    };

    const cancel = () => {
        setIsCanceled(true);
        cancelRequest();
    };

    return { loading, call, cancel };
}

/**
 * Base hook for POST Form Requests, supporting files
 * @param requestUrl Required. The API Post URL, either a string or a function producting a string.
 * This URL can be overridden in the call() function via config.
 * @param requestOptions Optional.
 */
export function useBaseAxiosPostFormRequest<TIn = FormData, TOut = void>(
    requestUrl: string | ((payload: TIn) => string),
    requestOptions?: AxioCallHookRequestOptions<TIn, TOut>
) {
    return useBaseAxiosPostRequest<TIn, TOut>(requestUrl, requestOptions, "multipart/form-data");
}
