import { BenefitType } from "../../benefitsApi";
import {
    AttachmentFileType,
    ClaimAttachmentCategory,
    ClaimAttachmentStatus,
    ClaimGroupingOption,
    ClaimSortColumn,
    ClaimSourceType,
    ClaimStatusName,
    PaymentMedium,
    SubmissionTransferStatus,
    UnifiedClaimDetailType
} from "./enums";
import { SpendingAccountInfo } from "./expenseClaims.models";
import { OnlineClaimSubmissionResponseModel } from "./onlineClaims.models";
import { appendQueryParamForEachItem, appendQueryParamIfValueNotNullOrUndefined } from "utils/uri-utils";

export interface ClaimBenefitFilter {
    name: string;
    benefitType: BenefitType | null;
    children: ClaimCategoryFilter[];
    filterParameter: ClaimFilterParameter;
}

export interface ClaimCategoryFilter extends ClaimBenefitFilter {
    ehcCategoryId: number | null;
    productMaximumGroupId: number | null;
    isCommonGroup: boolean;
    isAsebpPlan: boolean;
    isMyRetiree: boolean;
}

export interface ClaimFilterParameter {
    name: "benefitType" | "ehcCategoryId" | "productMaximumGroupId";
    value: number;
}

export interface AdjudicatedDentalInfo {
    calculations: AdjudicatedDentalInfoCalculations;
    paidPercentage: number | null;
    professionalFeeClaimed: number;
    professionalFeeEligible: number;
    labFeeClaimed: number;
    labFeeEligible: number;
}

export interface AdjudicatedDentalInfoCalculations {
    /** AdjudicationDetail.eligibleAmount less AdjudicationSummary.adjudicatedCobAmount */
    eligibleAmountLessCob: number;

    /** AdjudicationDetail.eligibleAmount less AdjudicationDetail.copayAmount */
    eligibleAmountLessCopay: number;

    /** The least of eligibleAmountLessCob and eligibleAmountLessCopay */
    eligibleAmount: number;
}

export interface AdjudicatedDrugInfo {
    calculations: AdjudicatedDrugInfoCalculations;
    isSubjectToTherapeuticAlternativePricing: boolean;
    drugCostClaimed: number;
    drugCostEligible: number;
    professionalFeeClaimed: number;
    professionalFeeEligible: number;
    drugUpchargeClaimed: number;
    drugUpchargeEligible: number;
}

export interface AdjudicatedDrugInfoCalculations {
    /** UnifiedClaimSummary.amountSubmitted less AdjudicationSummary.adjudicatedCobAmount */
    amountSubmittedLessCob: number;

    /** The least of eligibleAmountLessCob and eligibleAmountLessCopay */
    eligibleAmount: number;
}

export interface AdjudicatedHealthClaimInfo {
    admissionDate: string | null;
    dischargeDate: string | null;
    isTreatmentProvided: boolean;
    tripFromProvider: string;
    tripToProvider: string;
}

export interface AdjudicationDetail extends AdjudicationSummary {
    approvedUnitOfMeasure: string | null;
    benefitPlan: string | null;
    bodySide: string | null;
    claimCategory: string | null;
    copayAmount: number;
    deductibleAmount: number;
    /** Only populated for dental claims */
    dentalInfo: AdjudicatedDentalInfo | null;
    documentNumber: string | null;
    /** Only populated for drug claims */
    drugInfo: AdjudicatedDrugInfo | null;
    eligibleAmount: number;
    enteredDate: string | null;
    /** Only populated for foreign currency claims */
    foreignCurrencyInfo: ForeignCurrencyInfo | null;

    /** Only populated for hospital claims */
    healthClaimInfo: AdjudicatedHealthClaimInfo | null;

    isEmergency: boolean;
    microfilmNum: string | null;
    provider: string | null;
    providerId: string | null;
    quantityClaimed: number | null;
    quantityApproved: number | null;
    rejectOverMaxAmount: number | null;
    rejectOverMaxUnits: number | null;
    sectionNumber: string | null;
}

/** The result of ABC adjudication. */
export interface AdjudicationSummary {
    claimDetailId: number;
    calculations: AdjudicationSummaryCalculations;
    /** Is AdjudicationSummaryCalculations.calculatedCobAmount */
    cobAmount: number;
    eob: EobSummaryModel;
    productDescriptionApproved: string | null;
    productDescriptionSubmitted: string | null;
    productGroups: ProductGroupModel[];
    productNumberApproved: string | null;
    productNumberSubmitted: string | null;
    tooth: ToothSummary | null;
}

export interface AdjudicationSummaryCalculations {
    /** True if the feature toggle and configuration enabled the calculatedAdjudicatedCobAmount
     *  to be overridden with TotalPaidAmountsFromAlternateClaims. */
    alternateClaimsFactoredIntoCalculation: boolean;

    /** The amount transferred to HSA for this claim by the covered member. */
    amountTransferredToMembersHsa: number;

    /** The amount transferred to HSA for this claim by the spouse,
     *  filtered to include during only active relationship dates.(See #18600) */
    amountTransferredToSpousesHsa: number;

    /** This is the COB amount factored into the adjudication, determining eligible amounts and paid amount.
     *  If AlternateClaimsFactoredIntoCalculation is true,
     *  this amount may equal TotalPaidAmountsFromAlternateClaims. */
    calculatedAdjudicatedCobAmount: number;

    /// <summary>
    /// This is the total of CalculatedAdjudicatedCobAmount and MemberProvidedCobAmount,
    /// used to indicate total amount paid by other providers.
    /// </summary>
    calculatedCobAmount: number;

    /** Calculated as UnifiedClaimSummary.AmountSubmitted less:
     *  UnifiedClaimSummary.AmountPaid, CalculatedCobAmount and AmountTransferredToSpousesHsa;
     *  but not AmountTransferredToMembersHsa. */
    calculatedRemainingAmount: number;

    /** This is the COB amount the member specified was paid by other providers at the time
     *  they transferred the adjudicated claim to their HSA. Therefore, this amount is not used to determine
     *  eligibility or matters of adjudication, but does factor into the remaining amount eligible for transfer. */
    memberProvidedCobAmount: number;

    /** The ABC-provided COB amount from the import file.
     *  For adjudications with internal (ABC) COB, this number may be incorrect,
     *  and therefore overridden with TotalPaidAmountsFromAlternateClaims. */
    originalCobAmount: number;

    /** Total of the paid amounts from adjudications of the same ClaimLine from alternate CMs.
     *  This amount represents the Internal (ABC) COB amount, and may be used instead of . */
    totalPaidAmountsFromAlternateClaims: number;
}

export interface BenefitSummary {
    benefitType: BenefitType;
    shortName: string;
    displayName: string;
}

export interface ClaimAttachment {
    referenceId: string;
    category: ClaimAttachmentCategory;
    categoryName: string;
    contentType: string;
    createdDate: string;
    fileName: string;
    fileType: AttachmentFileType;
    /** Relevant for Online Claims only. */
    isFixable: boolean;
    isPurged: boolean;
    rejectionReason: string | null;
    status: ClaimAttachmentStatus | null;
}

export interface ClaimDetailGroup {
    coveredMember: ClaimPerson;
    patient: ClaimPerson;
    abcClaims: AbcClaimDetail[];
    expenseClaims: ExpenseClaimDetail[];
}

export interface ClaimHistoryPage {
    groups: ClaimSummaryGroup[];
    pageNumber: number;
    pageSize: number;
    totalNumberOfClaimGroups: number;
    /**  Stats for all claims from all pages. */
    totalStats: ClaimHistoryStats;
}

export interface ClaimHistoryStats {
    /** Total number of claim groups. */
    totalNumberOfClaimGroups: number;

    /** A sum of the claimed (submitted) amounts.
     * For linked (grouped) claims (e.g. Adjudicated + HSA), only the original claimed amount is factored into the calculation. */
    totalSubmitted: number;

    /** A sum of the paid (adjudicated) amounts.
     * For linked (grouped) claims (e.g. Online + HSA), both paid amounts will be factored into the calculation. */
    totalPaid: number;
}

export interface ClaimPayment {
    paymentNumber: string | null;
    amount: number;
    issuedDate: string;
    lastBankDepositFailureDate: string | null;
    medium: PaymentMedium | null;
    mediumDescription: string | null;
    outcome: "Neutral" | "Positive" | "Negative" | "Recoverable";
    payeeType: string | null;
    status: string;
    statusDescription: string;
    statusDate: string | null;
}

export interface ClaimPerson {
    certificateNumber: string;
    name: string;
}

export interface ClaimsFilter {
    benefitTypes?: BenefitType[];
    ehcCategoryIds?: number[];
    isActionableOnly?: boolean;
    isFixableOnly?: boolean;
    isMyRetiree?: boolean | null;
    isTransferableToHsaOnly?: boolean;
    isTransferred?: boolean | null;
    patientCertificateNumbers?: string[];
    returnUnmatchedPatientsWithCoveredMember?: boolean;
    productMaximumGroupIds?: number[];
    productMaximumGroupSourceCode?: string | null;
    referenceIds?: string[];
    searchText?: string | null;
    serviceStartDate?: Date | string | null;
    serviceEndDate?: Date | string | null;
    spendingAccountId?: number | null;
    spendingAccountCategoryIds?: number[];
    statusDescriptions?: string[];
    excludeStatusDescriptions?: string[];
    statusNames?: ClaimStatusName[];
    submissionFormats?: ClaimSourceType[];
    submittedStartDate?: Date | string | null;
    submittedEndDate?: Date | string | null;
    toothCode?: string | null;
}

export const getClaimsFilterQueryStringParameters = (filter: ClaimsFilter): string => {
    const params = new URLSearchParams();

    appendQueryParamForEachItem(params, filter.benefitTypes, "benefitType");
    appendQueryParamForEachItem(params, filter.ehcCategoryIds, "ehcCategoryId");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.isActionableOnly, "isActionableOnly");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.isFixableOnly, "isFixableOnly");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.isMyRetiree, "isMyRetiree");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.isTransferableToHsaOnly, "isTransferableToHsaOnly");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.isTransferred, "isTransferred");
    appendQueryParamForEachItem(params, filter.patientCertificateNumbers, "patientCertificateNumber");

    appendQueryParamIfValueNotNullOrUndefined(
        params,
        filter.returnUnmatchedPatientsWithCoveredMember,
        "returnUnmatchedPatientsWithCoveredMember"
    );

    appendQueryParamForEachItem(params, filter.productMaximumGroupIds, "productMaximumGroupId");
    appendQueryParamIfValueNotNullOrUndefined(
        params,
        filter.productMaximumGroupSourceCode,
        "productMaximumGroupSourceCode"
    );

    appendQueryParamForEachItem(params, filter.referenceIds, "referenceId");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.searchText, "searchText");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.serviceStartDate, "serviceStartDate");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.serviceEndDate, "serviceEndDate");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.spendingAccountId, "spendingAccountId");

    appendQueryParamForEachItem(params, filter.spendingAccountCategoryIds, "spendingAccountCategoryId");

    appendQueryParamForEachItem(params, filter.statusDescriptions, "statusDescription");
    appendQueryParamForEachItem(params, filter.excludeStatusDescriptions, "excludeStatusDescription");
    appendQueryParamForEachItem(params, filter.statusNames, "statusName");
    appendQueryParamForEachItem(params, filter.submissionFormats, "submissionFormat");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.submittedStartDate, "submittedStartDate");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.submittedEndDate, "submittedEndDate");
    appendQueryParamIfValueNotNullOrUndefined(params, filter.toothCode, "toothCode");

    return params.toString();
};

export interface ClaimStats {
    actionableClaimsCount: number;
    fixableClaimsCount: number;
    hasUsedCoordinationOfBenefits: boolean;
    resubmittableClaimsCount: number;
    recentlySubmittedClaimsCount: number;
    recentlySubmittedClaimsPreview: ClaimSummaryGroup[];
    transferableToHsaClaimsCount: number;
}

export interface ClaimStatusFilterOption {
    /** Unique display name. */
    name: string;

    /** Zero-based level number.
     * A value of 0 is the root level.
     * A value of 1 is the indented level.
     */
    level: number;

    /** Values to use on ClaimsFilter */
    filterValues: ClaimStatusFilterValue;
}

export interface ClaimStatusFilterValue {
    statusName: ClaimStatusName | null;
    statusDescription: string | null;
}

export interface ClaimStatusModel {
    statusName: ClaimStatusName;
    statusDescription: string;
    outcome: string;
}

export interface ClaimSummaryGroup {
    coveredMember: ClaimPerson;
    patient: ClaimPerson;
    claims: UnifiedClaimSummary[];
}

export interface EobMessageCategoryModel {
    id: number;
    name: string;
}

export interface EobMessageModel {
    id: string;
    text: string;
    categories: EobMessageCategoryModel[];
}

export interface EobSummaryModel {
    documentId: string;
    hasAdhocDocumentInformation: boolean;
    hasDocument: boolean;
    messages: EobMessageModel[];
    statementDate: string | null;
    statementNumber: number | null;
}

export interface ForeignCurrencyInfo {
    foreignClaimAmount: number | null;
    countryCurrency: string;
    claimExchangeRate: number | null;
    payExchangeRate: number | null;
    exchangeDate: string | null;
    rateBasedOn: string | null;
}

export interface GetClaimsWebRequest {
    // certificateNumbers: string[]; is set by the MyAsebp Api //
    grouping?: ClaimGroupingOption;
    pageNumber?: number;
    pageSize?: number;
    sortColumn?: ClaimSortColumn;
}

export const getGetClaimsWebRequestQueryStringParameters = (request: GetClaimsWebRequest): string => {
    const params = new URLSearchParams();

    appendQueryParamIfValueNotNullOrUndefined(params, request.grouping, "grouping");
    appendQueryParamIfValueNotNullOrUndefined(params, request.pageNumber, "pageNumber");
    appendQueryParamIfValueNotNullOrUndefined(params, request.pageSize, "pageSize");
    appendQueryParamIfValueNotNullOrUndefined(params, request.sortColumn, "sortColumn");

    return params.toString();
};

export interface ProductGroupModel {
    code: string;
    name: string;
}

export interface ToothSummary {
    toothCode: string;
    surfaceCode: string;
}

export interface TransferToHsaIneligibilityReason {
    displayName: string;
    // reasonType is returned in the server model, but not used on by this client at this time. //
}

export interface UnifiedClaimDetailBase extends UnifiedClaimSummary {
    type: UnifiedClaimDetailType;

    referenceId: string;

    payments: ClaimPayment[];
}

export interface AbcClaimDetail extends UnifiedClaimDetailBase {
    adjudicationResult: AdjudicationDetail | null;
    submission: AbcClaimSubmissionDetail | null;
}

export interface ExpenseClaimDetail extends UnifiedClaimDetailBase {
    ineligibilityReason: string;
    spendingAccount: SpendingAccountInfo;
    /** See also UnifiedClaimSummary.spendingAccountCategoryId */
    spendingAccountSubCategoryId: number;
    /** See also UnifiedClaimSummary.spendingAccountCategoryName */
    spendingAccountSubCategoryName: string;
    submission: ExpenseClaimSubmissionDetail;
}

export type UnifiedClaimDetail = AbcClaimDetail | ExpenseClaimDetail;

export interface UnifiedClaimSubmissionDetail {
    attachments: ClaimAttachment[];
    createdByApplication: string;
    createdThroughPlatform: string | null;
    createdByUser: string;
    lastUpdatedByUser: string | null;
    lastUpdatedDate: string | null;
}

export interface AbcClaimSubmissionDetail extends UnifiedClaimSubmissionDetail {
    onlineClaimSubmission: OnlineClaimSubmissionResponseModel;

    /** Date the claim was transferred to ABC */
    transferredDate: string | null;
    transferStatus: SubmissionTransferStatus;
    transferStatusName: string;
    transferTrackingId: number | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExpenseClaimSubmissionDetail extends UnifiedClaimSubmissionDetail {}

export interface UnifiedClaimSummary {
    referenceId: string;

    /** Only populated for ABC-claims which have been adjudicated. */
    adjudicationResult: AdjudicationSummary | null;

    amountSubmitted: number | null;
    amountPaid: number | null;

    /** For ABC claims, calculated as amountSubmitted less
     * AdjudicationSummary.cobAmount, amountPaid, AdjudicationSummary.spouseTransferredAmount.
     * For expense claims, calculated as amountSubmitted less amountPaid. */
    amountRemaining: number | null;

    benefit: BenefitSummary;

    /** Calculated as productMaximumGroupName, ehcCategoryName, or spendingAccountCategoryName */
    categoryName: string;

    /** The name of the product or service adjudicated or submitted. */
    description: string;

    /** Relevant for ABC-adjudicated claims only; see also spendingAccountCategoryId */
    ehcCategoryId: number | null;

    /** Relevant for ABC-adjudicated claims only; see also spendingAccountCategoryName */
    ehcCategoryName: string | null;

    /** Relevant for expense claims only */
    hasFailedPayment: boolean;

    /** True if either isEligibleForTransferToHsa, isFixable, or isResubmittable. */
    isActionable: boolean;

    /** Can call UnifiedClaimsController.DeleteClaim. */
    isDeletable: boolean;

    /** Action was dismissed */
    isDismissed: boolean;

    /** See transferToHsaIneligibilityReasons */
    isEligibleForTransferToHsa: boolean;

    /** Relevant for non-adjudicated online claims only */
    isFixable: boolean;

    isMyRetireeClaim: boolean | null;

    isResubmittable: boolean;

    membershipId: number | null;

    /** If this claim has been created as a result of a resubmission or adjustment,
     * this will be the reference to the adjudicated claim from which the resubmission or adjustment was created. */
    priorClaimReferenceId: string | null;

    /** Relevant for ABC-adjudicated claims only; see also spendingAccountCategoryId */
    productMaximumGroupId: number | null;

    /** Relevant for ABC-adjudicated claims only; see also spendingAccountCategoryName */
    productMaximumGroupName: string | null;

    /** For ABC claims, this is the adjudication date;
     *  For expense claims, Acceptance Status Date or Receipt Rejection Date. */
    processedDate: string | null;

    /** If a resubmission or adjustment has been created from this claim,
     * this will be the reference to the submission or adjudication which was created from the resubmission or adjustment.  */
    resubmissionReferenceId: string | null;

    serviceDate: string | null;

    /** Relevant for expense claims only; See ehcCategoryId and productMaximumGroupId for ABC-claims */
    spendingAccountCategoryId: number | null;

    /** Relevant for expense claims only; See ehcCategoryName and productMaximumGroupName for ABC-claims */
    spendingAccountCategoryName: string | null;

    spendingAccountId: number | null;

    status: ClaimStatusModel;

    submissionFormat: ClaimSourceType;
    submissionFormatName: string;

    submissionId: number | null;

    /** This will be the date of the submission, not the ABC Received Date (default)  */
    submittedDate: string | null;

    /** Populated for expense claims only if it was created as a transfer from an ABC-adjudicated claim,
     * this is the reference of the adjudicated claim from which the remaining amount was transferred. */
    transferredFromAdjudicatedClaimId: string | null;

    /** Populated for ABC-adjudicated claims only */
    transferredToExpenseClaimIds: string[];

    transferToHsaIneligibilityReasons: TransferToHsaIneligibilityReason[];
}
