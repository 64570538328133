import { OverAgeDependantDeclarationSubmission } from "modules/benefits/coverage/models";
import { NetworkRequest } from "./actions";

export const getMemberBenefits = (certificateNumber: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/{certificateNumber}/benefits",
        params: { certificateNumber }
    };
};

export const getDependantBenefits = (
    memberCertificateNumber: string,
    dependantCertificateNumber: string
): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/{memberCertificateNumber}/dependants/{dependantCertificateNumber}/benefits",
        params: { memberCertificateNumber, dependantCertificateNumber }
    };
};

export const getMemberBenefitHistory = (certificateNumber: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/{certificateNumber}/benefitHistory",
        params: { certificateNumber }
    };
};

export const getDependantBenefitHistory = (
    memberCertificateNumber: string,
    dependantCertificateNumber: string
): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/{memberCertificateNumber}/dependants/{dependantCertificateNumber}/benefitHistory",
        params: { memberCertificateNumber, dependantCertificateNumber }
    };
};

export const getBenefitUtilization = (certificateNumber: string, patientCertificateNumber: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/{certificateNumber}/benefits/utilization?patientCertificateNumber={patientCertificateNumber}",
        params: { certificateNumber, patientCertificateNumber }
    };
};

export const getOverAgeDependantDeclarations = (certificateNumber: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/{certificateNumber}/over-age-dependant-declarations",
        params: { certificateNumber }
    };
};

export const getProductMaximumGroups = (certificateNumber: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/{certificateNumber}/benefits/productMaximumGroups",
        params: { certificateNumber }
    };
};

export const saveOverAgeDependantDeclaration = (declaration: OverAgeDependantDeclarationSubmission): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/people/{certificateNumber}/over-age-dependant-declarations",
        params: { certificateNumber: declaration.coveredMemberCertificateNumber },
        body: declaration
    };
};

export const getDentalGuideTopicSummaries = (dentalPlanId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/dentalGuide/{dentalPlanId}/topicSummaries",
        params: { dentalPlanId }
    };
};

export const getDetailedDentalGuideTopic = (dentalPlanId: string, topicPlanId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/dentalGuide/{dentalPlanId}/topicDetails/{topicPlanId}",
        params: { dentalPlanId, topicPlanId }
    };
};

export const getDentalProcedureDetails = (dentalPlanId: string, procedureCode: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/dentalGuide/{dentalPlanId}/procedures/{procedureCode}",
        params: { dentalPlanId, procedureCode }
    };
};
