import { useBaseAxiosPostRequest } from "hooks/axiosHooks";
import { Log } from "modules/logging";

const controllerEndpoint = "/api/adjudicatedClaims/v5";

export const useMarkAsAlreadyTransferredToHsaRequest = () => {
    // the request itself has no body payload
    const base = useBaseAxiosPostRequest<{}>("");

    // call is overridden to have referenceId supplied
    const call = (referenceId: string) =>
        base.call({}, { url: `${controllerEndpoint}/${referenceId}/transferredToHsaStatus` }).then((result) => {
            if (result.isSuccess) {
                Log.markClaimAsAlreadyTransferredComplete(referenceId);
            }

            return result;
        });

    return { ...base, call };
};
