import { Reducer } from "redux";

import { MembershipCardActions } from "./actionCreator";
import { MembershipCardState } from "./models";

export const reducer: Reducer<MembershipCardState> = (state: MembershipCardState, action: MembershipCardActions) => {
    switch (action.type) {
        case "SHOW_MEMBERSHIP_CARD": {
            return {
                ...state,
                showMembershipCard: true
            };
        }
        case "HIDE_MEMBERSHIP_CARD": {
            return {
                ...state,
                showMembershipCard: false
            };
        }
        case "DOWNLOAD_MEMBERSHIP_CARD": {
            return { ...state };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            showMembershipCard: false
        } as MembershipCardState)
    );
};
