import * as React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { ButtonBase, Collapse, Zoom } from "@mui/material";

export interface Option {
    key: string;
    content: JSX.Element | string;
    value: any;
    muted?: boolean;
    visible?: boolean;
    animateVisibility?: boolean;
    icon?: JSX.Element;
    disabled?: boolean;
    warning?: JSX.Element;
    href?: string;
    indent?: boolean;
    fullWidth?: boolean;
    className?: string;
    accountType?: string;
}

export interface OptionListItemProps {
    option: Option;
    selected: boolean;
    onClick: () => any;
    className?: string;
    variant?: "box" | "chip";
}

export class OptionListItem extends React.PureComponent<OptionListItemProps> {
    public render() {
        const visible = this.props.option.visible !== false;

        const isLink = !!this.props.option.href;

        let additionalProps = {};

        if (isLink) {
            const link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
                return <RouterLink {...props} innerRef={ref as any} />;
            });

            additionalProps = {
                component: link,
                to: this.props.option.href
            };
        }

        const optionVariant = this.props.variant || "box";

        const button = (
            <ButtonBase
                id={this.props.option.key.toLowerCase() + "-option-button"}
                {...additionalProps}
                className={`${this.props.className || ""} option-button option ${optionVariant} ${
                    this.props.option.className || ""
                } ${this.props.selected ? "selected" : ""} ${this.props.option.muted ? "muted" : ""} ${
                    this.props.option.disabled ? "disabled" : ""
                }`}
                focusRipple
                onClick={this.props.onClick}
                disabled={this.props.option.disabled}>
                <div className="option-content-wrapper">
                    <div className="option-content">
                        {this.props.option.icon ? <span className="icon-wrapper">{this.props.option.icon}</span> : null}
                        <span className={`option-text option-text ${this.props.option.icon ? "" : "no-icon"}`}>
                            {this.props.option.content}
                        </span>
                    </div>
                    <Collapse in={this.props.option.warning && (this.props.selected || this.props.option.disabled)}>
                        <div className="option-warning-wrapper">
                            <div className="option-warning-offset" />
                            <div className="option-warning">{this.props.option.warning}</div>
                        </div>
                    </Collapse>
                </div>
            </ButtonBase>
        );

        return this.props.option.animateVisibility === true ? <Zoom in={visible}>{button}</Zoom> : button;
    }
}
