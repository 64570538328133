import { Reducer } from "redux";
import { HandledAction } from "./actionCreator";
import { FeedbackFormState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<FeedbackFormState> = (state: FeedbackFormState, action: KnownAction) => {
    switch (action.type) {
        case "SHOW_FEEDBACK_FORM": {
            return {
                ...state,
                showFeedbackForm: true
            };
        }
        case "HIDE_FEEDBACK_FORM": {
            return {
                ...state,
                showFeedbackForm: false
            };
        }
        case "SUBMIT_FEEDBACK_INIT": {
            return {
                ...state,
                submitting: true
            };
        }
        case "SUBMIT_FEEDBACK_COMPLETE": {
            return {
                ...state,
                submitting: false
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            showFeedbackForm: false,
            submitting: false
        } as FeedbackFormState)
    );
};
