import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Button, Icon } from "@mui/material";

class BackLink extends React.Component<RouteComponentProps<{}, {}, { location: { pathname: string }; history: {} }>> {
    public render() {
        const { history } = this.props;
        return history && history.length > 1 ? (
            <Button className="back-link" onClick={history.goBack}>
                <Icon>arrow_back</Icon>
                Back
            </Button>
        ) : null;
    }
}

export default withRouter(BackLink);
