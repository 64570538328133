import { NetworkRequest } from "./actions";

export const getCoveragePeriods = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/spendingAccounts/coveragePeriods`
    };
};

export const getSpendingAccounts = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/spendingAccounts"
    };
};

export const getSpendingAccountActivity = (spendingAccountId: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/spendingAccounts/${spendingAccountId}/activity?order=DESC`
    };
};

export const submitAllocation = (
    hsaAllocation: number,
    wsaAllocation: number,
    coveragePeriodId: number,
    membershipId: number,
    socialInsuranceNumber?: string,
    tfsaAllocation?: number,
    rrspAllocation?: number
): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/spendingAccounts/initialize`,
        body: {
            hsaAllocation,
            wsaAllocation,
            tfsaAllocation,
            rrspAllocation,
            coveragePeriodId,
            membershipId,
            socialInsuranceNumber
        }
    };
};
