import _ from "lodash";
import {
    AxiosHookRequestOptions,
    useBaseAxiosDeleteRequest,
    useBaseAxiosGetRequest,
    useBaseAxiosPostRequest
} from "hooks/axiosHooks";
import {
    ClaimBenefitFilter,
    ClaimDetailGroup,
    ClaimHistoryPage,
    ClaimsFilter,
    ClaimStats,
    getClaimsFilterQueryStringParameters,
    GetClaimsWebRequest,
    getGetClaimsWebRequestQueryStringParameters
} from "./unifiedClaims.models";
import { ClaimGroupingOption } from "./enums";
import { BenefitType } from "hooks/api/benefitsApi";
import { useAxiosGetRequestListener } from "hooks/axiosHooks";
import { Log } from "modules/logging";

const controllerEndpoint = "/api/unifiedClaims/v5";

export const useGetClaimCategoryFiltersRequest = () =>
    useBaseAxiosGetRequest<ClaimBenefitFilter[]>(`${controllerEndpoint}/categoryFilters`);

const getClaimsRequestParams = (input: GetClaimsRequestInput): string => {
    const requestParams = getGetClaimsWebRequestQueryStringParameters(input.request);
    const filterParams = getClaimsFilterQueryStringParameters(input.filter);
    return _.join(
        [requestParams, filterParams].filter((p) => !!p),
        "&"
    );
};

export const getExportClaimsRequestUrl = (input: GetClaimsRequestInput) =>
    `${controllerEndpoint}/export?${getClaimsRequestParams(input)}`;

export const getClaimAttachmentRequestUrl = (attachmentId: string) =>
    `${controllerEndpoint}/attachments/${attachmentId}/content`;

export const useGetClaimStatsRequest = () => useBaseAxiosGetRequest<ClaimStats>(`${controllerEndpoint}/stats`);

export interface GetClaimsRequestInput {
    request: GetClaimsWebRequest;
    filter: ClaimsFilter;
}

export const useGetClaimsRequest = (
    input: GetClaimsRequestInput,
    requestOptions?: AxiosHookRequestOptions<ClaimHistoryPage>
) => useBaseAxiosGetRequest<ClaimHistoryPage>(`${controllerEndpoint}?${getClaimsRequestParams(input)}`, requestOptions);

export const useGetClaimDetailRequest = (referenceId: string, grouping?: ClaimGroupingOption) => {
    const request = useBaseAxiosGetRequest<ClaimDetailGroup>(
        `${controllerEndpoint}/${referenceId}${!!grouping ? `?grouping=${grouping}` : ""}`
    );

    useAxiosGetRequestListener(request, (_data, error) => {
        if (!error) {
            Log.getClaimDetailsSucceeded(referenceId);
        } else {
            Log.getClaimDetailsFailed(referenceId, error.message);
        }
    });

    return request;
};

export const useUpdateClaimDismissedStatusRequest = () => {
    /** The request itself has no body payload */
    const request = useBaseAxiosPostRequest<{}>("");

    /** call is overridden to supply the necessary parameters */
    const call = (referenceId: string, dismissType: string) =>
        request.call({}, { url: `${controllerEndpoint}/${referenceId}/dismissedStatus` }).then((result) => {
            (result) => {
                if (result.isSuccess) {
                    Log.dismissClaimSucceeded(referenceId, dismissType);
                } else {
                    Log.dismissClaimFailed(referenceId, dismissType, result?.axiosError?.message);
                }
            };

            return result;
        });

    return { ...request, call };
};

const getDeleteClaimEndpoint = (referenceId: string) => `${controllerEndpoint}/${referenceId}`;

export const useDiscardDraftClaimRequest = (numberOfDuplicatesFound: number) =>
    useBaseAxiosDeleteRequest<number>((submissionId) => getDeleteClaimEndpoint(`OC${submissionId}`), {
        onRequestCompleted: (result) => {
            if (result.isSuccess) {
                Log.discardClaimSucceeded(numberOfDuplicatesFound);
            } else {
                Log.discardClaimFailed();
            }
        }
    });

export const useDeleteClaimRequest = () =>
    useBaseAxiosDeleteRequest<string>(getDeleteClaimEndpoint, {
        onRequestCompleted: (result) => {
            if (result.isSuccess) {
                Log.deleteClaimSucceeded();
            } else {
                Log.deleteClaimFailed();
            }
        }
    });

/** Will query ClaimsApi for non-transferred spending account claims
 * when benefitType, patientCertificateNumber, and serviceDate are all supplied. */
export const useGetPossibleDuplicateClaimsRequest = (
    mode: "Transfer" | "SubmitNew",
    benefitType: BenefitType | null,
    patientCertificateNumber: string | null,
    serviceDate: string | Date | null,
    /** optional filter */
    spendingAccountCategoryIds: number[]
) => {
    const callEndpoint =
        (benefitType === BenefitType.HSA || benefitType === BenefitType.WSA) &&
        Boolean(patientCertificateNumber) &&
        Boolean(serviceDate);

    const baseRequest = useGetClaimsRequest(
        {
            request: { grouping: ClaimGroupingOption.None, pageSize: 100 },
            filter: {
                benefitTypes: Boolean(benefitType) ? [benefitType] : [],
                patientCertificateNumbers: Boolean(patientCertificateNumber) ? [patientCertificateNumber] : [],
                serviceStartDate: serviceDate,
                serviceEndDate: serviceDate,
                isTransferred: mode === "Transfer" ? false : null,
                spendingAccountCategoryIds
            }
        },
        { axios: { manual: !callEndpoint } }
    );

    useAxiosGetRequestListener(baseRequest, (data, error) => {
        console?.log("Log.getDuplicateClaimsComplete");
        Log.getDuplicateClaimsComplete(mode === "Transfer", !Boolean(error), data?.totalNumberOfClaimGroups ?? 0);
    });

    return { data: (baseRequest.data?.groups ?? []).flatMap((group) => group.claims), loading: baseRequest.loading };
};
