import { OverAgeDependant } from "modules/benefits/coverage/models";
import { CombinedStatus, InheritedMembership, OwnedMembership, Person, PersonLink, Relative, VoRelationshipTypeEnum, VoStatusEnum } from "./models";
import RelativeComparator from "./relativeComparator";
import { subYears, differenceInDays, differenceInYears } from "date-fns";
import cloneDeep from "clone-deep";

export default class FamilyTreeBuilder {
    private selectedPersonCertificateNumber: string;
    private linkedPeople: Person[];
    private ownedMemberships: OwnedMembership[];
    private inheritedMemberships: InheritedMembership[];
    private overAgeDependants: OverAgeDependant[];

    public constructor(selectedPersonCertificateNumber: string, linkedPeople: Person[], ownedMemberships: OwnedMembership[], inheritedMemberships: InheritedMembership[], overAgeDependants: OverAgeDependant[]) {
        this.selectedPersonCertificateNumber = selectedPersonCertificateNumber;
        this.linkedPeople = cloneDeep(linkedPeople);
        this.ownedMemberships = cloneDeep(ownedMemberships);
        this.inheritedMemberships = cloneDeep(inheritedMemberships);
        this.overAgeDependants = cloneDeep(overAgeDependants);
    }

    private isSpouse(selectedPersonCertificateNumber: string, linkedPersonCertificateNumber: string, ownedMemberships: OwnedMembership[], inheritedMemberships: InheritedMembership[]): boolean {
        if (!!inheritedMemberships) {
            const spouseMemberships = inheritedMemberships.filter(
                m => !!m.coveredMember && m.coveredMember.relationshipTypeCode === VoRelationshipTypeEnum.Spouse && (selectedPersonCertificateNumber === linkedPersonCertificateNumber || m.certificateNumber === linkedPersonCertificateNumber)
            );

            if (spouseMemberships.length > 0) {
                return true;
            }
        }

        if (!!ownedMemberships) {
            const membershipsWithSpouses = ownedMemberships.filter(m => (m.dependants || []).filter(d => d.certificateNumber === linkedPersonCertificateNumber && d.relationshipTypeCode === VoRelationshipTypeEnum.Spouse).length > 0);

            if (membershipsWithSpouses.length > 0) {
                return true;
            }
        }

        return false;
    }

    private isChild(selectedPersonCertificateNumber: string, linkedPersonCertificateNumber: string, ownedMemberships: OwnedMembership[], inheritedMemberships: InheritedMembership[]): boolean {
        return (
            inheritedMemberships.filter(m => selectedPersonCertificateNumber === linkedPersonCertificateNumber && m.coveredMember.relationshipTypeCode === VoRelationshipTypeEnum.Child).length > 0 ||
            ownedMemberships.filter(m => (m.dependants || []).filter(d => d.certificateNumber === linkedPersonCertificateNumber && d.relationshipTypeCode === VoRelationshipTypeEnum.Child).length > 0).length > 0
        );
    }

    private isOADependant(selectedPersonCertificateNumber: string, linkedPersonCertificateNumber: string, ownedMemberships: OwnedMembership[], inheritedMemberships: InheritedMembership[], dependant: Person): boolean {
        return (
            this.isOlderThan21(dependant.birthDate) &&
            !dependant.personStatus.isPermanentlyDisabled &&
            !dependant.personStatus.isTemporarilyDisabled &&
            (inheritedMemberships.filter(
                m => selectedPersonCertificateNumber === linkedPersonCertificateNumber && (m.coveredMember.relationshipTypeCode === VoRelationshipTypeEnum.Child || m.coveredMember.relationshipTypeCode === VoRelationshipTypeEnum.Other)
            ).length > 0 ||
                ownedMemberships.filter(
                    m =>
                        (m.dependants || []).filter(d => d.certificateNumber === linkedPersonCertificateNumber && (d.relationshipTypeCode === VoRelationshipTypeEnum.Child || d.relationshipTypeCode === VoRelationshipTypeEnum.Other)).length >
                        0
                ).length > 0)
        );
    }

    private isOlderThan21(birthDateRecord: string): boolean {
        const _21yearsAgo = subYears(new Date(), 21);
        return differenceInDays(_21yearsAgo, new Date(birthDateRecord)) >= 0;
    }

    private isOtherDependantType(selectedPersonCertificateNumber: string, linkedPersonCertificateNumber: string, ownedMemberships: OwnedMembership[], inheritedMemberships: InheritedMembership[]): boolean {
        return (
            inheritedMemberships.filter(m => selectedPersonCertificateNumber === linkedPersonCertificateNumber && m.coveredMember.relationshipTypeCode > 3).length > 0 ||
            ownedMemberships.filter(m => (m.dependants || []).filter(d => d.certificateNumber === linkedPersonCertificateNumber && d.relationshipTypeCode > 3).length > 0).length > 0
        );
    }

    private getStatusRanking(combinedStatus: CombinedStatus): number {
        if (!combinedStatus) {
            return 0;
        } else if (combinedStatus.statusCode === VoStatusEnum.Active) {
            return 5;
        } else if (combinedStatus.statusCode === VoStatusEnum.ActiveWithNoBenefits) {
            return 4;
        } else if (combinedStatus.statusCode === VoStatusEnum.Terminated && combinedStatus.hasUpcomingTermination === true) {
            return 3;
        } else if (combinedStatus.statusCode === VoStatusEnum.Terminated) {
            return 1;
        } else {
            return VoStatusEnum.Pending;
        }
    }

    private getCombinedStatus(selectedPersonCertificateNumber: string, linkedPersonCertificateNumber: string, ownedMemberships: OwnedMembership[], inheritedMemberships: InheritedMembership[]): CombinedStatus {
        function toCombinedStatus(link: PersonLink): CombinedStatus {
            return {
                statusCode: link.personLinkStatusCode,
                statusEffectiveDate: link.relationshipEffectiveDate,
                hasUpcomingTermination: link.hasPersonLinkUpcomingTermination,
                isTerminated: !link.hasPersonLinkUpcomingTermination && link.personLinkStatusCode === VoStatusEnum.Terminated,
                terminationDate: link.relationshipTerminationDate
            };
        }
        if ((ownedMemberships || []).length > 0 && selectedPersonCertificateNumber !== linkedPersonCertificateNumber) {
            const dependant = ownedMemberships
                .map(om => om.dependants || [])
                .reduce((accum, deps) => {
                    return accum.concat(deps);
                })
                .find(d => d.certificateNumber === linkedPersonCertificateNumber);
            if (dependant) {
                return toCombinedStatus(dependant);
            }

            if ((inheritedMemberships || []).length > 0) {
                const activeMembership = inheritedMemberships.find(inheritedMembership => {
                    return inheritedMembership.coveredMember && inheritedMembership.coveredMember.certificateNumber === linkedPersonCertificateNumber && !inheritedMembership.isTerminated;
                });
                if (activeMembership) {
                    return toCombinedStatus(activeMembership.coveredMember);
                } else {
                    // find most recent termination date
                    const termDates = [...inheritedMemberships]
                        .filter(m => m.terminationDate != null)
                        .map(m => new Date(m.terminationDate))
                        .sort((a, b) => {
                            return b.getTime() - a.getTime();
                        });
                    const latestDate = termDates.length > 0 ? termDates[0] : null;

                    return {
                        statusCode: VoStatusEnum.Terminated,
                        statusEffectiveDate: null,
                        hasUpcomingTermination: false,
                        isTerminated: true,
                        terminationDate: latestDate
                    };
                }
            }
        } else if ((ownedMemberships || []).length === 0) {
            const coveredMembership = (inheritedMemberships || []).find(a => a.coveredMember && !!a.coveredMember.personLinkStatusCode);
            if (selectedPersonCertificateNumber === linkedPersonCertificateNumber && coveredMembership) {
                return toCombinedStatus(coveredMembership.coveredMember);
            } else {
                return (inheritedMemberships || [])
                    .map(a => {
                        return {
                            statusCode: a.statusCode,
                            statusEffectiveDate: a.coveredMember.relationshipEffectiveDate,
                            hasUpcomingTermination: a.hasUpcomingTermination,
                            isTerminated: a.statusCode === VoStatusEnum.Terminated,
                            terminationDate: a.terminationDate
                        };
                    })
                    .reduce((a, b) => {
                        if (this.getStatusRanking(a) < this.getStatusRanking(b)) {
                            return b;
                        }
                        return a;
                    }, null);
            }
        }
    }

    private isDependantOfMember(dependantCertificateNumber: string, ownedMemberships: OwnedMembership[]): boolean {
        return ownedMemberships.filter(m => (m.dependants || []).filter(d => d.certificateNumber === dependantCertificateNumber).length > 0).length > 0;
    }

    private haveBenefitsBeenTerminatedForSevenYears = (status: CombinedStatus) => {
        if (status && status.isTerminated && status.terminationDate) {
            return differenceInYears(new Date(), new Date(status.terminationDate)) >= 7;
        } else {
            return false;
        }
    };

    private setPersonHasIdenticalFirstName = (people: Person[]) => {
        const identicalFirstNames = this.getIdenticalFirstNames(people);

        people.forEach(f => {
           f.hasIdenticalFirstNameAsFamilyMember = identicalFirstNames.some(name => name === f.name.first) ? true : false;
        });
    };

    private getIdenticalFirstNames(people: Person[]): string[] {
        const identicalNames: string[] = [];

        [...people].forEach(fam => {
            if ([...people].filter(f => f.name.first === fam.name.first).length > 1 && !identicalNames.some(name => name === fam.name.first)) {
                identicalNames.push(fam.name.first);
            }
        });
        return identicalNames;
    }

    public build(): Relative[] {
        const linkedPeopleCopy = [...this.linkedPeople];
        this.setPersonHasIdenticalFirstName(linkedPeopleCopy);
        const relatives = (linkedPeopleCopy || [])
            .map(person => {
                const combinedStatus = this.getCombinedStatus(this.selectedPersonCertificateNumber, person.certificateNumber, this.ownedMemberships, this.inheritedMemberships);
                return {
                    ...person,
                    isSpouse: this.isSpouse(this.selectedPersonCertificateNumber, person.certificateNumber, this.ownedMemberships, this.inheritedMemberships),
                    isChild: person.personStatus?.isDependant && this.isChild(this.selectedPersonCertificateNumber, person.certificateNumber, this.ownedMemberships, this.inheritedMemberships),
                    isOtherDependant: person.personStatus?.isDependant && this.isOtherDependantType(this.selectedPersonCertificateNumber, person.certificateNumber, this.ownedMemberships, this.inheritedMemberships) ? true : false,
                    isOverAgeDependant: person.personStatus?.isDependant && this.isOADependant(this.selectedPersonCertificateNumber, person.certificateNumber, this.ownedMemberships, this.inheritedMemberships, person),
                    overAgeDependantRecord: [...this.overAgeDependants].find(d => d.certificateNumber === person.certificateNumber),
                    isDependantOfMember: person.personStatus?.isDependant && this.isDependantOfMember(person.certificateNumber, this.ownedMemberships),
                    combinedStatus
                };
            })
            .filter(rel => !this.haveBenefitsBeenTerminatedForSevenYears(rel.combinedStatus));

        return relatives.sort(RelativeComparator.compare(this.selectedPersonCertificateNumber));
    }
}
