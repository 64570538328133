import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Card, CardContent, Icon, Typography } from "@mui/material";

import { ColoredAvatar, colorVariant } from "modules/common/components/misc";

export interface CardLinkProps {
    text: string;
    to: string;
    iconName: string;
    className?: string;
    color?: colorVariant;
}

export class CardLink extends React.Component<CardLinkProps> {
    public render() {
        return (
            <RouterLink to={this.props.to} className={`card-link ${this.props.className ? this.props.className : ""}`}>
                <Card color="secondary" className="card-link-card">
                    <CardContent>
                        <span className="card-link-icon">
                            <ColoredAvatar size="lg" color="3">
                                <Icon>bar_chart</Icon>
                            </ColoredAvatar>
                        </span>
                        <Typography component="span" className="card-link-text">
                            {this.props.text}
                        </Typography>
                    </CardContent>
                </Card>
            </RouterLink>
        );
    }
}
