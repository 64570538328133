import API from "api";
import { NetworkRequest } from "api/actions";
import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import { UpdatePersonPhoneNumbersAction } from "modules/person/actions";
import { ApplicationState as AppState, AppThunkAction } from "store";
import * as actions from "./actions";
import { EmailUpdateConfirmationDetails, UpdateEmailRequest } from "./models";
import personalInfoVerificationActionCreators, { PersonalInfoVerificationActions } from "modules/profile/personalInfoVerification/actionCreator";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";

export type HandledAction =
    | actions.SaveEmailInitAction
    | actions.SaveEmailCompleteAction
    | actions.GetEmailUpdateConfirmationDetailsInitAction
    | actions.GetEmailUpdateConfirmationDetailsCompleteAction
    | actions.CheckIsEmployerEmailAddressInitAction
    | actions.CheckIsEmployerEmailAddressCompleteAction;
   
export type KnownAction = HandledAction | NetworkRequest | AddNotificationAction | UpdatePersonPhoneNumbersAction | PersonalInfoVerificationActions;

export const actionCreators = {
    updateEmail:
        (updateEmailRequest: UpdateEmailRequest): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const task = Promise.resolve()
                .then(() => dispatch({ type: "SAVE_EMAIL_INIT" }))
                .then(() => {
                    return dispatch(
                        API.profile.updateEmail(
                            updateEmailRequest.newEmailAddress,
                            updateEmailRequest.confirmationPassword
                        )
                    )
                        .then(
                            (response) => {
                                if (response?.status === 200) {
                                    return response.json() as Promise<EmailUpdateConfirmationDetails>;
                                } else {
                                    let message = "Oops! Something went wrong. Please try again.";

                                    if (response?.status === 403 || response?.status === 401) {
                                        message = "The password you have provided is incorrect.";
                                    }

                                    if (response?.status === 400) {
                                        message =
                                            "Oops! Something went wrong. Please try again. If the problem continues, please contact us at 1-877-431-4786 or benefits@asebp.ca.";
                                    }

                                    dispatch({
                                        type: "ADD_NOTIFICATION",
                                        message,
                                        style: NotificationStyle.Error,
                                        autohide: true,
                                        canDismiss: true
                                    });
                                    dispatch({
                                        type: "SAVE_EMAIL_COMPLETE",
                                        confirmationDetails: null,
                                        succeeded: false,
                                        err: message
                                    });
                                }
                            },
                            (reason: any) => {
                                return null;
                            }
                        )
                        .then(
                            (confirmationDetails: EmailUpdateConfirmationDetails) => {
                                if (confirmationDetails) {
                                    dispatch({
                                        type: "ADD_NOTIFICATION",
                                        message:
                                            "A confirmation email was sent to " +
                                            confirmationDetails.newEmailAddress +
                                            ".",
                                        style: NotificationStyle.Success,
                                        autohide: true,
                                        canDismiss: true
                                    });
                                    dispatch({
                                        type: "SAVE_EMAIL_COMPLETE",
                                        confirmationDetails,
                                        succeeded: true,
                                        err: null
                                    });
                                }
                            },
                            () => null
                        );
                });
            return task;
        },
    getEmailUpdateConfirmation: (ignoreCache: boolean) => (dispatch, getState: () => AppState) => {
        const state = getState();

        if (ignoreCache || !state.profile.emailState || !state.profile.emailState.confirmationDetails) {
            dispatch({ type: "GET_EMAIL_UPDATE_CONFIRMATION_INIT" });
            dispatch(API.profile.getUpdateEmailConfirmation())
                .then(
                    (response) => {
                        if (response?.status === 200) {
                            return response.json() as Promise<EmailUpdateConfirmationDetails>;
                        } else {
                            dispatch({
                                type: "GET_EMAIL_UPDATE_CONFIRMATION_COMPLETE",
                                confirmationDetails: null,
                                succeeded: false,
                                err: "Failed to get update email confirmation"
                            });
                            return null;
                        }
                    },
                    (reason: any) => {
                        return null;
                    }
                )
                .then(
                    (confirmationDetails: EmailUpdateConfirmationDetails) => {
                        if (confirmationDetails) {
                            dispatch({
                                type: "GET_EMAIL_UPDATE_CONFIRMATION_COMPLETE",
                                confirmationDetails,
                                succeeded: true
                            });
                        }
                    },
                    () => null
                );
        }
    },
    checkIsEmployerEmailAddress: (email: string): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: "CHECK_IS_EMPLOYER_EMAIL_INIT", email });
        try {
            const response: Response = await dispatch(API.profile.checkIsEmployerEmailAddress(email));
            if (response?.status === 200) {
                const result = (await response.json()) as boolean;
                dispatch({
                    type: "CHECK_IS_EMPLOYER_EMAIL_COMPLETE",
                    isEmployerEmail: result,
                    succeeded: true
                });

                return result;
            } else {
                throw new Error(
                    `Error checking if email is an employer email address. Received response status ${response.status}`
                );
            }
        } catch (error) {
            dispatch({
                type: "CHECK_IS_EMPLOYER_EMAIL_COMPLETE",
                succeeded: false,
                error
            });
        }

        return false;
    }
};
