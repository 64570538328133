import { Button } from "@mui/material";
import { Location, LocationDescriptor } from "history";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import React from "react";

interface LinkButtonProps {
    color?: "primary" | "secondary";
    variant?: "text" | "outlined" | "contained";
    className?: string;
    disabled?: boolean;
    to: LocationDescriptor | ((location: Location<any>) => LocationDescriptor<any>);
    children?: React.ReactNode;
    target?: "_blank" | "_self" | "_parent" | null;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const LinkButton = ({ children, color, variant, className, to, disabled, target, onClick }: LinkButtonProps) => {
    const linkToPage = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
        return <RouterLink {...props} innerRef={ref as any} target={target} />;
    });

    return (
        <Button
            color={color ?? "primary"}
            disabled={disabled}
            variant={variant}
            className={className}
            component={linkToPage}
            to={to}
            onClick={onClick}>
            {children}
        </Button>
    );
};
