import * as React from "react";
import { Typography } from "@mui/material";

import { Address, Country, getCountryName, getProvinceName } from "../models";
import { ProfileAddressPlaceholder } from "./profileAddressPlaceholder";

export interface ProfileAddressViewProps {
    address: Address;
    countries: Country[];
    isLoading: boolean;
}

export class ProfileAddressView extends React.PureComponent<ProfileAddressViewProps> {
    public render() {
        return (
            <Typography variant="subtitle1" gutterBottom>
                {this.props.isLoading ? (
                    <ProfileAddressPlaceholder />
                ) : this.props.address ? (
                    <div>
                        <div>
                            <span>{this.props.address.suiteNumber ? <span id="suite-number">{this.props.address.suiteNumber}-</span> : null}</span>
                            <span>{this.props.address.street ? <span id="street-address">{this.props.address.street}</span> : null}</span>
                        </div>
                        <div>
                            <span>{this.props.address.city ? <span id="city">{this.props.address.city}, &nbsp;</span> : null}</span>
                            <span>
                                {this.props.address.provinceCode ? (
                                    <span id="province">
                                        {getProvinceName(this.props.countries, this.props.address.countryCode, this.props.address.provinceCode)}
                                        &nbsp;
                                    </span>
                                ) : null}
                            </span>
                            <span>{this.props.address.postalCode ? <span id="postal-code">{this.props.address.postalCode.toUpperCase()}</span> : null}</span>
                        </div>
                        <div>
                            <span>{this.props.address.countryCode ? <span id="country">{getCountryName(this.props.countries, this.props.address.countryCode)}</span> : null}</span>
                        </div>
                    </div>
                ) : (
                    <Typography variant="caption">Not set</Typography>
                )}
            </Typography>
        );
    }
}
