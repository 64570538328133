import { AppThunkAction } from "store";

import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";

import API from "api";
import { NetworkRequest } from "api/actions";

import { DocumentPaginationState, DocumentStats } from "./models";
import * as actions from "./actions";
import { DocumentCollectionName, DocumentCollectionPageResponse } from "api/documentsApi";

export type HandledAction = actions.GetDocumentsInitAction | actions.GetDocumentsCompleteAction | actions.TrackDocumentDownloadAction | actions.GetDocumentStatsInitAction | actions.GetDocumentStatsCompleteAction;

export type DocumentsActions = HandledAction | NetworkRequest | AddNotificationAction;

export const DocumentsActionCreators = {
    getDocuments: (collectionName: DocumentCollectionName, paginationState?: DocumentPaginationState): AppThunkAction<DocumentsActions> => (dispatch, getState) => {
        const pageNumber = paginationState ? paginationState.pageNumber : 1;
        dispatch({ type: "GET_DOCUMENTS_INIT", collectionName, pageNumber: paginationState ? paginationState.pageNumber : 1 });

        return Promise.resolve()
            .then(() => {
                return dispatch(API.documents.getDocuments(collectionName, paginationState));
            })
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<DocumentCollectionPageResponse>;
                } else {
                    dispatch({ type: "GET_DOCUMENTS_COMPLETE", collectionName, pageNumber, response: null, succeeded: false });
                    dispatch({ type: "ADD_NOTIFICATION", message: "Oops! Documents aren't available right now. Please try again later.", style: NotificationStyle.Error, autohide: true, canDismiss: true });
                }
            })
            .then((response: DocumentCollectionPageResponse) => {
                if (response) {
                    dispatch({ type: "GET_DOCUMENTS_COMPLETE", collectionName, pageNumber, response, succeeded: true });
                }
                return response;
            });
    },
    trackDocumentDownload: (collectionName: DocumentCollectionName) => dispatch => {
        dispatch({ type: "TRACK_DOCUMENT_DOWNLOAD", collectionName });
    },
    getDocumentStats: (): AppThunkAction<DocumentsActions> => (dispatch, getState) => {
        dispatch({ type: "GET_DOCUMENT_STATS_INIT" });
        return dispatch(API.documents.getDocumentStats())
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<DocumentStats>;
                } else {
                    dispatch({ type: "GET_DOCUMENT_STATS_COMPLETE", documentStats: null, succeeded: false });
                }
            })
            .then((documentStats: DocumentStats) => {
                if (documentStats != null) {
                    dispatch({ type: "GET_DOCUMENT_STATS_COMPLETE", documentStats, succeeded: true });
                }
                return null;
            });
    }
};
