import React from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { Redirect, useLocation } from "react-router-dom";

import { ApplicationState } from "store";

// Higher order component (HOC) to redirect to login page
// if user is not authenticated and tries to navigate a page that requires authentication
export function SecuredPage<ChildComponentProps>(ChildComponent: React.ComponentType<ChildComponentProps>) {
  return function WrappedComponent(props: ChildComponentProps) {
    const isAuthenticated = useAppSelector((state: ApplicationState) => state.auth.session && !!state.auth.session.userId);
    const location = useLocation();
    
    if (!isAuthenticated) {
      return <Redirect to={{ 
        pathname: "/login",
        state: {
          from: location
        }
       }} />;
    }

    return <ChildComponent {...props} />;
  };
}

export default SecuredPage;
