import * as React from "react";

import { Avatar, ButtonBase } from "@mui/material";
import { BenefitCode } from "modules/benefits/models";
import { SpendingAccountType } from "modules/benefits/spendingAccounts/models";
import { colorVariant } from "./colorVariant";

export const getBenefitColor = (code: BenefitCode): colorVariant => {
    switch (code) {
        case BenefitCode.AllBenefits:
            return "benefit";
        case BenefitCode.Dental:
            return "dental";
        case BenefitCode.Vision:
            return "vision";
        case BenefitCode.EHC:
            return "ehc";
        case BenefitCode.HSA:
            return "hsa";
        case BenefitCode.WSA:
            return "wsa";
        case BenefitCode.AccidentalDeathAndDismemberment:
        case BenefitCode.ExtendedDisability:
        case BenefitCode.Life:
            return "other";
        default:
            return "ehc";
    }
};

export const getSpendingAccountColor = (code: SpendingAccountType): colorVariant => {
    switch (code) {
        case SpendingAccountType.HSA:
            return "hsa";
        case SpendingAccountType.WSA:
            return "wsa";
        default:
            return "ehc";
    }
};

type sizeVariant = "sm" | "md" | "lg" | "xl";

interface ComponentProps {
    children: JSX.Element;
    color?: colorVariant;
    muted?: boolean;
    size?: sizeVariant;
    className?: string;
    onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
}

const ColoredAvatar= (props: ComponentProps) => {
    const colorStyles = {
        "0": { backgroundColor: "rgba(0,0,0,0)" },
        "1": { backgroundColor: "#F44336" },
        "2": { backgroundColor: "#E53935" },
        "3": { backgroundColor: "#E91E63" },
        "4": { backgroundColor: "#D81B60" },
        "5": { backgroundColor: "#9C27B0" },
        "6": { backgroundColor: "#8E24AA" },
        "7": { backgroundColor: "#673AB7" },
        "8": { backgroundColor: "#5E35B1" },
        "9": { backgroundColor: "#3F51B5" },
        "10": { backgroundColor: "#3949AB" },
        "11": { backgroundColor: "#2196F3" },
        "12": { backgroundColor: "#1E88E5" },
        "13": { backgroundColor: "#009688" },
        "14": { backgroundColor: "#00897B" },
        "15": { backgroundColor: "#43A047" },
        "16": { backgroundColor: "#388E3C" },

        ehc: { backgroundColor: "#2196F3" },
        dental: { backgroundColor: "#D81B60" },
        hsa: { backgroundColor: "#9C27B0" },
        wsa: { backgroundColor: "#00897B" },
        rrsp: { backgroundColor: "#552a0e" },
        tfsa: { backgroundColor: "#498900" },
        vision: { backgroundColor: "#EFA725" },
        benefit: { backgroundColor: "#817C00" },
        other: { backgroundColor: "#595959" },
    };

    const sizeStyles: Record<sizeVariant, React.CSSProperties> = {
        sm: { width: "20px", height: "20px", fontSize: "24px" },
        md: { width: "32px", height: "32px", fontSize: "32px" },
        lg: { width: "36px", height: "36px", fontSize: "36px" },
        xl: { width: "64px", height: "64px" },
    };

    return props.onClick ? (
        <ButtonBase
            id="avatar-button"
            onClick={props.onClick}
            className={`avatar-icon-container clickable-avatar ${props.className ? props.className : ""}`}
            sx={{
                ".avatar": {
                    ...sizeStyles[props.size || "sm"],
                    backgroundColor: colorStyles[props.color || "0"],
                    opacity: props.muted ? 0.5 : 1,
                },
            }}
        >
            <Avatar className={`avatar ${props.muted ? "muted" : ""} ${props.color ? "has-color" : ""} ${props.size}`}>{props.children}</Avatar>
        </ButtonBase>
    ) : (
        <div className={`avatar-icon-container ${props.className ? props.className : ""}`}>
            <Avatar
                className={`avatar ${props.muted ? "muted" : ""} ${props.color ? "has-color" : ""} ${props.size}`}
                sx={{
                    ...sizeStyles[props.size || "sm"],
                    backgroundColor: colorStyles[props.color || "0"],
                    opacity: props.muted ? 0.5 : 1,
                }}
            >
                {props.children}
            </Avatar>
        </div>
    );
};

export default ColoredAvatar;
