import * as React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Grid, Card, CardContent, Typography, CardActions, Button } from "@mui/material";

import { Markdown } from "modules/common/components/misc";
import { FeatureArticlePreview, FeatureToggles, FeatureToggleValue } from "../models";
import { PilotChip } from "./pilotChip";

export interface FeatureArticleListProps {
    articles: FeatureArticlePreview[];
    featureToggles: FeatureToggles;
    isFetching: boolean;
}

export class FeatureArticleList extends React.Component<FeatureArticleListProps> {
    public render() {
        const { articles, featureToggles, isFetching } = this.props;

        const availableFeatureArticles =
            articles && articles.length ? articles.filter(article => article.isPublished && (!featureToggles[article.toggleableFeatureKey] || featureToggles[article.toggleableFeatureKey].toggleValue === FeatureToggleValue.On)) : [];

        return (
            <div className="stretched-content">
                <Grid container spacing={0} className="whats-new-list">
                    {isFetching ? <div>Fetching...</div> : null}
                    {!isFetching && availableFeatureArticles.length
                        ? availableFeatureArticles.map(article => {
                              const detailLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
                                  return <RouterLink {...props} innerRef={ref as any} />;
                              });
                              const featureToggle = article.toggleableFeatureKey ? featureToggles[article.toggleableFeatureKey] : null;

                              return (
                                  <Grid key={article.id} item md={12}>
                                      <Card className="whats-new-list-item">
                                          <CardContent>
                                              <Typography gutterBottom variant="h5" component="h2">
                                                  {article.title} {featureToggle && featureToggle.isPilotOnly ? <PilotChip /> : null}
                                              </Typography>
                                              <Markdown source={article.preview} />
                                          </CardContent>
                                          <CardActions>
                                              <Button size="small" color="primary" component={detailLink} to={`/whats-new/${article.id}`}>
                                                  Learn More
                                              </Button>
                                          </CardActions>
                                      </Card>
                                  </Grid>
                              );
                          })
                        : null}

                    {!isFetching && !availableFeatureArticles.length ? (
                        <Grid item md={12}>
                            <Card className="whats-new-list-item">
                                <CardContent>Nothing new at the moment. Check back here for information on new features as they arrive!</CardContent>
                            </Card>
                        </Grid>
                    ) : null}
                </Grid>
            </div>
        );
    }
}
