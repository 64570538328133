import { Reducer } from "redux";

import { HandledAction } from "modules/profile/email/actionCreator";
import { ProfileEmailState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<ProfileEmailState> = (state: ProfileEmailState, action: KnownAction): ProfileEmailState => {
    switch (action.type) {
        case "SAVE_EMAIL_INIT": {
            return {
                ...state,
                isSaving: true
            };
        }
        case "SAVE_EMAIL_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    isSaving: false,
                    confirmationDetails: action.confirmationDetails,
                    errorMessage: null
                };
            } else {
                return {
                    ...state,
                    isSaving: false,
                    errorMessage: action.err
                };
            }
        }
        case "GET_EMAIL_UPDATE_CONFIRMATION_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_EMAIL_UPDATE_CONFIRMATION_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                isSaving: false,
                confirmationDetails: action.confirmationDetails,
                errorMessage: null
            };
        }
        case "CHECK_IS_EMPLOYER_EMAIL_INIT": {
            return {
                ...state,
                isEmployerEmailCheck: {
                    ...state.isEmployerEmailCheck,
                    email: action.email,
                    isFetching: true,
                }
            }
        }
        case "CHECK_IS_EMPLOYER_EMAIL_COMPLETE": {
            return {
                ...state,
                isEmployerEmailCheck: {
                    ...state.isEmployerEmailCheck,
                    isEmployerEmail: action.succeeded ? action.isEmployerEmail : false,
                    error: action.succeeded ? null : action.error,
                    isFetching: false
                }
            }
        }
    }
    return (
        state ||
        ({
            isSaving: false,
            isFetching: false,
            confirmationDetails: null,
            isEmployerEmailCheck: null,
            errorMessage: null
        } as ProfileEmailState)
    );
};
