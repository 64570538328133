import * as React from "react";
import { Accordion, AccordionSummary, Grid, Typography, Button, AccordionDetails } from "@mui/material";

import { BankAccountForm } from "./bankAccountForm";
import { ProfileBankAccountView } from "./profileBankAccountView";
import { Bank, BankAccount, BankAccountRemoval, BankAccountType } from "../models";

export interface ProfileBankAccountProps {
    bankAccount: BankAccount;
    isSaving: boolean;
    saveProfileBankAccount: (bankAccount: BankAccount) => any;
    banks: Bank[];
    bankAccountTypeId: BankAccountType;
    removeProfileBankAccount: (bankAccountRemoval: BankAccountRemoval) => any;
    toolTip: string;
    isReadOnly: boolean;
    isRemoving: boolean;
    isLoading: boolean;
}

export interface ProfileBankAccountState {
    isExpanded: boolean;
}

export class ProfileBankAccountSection extends React.Component<ProfileBankAccountProps, ProfileBankAccountState> {
    public state: ProfileBankAccountState = {
        isExpanded: false
    };

    public componentDidUpdate(prevProps: ProfileBankAccountProps) {
        const { isSaving, isRemoving, bankAccount } = this.props;
        const { isSaving: prevIsSaving, isRemoving: prevIsRemoving, bankAccount: prevBankAccount } = prevProps;

        const wasBeingSaved = !isSaving && prevIsSaving !== isSaving;
        const wasBeingRemoved = !isRemoving && prevIsRemoving !== isRemoving;
        const bankAccountHasChanged = prevBankAccount !== bankAccount;

        if ((wasBeingSaved || wasBeingRemoved) && bankAccountHasChanged && this.state.isExpanded) {
            this.collapsePanel();
        }
    }

    public render() {
        return (
            <Accordion expanded={this.state.isExpanded} className="profile-line">
                <AccordionSummary classes={{ disabled: "panel-summary disabled", root: "panel-summary" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6">
                                {this.props.bankAccountTypeId === BankAccountType.Deposit ? "Deposit" : "Withdrawal"}
                                &nbsp;bank account
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={this.state.isExpanded ? 9 : 8} className="bank-account-view">
                            <div>
                                <ProfileBankAccountView
                                    bankAccount={this.props.bankAccount}
                                    banks={this.props.banks}
                                    isLoading={this.props.isLoading}
                                    isReadOnly={this.props.isReadOnly}
                                />
                                <Typography component="div" className="tool-tip">
                                    {this.props.toolTip}
                                </Typography>
                            </div>
                        </Grid>
                        {!this.state.isExpanded ? (
                            <Grid item xs={12} md={1} className="panel-summary-action">
                                <Button
                                    id={"edit-bank-account-button-" + this.props.bankAccountTypeId}
                                    variant="outlined"
                                    onClick={this.expandPanel}
                                    disabled={this.props.isLoading || this.props.isReadOnly}>
                                    {this.props.bankAccount ? "Edit" : "Add"}
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {this.state.isExpanded ? (
                        <BankAccountForm
                            profileBankAccount={this.props.bankAccount}
                            banks={this.props.banks}
                            onSaveBankAccountRequested={this.props.saveProfileBankAccount}
                            onCancel={this.collapsePanel}
                            isSaving={this.props.isSaving}
                            bankAccountTypeId={this.props.bankAccountTypeId}
                            isRemoving={this.props.isRemoving}
                            isReadOnly={this.props.isReadOnly}
                            onRemoveBankAccountRequested={this.props.removeProfileBankAccount}
                        />
                    ) : null}
                    <></>
                </AccordionDetails>
            </Accordion>
        );
    }

    private expandPanel = () => {
        this.setState((s) => ({ ...s, isExpanded: true }));
    };

    private collapsePanel = () => {
        this.setState((s) => ({ ...s, isExpanded: false }));
    };
}
