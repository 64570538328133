import { AppInsightsLogger } from "./appInsightsLogger";
import { GoogleAnalyticsLogger } from "./googleAnalyticsLogger";

const featureUsageWithSucceeded = (eventName: "FeatureUsage-Claims-NewClaimSubmitted", succeeded: boolean) => {
    AppInsightsLogger.trackEvent({ name: eventName }, { name: eventName, succeeded });
};

/** MyAsebp logger to App Insights and Google Analytics */
export const Log = {
    addClaimAttachmentFailed: (error: string) => {
        const eventName = "Add attachment failed";
        AppInsightsLogger.trackEvent({ name: eventName }, { error });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, error);
    },
    addClaimAttachmentSucceeded: () => {
        const eventName = "Added attachment";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    claimFieldValueSet: (fieldName: string) => {
        const eventName = "Set claim field value";
        AppInsightsLogger.trackEvent({ name: eventName }, { field: fieldName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, fieldName);
    },
    claimsFilterUpdated: (
        view: string,
        membershipType: string | null,
        benefitType: string | null,
        pageNumber: number,
        patientCertificateNumber: string | null,
        searchText: string | null
    ) => {
        const props = {
            view,
            membershipType,
            benefitType,
            pageNumber: pageNumber?.toString(),
            patientCertificateNumber,
            searchText
        };

        const eventName = "Updated claim filter";
        AppInsightsLogger.trackEvent({ name: eventName }, props);
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    createDraftClaimFailed: (reason: "Failed validation" | "Error") => {
        const eventName = "Save claim failed";
        AppInsightsLogger.trackEvent({ name: eventName }, { reason });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, reason);
    },
    createDraftClaimSucceeded: () => {
        const eventName = "Saved claim";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    deleteClaimAttachmentFailed: (error: string) => {
        const eventName = "Delete attachment failed";
        AppInsightsLogger.trackEvent({ name: eventName }, { error });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, error);
    },
    deleteClaimAttachmentSucceeded: () => {
        const eventName = "Deleted attachment";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    deleteClaimFailed: () => {
        const eventName = "Delete claim failed";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    deleteClaimSucceeded: () => {
        const eventName = "Deleted claim";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    discardClaimFailed: () => {
        const eventName = "Discard claim failed";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    discardClaimSucceeded: (numberOfDuplicatesFound: number) => {
        const eventName = "Discarded claim";
        AppInsightsLogger.trackEvent(
            { name: eventName },
            { numberOfDuplicatesFound: numberOfDuplicatesFound.toString() }
        );
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    dismissClaimFailed: (reference: string, dismissActionType: string, error: string) => {
        const props = {
            reference,
            dismissActionType,
            error
        };
        const eventName = "Dismissed actionable claim failed";
        AppInsightsLogger.trackEvent({ name: eventName }, props);
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, error);
    },
    dismissClaimSucceeded: (reference: string, dismissActionType: string) => {
        const props = {
            reference,
            dismissActionType
        };
        const eventName = "Dismissed actionable claim";
        AppInsightsLogger.trackEvent({ name: eventName }, props);
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, dismissActionType);
    },
    getClaimDetailsFailed: (referenceId: string, error: string) => {
        const eventName = "View claim details failed";
        AppInsightsLogger.trackEvent({ name: eventName }, { referenceId, error });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, referenceId);
    },
    getClaimDetailsSucceeded: (referenceId: string) => {
        const eventName = "Viewed claim details";
        AppInsightsLogger.trackEvent({ name: eventName }, { referenceId });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, referenceId);
    },
    getDuplicateClaimsComplete: (forTransfer: boolean, succeeded: boolean, numberOfClaims: number) => {
        const eventName = forTransfer
            ? "Transfer to HSA: Number of possible duplicate claims"
            : `Submit claim: Duplicates - ${succeeded ? "complete" : "failed"}`;

        const props = {
            numberOfClaims: numberOfClaims.toString()
        };
        AppInsightsLogger.trackEvent({ name: eventName }, props);
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    markClaimAsAlreadyTransferredComplete: (referenceId: string) => {
        const eventName = "Transfer to HSA: Claim was marked as already transferred to HSA";
        const props = {
            referenceId
        };
        AppInsightsLogger.trackEvent({ name: eventName }, props);
        GoogleAnalyticsLogger.trackEvent(eventName, eventName);
    },
    startedNewClaim: () => {
        const eventName = "Started new claim";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
    },
    submitNewClaimFailed: () => {
        const eventName = "Submit claim failed";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
        featureUsageWithSucceeded("FeatureUsage-Claims-NewClaimSubmitted", false);
    },
    submitNewClaimSucceeded: (secondsToComplete: number, numberOfDuplicatesFound: number) => {
        const eventName = "Submitted claim";
        AppInsightsLogger.trackEvent(
            { name: eventName },
            {
                secondsToComplete: secondsToComplete.toString(),
                numberOfDuplicatesFound: numberOfDuplicatesFound.toString()
            }
        );
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, secondsToComplete.toString());
        featureUsageWithSucceeded("FeatureUsage-Claims-NewClaimSubmitted", true);
    },
    transferToHsaClaimFailed: (referenceId: string, duplicateWarningShown: boolean, error: string) => {
        const props = {
            referenceId,
            duplicateWarningShown,
            error
        };
        const eventName = "Transferred claim to HSA failed";
        AppInsightsLogger.trackEvent({ name: eventName }, props);
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, referenceId);
    },
    transfertoHsaClaimSucceeded: (referenceId: string, duplicateWarningShown: boolean) => {
        const props = {
            referenceId,
            duplicateWarningShown,
            error: null
        };
        const eventName = "Transferred claim to HSA";
        AppInsightsLogger.trackEvent({ name: eventName }, props);
        GoogleAnalyticsLogger.trackEvent("Claims", eventName, referenceId);
    },
    transferToHsaDialogOpened: () => {
        const eventName = "Viewed transfer to HSA dialog on claim details";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
        GoogleAnalyticsLogger.trackModalView();
    },
    transferToHsaDialogClosed: () => {
        const eventName = "Closed transfer to HSA dialog on claim details";
        AppInsightsLogger.trackEvent({ name: eventName });
        GoogleAnalyticsLogger.trackEvent("Claims", eventName);
        GoogleAnalyticsLogger.trackModalView();
    }
};
