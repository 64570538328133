import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop(props: React.PropsWithChildren): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return <>{props.children}</>;
}

export default ScrollToTop;
