import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";

import { SiteArea, SiteNode } from "routeProvider";
import { AppInsightsLogger } from "modules/logging/appInsightsLogger";

export interface SubNavProps {
    currentPath: string;
    area: SiteArea;
}

export class SubNav extends React.PureComponent<SubNavProps> {
    public render() {
        const { currentPath, area } = this.props;

        const handleNavClick = (siteNode: SiteNode) => {
            const logName = siteNode.displayName.replace(/\s/g, "");
            AppInsightsLogger.trackEvent(
                { name: `FeatureUsage-SubNav-${logName}` },
                { basePath: siteNode.basePath, routingPath: siteNode.routingPath, displayName: siteNode.displayName }
            );
        };

        return (
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <nav className={`sub-nav secondary-color`}>
                    <ul>
                        {area.pages
                            .filter((n) => !!n.basePath)
                            .map((n) => {
                                const isSelected = n.highlightExactMatchOnly
                                    ? currentPath.toLowerCase() === n.basePath.toLowerCase()
                                    : currentPath.toLowerCase().indexOf(n.basePath) === 0;

                                return (
                                    <li key={n.basePath}>
                                        <RouterLink
                                            key={n.basePath}
                                            to={n.basePath}
                                            className={`${isSelected ? "active" : ""}`}
                                            onClick={() => handleNavClick(n)}>
                                            {n.displayName}
                                        </RouterLink>
                                    </li>
                                );
                            })}
                    </ul>
                </nav>
            </Box>
        );
    }
}
