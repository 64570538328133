import React from "react";
import { InputBase, IconButton, Box } from "@mui/material";

interface SearchBaitProps {
  searchText: string;
  onClick: () => any;
  className?: string;
}

class SearchBait extends React.Component<SearchBaitProps> {
  public render() {
    const { searchText, className } = this.props;
    return <>
        <IconButton
          onClick={this.props.onClick}
          className={className}
          size="large"
          sx={{
            display: {xs: 'block', md: 'none'}, 
            color: "#FFF",
            "& .material-icons": {
              fontSize: 48,
            },
          }}
        >
          <i className="material-icons">search</i>
        </IconButton>
        <Box
          className={className}
          sx={{
            display: { xs: 'none', md: 'block'},
            position: "relative",
            borderRadius: "borderRadius",
            backgroundColor: "#F0F0F0",
            border: t => `1px solid ${t.palette.common.white}`,
            "&:hover": {
              backgroundColor: "#F1F1F1",
              cursor: "pointer",
            },
            marginRight: theme => theme.spacing(2),
            marginLeft: 0,
            width: "100%",
            "@media (min-width: 600px)": {
              marginLeft: theme => theme.spacing(3),
              width: "auto",
            },
          }}
        >
          <Box
            sx={{
              width: theme => theme.spacing(7),
              height: "100%",
              position: "absolute",
              pointerEvents: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i className="material-icons">search</i>
          </Box>
          <InputBase
            placeholder={searchText || "Search"}
            sx={{
              color: "inherit",
              width: "100%",
              "@media (min-width: 960px)": {
                width: 260,
              },
              "&:hover": {
                cursor: "pointer",
              },
            }}
            inputProps={{ "aria-label": "Search", sx: { paddingLeft: "4rem", paddingRight: "0.5rem" } }}
            onClick={this.props.onClick}
            onFocus={(e) => e.currentTarget.blur()}
            value=""
          />
        </Box>
    </>;
  }
}

export default SearchBait;
