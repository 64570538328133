import { Address } from "modules/profile/address/models";
import { BankAccount, BankAccountRemoval } from "modules/profile/bankAccount/models";
import { ProfilePhoneNumbers } from "modules/profile/phoneNumbers/models";
import { NetworkRequest } from "./actions";

export const getCountries = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/countries/",
        params: {}
    };
};

export const getBanks = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/banks",
        params: {}
    };
};

export const getAddress = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/profile/address`,
        params: {}
    };
};

export const saveAddress = (address: Address): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/profile/address`,
        body: address
    };
};

export const getPhoneNumbers = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/profile/phoneNumbers`,
        params: {}
    };
};

export const savePhoneNumbers = (phoneNumbers: ProfilePhoneNumbers): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/profile/phoneNumbers`,
        body: phoneNumbers
    };
};

export const getBankAccounts = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/profile/bankAccounts",
        params: {}
    };
};

export const saveBankAccount = (bankAccount: BankAccount): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/profile/bankAccounts",
        body: bankAccount
    };
};
export const removeBankAccount = (bankAccountRemoval: BankAccountRemoval): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "DELETE",
        url: `/api/profile/bankAccounts/${bankAccountRemoval.bankAccountTypeId}`,
        body: bankAccountRemoval,
        headers: { "Content-Type": "application/json" }
    };
};
export const updateEmail = (newEmailAddress: string, password: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/profile/email`,
        body: {
            newEmailAddress,
            password
        },
        doNotEnsureToken: true
    };
};

export const getUpdateEmailConfirmation = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/profile/email/confirmationDetails`,
        doNotEnsureToken: true
    };
};

export const checkIsPersonalInfoVerificationDue = (): NetworkRequest => ({
    type: "NETWORK_REQUEST",
    method: "GET",
    url: "/api/profile/isPersonalInfoVerificationDue"
});

export const verifyPersonalInfo = (): NetworkRequest => ({
    type: "NETWORK_REQUEST",
    method: "POST",
    url: "/api/profile/verifyPersonalInfo"
});

export const checkIsEmployerEmailAddress = (email: string): NetworkRequest => ({
    type: "NETWORK_REQUEST",
    method: "GET",
    url: "/api/profile/email/isEmployerEmail",
    queryParams: { email }
})
