import * as React from "react";

export interface PlaceholderBoxProps {
    color?: "extralight" | "light" | "dark";
    size?: "small" | "large" | "full";
    borderRadius?: string;
    className?: string;
}

export class PlaceholderBox extends React.PureComponent<PlaceholderBoxProps> {
    public render() {
        const color = this.props.color || "light";
        const size = this.props.size || "small";
        const borderRadius = this.props.borderRadius || "none";

        return <div className={`placeholder-box ${color} ${size} ${this.props.className || ""}`} style={{ borderRadius }} />;
    }
}
