import { Reducer } from "redux";
import { AuthState } from "./models";
import { HandledAction } from "modules/auth/actionCreator";

export type KnownAction = HandledAction;

export const reducer: Reducer<AuthState> = (state: AuthState, action: KnownAction) => {
    switch (action.type) {
        // Part of the auth state is populated server side,
        // so when the app bootstraps on the client,
        // we will make sure the login state is present
        case "@@INIT": {
            return {
                ...state,
                login: {
                    isFetching: false,
                    message: null,
                    secondaryMessage: null,
                    nonPilotUserLoggedIn: false,
                    artaLoginUrl: null
                }
            };
        }
        case "LOG_IN_INIT": {
            return {
                ...state,
                login: {
                    ...state.login,
                    isFetching: true,
                    message: null,
                    secondaryMessage: null,
                    nonPilotUserLoggedIn: false,
                    artaLoginUrl: null
                }
            };
        }
        case "LOG_IN_COMPLETE": {
            return {
                ...state,
                login: {
                    ...state.login,
                    isFetching: false,
                    message: action.authResponse.message,
                    secondaryMessage: action.authResponse.secondaryMessage,
                    nonPilotUserLoggedIn: action.nonPilotUserLoggedIn,
                    artaLoginUrl: action.authResponse.artaLoginUrl
                },
                session: {
                    ...state.session,
                    userId: action.authResponse.userId,
                    accessTokenExpiry: action.authResponse.succeeded
                        ? action.authResponse.accessTokenExpiry
                        : state.session.accessTokenExpiry,
                    antiforgeryToken: action.authResponse.succeeded
                        ? action.authResponse.antiforgeryToken
                        : state.session.antiforgeryToken,
                    idle: false,
                    emulatedSession: action.authResponse.emulatedSession
                }
            };
        }
        case "REAUTHENTICATE_INIT": {
            return {
                ...state,
                login: {
                    ...state.login,
                    isFetching: true,
                    message: null,
                    secondaryMessage: null,
                    nonPilotUserLoggedIn: false,
                    artaLoginUrl: null
                }
            };
        }
        case "REAUTHENTICATE_COMPLETE": {
            return {
                ...state,
                login: {
                    ...state.login,
                    isFetching: false,
                    message: action.authResponse.message,
                    secondaryMessage: action.authResponse.secondaryMessage,
                    nonPilotUserLoggedIn: false,
                    artaLoginUrl: null
                },
                session: action.authResponse.succeeded
                    ? {
                          ...state.session,
                          userId: action.authResponse.userId,
                          accessTokenExpiry: action.authResponse.accessTokenExpiry,
                          antiforgeryToken: action.authResponse.antiforgeryToken,
                          idle: false
                      }
                    : state.session
            };
        }
        case "REFRESH_ACCESS_TOKEN_INIT": {
            return state;
        }
        case "REFRESH_ACCESS_TOKEN_COMPLETE": {
            if (action.authResponse.succeeded) {
                return {
                    ...state,
                    session: {
                        ...state.session,
                        userId: action.authResponse.userId,
                        accessTokenExpiry: action.authResponse.accessTokenExpiry,
                        antiforgeryToken: action.authResponse.antiforgeryToken
                    }
                };
            }
            return state;
        }
        case "SET_USER_IDLE": {
            return {
                ...state,
                session: {
                    ...state.session,
                    idle: action.idle
                }
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            login: {
                isFetching: false,
                message: null,
                secondaryMessage: null,
                nonPilotUserLoggedIn: false,
                artaLoginUrl: null
            },
            session: {
                userId: null,
                accessTokenExpiry: 0,
                inactiveReauthRequiredMs: 0,
                inactiveForcedLogoutMs: 0,
                idle: false
            }
        } as AuthState)
    );
};
