import { Reducer } from "redux";
import { RegistrationState, NewMemberInfo, NewMemberAccountDetailsInfo } from "./models";
import { KnownAction } from "./actionCreator";

function CreateEmptyRegistrationState(): RegistrationState {
    const emptyRegistrationState = {
        newMemberInfo: {
            certificateNumber: "",
            dateOfBirth: null,
            fullName: "",
            hasAgreedToTermsAndConditions: false
        } as NewMemberInfo,
        newMemberAccountDetailsInfo: {
            emailAddress: "",
            password: ""
        } as NewMemberAccountDetailsInfo,
        isVerified: false,
        isAccountRegistered: false,
        isCancelled: false,
        memberVerificationErrors: null,
        accountDetailErrors: null,
        isMemberInfoDirty: false,
        isAccountDetailsDirty: false,
        isFetching: false,
        isPasswordStrongEnough: true,
        isEmailAddressAvailable: true
    } as RegistrationState;

    return emptyRegistrationState;
}

export const reducer: Reducer<RegistrationState> = (state: RegistrationState, action: KnownAction) => {
    switch (action.type) {
        case "VERIFY_NEW_MEMBER_INIT": {
            return {
                ...state,
                isFetching: true,
                isPasswordStrongEnough: true
            };
        }
        case "VERIFY_NEW_MEMBER_COMPLETE": {
            return {
                ...state,
                isVerified: action.succeeded,
                memberVerificationErrors: action.memberVerificationErrors,
                accountDetailErrors: null,
                isFetching: false
            };
        }
        case "SET_NEW_MEMBER_INFO": {
            const newMemberInfo = {
                ...state.newMemberInfo,
                certificateNumber: action.certificateNumber.trim(),
                fullName: action.fullName,
                dateOfBirth: action.dateOfBirth,
                hasAgreedToTermsAndConditions: action.hasAgreedToTermsAndConditions
            };

            const isMemberInfoDirty = JSON.stringify(newMemberInfo) !== JSON.stringify(CreateEmptyRegistrationState().newMemberInfo);

            const isVerified = !isMemberInfoDirty && state.isVerified;

            return {
                ...state,
                isVerified: isVerified,
                isMemberInfoDirty: isMemberInfoDirty,
                memberVerificationErrors: null,
                newMemberInfo: newMemberInfo
            };
        }
        case "CANCEL_NEW_MEMBER_REGISTRATION": {
            return {
                ...CreateEmptyRegistrationState(),
                isCancelled: true
            };
        }
        case "INITIALIZE_NEW_MEMBER_REGISTRATION": {
            return {
                ...CreateEmptyRegistrationState()
            };
        }
        case "SET_ACCOUNT_DETAILS": {
            const newMemberAccountDetailsInfo = {
                ...state.newMemberAccountDetailsInfo,
                emailAddress: action.emailAddress,
                password: action.password
            };

            const isAccountDetailsDirty = JSON.stringify(newMemberAccountDetailsInfo) !== JSON.stringify(CreateEmptyRegistrationState().newMemberAccountDetailsInfo);

            const isAccountRegistered = !isAccountDetailsDirty && state.isAccountRegistered;

            return {
                ...state,
                accountDetailErrors: null,
                isAccountRegistered: isAccountRegistered,
                isAccountDetailsDirty: isAccountDetailsDirty,
                newMemberAccountDetailsInfo: newMemberAccountDetailsInfo,
                isPasswordStrongEnough: true,
                isEmailAddressAvailable: true
            };
        }
        case "BACK_TO_MEMBER_VERIFICATION": {
            return {
                ...state,
                accountDetailErrors: null,
                isPasswordStrongEnough: true,
                isEmailAddressAvailable: true
            };
        }
        case "FINALIZE_MEMBER_REGISTRATION_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "FINALIZE_MEMBER_REGISTRATION_COMPLETE": {
            return {
                ...state,
                isAccountRegistered: action.succeeded,
                accountDetailErrors: action.accountDetailErrors,
                isPasswordStrongEnough: action.isPasswordStrongEnough,
                isEmailAddressAvailable: action.isEmailAddressAvailable,
                isFetching: false
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || CreateEmptyRegistrationState();
};
