import { AlertState } from "modules/alerts/models";
import { reducer as AlertsReducer } from "modules/alerts/reducer";
import { AuthState } from "modules/auth/models";
import { reducer as AuthReducer } from "modules/auth/reducer";
import { BenefitsState } from "modules/benefits/models";
import { reducer as BenefitsReducer } from "modules/benefits/reducer";
import { CampaignsState } from "modules/campaigns/models";
import { reducer as CampaignsReducer } from "modules/campaigns/reducer";
import { ClaimsState } from "modules/claims/models";
import { reducer as ClaimsReducer } from "modules/claims/reducer";
import { ConfigState } from "modules/config/models";
import { reducer as ConfigReducer } from "modules/config/reducer";
import { ContactState } from "modules/contact/models";
import { reducer as ContactReducer } from "modules/contact/reducer";
import { DashboardState } from "modules/dashboard/models";
import { reducer as DashboardReducer } from "modules/dashboard/reducer";
import { DocumentsState } from "modules/documents/models";
import { reducer as DocumentsReducer } from "modules/documents/reducer";
import { FeaturesState } from "modules/features/models";
import { reducer as FeaturesReducer } from "modules/features/reducer";
import { FeedbackFormState } from "modules/feedback/models";
import { reducer as FeedbackReducer } from "modules/feedback/reducer";
import { NoticesState } from "modules/notices/models";
import { reducer as NoticesReducer } from "modules/notices/reducer";
import { NotificationsState } from "modules/notifications/models";
import { reducer as NotificationsReducer } from "modules/notifications/reducer";
import { PersonState } from "modules/person/models";
import { reducer as PersonReducer } from "modules/person/reducer";
import { ProfileState } from "modules/profile/models";
import { reducer as ProfileReducer } from "modules/profile/reducer";
import { SearchState } from "modules/search/models";
import { reducer as SearchReducer } from "modules/search/reducer";
import { ServiceProvidersState } from "modules/serviceProviders/models";
import { reducer as ServiceProvidersReducer } from "modules/serviceProviders/reducer";
import { PreferencesState } from "modules/preferences/models";
import { reducer as PreferencesReducer } from "modules/preferences/reducer";
import { InboxState } from "modules/inbox/models";
import { reducer as InboxReducer } from "modules/inbox/reducer";

export interface ApplicationState {
    alerts: AlertState;
    auth: AuthState;
    benefits: BenefitsState;
    campaigns: CampaignsState;
    claims: ClaimsState;
    config: ConfigState;
    contact: ContactState;
    dashboard: DashboardState;
    documents: DocumentsState;
    features: FeaturesState;
    feedbackForm: FeedbackFormState;
    inbox: InboxState
    notices: NoticesState;
    notifications: NotificationsState;
    person: PersonState;
    profile: ProfileState;
    search: SearchState;
    serviceProviders: ServiceProvidersState;
    preferences: PreferencesState;
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = (
    dispatch: (action: TAction | AppThunkAction<TAction>) => Promise<any>,
    getState: () => ApplicationState
) => any;

export const reducers = {
    alerts: AlertsReducer,
    auth: AuthReducer,
    benefits: BenefitsReducer,
    campaigns: CampaignsReducer,
    claims: ClaimsReducer,
    config: ConfigReducer,
    contact: ContactReducer,
    dashboard: DashboardReducer,
    documents: DocumentsReducer,
    features: FeaturesReducer,
    feedbackForm: FeedbackReducer,
    notices: NoticesReducer,
    notifications: NotificationsReducer,
    person: PersonReducer,
    profile: ProfileReducer,
    search: SearchReducer,
    serviceProviders: ServiceProvidersReducer,
    preferences: PreferencesReducer,
    inbox: InboxReducer
};

