import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { MembershipCardActionCreators } from "modules/benefits/membershipCard/actionCreator";
import { CommonActionCreators } from "modules/common/actionCreator";
import { HeaderContent } from "modules/common/components/layout";
import { FeaturesActionCreators } from "modules/features/actionCreator";
import { FeatureToggles, FeatureArticle, UserActivity, UserActivityKeys } from "modules/features/models";
import { ApplicationState } from "store";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";
import { getCoveredMemberSummary } from "modules/person/coveredMemberSummary";

class Header extends React.Component<ComponentProps> {
    public render() {
        const { coveredMember, isAuthenticated, isEmulated, pathname, featureToggles, featureArticles, hasUnreadFeatureArticles, searchText } = this.props;
        return (
            <HeaderContent
                isAuthenticated={isAuthenticated}
                isEmulated={isEmulated}
                coveredMember={coveredMember}
                pathname={pathname}
                featureToggles={featureToggles}
                featureArticles={featureArticles}
                showMembershipCard={this.props.showMembershipCard}
                hasUnreadFeatureArticles={hasUnreadFeatureArticles}
                updateLastViewedFeatureArticles={this.updateLastViewedFeatureArticles}
                trackNavDrawer={this.props.trackNavDrawer}
                searchText={searchText}
            />
        );
    }

    private updateLastViewedFeatureArticles = () => {
        this.props.saveUserActivity({
            key: UserActivityKeys.LastViewedFeatureArticles,
            value: new Date().toISOString()
        });
    };
}

interface IStateToProps {
    coveredMember: CoveredMemberSummary;
    isAuthenticated: boolean;
    isEmulated: boolean;
    pathname: string;
    featureToggles: FeatureToggles;
    featureArticles: FeatureArticle[];
    hasUnreadFeatureArticles: boolean;
    searchText: string;
}

interface IDispatchToProps {
    showMembershipCard: () => any;
    saveUserActivity: (activity: Partial<UserActivity>) => any;
    trackNavDrawer: () => any;
}

type ComponentProps = IStateToProps & IDispatchToProps & Partial<RouteComponentProps<{}, {}, { location: { pathname: string } }>>;

export default withRouter(
    connect(
        (state: ApplicationState, ownProps: RouteComponentProps<{}, {}, { location: { pathname: string } }>) => {
            const coveredMember = getCoveredMemberSummary(state);
            return {
                coveredMember,
                isAuthenticated: !!state.auth.session.userId,
                isEmulated: state.auth.session.emulatedSession,
                pathname: ownProps.location ? ownProps.location.pathname : "",
                featureToggles: state.features.featureToggles,
                featureArticles: state.features.featureArticleList.articles,
                hasUnreadFeatureArticles: state.features.hasUnreadFeatureArticles,
                searchText: state.search.searchText
            };
        },
        {
            showMembershipCard: MembershipCardActionCreators.showMembershipCard,
            saveUserActivity: FeaturesActionCreators.saveUserActivity,
            trackNavDrawer: CommonActionCreators.trackNavDrawer
        }
    )(Header)
);
