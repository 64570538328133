import { Reducer } from "redux";

import { HandledAction } from "./actionCreator";

import { SpendingAccountActivityDictionary, SpendingAccountsState } from "./models";
import cloneDeep from "clone-deep";

export type KnownAction = HandledAction;

export const reducer: Reducer<SpendingAccountsState> = (state: SpendingAccountsState, action: KnownAction): SpendingAccountsState => {
    switch (action.type) {
        case "GET_SPENDING_ACCOUNTS_INIT": {
            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isFetching: true
                }
            };
        }
        case "GET_SPENDING_ACCOUNTS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    spendingAccountList: {
                        ...state.spendingAccountList,
                        isFetching: false,
                        membershipSpendingAccounts: null,
                        errorMessage: action.err
                    }
                };
            }

            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isFetching: false,
                    membershipSpendingAccounts: action.spendingAccounts,
                    errorMessage: null
                }
            };
        }
        case "GET_SPENDING_ACCOUNT_ACTIVITY_INIT": {
            return {
                ...state,
                spendingAccountActivity: {
                    ...state.spendingAccountActivity,
                    isFetching: true
                }
            };
        }
        case "GET_SPENDING_ACCOUNT_ACTIVITY_COMPLETE": {
            const newState = cloneDeep(state);

            if (action.succeeded) {
                newState.spendingAccountActivity.records[action.spendingAccountActivity.id] = action.spendingAccountActivity;
            } else {
                newState.spendingAccountActivity.isFetching = false;
            }

            return newState;
        }
        case "GET_SPENDING_ACCOUNT_CLAIMS_INIT": {
            return {
                ...state,
                spendingAccountActivity: {
                    ...state.spendingAccountActivity,
                    isFetching: true
                }
            };
        }
        case "GET_SPENDING_ACCOUNT_CLAIMS_COMPLETE": {
            const newState = {
                ...state,
                spendingAccountActivity: cloneDeep(state.spendingAccountActivity),
            };
            newState.spendingAccountActivity.isFetching = false;
            if (action.succeeded) {
                newState.spendingAccountActivity.records[action.spendingAccountId].claims = action.claimsResponse;
            }

            return newState;
        }
        case "SUBMIT_ALLOCATION_INIT": {
            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isSavingAllocation: true
                }
            };
        }
        case "SUBMIT_ALLOCATION_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    spendingAccountList: {
                        ...state.spendingAccountList,
                        isSavingAllocation: false,
                        isSpendingAccountAllocationDialogOpen: true,
                        errorMessage: action.error
                    }
                };
            }

            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isSavingAllocation: false,
                    isSpendingAccountAllocationDialogOpen: false,
                    errorMessage: null
                }
            };
        }
        case "START_EDIT_ALLOCATION_INIT": {
            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isSpendingAccountAllocationDialogOpen: true,
                    errorMessage: null
                }
            };
        }
        case "CANCEL_EDIT_ALLOCATION_INIT": {
            return {
                ...state,
                spendingAccountList: {
                    ...state.spendingAccountList,
                    isSpendingAccountAllocationDialogOpen: false,
                    errorMessage: null
                }
            };
        }
        case "SET_SPENDING_ACCOUNT_ACTIVITY_TAB": {
            return {
                ...state,
                activeTab: action.tab
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            spendingAccountList: {
                isSavingAllocation: false,
                membershipSpendingAccounts: null,
                isSpendingAccountAllocationDialogOpen: false,
                isFetching: true
            },
            spendingAccountActivity: {
                isFetching: false,
                records: {} as SpendingAccountActivityDictionary
            },
            activeTab: "credits"
        } as SpendingAccountsState)
    );
};
