import { AppInsightsLogger } from "modules/logging/appInsightsLogger";
import { GoogleAnalyticsLogger } from "modules/logging/googleAnalyticsLogger";

import * as AuthActions from "modules/auth/actions";
import * as BenefitCoverageActions from "modules/benefits/coverage/actions";
import * as DrugSearchActions from "modules/benefits/drugs/actions";
import * as MembershipCardActions from "modules/benefits/membershipCard/actions";
import * as ClaimDetailsActions from "modules/claims/claimDetails/actions";
import * as ClaimListActions from "modules/claims/claimList/actions";
import * as ClaimSubmissionActions from "modules/claims/claimSubmission/actions";
import * as CommonActions from "modules/common/actions";
import * as DocumentActions from "modules/documents/actions";
import * as FeatureActions from "modules/features/actions";
import * as ProfileAddressActions from "modules/profile/address/actions";
import * as ProfileBankAccountActions from "modules/profile/bankAccount/actions";
import * as ProfilePhoneNumberActions from "modules/profile/phoneNumbers/actions";
import * as RegistrationActions from "modules/profile/registration/actions";
import * as ProfileVerificationActions from "modules/profile/verification/actions";
import * as ServiceProvidersActions from "modules/serviceProviders/actions";
import * as SpendingAccountsActions from "modules/benefits/spendingAccounts/actions";
import * as ContactActions from "modules/contact/actions";
import * as TaxSlipsPreferenceActions from "modules/preferences/taxSlips/actions";
import * as InboxActions from "modules/inbox/actions";
import * as DentalGuideActions from "modules/benefits/dentalGuide/actions";

import { Formatter, getSecondsSinceDate } from "modules/utils";
import { UserActivityKeys } from "../features/models";

type LoggedAction =
    | AuthActions.LogInCompleteAction
    | AuthActions.ReauthenticateCompleteAction
    | AuthActions.SetUserIdleStatus
    | BenefitCoverageActions.SaveOverAgeDependantDeclarationActionComplete
    | ClaimDetailsActions.DeleteClaimCompleteAction
    | ClaimDetailsActions.GetClaimDetailsCompleteAction
    | ClaimDetailsActions.TransferClaimToHsaCompleteAction
    | ClaimDetailsActions.ShowTransferToHsaDialogOnDetailsAction
    | ClaimDetailsActions.HideTransferToHsaDialogOnDetailsAction
    | ClaimDetailsActions.GetTransferDuplicateClaimsCompleteAction
    | ClaimListActions.DismissClaimCompleteAction
    | ClaimListActions.UpdateClaimsFilterAction
    | ClaimListActions.SaveMarkingAsClaimAlreadyTransferredToHsaCompleteAction
    | ClaimSubmissionActions.InitClaimEditorAction
    | ClaimSubmissionActions.SetClaimFieldValue
    | ClaimSubmissionActions.SaveClaimCompleteAction
    | ClaimSubmissionActions.SubmitClaimCompleteAction
    | ClaimSubmissionActions.AddAttachmentCompleteAction
    | ClaimSubmissionActions.DeleteAttachmentCompleteAction
    | ClaimSubmissionActions.GetSubmitDuplicateClaimsCompleteAction
    | ClaimSubmissionActions.DiscardClaimCompleteAction
    | CommonActions.TrackMobileNavDrawerOpenedAction
    | CommonActions.TrackLoggedInUserIsEmulatedAction
    | DocumentActions.TrackDocumentDownloadAction
    | DrugSearchActions.SearchDrugsComplete
    | DrugSearchActions.GetDrugDetailsComplete
    | FeatureActions.SaveUserActivityCompleteAction
    | MembershipCardActions.ShowMembershipCard
    | MembershipCardActions.HideMembershipCard
    | MembershipCardActions.DownloadMembershipCard
    | ProfilePhoneNumberActions.SavePhoneNumbersCompleteAction
    | ProfileBankAccountActions.SaveBankAccountCompleteAction
    | ProfileBankAccountActions.RemoveBankAccountCompleteAction
    | ProfileAddressActions.SaveAddressCompleteAction
    | ProfileVerificationActions.VerifyCompleteAction
    | RegistrationActions.InitializeNewMemberRegistrationAction
    | RegistrationActions.VerifyNewMemberCompleteAction
    | RegistrationActions.FinalizeMemberRegistrationCompleteAction
    | RegistrationActions.CancelNewMemberRegistrationAction
    | ServiceProvidersActions.SetSelectedProviderClassAction
    | ServiceProvidersActions.SaveServiceProviderRatingResponseCompleteAction
    | ServiceProvidersActions.SubmitServiceProviderRatingComplete
    | ServiceProvidersActions.GetIneligibleProvidersCompleteAction
    | ServiceProvidersActions.GetProviderLocationsCompleteAction
    | ServiceProvidersActions.GetHomeAddressGeoCoordinateCompleteAction
    | SpendingAccountsActions.SetActiveSpendingAccountActivityTabAction
    | SpendingAccountsActions.GetSpendingAccountActivityCompleteAction
    | ContactActions.SubmitFeedbackCompleteAction
    | ContactActions.SubmitInquiryCompleteAction
    | ProfileVerificationActions.LogDeepLinkAction
    | TaxSlipsPreferenceActions.SavePreferenceCompleteAction
    | InboxActions.SubmitNewConversationCompleteAction
    | InboxActions.SubmitNewConversationMessageCompleteAction
    | InboxActions.CloseConversationCompleteAction
    | InboxActions.GetConversationsCompleteAction
    | InboxActions.GetConversationCompleteAction
    | InboxActions.ReOpenConversationCompleteAction
    | DentalGuideActions.GetDentalProcedureDetailCompleteAction;

function createLoggingMiddleware() {
    return (store) => (next) => (action: LoggedAction) => {
        switch (action.type) {
            case "REOPEN_CONVERSATION_COMPLETE": {
                const area = "Inbox";
                const event = "ConversationReOpened";
                const eventName = `FeatureUsage-${area}-${event}}`;
                AppInsightsLogger.trackEvent(
                    { name: eventName },
                    { name: event, area: area, action: action.type, succeeded: action.succeeded }
                );
                GoogleAnalyticsLogger.trackEvent(area, event, action.succeeded ? "succeeded" : "failed");
                break;
            }
            case "GET_CONVERSATION_COMPLETE": {
                const area = "Inbox";
                const event = "Get Conversation";
                const eventName = `${event}: ${action.succeeded ? "succeeded" : "failed"}`;
                AppInsightsLogger.trackEvent(
                    { name: event },
                    { name: eventName, area: area, action: action.type, succeeded: action.succeeded }
                );
                GoogleAnalyticsLogger.trackEvent(area, event, action.succeeded ? "succeeded" : "failed");
                break;
            }
            case "GET_CONVERSATIONS_COMPLETE": {
                const area = "Inbox";
                const event = "Get Conversations";
                const eventName = `${event}: ${action.succeeded ? "succeeded" : "failed"}`;
                AppInsightsLogger.trackEvent(
                    { name: event },
                    { name: eventName, area: area, action: action.type, succeeded: action.succeeded }
                );
                GoogleAnalyticsLogger.trackEvent(area, event, action.succeeded ? "succeeded" : "failed");
                break;
            }
            case "CLOSE_CONVERSATION_COMPLETE": {
                const area = "Inbox";
                const event = "ConversationResolved";
                const eventName = `FeatureUsage-${area}-${event}`;
                AppInsightsLogger.trackEvent(
                    { name: eventName },
                    { name: event, area: area, action: action.type, succeeded: action.succeeded }
                );
                GoogleAnalyticsLogger.trackEvent(area, event, action.succeeded ? "succeeded" : "failed");
                break;
            }
            case "SUBMIT_NEW_CONVERSATION_COMPLETE": {
                const area = "Inbox";
                const event = "NewConversationSubmitted";
                const eventName = `FeatureUsage-${area}-${event}`;
                AppInsightsLogger.trackEvent(
                    { name: eventName },
                    { name: event, area: area, action: action.type, succeeded: action.succeeded }
                );
                GoogleAnalyticsLogger.trackEvent(area, event, action.succeeded ? "succeeded" : "failed");
                break;
            }
            case "SUBMIT_NEW_CONVERSATION_MESSAGE_COMPLETE": {
                const area = "Inbox";
                const event = "NewConversationMessageSubmitted";
                const eventName = `FeatureUsage-${area}-${event}`;
                AppInsightsLogger.trackEvent(
                    { name: eventName },
                    { name: event, area: area, action: action.type, succeeded: action.succeeded }
                );
                GoogleAnalyticsLogger.trackEvent(area, event, action.succeeded ? "succeeded" : "failed");
                break;
            }
            case "INITIALIZE_NEW_MEMBER_REGISTRATION": {
                const eventName = "Registration started";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent("Registration", eventName);
                break;
            }
            case "VERIFY_NEW_MEMBER_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Registration: verification complete";

                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Registration", eventName);
                } else {
                    const eventName = "Registration: verification failed";

                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Registration", eventName);
                }
                break;
            }
            case "FINALIZE_MEMBER_REGISTRATION_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Registration: complete";

                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Registration", eventName);
                } else {
                    const eventName = "Registration: failed";
                    const isEmailAddressAvailable = (action.isEmailAddressAvailable || "false").toString();
                    const isPasswordStrongEnough = (action.isPasswordStrongEnough || "").toString();

                    AppInsightsLogger.trackEvent(
                        { name: eventName },
                        { isEmailAddressAvailable, isPasswordStrongEnough }
                    );
                    GoogleAnalyticsLogger.trackEvent("Registration", eventName);
                }
                break;
            }
            case "CANCEL_NEW_MEMBER_REGISTRATION": {
                const eventName = "Registration: cancelled";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent("Registration", eventName);
                break;
            }
            case "INIT_CLAIM_EDITOR": {
                const eventName = "Started new claim";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                break;
            }
            case "SET_CLAIM_FIELD_VALUE": {
                const eventName = "Set claim field value";
                AppInsightsLogger.trackEvent({ name: eventName }, { field: action.name });
                GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.name);
                break;
            }
            case "SAVE_CLAIM_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Saved claim";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                } else {
                    const eventName = "Save claim failed";
                    AppInsightsLogger.trackEvent(
                        { name: eventName },
                        {
                            reason:
                                action.validationState && !action.validationState.isValid
                                    ? "Failed validation"
                                    : "Error"
                        }
                    );
                    GoogleAnalyticsLogger.trackEvent(
                        "Claims",
                        eventName,
                        action.validationState && !action.validationState.isValid ? "Failed validation" : ""
                    );
                }
                break;
            }
            case "SUBMIT_CLAIM_COMPLETE": {
                if (action.succeeded) {
                    const state = store.getState();
                    const startTime = new Date(state.claims.claimEditor.claim.createdDate);
                    const seconds = getSecondsSinceDate(startTime);
                    const numberOfDuplicatesFound =
                        state.claims.claimEditor.possibleDuplicateClaims &&
                        state.claims.claimEditor.possibleDuplicateClaims.claims
                            ? state.claims.claimEditor.possibleDuplicateClaims.claimsTotal
                            : 0;

                    const eventName = "Submitted claim";
                    AppInsightsLogger.trackEvent(
                        { name: eventName },
                        {
                            secondsToComplete: seconds.toString(),
                            numberOfDuplicatesFound: numberOfDuplicatesFound.toString()
                        }
                    );
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, seconds.toString());
                } else {
                    const eventName = "Submit claim failed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                }

                // Feature usage Logging
                const eventName = "FeatureUsage-Claims-NewClaimSubmitted";
                AppInsightsLogger.trackEvent({ name: eventName }, { name: eventName, succeeded: action.succeeded });

                break;
            }
            case "DISCARD_CLAIM_COMPLETE": {
                if (action.succeeded) {
                    const state = store.getState();

                    const numberOfDuplicatesFound =
                        state.claims.claimEditor.possibleDuplicateClaims &&
                        state.claims.claimEditor.possibleDuplicateClaims.claims
                            ? state.claims.claimEditor.possibleDuplicateClaims.claimsTotal
                            : 0;

                    const eventName = "Discarded claim";
                    AppInsightsLogger.trackEvent(
                        { name: eventName },
                        { numberOfDuplicatesFound: numberOfDuplicatesFound.toString() }
                    );
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                } else {
                    const eventName = "Discard claim failed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                }

                break;
            }
            case "GET_SUBMIT_DUPLICATE_CLAIMS_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Submit claim: Duplicates - complete";

                    const totalDuplicateClaimsFound = (action.claimsResponse
                        ? action.claimsResponse.claimsTotal
                        : ""
                    ).toString();
                    AppInsightsLogger.trackEvent({ name: eventName }, { totalDuplicateClaimsFound });

                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                } else {
                    const eventName = "Submit claim: Duplicates - failed";

                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                }
                break;
            }
            case "DELETE_CLAIM_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Deleted claim";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                } else {
                    const eventName = "Delete claim failed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                }
                break;
            }
            case "LOG_IN_COMPLETE": {
                if (action.authResponse.succeeded) {
                    const eventName = "Logged in";
                    AppInsightsLogger.setAuthenticatedUserContext(action.authResponse.userId);
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.setUserId(action.authResponse.userId);
                    GoogleAnalyticsLogger.trackEvent("Auth", eventName);
                } else {
                    const eventName = "Log in failed";
                    const eventData = { message: action.authResponse.message, username: action.username };
                    AppInsightsLogger.trackEvent({ name: eventName }, eventData);
                    GoogleAnalyticsLogger.trackEvent("Auth", eventName, action.authResponse.message);
                }
                break;
            }
            case "REAUTHENTICATE_COMPLETE": {
                if (action.authResponse.succeeded) {
                    const eventName = "Reauthenticated";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Auth", eventName);
                } else {
                    const eventName = "Reauthentication failed";
                    AppInsightsLogger.trackEvent({ name: eventName }, { message: action.authResponse.message });
                    GoogleAnalyticsLogger.trackEvent("Auth", eventName, action.authResponse.message);
                }
                break;
            }
            case "ADD_CLAIM_ATTACHMENT_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Added attachment";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                } else {
                    const eventName = "Add attachment failed";
                    AppInsightsLogger.trackEvent({ name: eventName }, { error: action.error });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.error);
                }
                break;
            }
            case "DELETE_CLAIM_ATTACHMENT_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Deleted attachment";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                } else {
                    const eventName = "Delete attachment failed";
                    AppInsightsLogger.trackEvent({ name: eventName }, { error: action.error });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.error);
                }
                break;
            }
            case "SAVE_USER_ACTIVITY_COMPLETE": {
                switch (action.key) {
                    case UserActivityKeys.LastViewedFeatureArticles: {
                        const eventName = "Viewed feature article list";
                        AppInsightsLogger.trackEvent({ name: eventName });
                        GoogleAnalyticsLogger.trackEvent("Features", eventName);
                    }
                }
            }
            case "SHOW_MEMBERSHIP_CARD": {
                const eventName = "Viewed wallet card";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent("Wallet Card", eventName);
                GoogleAnalyticsLogger.trackModalView();
                break;
            }
            case "HIDE_MEMBERSHIP_CARD": {
                const eventName = "Closed wallet card";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent("Wallet Card", eventName);
                GoogleAnalyticsLogger.trackModalView();
                break;
            }
            case "DOWNLOAD_MEMBERSHIP_CARD": {
                const eventName = "Downloaded wallet Card";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent("Wallet Card", eventName);
                GoogleAnalyticsLogger.trackModalView();
                break;
            }
            case "GET_CLAIM_DETAILS_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Viewed claim details";
                    AppInsightsLogger.trackEvent({ name: eventName }, { referenceId: action.referenceId });
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.referenceId);
                } else {
                    const eventName = "View claim details failed";
                    AppInsightsLogger.trackEvent(
                        { name: eventName },
                        { referenceId: action.referenceId, error: action.err }
                    );
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.referenceId);
                }
                break;
            }
            case "SET_USER_IDLE": {
                if (action.idle != null && action.idle) {
                    const eventName = "Session timed out";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Auth", eventName);
                } else {
                    const eventName = "Session resumed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Auth", eventName);
                }
                break;
            }
            case "TRANSFER_CLAIM_TO_HSA_COMPLETE": {
                const props = {
                    referenceId: action.referenceId,
                    duplicateWarningShown: action.duplicateWarningShown
                        ? action.duplicateWarningShown.toString()
                        : null,
                    error: action.err
                };

                if (action.succeeded) {
                    const eventName = "Transferred claim to HSA";
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.referenceId);
                } else {
                    const eventName = "Transferred claim to HSA failed";
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.referenceId);
                }
                break;
            }
            case "SHOW_TRANSFER_TO_HSA_DIALOG_ON_DETAILS": {
                const eventName = "Viewed transfer to HSA dialog on claim details";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                GoogleAnalyticsLogger.trackModalView();
                break;
            }
            case "HIDE_TRANSFER_TO_HSA_DIALOG_ON_DETAILS": {
                const eventName = "Closed transfer to HSA dialog on claim details";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                GoogleAnalyticsLogger.trackModalView();
                break;
            }
            case "DISMISS_CLAIM_COMPLETE": {
                const props = {
                    reference: action.referenceId,
                    dismissActionType: action.dismissActionType
                };

                if (action.succeeded) {
                    const eventName = "Dismissed actionable claim";
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.dismissActionType);
                } else {
                    const eventName = "Dismissed actionable claim failed";
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Claims", eventName, action.err);
                }
                break;
            }
            case "UPDATE_CLAIMS_FILTER": {
                const eventName = "Updated claim filter";
                const props = {
                    view: action.filter.view,
                    membershipType: action.filter.membershipType ? null : action.filter.membershipType,
                    benefitType: Formatter.formatBenefitType(action.filter.benefitType),
                    pageNumber: action.filter.pageNumber.toString(),
                    patientCertificateNumber: action.filter.patientCertificateNumber
                        ? null
                        : action.filter.patientCertificateNumber,
                    searchText: action.filter.searchText ? action.filter.searchText : null
                };

                AppInsightsLogger.trackEvent({ name: eventName }, props);
                GoogleAnalyticsLogger.trackEvent("Claims", eventName);
                break;
            }
            case "SAVE_OVER_AGE_DEPENDANT_DECLARATION_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Over-age dependant declaration submitted";
                    const props = {
                        schoolYearId: action.declaration.schoolYearId.toString(),
                        coveredMemberCertificateNumber: action.declaration.coveredMemberCertificateNumber,
                        dependantCertificateNumber: action.declaration.dependantCertificateNumber
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Coverage", eventName);
                } else {
                    const eventName = "Over-age dependant declaration submission failed";
                    const props = {
                        schoolYearId: action.declaration.schoolYearId.toString(),
                        coveredMemberCertificateNumber: action.declaration.coveredMemberCertificateNumber,
                        dependantCertificateNumber: action.declaration.dependantCertificateNumber
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Coverage", eventName);
                }
                break;
            }
            case "SAVE_ADDRESS_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Profile: Address saved";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                } else {
                    const eventName = "Profile: Address save failed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                }
                break;
            }
            case "SAVE_BANK_ACCOUNT_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Profile: Bank account saved";
                    const props = {
                        bankAccountType: action.bankAccount.accountTypeId.toString()
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                } else {
                    const eventName = "Profile: Bank account save failed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                }
                break;
            }
            case "REMOVE_BANK_ACCOUNT_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Profile: Bank account removed";
                    const props = {
                        bankAccountType: action.bankAccountRemoval.bankAccountTypeId.toString()
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                } else {
                    const eventName = "Profile: Bank account remove failed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                }
                break;
            }
            case "SAVE_PHONE_NUMBERS_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Profile: Phone number saved";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                } else {
                    const eventName = "Profile: Phone number save failed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                }
                break;
            }
            case "VERIFY_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Profile: Email verified";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                } else {
                    const eventName = "Profile: Email verification failed";
                    const props = {
                        verificationError: action.verificationError || ""
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Profile", eventName);
                }
                break;
            }
            case "SEARCH_DRUGS_COMPLETE": {
                if (action.succeeded && action.matches.length === 0) {
                    const eventName = "Drugs: search returned no results";
                    const props = {
                        drugName: action.name
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Drugs", eventName);
                }
                break;
            }
            case "GET_DRUG_DETAILS_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Drugs: fetched details";
                    const props = {
                        drugName: action.name
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Drugs", eventName);
                }
                // Feature usage Logging
                const eventName = "FeatureUsage-DrugInquiry-DrugOpened";
                AppInsightsLogger.trackEvent(
                    { name: eventName },
                    { name: eventName, succeeded: action.succeeded, drugName: action.name }
                );
                break;
            }
            case "TRACK_DOCUMENT_DOWNLOAD": {
                const eventName = "FeatureUsage-Documents-Downloaded";
                const props = {
                    collectionName: action.collectionName
                };
                AppInsightsLogger.trackEvent({ name: eventName }, props);
                GoogleAnalyticsLogger.trackEvent(eventName, eventName);
                break;
            }
            case "SET_SPENDING_ACCOUNT_ACTIVITY_TAB": {
                const eventName = "Change Spending Account Activity Tab";
                const props = {
                    tab: action.tab
                };
                AppInsightsLogger.trackEvent({ name: eventName }, props);
                GoogleAnalyticsLogger.trackEvent(eventName, eventName);
                break;
            }
            case "GET_SPENDING_ACCOUNT_ACTIVITY_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Fetched spending account activity complete";
                    const props = {
                        accountType: action.spendingAccountActivity.accountType,
                        isActive: action.spendingAccountActivity.isActive.toString(),
                        isRunoff: action.spendingAccountActivity.isInRunoff.toString(),
                        isExpired: action.spendingAccountActivity.isExpired.toString(),
                        isCancelled: action.spendingAccountActivity.isCancelled.toString(),
                        isTerminated: action.spendingAccountActivity.isTerminated.toString()
                    };

                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent(eventName, eventName);
                } else {
                    const eventName = "Fetched spending account activity failed";
                    const props = {
                        accountType: action.spendingAccountActivity.accountType,
                        isActive: action.spendingAccountActivity.isActive.toString(),
                        isRunoff: action.spendingAccountActivity.isInRunoff.toString(),
                        isExpired: action.spendingAccountActivity.isExpired.toString(),
                        isCancelled: action.spendingAccountActivity.isCancelled.toString(),
                        isTerminated: action.spendingAccountActivity.isTerminated.toString()
                    };

                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent(eventName, eventName);
                }
                break;
            }
            case "SAVE_MARKING_AS_CLAIM_ALREADY_TRANSFERRED_TO_HSA_COMPLETE": {
                const eventName = "Transfer to HSA: Claim was marked as already transferred to HSA";
                const props = {
                    referenceId: action.referenceId
                };
                AppInsightsLogger.trackEvent({ name: eventName }, props);
                GoogleAnalyticsLogger.trackEvent(eventName, eventName);
                break;
            }
            case "GET_TRANSFER_DUPLICATE_CLAIMS_COMPLETE": {
                const eventName = "Transfer to HSA: Number of possible duplicate claims";
                const props = {
                    numberOfClaims: (action.claimsResponse && action.claimsResponse.claimsTotal
                        ? action.claimsResponse.claimsTotal
                        : 0
                    ).toString()
                };
                AppInsightsLogger.trackEvent({ name: eventName }, props);
                GoogleAnalyticsLogger.trackEvent(eventName, eventName);
                break;
            }
            case "TRACK_MOBILE_NAV_DRAWER_OPENED": {
                const eventName = "Mobile navigation expanded";

                const props = {
                    panelName: action.panelName
                };

                AppInsightsLogger.trackEvent({ name: eventName }, props);
                GoogleAnalyticsLogger.trackEvent(eventName, eventName);
                break;
            }
            case "TRACK_LOGGED_IN_USER_EMULATED": {
                const eventName = action.isEmulated ? "User - Emulated" : "User - Not Emulated";
                AppInsightsLogger.trackEvent({ name: eventName });
                GoogleAnalyticsLogger.trackEvent(eventName, eventName);
                break;
            }
            case "SUBMIT_INQUIRY_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Inquiry: submitted";
                    const props = {
                        subject: action.inquiry.subject,
                        preferredContactMethod: action.inquiry.preferredContactMethod
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Inquiry", eventName);
                }
                break;
            }
            case "SUBMIT_FEEDBACK_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Feedback: submitted";
                    const props = {
                        rating: (action.feedback ? action.feedback.rating : 0).toString()
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Feedback", eventName);
                }
                break;
            }
            case "SET_SELECTED_PROVIDER_CLASS": {
                if (action.providerClassId) {
                    const eventName = "Providers: filtered to class";
                    const props = {
                        classId: action.providerClassId.toString()
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Providers", eventName);
                }
                break;
            }
            case "GET_PROVIDERS_COMPLETE": {
                if (action.succeeded && action.page) {
                    const eventName = "Providers: fetched a page";
                    const props = {
                        numberOfServiceProvidersLoaded: action.page.items.length.toString(),
                        total: action.page.totalItemCount.toString()
                    };
                    AppInsightsLogger.trackEvent({ name: eventName }, props);
                    GoogleAnalyticsLogger.trackEvent("Providers", eventName);
                }

                // Feature usage Logging
                const eventName = "FeatureUsage-Providers-AreaSearched";
                AppInsightsLogger.trackEvent({ name: eventName }, { name: eventName, succeeded: action.succeeded });
                break;
            }
            case "SUBMIT_SERVICE_PROVIDER_RATING_COMPLETE": {
                // Feature usage Logging
                const eventName = "FeatureUsage-Providers-RatedFromMyAsebp";
                AppInsightsLogger.trackEvent(
                    { name: eventName },
                    {
                        name: eventName,
                        succeeded: action.succeeded
                    }
                );
                break;
            }
            case "SAVE_SERVICE_PROVIDER_RATING_RESPONSE_REQUEST_COMPLETE": {
                // Feature usage Logging
                const eventName = "FeatureUsage-Providers-RatedFromEmail";
                AppInsightsLogger.trackEvent(
                    { name: eventName },
                    {
                        name: eventName,
                        succeeded: action.succeeded
                    }
                );
                break;
            }
            case "GET_INELIGIBLE_PROVIDERS_COMPLETE": {
                // Feature usage Logging
                const eventName = "FeatureUsage-Providers-IneligiblePageViewed";
                AppInsightsLogger.trackEvent({ name: eventName }, { name: eventName, succeeded: action.succeeded });
                GoogleAnalyticsLogger.trackEvent("Providers", eventName);
                break;
            }
            case "GET_HOME_ADDRESS_GEO_COORDINATE_COMPLETE": {
                if (action.succeeded && action.homeAddressGeoCoordinate) {
                    const eventName = "Providers: home address geo location found";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Providers", eventName);
                }
                break;
            }

            case "LOG_DEEP_LINK": {
                const eventName = "Deeplink Clicked";
                const props = {
                    isMobile: action.isMobile,
                    verificationType: action.verificationType
                };
                AppInsightsLogger.trackEvent({ name: eventName }, props);
                GoogleAnalyticsLogger.trackEvent("Verification", eventName);
                break;
            }
            case "SAVE_PREFERENCE_COMPLETE": {
                if (action.succeeded) {
                    const eventName = "Preference: T4A consent saved";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Preference", eventName);
                } else {
                    const eventName = "Preference: T4A consent save failed";
                    AppInsightsLogger.trackEvent({ name: eventName });
                    GoogleAnalyticsLogger.trackEvent("Preference", eventName);
                }
                break;
            }
            case "GET_DENTAL_PROCEDURE_DETAILS_COMPLETE_ACTION": {
                // Feature usage Logging
                const eventName = "FeatureUsage-DentalGuide-ProcedureViewed";
                AppInsightsLogger.trackEvent(
                    { name: eventName },
                    {
                        name: eventName,
                        succeeded: action.succeeded,
                        procedureCode: action?.dentalProcedureDetails?.productExternalId
                    }
                );
                break;
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = action;
            }
        }

        return next(action);
    };
}

const middleware = createLoggingMiddleware();
export default middleware;
