import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store/configureStore";

type DispatchFunc = () => AppDispatch;

/**
 * Typed hooks for Redux. Use these instead of the ones from react-redux.
 *
 * useAppDispatch is a hook that provides a dispatch function. This function can be used to dispatch actions
 * to the Redux store. Actions are payloads of information that send data from the application to the Redux store.
 * @function useAppDispatch
 * @returns {AppDispatch} A reference to the dispatch function from the Redux store.
 */
export const useAppDispatch: DispatchFunc = useDispatch;

/**
 * Typed hooks for Redux. Use these instead of the ones from react-redux.
 *
 * useAppSelector is a typed selector hook for Redux. It allows you to extract data from the Redux store state,
 * using a selector function. The selector function is provided as the argument to the useAppSelector hook.
 * @function useAppSelector
 * @returns {TypedUseSelectorHook<RootState>} A typed version of the useSelector hook from Redux.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
