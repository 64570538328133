import { useEffect, useState } from "react";
import { useBaseAxiosGetRequest } from "./axiosHooks";
import { AxiosError, AxiosResponse } from "axios";

/** callback will be called each time the GET request finishes loading.
 * Because useBaseAxiosGetRequest can either be called automatically or manually,
 * using a listener hook is the best way to do this; as it will respond to both
 * automatic and explicit loads.
 */
export const useAxiosGetRequestListener = <TOut, TError, TBody>(
    request: ReturnType<typeof useBaseAxiosGetRequest<TOut, TError, TBody>>,
    onRequestCompleted: (data: TOut | null, error: AxiosError<TError, TBody>, response: AxiosResponse<TOut>) => void
) => {
    const [isActivated, setIsActivated] = useState(request.loading);

    useEffect(() => {
        if (request.loading) {
            setIsActivated(true);
        } else if (isActivated) {
            setIsActivated(false);
            onRequestCompleted(request.data, request.error, request.response);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request.loading, request.data, request.error, request.response]);
};
