import * as React from "react";
import { CircularProgress } from "@mui/material";

export interface LoadingProps {
    loadingText?: string;
    loadingIconSize?: LoadingIconSize;
    color?: "primary" | "secondary";
    hidden?: boolean;
    className?: string;
}

export enum LoadingIconSize {
    default = 1,
    small = 2,
    extraSmall = 3,
    large = 4
}

export const Loading = (props: LoadingProps) => {
    const progressSize =
        props.loadingIconSize === LoadingIconSize.small
            ? {
                  size: 18,
                  thickness: 2
              }
            : props.loadingIconSize === LoadingIconSize.extraSmall
            ? {
                  size: 8
              }
            : props.loadingIconSize === LoadingIconSize.large
            ? {
                  size: 40
              }
            : {};

    return (
        <span className={`${props.className} ${props.hidden ? "hidden" : "visible"}`}>
            <CircularProgress color={props.color} {...progressSize} />
            {!!props.loadingText && props.loadingText}
        </span>
    );
};
