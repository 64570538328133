import { addDays } from "date-fns";

import { XhrUploadHandlers } from "api/xhrUpload";
import { SpendingAccountType } from "modules/benefits/spendingAccounts/models";
import { ClaimsFilterState } from "modules/claims/claimList/models";
import { ClaimSubmission } from "modules/claims/claimSubmission/models";
import { ClaimAttachmentCategory } from "hooks/api/claimsApi";
import { MembershipType } from "modules/claims/models";
import { NetworkRequest } from "./actions";
import { UrlBuilder } from "./urlBuilder";
import Formatter from "modules/utils/formatter";

export const buildFilterQueryParams = (claimsFilterState: ClaimsFilterState) => {
    const queryParams = {} as any;

    if (claimsFilterState) {
        queryParams.pageNumber = claimsFilterState.pageNumber.toString();

        if (claimsFilterState.searchText && claimsFilterState.searchText.trim().length > 0) {
            queryParams.searchText = claimsFilterState.searchText;
        }

        if (claimsFilterState.view === "Actionable") {
            queryParams.isActionableOnly = true;
        }

        if (claimsFilterState.patientCertificateNumber) {
            queryParams.patientCertificateNumber = claimsFilterState.patientCertificateNumber;
        }

        if (claimsFilterState.benefitType) {
            queryParams.benefitType = claimsFilterState.benefitType;
        }

        if (claimsFilterState.membershipType) {
            if (claimsFilterState.membershipType === MembershipType.MyRetireePlan) {
                queryParams.isMyRetireeOnly = true;
            }
            if (claimsFilterState.membershipType === MembershipType.ASEBP) {
                queryParams.isAsebpOnly = true;
            }
        }

        if (claimsFilterState.claimCategory != null) {
            queryParams.claimCategory = claimsFilterState.claimCategory;
        }

        if (claimsFilterState.view === "Fixable") {
            queryParams.isFixableOnly = true;
            queryParams.isActionableOnly = true;
        }

        if (claimsFilterState.view === "SubmittableToHSA") {
            queryParams.isTransferrableToHsaOnly = true;
            queryParams.isActionableOnly = true;
        }

        if (claimsFilterState.format) {
            queryParams.format = claimsFilterState.format;
        }

        if (claimsFilterState.serviceDateFilterOption) {
            if (claimsFilterState.serviceDateFilterOption.startDate) {
                queryParams.serviceStartDate = Formatter.formatLocalDateToISODateOnlyString(
                    claimsFilterState.serviceDateFilterOption.startDate
                );
            }
            if (claimsFilterState.serviceDateFilterOption.endDate) {
                queryParams.serviceEndDate = Formatter.formatLocalDateToISODateOnlyString(
                    claimsFilterState.serviceDateFilterOption.endDate
                );
            }
        }

        if (claimsFilterState.view === "Recent") {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

            queryParams.submittedStartDate = addDays(today, -7).toJSON();
            queryParams.sortOn = "SubmittedDate";
        }

        if (claimsFilterState.productMaximumGroupCode) {
            queryParams.productMaximumGroupSourceCode = claimsFilterState.productMaximumGroupCode;
        }

        if (claimsFilterState.includeCoveredOnly) {
            queryParams.includeCoveredOnly = claimsFilterState.includeCoveredOnly;
        }

        if (claimsFilterState.spendingAccountId) {
            queryParams.spendingAccountId = claimsFilterState.spendingAccountId;
        }

        if (claimsFilterState.pageSize) {
            queryParams.pageSize = claimsFilterState.pageSize;
        }

        if (claimsFilterState.isTransferred === true || claimsFilterState.isTransferred === false) {
            queryParams.isTransferred = claimsFilterState.isTransferred;
        }

        if (
            claimsFilterState.spendingAccountClaimCategoryIds &&
            claimsFilterState.spendingAccountClaimCategoryIds.length > 0
        ) {
            queryParams.spendingAccountClaimCategoryIds = claimsFilterState.spendingAccountClaimCategoryIds;
        }
    }
    return queryParams;
};

export const getExportClaimsUrl = (claimsFilterState: ClaimsFilterState): string => {
    const params = buildFilterQueryParams(claimsFilterState);
    const uriBuilder = new UrlBuilder("/api/claims/export");
    uriBuilder.addQueryParams(params);
    return uriBuilder.getUrl();
};

export const getClaimStats = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/claims/stats"
    };
};

export const getAvailableClaimBenefitFilters = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/claims/categoryFilters"
    };
};

export const getClaims = (claimsFilterState: ClaimsFilterState): NetworkRequest => {
    const queryParams = buildFilterQueryParams(claimsFilterState);

    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/claims",
        queryParams
    };
};

export const saveClaim = (claim: ClaimSubmission): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/claims",
        body: claim
    };
};

export const attachFile = (
    file: File,
    attachmentCategory: ClaimAttachmentCategory,
    claimId: number,
    xhrHandlers: XhrUploadHandlers
): NetworkRequest => {
    const body = new FormData();
    body.append("files", file);

    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/claims/{id}/attachments",
        params: { id: claimId },
        queryParams: { attachmentCategory },
        body,
        isXhr: true,
        xhrHandlers
    };
};

export const getClaimWorkingCopy = (referenceId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/claims/${referenceId}`
    };
};

export const getClaimDetails = (referenceId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/claims/${referenceId}/linked`
    };
};

export const deleteAttachment = (referenceId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "DELETE",
        url: "/api/claims/attachments/{referenceId}",
        params: { referenceId }
    };
};

export const getSpendingAccountClaimCategories = (
    spendingAccountType: SpendingAccountType,
    spendingAccountId: number
): NetworkRequest => {
    const accountType = spendingAccountType === SpendingAccountType.WSA ? "wsa" : "hsa";

    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/claims/${accountType}SpendingAccountClaimCategories/${spendingAccountId}`
    };
};

export const deleteClaim = (referenceId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "DELETE",
        url: `/api/claims/${referenceId}`
    };
};

export const replaceAttachment = (
    file: File,
    replacedAttachmentReferenceId: string,
    xhrHandlers: XhrUploadHandlers
): NetworkRequest => {
    const body = new FormData();
    body.append("file", file);

    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/claims/attachments/${replacedAttachmentReferenceId}`,
        body,
        isXhr: true,
        xhrHandlers
    };
};

export const fixClaim = (referenceId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/claims/${referenceId}/fix`
    };
};

export const dismissClaim = (referenceId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/claims/${referenceId}/dismissedStatus`
    };
};

export const transferClaimToHsa = (
    referenceId: string,
    amountToTransfer: number,
    amountPaidByOtherProvider: number | null,
    membershipId: number | null,
    duplicateWarningShown: boolean
): NetworkRequest => {
    const webRequest = {
        AmountToTransfer: amountToTransfer,
        AmountPaidByOtherProvider: amountPaidByOtherProvider,
        MembershipId: membershipId,
        DuplicateWarningShown: duplicateWarningShown
    };

    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/claims/${referenceId}/linked`,
        body: webRequest
    };
};

export const saveAsAlreadyTransferredToHsa = (referenceId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/claims/${referenceId}/transferredToHsaStatus`
    };
};
