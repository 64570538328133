import { Reducer } from "redux";
import { InboxState, MessageModel, MessageViewModel, LocalMessageAttachment } from "./models";
import { KnownActions } from "./actionCreator";
import { Formatter } from "modules/utils";
import StateAdjuster from "./conversationStateAdjuster";
import cloneDeep from "clone-deep";

export const reducer: Reducer<InboxState> = (state: InboxState, action: KnownActions) => {
    switch (action.type) {
        case "GET_CONVERSATIONS_INIT": {
            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    isFetching: true,
                    isBackgroundFetching: action.isBackgroundFetching
                }
            };
        }
        case "GET_CONVERSATIONS_COMPLETE": {
            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    errorMessage: action.succeeded ? null : action.error,
                    isFetching: false,
                    isBackgroundFetching: false,
                    conversations: action.conversations
                }
            };
        }
        case "GET_CONVERSATION_INIT": {
            const conversations = state.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    return {
                        ...conversation,
                        isFetching: true,
                        isBackgroundFetching: action.isBackgroundFetching
                    };
                }

                return conversation;
            });

            const isFetchingSelectedConversation =
                state.conversationState.selectedConversationId === action.conversationId;

            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    conversations: conversations
                },
                conversationState: {
                    ...state.conversationState,
                    isFetching: isFetchingSelectedConversation,
                    isBackgroundFetching: isFetchingSelectedConversation ? action.isBackgroundFetching : false
                }
            };
        }
        case "GET_CONVERSATION_COMPLETE": {
            let conversations = [...state.conversationsState.conversations];
            const conversation = conversations.find((c) => c.id === action.conversationId);
            if (conversation) {
                conversations = conversations.map((conversation) => {
                    if (conversation.id === action.conversationId) {
                        // conversations come without messages, but could have them already related so we need to create a new one
                        // and assign the old messages and let the get conversations messages method update those if required
                        if (action.succeeded) {
                            return {
                                ...action.conversation,
                                messages: conversation.messages ? [...conversation.messages] : []
                            };
                        }
                        return {
                            ...conversation,
                            isFetching: false,
                            isBackgroundFetching: false
                        };
                    }
                    return conversation;
                });
            } else {
                conversations.unshift(action.conversation);
            }

            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    conversations: conversations
                },
                conversationState: {
                    ...state.conversationState,
                    isFetching: false,
                    isBackgroundFetching: false,
                    errorMessage: action.succeeded ? null : action.error
                }
            };
        }
        case "SET_NEW_CONVERSATION_MODAL_OPEN": {
            return {
                ...state,
                newConversationState: {
                    ...state.newConversationState,
                    isOpen: action.isOpen
                }
            };
        }
        case "CLEAR_MESSAGE_ATTACHMENTS": {
            return {
                ...state,
                conversationState: {
                    ...state.conversationState,
                    messageAttachments: []
                }
            };
        }
        case "SUBMIT_NEW_CONVERSATION_INIT": {
            return {
                ...state,
                newConversationState: {
                    ...state.newConversationState,
                    isSubmitting: true
                }
            };
        }
        case "SUBMIT_NEW_CONVERSATION_COMPLETE": {
            return {
                ...state,
                newConversationState: {
                    ...state.newConversationState,
                    errorMessage: action.succeeded ? null : action.error,
                    isSubmitting: false
                }
            };
        }
        case "SET_CONVERSATION_MODAL_OPEN": {
            return {
                ...state,
                conversationState: {
                    ...state.conversationState,
                    isOpen: action.isOpen,
                    messageAttacgments: !action.isOpen ? [] : state.conversationState.messageAttachments
                }
            };
        }
        case "SET_SELECTED_CONVERSATION": {
            return {
                ...state,
                conversationState: {
                    ...state.conversationState,
                    selectedConversationId: action.conversationId
                }
            };
        }
        case "GET_CONVERSATION_MESSAGE_INIT": {
            const conversations = state.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    const messages = conversation.messages.map((message) => {
                        if (message.id === action.messageId) {
                            return {
                                ...message,
                                isFetching: true,
                                isBackgroundFetching: action.isBackgroundFetching
                            };
                        }
                        return message;
                    });

                    return {
                        ...conversation,
                        messages: messages
                    };
                }
                return conversation;
            });

            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    conversations: conversations
                }
            };
        }
        case "GET_CONVERSATION_MESSAGE_COMPLETE": {
            const conversations = state.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    let messages = [...conversation.messages].filter((m) => m.id !== -1);

                    const message = messages.find((x) => x.id === action.messageId);
                    // if we find the message we are updating one in the list other wise we are just adding it
                    if (message) {
                        messages = messages.map((message) => {
                            if (message.id === action.messageId) {
                                if (action.succeeded) {
                                    return action.message;
                                }
                                return {
                                    ...message,
                                    isFetching: false,
                                    isBackgroundFetching: false,
                                    conversationId: action.conversationId,
                                    id: action.messageId,
                                    errorMessage: action.error
                                };
                            }
                            return message;
                        });
                    } else {
                        // if the action is a success push it, if it fails... push it but with an error message
                        messages.push(
                            action.succeeded
                                ? action.message
                                : ({
                                      ...action.message,
                                      id: action.messageId,
                                      conversationId: action.conversationId,
                                      body: null,
                                      errorMessage: action.error,
                                      isFetching: false,
                                      isBackgroundFetching: false,
                                      isSubmitting: false
                                  } as MessageModel)
                        );
                    }

                    return {
                        ...conversation,
                        messages: messages
                    };
                }
                return conversation;
            });

            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    conversations: conversations
                }
            };
        }
        case "GET_CONVERSATION_MESSAGES_INIT": {
            const conversations = state.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    return {
                        ...conversation,
                        isBackgroundFetching: action.isBackgroundFetching,
                        isFetching: true
                    };
                }
                return conversation;
            });

            const isFetchingSelectedConversation =
                action.conversationId === state.conversationState.selectedConversationId;

            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    conversations: conversations
                },
                conversationState: {
                    ...state.conversationState,
                    isBackgroundFetching: isFetchingSelectedConversation ? action.isBackgroundFetching : false,
                    isFetching: isFetchingSelectedConversation
                }
            };
        }
        case "GET_CONVERSATION_MESSAGES_COMPLETE": {
            const conversations = state.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    return {
                        ...conversation,
                        isFetching: false,
                        isBackgroundFetching: false,
                        messages: action.messages,
                        errorMessage: action.succeeded ? null : action.error
                    };
                }
                return conversation;
            });

            const isFetchingSelectedConversation =
                action.conversationId === state.conversationState.selectedConversationId;

            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    conversations: conversations
                },
                conversationState: {
                    ...state.conversationState,
                    isFetching: false,
                    isBackgroundFetching: false,
                    errorMessage: isFetchingSelectedConversation ? (action.succeeded ? null : action.error) : null
                }
            };
        }
        case "SET_CONVERSATION_MESSAGE_VIEWED_INIT": {
            const clonedState = cloneDeep(state);

            const conversations = clonedState.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    conversation.messages = conversation.messages
                        ? conversation.messages.map((message) => {
                              if (message.id === action.messageId) {
                                  return {
                                      ...message,
                                      viewedOnUtc: Formatter.getUTCNow(),
                                      isSubmitting: true
                                  };
                              }
                              return message;
                          })
                        : [];
                }
                return conversation;
            });

            return {
                ...clonedState,
                conversationsState: {
                    ...clonedState.conversationsState,
                    conversations: conversations
                }
            };
        }
        case "SET_CONVERSATION_MESSAGE_VIEWED_COMPLETED": {
            const clonedState = cloneDeep(state);

            const conversations = clonedState.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    conversation.messages = conversation.messages
                        ? conversation.messages.map((message) => {
                              if (message.id === action.messageId) {
                                  return {
                                      ...message,
                                      isSubmitting: false,
                                      errorMessage: action.succeeded ? null : action.error
                                  };
                              }
                              return message;
                          })
                        : [];
                }
                return conversation;
            });

            return {
                ...clonedState,
                conversationsState: {
                    ...clonedState.conversationsState,
                    conversations: conversations
                }
            };
        }
        case "GET_CATEGORIES_INIT": {
            return {
                ...state,
                categoryState: {
                    ...state.categoryState,
                    isFetching: true
                }
            };
        }
        case "GET_CATEGORIES_COMPLETE": {
            return {
                ...state,
                categoryState: {
                    ...state.categoryState,
                    isFetching: false,
                    categories: action.categories,
                    errorMessage: action.succeeded ? null : action.error
                }
            };
        }
        case "CLOSE_CONVERSATION_INIT": {
            const conversations = state.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    return {
                        ...conversation,
                        isSubmitting: true
                    };
                }

                return conversation;
            });

            const isSubmittingSelectedConversation =
                state.conversationState.selectedConversationId === action.conversationId;

            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    conversations: conversations
                },
                conversationState: {
                    ...state.conversationState,
                    isSubmitting: isSubmittingSelectedConversation
                }
            };
        }
        case "CLOSE_CONVERSATION_COMPLETE": {
            const conversations = state.conversationsState.conversations.map((conversation) => {
                if (conversation.id === action.conversationId) {
                    return {
                        ...conversation,
                        resolvedOnUtc: action.succeeded ? Formatter.getUTCNow() : null,
                        errorMessage: action.succeeded ? null : action.error,
                        isSubmitting: false
                    };
                }

                return conversation;
            });

            const isSubmittingSelectedConversation =
                state.conversationState.selectedConversationId === action.conversationId;

            let selectedConversationError = null;
            if (isSubmittingSelectedConversation && !action.succeeded) {
                selectedConversationError = action.error;
            }

            return {
                ...state,
                conversationsState: {
                    ...state.conversationsState,
                    conversations: conversations
                },
                conversationState: {
                    ...state.conversationState,
                    isSubmitting: isSubmittingSelectedConversation ? false : state.conversationState.isSubmitting,
                    message: action.succeeded ? "" : state.conversationState.message,
                    messageAttachments: action.succeeded ? [] : state.conversationState.messageAttachments,
                    errorMessage: selectedConversationError
                }
            };
        }
        case "REOPEN_CONVERSATION_INIT": {
            return StateAdjuster.newConversationMessageInit(state, action);
        }
        case "REOPEN_CONVERSATION_COMPLETE": {
            return StateAdjuster.newConversationMessageComplete(state, action);
        }
        case "SUBMIT_NEW_CONVERSATION_MESSAGE_INIT": {
            return StateAdjuster.newConversationMessageInit(state, action);
        }
        case "SUBMIT_NEW_CONVERSATION_MESSAGE_COMPLETE": {
            return StateAdjuster.newConversationMessageComplete(state, action);
        }
        case "SET_SELECTED_CONVERSATION_MESSAGE": {
            return {
                ...state,
                conversationState: {
                    ...state.conversationState,
                    message: action.message
                }
            };
        }
        case "ADD_MESSAGE_ATTACHMENT_INIT": {
            return {
                ...state,

                conversationState: {
                    ...state.conversationState,
                    messageAttachments: state.conversationState.messageAttachments.concat({
                        uploadTokenId: null,
                        localId: action.localId,
                        fileName: action.file.name,
                        file: { ...action.file },
                        percentUploaded: 0,
                        isSubmitting: true
                    } as LocalMessageAttachment)
                }
            };
        }
        case "ADD_MESSAGE_ATTACHMENT_PROGRESS": {
            return {
                ...state,
                conversationState: {
                    ...state.conversationState,
                    messageAttachments: state.conversationState.messageAttachments.map((messageAttachment) => {
                        if (messageAttachment.localId === action.localId) {
                            return {
                                ...messageAttachment,
                                percentUploaded: action.percentUploaded
                            };
                        }
                        return messageAttachment;
                    })
                }
            };
        }
        case "ADD_MESSAGE_ATTACHMENT_COMPLETE": {
            return {
                ...state,
                conversationState: {
                    ...state.conversationState,
                    messageAttachments: state.conversationState.messageAttachments.map((messageAttachment) => {
                        if (messageAttachment.localId === action.localId) {
                            return {
                                ...messageAttachment,
                                uploadTokenId: action.uploadTokenId,
                                percentUploaded: 100,
                                isSubmitting: false,
                                errorMessage: action.error
                            };
                        }
                        return messageAttachment;
                    })
                }
            };
        }
        case "DELETE_MESSAGE_ATTACHMENT_INIT": {
            return {
                ...state,
                conversationState: {
                    ...state.conversationState,
                    messageAttachments: state.conversationState.messageAttachments.map((messageAttachment) => {
                        if (messageAttachment.localId === action.localId) {
                            return {
                                ...messageAttachment,
                                isSubmitting: true,
                                isDeleting: true
                            };
                        }
                        return messageAttachment;
                    })
                }
            };
        }
        case "DELETE_MESSAGE_ATTACHMENT_COMPLETE": {
            return {
                ...state,
                conversationState: {
                    ...state.conversationState,
                    messageAttachments: state.conversationState.messageAttachments.filter(
                        (messageAttachment) => messageAttachment.localId !== action.localId
                    )
                }
            };
        }
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            conversationsState: {
                isFetching: false,
                isBackgroundFetching: false,
                errorMessage: null,
                conversations: []
            },
            conversationState: {
                isFetching: false,
                isBackgroundFetching: false,
                errorMessage: null,
                isOpen: false,
                isSubmitting: false,
                selectedConversationId: null,
                messageAttachments: []
            },
            newConversationState: {
                isOpen: false,
                isSubmitting: false
            },
            categoryState: {
                isFetching: false,
                errorMessage: null,
                categories: []
            }
        } as InboxState)
    );
};
