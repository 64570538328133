import { push } from "redux-first-history";

import API from "api";
import { NetworkRequest } from "api/actions";
import { PersonActionCreators, PersonActions } from "modules/person/actionCreator";
import { actionCreators as InboxActionCreators, KnownActions as InboxActions } from "modules/inbox/actionCreator";
import { Formatter } from "modules/utils";
import { ApplicationState as AppState, AppThunkAction } from "store";
import * as actions from "./actions";
import { AuthResponse } from "./models";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";
import { signalRConnection } from "signalr";
import { RouterAction } from "routes";

export type DownstreamActions = PersonActions | InboxActions;

export type HandledAction =
    | actions.LogInInitAction
    | actions.LogInCompleteAction
    | actions.ReauthenticateInitAction
    | actions.ReauthenticateCompleteAction
    | actions.RefreshAccessTokenInitAction
    | actions.RefreshAccessTokenCompleteAction
    | actions.SetUserIdleStatus
    | actions.ApplicationInitAction;

export type AuthActions = HandledAction | DownstreamActions | RouterAction | NetworkRequest;

const MIN_SECONDS_VALUE = 1000000000000;
const MILLISECONDS_CONST = 1000;
const MINUTES_CONST = MILLISECONDS_CONST * 60;
const MINUTES_THRESHOLD = 30;

export const RefreshToken = {
    promise: null
};

function userIsAuthenticated(state: AppState): boolean {
    return state.auth.session.userId && !!state.auth.session.accessTokenExpiry;
}

function shouldReauthenticate(state: AppState): boolean {
    const expiry = state.auth.session.accessTokenExpiry;
    const exp = expiry < MIN_SECONDS_VALUE ? expiry * MILLISECONDS_CONST : expiry;
    const now = new Date().getTime();
    const isExpired = exp < now || exp - now < MINUTES_CONST * MINUTES_THRESHOLD;
    return isExpired;
}

export const AuthActionCreators = {
    logIn:
        (username: string, password: string, returnUrl?: string): AppThunkAction<AuthActions> =>
        (dispatch, getState) => {
            dispatch({ type: "LOG_IN_INIT" });

            const task = dispatch(API.authentication.logIn(username, password))
                .then((response) => {
                    if (response?.status === 200) {
                        (response.json() as Promise<AuthResponse>)
                            .then((authResponse: AuthResponse) => {
                                dispatch({
                                    type: "LOG_IN_COMPLETE",
                                    username,
                                    authResponse,
                                    nonPilotUserLoggedIn: false
                                });
                                dispatch(push(returnUrl || "/"));
                                return authResponse;
                            })
                            .then((authResponse: AuthResponse) =>
                                dispatch(PersonActionCreators.getPerson(authResponse.userId))
                            )
                            .then(() => {
                                const toggle = getState()?.features?.featureToggles?.EnableMessaging;
                                if (FeatureToggleChecker.isOn(toggle)) {
                                    signalRConnection.connect();
                                    dispatch(InboxActionCreators.getConversations());
                                }
                            });
                    } else if (response?.status === 400 || response?.status === 401) {
                        dispatch({
                            type: "LOG_IN_COMPLETE",
                            username,
                            authResponse: {
                                succeeded: false,
                                message: "Your username or password is incorrect. Please try signing in again."
                            },
                            nonPilotUserLoggedIn: false
                        });
                    } else if (response?.status === 403) {
                        (response.json() as Promise<AuthResponse>).then((authResponse: AuthResponse) => {
                            if (authResponse.message === "not_verfied") {
                                if (authResponse.isVerificationExpired) {
                                    dispatch({
                                        type: "LOG_IN_COMPLETE",
                                        username,
                                        authResponse: {
                                            succeeded: false,
                                            message:
                                                "Looks like your confirmation email has expired. You'll need to re-register - be sure to verify your account."
                                        },
                                        nonPilotUserLoggedIn: false
                                    });
                                } else {
                                    dispatch({
                                        type: "LOG_IN_COMPLETE",
                                        username,
                                        authResponse: {
                                            succeeded: false,
                                            message: `Looks like you haven't confirmed your account yet. Please find the email we sent to ${
                                                authResponse.emailAddress
                                            } at ${Formatter.formatLocalDate(
                                                authResponse.emailSentDateTime,
                                                true,
                                                true
                                            )} and click the verification link.`,
                                            secondaryMessage: `After checking your spam folder, if you still can't find this email, please re-register your account.`
                                        },
                                        nonPilotUserLoggedIn: false
                                    });
                                }
                            } else if (authResponse.message === "user_membership_terminated") {
                                dispatch({
                                    type: "LOG_IN_COMPLETE",
                                    username,
                                    authResponse: {
                                        succeeded: false,
                                        message: `Uh oh! We can't sign you in. Please contact ASEBP for assistance.`
                                    },
                                    nonPilotUserLoggedIn: false
                                });
                            } else if (authResponse.message === "possible_arta_login") {
                                dispatch({
                                    type: "LOG_IN_COMPLETE",
                                    username,
                                    authResponse: { succeeded: false, artaLoginUrl: authResponse.artaLoginUrl },
                                    nonPilotUserLoggedIn: false
                                });
                            } else {
                                dispatch({
                                    type: "LOG_IN_COMPLETE",
                                    username,
                                    authResponse: {
                                        succeeded: false,
                                        message: "Oops! We can't sign you in right now. Please try again later."
                                    },
                                    nonPilotUserLoggedIn: false
                                });
                            }
                        });
                    } else {
                        dispatch({
                            type: "LOG_IN_COMPLETE",
                            username,
                            authResponse: {
                                succeeded: false,
                                message: "Oops! We can't sign you in right now. Please try again later."
                            },
                            nonPilotUserLoggedIn: false
                        });
                    }
                })
                .catch((error) => {
                    return console?.log(error);
                });

            return task;
        },
    // Intended to log out the session without redirecting to the log in page.
    // This is called when the session timeout dialog is shown so that a browser
    // refresh does not bypass the need to log in.
    logOutSilently: (): AppThunkAction<AuthActions> => (dispatch, getState) => {
        dispatch(API.authentication.logOut()).then(() => {
            const toggle = getState()?.features?.featureToggles?.EnableMessaging;
            if (FeatureToggleChecker.isOn(toggle)) {
                signalRConnection.disconnect();
            }
        });
    },
    // This is similar to log in, but does not redirect to the dashboard after.
    // Used by the session timeout dialog to allow the user to reauth and keep working where they are.
    reauthenticate:
        (password: string): AppThunkAction<AuthActions> =>
        (dispatch, getState) => {
            dispatch({ type: "REAUTHENTICATE_INIT" });

            const username = getState().auth.session.userId;
            const task = dispatch(API.authentication.reauthenticate(username, password)).then((response) => {
                if (response?.status === 200) {
                    (response.json() as Promise<AuthResponse>)
                        .then((authResponse: AuthResponse) => {
                            dispatch({ type: "REAUTHENTICATE_COMPLETE", authResponse });
                            return authResponse;
                        })
                        .then(() => {
                            const toggle = getState()?.features?.featureToggles?.EnableMessaging;
                            if (FeatureToggleChecker.isOn(toggle)) {
                                signalRConnection.connect();
                                dispatch(InboxActionCreators.getConversations(true));
                            }
                        });
                } else if (response?.status === 400 || response?.status === 401) {
                    dispatch({
                        type: "REAUTHENTICATE_COMPLETE",
                        authResponse: {
                            succeeded: false,
                            message: "Password is incorrect. Please try signing in again."
                        }
                    });
                } else {
                    dispatch({
                        type: "REAUTHENTICATE_COMPLETE",
                        authResponse: {
                            succeeded: false,
                            message: "Oops! We can't sign you in right now. Please try again later."
                        }
                    });
                }
            });

            return task;
        },
    refresh: (): AppThunkAction<AuthActions> => (dispatch, getState) => {
        const state = getState();

        if (RefreshToken.promise != null) {
            return RefreshToken.promise;
        }

        if (userIsAuthenticated(state) && shouldReauthenticate(state)) {
            dispatch({ type: "REFRESH_ACCESS_TOKEN_INIT" });

            RefreshToken.promise = Promise.resolve(true).then(
                () => {
                    return dispatch(API.authentication.refreshToken()).then(
                        (response) => {
                            RefreshToken.promise = null;
                            if (response?.status === 200) {
                                return (response.json() as Promise<AuthResponse>).then((authResponse: AuthResponse) => {
                                    dispatch({ type: "REFRESH_ACCESS_TOKEN_COMPLETE", authResponse });
                                    return true;
                                });
                            } else if (response?.status === 401) {
                                dispatch({
                                    type: "REFRESH_ACCESS_TOKEN_COMPLETE",
                                    authResponse: {
                                        succeeded: false,
                                        message: "Failed to refresh token. Session must be expired."
                                    }
                                });
                                return false;
                            } else {
                                dispatch({
                                    type: "REFRESH_ACCESS_TOKEN_COMPLETE",
                                    authResponse: { succeeded: false, message: "Unable to refresh token." }
                                });
                                return false;
                            }
                        },
                        () => {
                            RefreshToken.promise = null;
                            return false;
                        }
                    );
                },
                () => {
                    RefreshToken.promise = null;
                    return false;
                }
            );
        }
        return RefreshToken.promise || Promise.resolve(true);
    },
    extendSession: (): AppThunkAction<AuthActions> => (dispatch, getState) => {
        dispatch(API.authentication.extendSession());
    },
    setUserIdle:
        (idle: boolean): AppThunkAction<AuthActions> =>
        (dispatch, getState) => {
            dispatch({ type: "SET_USER_IDLE", idle });
            const toggle = getState()?.features?.featureToggles?.EnableMessaging;
            if (FeatureToggleChecker.isOn(toggle)) {
                dispatch({ type: "SET_CONVERSATION_MODAL_OPEN", isOpen: false });
            }
        }
};
