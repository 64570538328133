import * as React from "react";

export interface DotSeparatorProps {
    multiline?: boolean;
}

export class DotSeparator extends React.PureComponent<DotSeparatorProps> {
    public render() {
        const { multiline } = this.props;
        const multilineClass = multiline ? "multiline" : "";

        return <span className={`separator ${multilineClass}`}>•</span>;
    }
}
