export interface ApplicationState {
    notifications: NotificationsState;
}

export enum NotificationStyle {
    Default = 0,
    Success = 1,
    Error = 2
}

export interface NotificationsState {
    message: string;
    style: NotificationStyle;
    isSuccess: boolean;
    autohide: boolean;
    canDismiss: boolean;
}
