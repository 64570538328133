import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { SearchResult } from "../models";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { Tag } from "modules/common/components/misc";

interface SearchResultListItemProps {
    result: SearchResult;
    onClick: () => any;
}

export class SearchResultListItem extends React.Component<SearchResultListItemProps> {
    public render() {
        const { result } = this.props;
        const isInternalLink = result.url.indexOf("http") < 0;
        const internalLink = React.forwardRef<HTMLAnchorElement, any>((props, ref) => {
            return <RouterLink {...props} innerRef={ref as any} />;
        });

        return isInternalLink ? (
            <Card>
                <CardActionArea component={internalLink} to={result.url} onClick={this.props.onClick}>
                    <CardContent className="">
                        <Typography variant="h5" component="h2" className="highlighted-search-result" color="primary">
                            {result.highlights && result.highlights["title"] ? <span dangerouslySetInnerHTML={{ __html: result.highlights["title"] }}></span> : result.title}
                        </Typography>
                        <Typography gutterBottom color="textSecondary" variant="subtitle2" component="span">
                            {result.url}
                        </Typography>
                        <Typography variant="body2" color="textPrimary" component="p" className="highlighted-search-result">
                            {result.highlights && result.highlights["description"] ? <span dangerouslySetInnerHTML={{ __html: result.highlights["description"] }}></span> : result.description}
                        </Typography>
                        <div style={{ marginTop: 16 }}>
                            {result.highlights
                                ? Object.keys(result.highlights)
                                      .filter(k => k != "title" && k != "description")
                                      .map(k => {
                                          return <div key={k}>{result.highlights[k]}</div>;
                                      })
                                : null}
                            {result.tags && result.tags.length
                                ? result.tags.map(tag => {
                                      return <Tag key={tag} label={tag} />;
                                  })
                                : null}
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        ) : (
            <Card>
                <CardActionArea href={result.url} target="_blank" rel="noopener noreferrer" onClick={this.props.onClick}>
                    <CardContent>
                        <Typography variant="h5" component="h2" className="highlighted-search-result" color="primary">
                            {result.title}
                        </Typography>
                        <Typography gutterBottom color="textSecondary" variant="subtitle2" component="span">
                            {result.url}
                        </Typography>
                        <Typography variant="body2" color="textPrimary" component="p" className="highlighted-search-result">
                            {result.description}
                        </Typography>
                        <div style={{ marginTop: 16 }}>
                            {result.highlights
                                ? Object.keys(result.highlights)
                                      .filter(k => k != "title" && k != "description")
                                      .map(k => {
                                          return <div key={k}>{result.highlights[k]}</div>;
                                      })
                                : null}
                            {result.tags && result.tags.length
                                ? result.tags.map(tag => {
                                      return <Tag key={tag} label={tag} />;
                                  })
                                : null}
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
}
