import { subMonths, isAfter } from "date-fns";

import { Option } from "modules/common/components/forms";
import { Relative, VoStatusEnum } from "modules/person/models";
import { Formatter } from "modules/utils";

export interface GroupedPersonOptions {
    activeOptions: Option[];
    allOptions: Option[];
}

/** Filters the family members (including the covered member) into `activeOptions` and `allOptions` based on combined status */
export function getGroupedPersonOptions(
    family: Relative[],
    memberCertificateNumber: string,
    hideLongTerminated: boolean,
    allowCustomValues: boolean,
    isLocked: boolean,
    currentValue: string,
    showClearOption?: boolean,
    clearOptionLabel?: string,
    someoneElseKey?: string,
    someoneElseIcon?: JSX.Element
): GroupedPersonOptions {
    const options = {} as GroupedPersonOptions;
    const now = new Date();
    const nineteenMonthsAgo = subMonths(now, 19);

    const memberPlusDependants = family.filter(
        (m) => m.certificateNumber === memberCertificateNumber || m.isDependantOfMember
    );

    options.activeOptions = memberPlusDependants
        .filter((r) => !r.combinedStatus || !r.combinedStatus.isTerminated)
        .map((r) => {
            return {
                key: r.certificateNumber,
                content: Formatter.formatFamilyMemberName(r, true),
                value: r.certificateNumber,
                muted: !!r.combinedStatus && r.combinedStatus.isTerminated,
                animateVisibility: false,
                visible: true,
                disabled: isLocked
            } as Option;
        })
        .filter((option) => {
            return !isLocked || option.value === currentValue;
        });

    if (showClearOption) {
        options.activeOptions = [
            {
                key: "CLEAR_OPTION",
                content: clearOptionLabel || "",
                value: null,
                muted: false,
                animateVisibility: false,
                visible: true,
                disabled: isLocked
            } as Option
        ].concat(options.activeOptions);
    }

    options.allOptions = options.activeOptions.concat(
        memberPlusDependants
            .filter(
                (r) =>
                    r.combinedStatus &&
                    r.combinedStatus.isTerminated &&
                    (!hideLongTerminated || isAfter(new Date(r.combinedStatus.terminationDate), nineteenMonthsAgo))
            )
            .map((r) => {
                return {
                    key: r.certificateNumber,
                    content: Formatter.formatFamilyMemberName(r, true),
                    value: r.certificateNumber,
                    muted: !!r.combinedStatus && r.combinedStatus.statusCode === VoStatusEnum.Terminated,
                    animateVisibility: true,
                    visible: true,
                    disabled: isLocked
                } as Option;
            })
            .filter((option) => {
                return !isLocked || option.value === currentValue;
            })
    );

    if (allowCustomValues) {
        options.allOptions = options.allOptions.concat(
            [
                {
                    key: someoneElseKey,
                    content: "Someone else",
                    value: someoneElseKey,
                    muted: false,
                    animateVisibility: false,
                    visible: true,
                    icon: someoneElseIcon,
                    disabled: isLocked
                }
            ].filter((option) => {
                return !isLocked || option.value === currentValue;
            })
        );
    }

    return options;
}
