import * as React from "react";
import { connect } from "react-redux";
import { Snackbar, Button } from "@mui/material";

import { ApplicationState } from "store";
import * as NotificationsActionCreators from "../actionCreator";
import { NotificationsState, NotificationStyle } from "../models";

/**
 * TOAST Notifications
 *      Referenced in 
 *          "src\modules\common\components\layout\LayoutBase.tsx"
 *      to render Toast notification at top of page. 
 * 
 *      Uses dispatch of type: "ADD_NOTIFICATION"
 */


class Notifications extends React.PureComponent<ComponentProps> {
    public render() {
        let style = "";
        if (this.props.style === NotificationStyle.Error) {
            style = "error";
        } else if (this.props.style === NotificationStyle.Success) {
            style = "success";
        }

        return (
            <Snackbar
                className={`notification-snackbar ${style}`}
                open={!!this.props.message}
                message={<span id="snackbar-text">{this.props.message || ""}</span>}
                autoHideDuration={this.props.autohide ? 10000 : 15000}
                action={
                    this.props.canDismiss ? (
                        <Button id="snackbar-ok-button" onClick={this.handleRequestClose}>
                            OK
                        </Button>
                    ) : null
                }
                onClose={this.handleRequestClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            />
        );
    }

    private handleRequestClose = () => {
        this.props.dismissNotification();
    };
}

export default connect(
    (state: ApplicationState): NotificationsState => {
        return state.notifications;
    },
    NotificationsActionCreators // map all search action creators to dispatch
)(Notifications);

type ComponentProps = NotificationsState & typeof NotificationsActionCreators;
