import * as React from "react";
import { connect } from "react-redux";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Button, Icon } from "@mui/material";

import { ApplicationState } from "store";
import { FeedbackActionCreators } from "../actionCreator";

export interface OpenFeedbackFormButtonProps {
    renderWithIcon?: boolean;
    referrerUrl?: string;
}

class OpenFeedbackFormButton extends React.PureComponent<ComponentProps> {
    public render() {
        const linkToContactPage = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
            return <RouterLink {...props} innerRef={ref as any} />;
        });

        return this.props.isAuthenticated ? (
            <>
                <div className={`${this.props.renderWithIcon ? "with-icon" : ""}`}>
                    <Button component={linkToContactPage} to={"/contact?mode=feedback&referrerUrl=" + (this.props.referrerUrl ? this.props.referrerUrl : "")}>
                        {this.props.renderWithIcon ? (
                            <div className="icon-container">
                                <Icon>feedback</Icon>
                            </div>
                        ) : null}
                        <span>Feedback</span>
                    </Button>
                </div>
            </>
        ) : null;
    }

    private open = () => {
        this.props.openFeedbackForm();
    };
}

interface IStateToProps {
    isAuthenticated: boolean;
}

interface IDispatchToProps {
    openFeedbackForm: () => any;
}

type ComponentProps = OpenFeedbackFormButtonProps & IStateToProps & IDispatchToProps;

export default connect(
    (state: ApplicationState) => {
        return {
            isAuthenticated: !!state.auth.session.userId
        };
    },
    {
        openFeedbackForm: FeedbackActionCreators.showFeedbackForm
    }
)(OpenFeedbackFormButton);
