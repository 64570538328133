import { NetworkRequest } from "api/actions";
import * as actions from "./actions";
import API from "api";
import { AppThunkAction } from "store";
import {
    CategoryModel,
    ConversationModel,
    IncomingConversation,
    IncomingMessage,
    MessageModel,
    NewConversationModel,
    NewMessageModel,
    PagedConversationsModel
} from "./models";
import { ConversationUtils } from "./conversationUtils";
import { MAX_ALLOWED_UPLOAD_BYTES } from "modules/constants";
import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import { v4 as uuid } from "uuid";
import { ApiValidationError } from "api/models";

export type KnownActions =
    | actions.SubmitNewConversationInitAction
    | actions.SubmitNewConversationCompleteAction
    | actions.SetNewConversationModalOpenAction
    | actions.SetConversationModalOpenAction
    | actions.SetSelectedConversationAction
    | actions.GetConversationMessageInitAction
    | actions.GetConversationMessageCompleteAction
    | actions.GetConversationMessagesInitAction
    | actions.GetConversationMessagesCompleteAction
    | actions.GetConversationInitAction
    | actions.GetConversationCompleteAction
    | actions.SubmitNewConversationMessageInitAction
    | actions.SubmitNewConversationMessageCompleteAction
    | actions.SetConversationMessageViewedInitAction
    | actions.SetConversationMessageViewedCompletedAction
    | actions.GetConversationsInitAction
    | actions.GetConversationsCompleteAction
    | actions.GetCategoriesInitAction
    | actions.GetCategoriesCompleteAction
    | actions.ReOpenConversationInitAction
    | actions.ReOpenConversationCompleteAction
    | actions.CloseConversationInitAction
    | actions.CloseConversationCompleteAction
    | actions.SetSelectedConversationMessageAction
    | actions.AddMessageAttachmentInitAction
    | actions.AddMessageAttachmentProgressAction
    | actions.AddMessageAttachmentCompleteAction
    | actions.DeleteMessageAttachmentInitAction
    | actions.DeleteMessageAttachmentCompleteAction
    | actions.ClearMessageAttachmentsAction;

export type DispatchedAction = KnownActions | NetworkRequest | AddNotificationAction;

export const actionCreators = {
    reOpenConversation:
        (conversationId: number, newMessage: NewMessageModel): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "REOPEN_CONVERSATION_INIT",
                message: newMessage,
                conversationId,
                isSubmitting: true
            });

            return dispatch(API.inbox.submitConversationMessage(conversationId, newMessage))
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<number>;
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then((messageId) => {
                    if (messageId) {
                        dispatch({
                            type: "REOPEN_CONVERSATION_COMPLETE",
                            conversationId,
                            messageId,
                            message: newMessage,
                            succeeded: true
                        });

                        const conversationUtils = ConversationUtils.newInstance(getState, conversationId, messageId);
                        const conversation = conversationUtils.conversation;
                        if (!!conversation) {
                            const message = conversationUtils.conversationMessage;
                            // if there is no message in state go get it
                            if (!message) {
                                dispatch(actionCreators.getConversationMessage(conversationId, messageId, true));
                            }
                        }
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "REOPEN_CONVERSATION_COMPLETE",
                        conversationId,
                        messageId: -1,
                        message: newMessage,
                        succeeded: false,
                        error: "Failed to submit message: " + error
                    });
                });
        },
    closeConversation:
        (conversationId: number): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "CLOSE_CONVERSATION_INIT",
                conversationId: conversationId,
                isSubmitting: true
            });
            const conversationUtils = ConversationUtils.newInstance(getState, conversationId);
            const initialConversationVersion = conversationUtils.conversationVersion;

            return dispatch(API.inbox.closeConversation(conversationId))
                .then((response) => {
                    if (response?.status === 204) {
                        return dispatch({
                            type: "CLOSE_CONVERSATION_COMPLETE",
                            conversationId,
                            succeeded: true
                        });
                    }
                    if (response?.status === 409) {
                        return dispatch({
                            type: "CLOSE_CONVERSATION_COMPLETE",
                            conversationId,
                            succeeded: false,
                            error: "Conflict with resolving conversation"
                        });
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then(() => {
                    // check to see if we have the latest conversation  already - if not get it
                    if (conversationUtils.shouldUpdateConversation(initialConversationVersion)) {
                        return dispatch(actionCreators.getConversation(conversationId, true));
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "CLOSE_CONVERSATION_COMPLETE",
                        conversationId,
                        succeeded: false,
                        error: "Failed to set conversation resolved: " + error
                    });
                });
        },
    getCategories: (): AppThunkAction<DispatchedAction> => (dispatch, getState) => {
        dispatch({
            type: "GET_CATEGORIES_INIT"
        });

        return dispatch(API.inbox.getCategories())
            .then((response) => {
                if (response?.status === 200) {
                    return response.json() as Promise<CategoryModel[]>;
                }
                throw new Error(`Response Status: ${response.status}`);
            })
            .then((categories: CategoryModel[]) => {
                dispatch({
                    type: "GET_CATEGORIES_COMPLETE",
                    succeeded: true,
                    categories: categories ? categories : []
                });
            })
            .catch((error) => {
                dispatch({
                    type: "GET_CATEGORIES_COMPLETE",
                    succeeded: false,
                    categories: null,
                    error: `Failed to get conversations: ${error}`
                });
            });
    },
    getConversations:
        (isBackgroundFetching?: boolean): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "GET_CONVERSATIONS_INIT",
                isBackgroundFetching: isBackgroundFetching
            });

            return dispatch(API.inbox.getConversations())
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<PagedConversationsModel>;
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then((pagedConversationModel: PagedConversationsModel) => {
                    dispatch({
                        type: "GET_CONVERSATIONS_COMPLETE",
                        conversations: pagedConversationModel ? pagedConversationModel.results : [],
                        succeeded: true
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: "GET_CONVERSATIONS_COMPLETE",
                        succeeded: false,
                        error: "Failed to get conversations: " + error,
                        conversations: null
                    });
                });
        },
    getConversation:
        (conversationId: number, isBackgroundFetching?: boolean): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "GET_CONVERSATION_INIT",
                isBackgroundFetching: isBackgroundFetching,
                conversationId: conversationId
            });

            return dispatch(API.inbox.getConversation(conversationId))
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<ConversationModel>;
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then((conversation: ConversationModel) => {
                    if (conversation) {
                        dispatch({
                            type: "GET_CONVERSATION_COMPLETE",
                            conversationId: conversationId,
                            conversation,
                            succeeded: true
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "GET_CONVERSATION_COMPLETE",
                        succeeded: false,
                        error: "Failed to get conversation: " + error,
                        conversationId: conversationId
                    });
                });
        },
    submitNewConversation:
        (newConversation: NewConversationModel): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            newConversation.username = getState().person.person.certificateNumber;

            dispatch({
                type: "SUBMIT_NEW_CONVERSATION_INIT",
                conversation: newConversation,
                isSubmitting: true
            });

            return dispatch(API.inbox.submitNewConversation(newConversation))
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<number>;
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then((conversationId) => {
                    if (conversationId) {
                        // check to see if state already has the conversation if not then go get it.
                        const conversation = ConversationUtils.newInstance(getState, conversationId).conversation;
                        if (!conversation) {
                            dispatch(actionCreators.getConversation(conversationId, true));
                        }
                        dispatch(actionCreators.setConversationModalOpen(false, true));
                        dispatch({
                            type: "SUBMIT_NEW_CONVERSATION_COMPLETE",
                            conversation: newConversation,
                            succeeded: true
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "SUBMIT_NEW_CONVERSATION_COMPLETE",
                        succeeded: false,
                        error: "Failed to submit conversation: " + error
                    });
                });
        },
    setConversationModalOpen:
        (isOpen: boolean, isNew: boolean): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            if (!isOpen) {
                dispatch(actionCreators.clearMessageAttachments());
            }
            if (isNew) {
                return dispatch({
                    type: "SET_NEW_CONVERSATION_MODAL_OPEN",
                    isOpen
                });
            } else {
                return dispatch({
                    type: "SET_CONVERSATION_MODAL_OPEN",
                    isOpen
                });
            }
        },
    setSelectedConversation:
        (conversationId: number): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({ type: "SET_SELECTED_CONVERSATION", conversationId });
            dispatch(actionCreators.getConversation(conversationId));
            dispatch(actionCreators.getConversationMessages(conversationId));
            return dispatch(actionCreators.setConversationModalOpen(true, false));
        },
    getConversationMessage:
        (conversationId: number, messageId: number, isBackgroundFetching?: boolean): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "GET_CONVERSATION_MESSAGE_INIT",
                conversationId,
                messageId,
                isBackgroundFetching: isBackgroundFetching
            });

            return dispatch(API.inbox.getConversationMessage(conversationId, messageId))
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<MessageModel>;
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then((message) => {
                    if (message) {
                        dispatch({
                            type: "GET_CONVERSATION_MESSAGE_COMPLETE",
                            conversationId: conversationId,
                            messageId: messageId,
                            message: message,
                            succeeded: true
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "GET_CONVERSATION_MESSAGE_COMPLETE",
                        conversationId,
                        messageId,
                        succeeded: false,
                        error: "Failed to get message: " + error
                    });
                });
        },
    clearMessageAttachments: (): AppThunkAction<DispatchedAction> => (dispatch, getState) => {
        return dispatch({
            type: "CLEAR_MESSAGE_ATTACHMENTS"
        });
    },
    getConversationMessages:
        (conversationId: number, isBackgroundFetching?: boolean): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "GET_CONVERSATION_MESSAGES_INIT",
                conversationId,
                isBackgroundFetching: isBackgroundFetching
            });

            return dispatch(API.inbox.getConversationMessages(conversationId))
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<MessageModel[]>;
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then((messages) => {
                    dispatch({
                        type: "GET_CONVERSATION_MESSAGES_COMPLETE",
                        conversationId: conversationId,
                        messages: messages ? messages : [],
                        succeeded: true
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: "GET_CONVERSATION_MESSAGES_COMPLETE",
                        conversationId: conversationId,
                        succeeded: false,
                        error: "Failed to get messages: " + error
                    });
                });
        },
    submitNewConversationMessage:
        (conversationId: number, newMessage: NewMessageModel): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "SUBMIT_NEW_CONVERSATION_MESSAGE_INIT",
                message: newMessage,
                conversationId,
                isSubmitting: true
            });

            return dispatch(API.inbox.submitConversationMessage(conversationId, newMessage))
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<number>;
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then((messageId) => {
                    if (messageId) {
                        dispatch({
                            type: "SUBMIT_NEW_CONVERSATION_MESSAGE_COMPLETE",
                            conversationId,
                            messageId,
                            message: newMessage,
                            succeeded: true
                        });

                        const conversationUtils = ConversationUtils.newInstance(getState, conversationId, messageId);
                        const conversation = conversationUtils.conversation;
                        if (!!conversation) {
                            const message = conversationUtils.conversationMessage;
                            // if there is no message in state go get it
                            if (!message) {
                                dispatch(actionCreators.getConversationMessage(conversationId, messageId, true));
                            }
                        }
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "SUBMIT_NEW_CONVERSATION_MESSAGE_COMPLETE",
                        conversationId,
                        messageId: -1,
                        message: newMessage,
                        succeeded: false,
                        error: "Failed to submit message: " + error
                    });
                });
        },
    setConversationMessageViewed:
        (conversationId: number, messageId: number): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            // get the current message state version
            const conversationUtils = ConversationUtils.newInstance(getState, conversationId, messageId);
            const initialMessageVersion = conversationUtils.conversationMessageVersion;

            dispatch({
                type: "SET_CONVERSATION_MESSAGE_VIEWED_INIT",
                conversationId: conversationId,
                messageId: messageId,
                isSubmitting: true
            });
            return dispatch(API.inbox.setConversationMessageViewed(conversationId, messageId))
                .then((response) => {
                    if (response?.status === 204 || response?.status === 409) {
                        return dispatch({
                            type: "SET_CONVERSATION_MESSAGE_VIEWED_COMPLETED",
                            conversationId,
                            messageId,
                            succeeded: true
                        });
                    }
                    throw new Error(`Response status: ${response.status}`);
                })
                .then(() => {
                    if (conversationUtils.shouldUpdateMessage(initialMessageVersion)) {
                        return dispatch(actionCreators.getConversationMessage(conversationId, messageId, true));
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: "SET_CONVERSATION_MESSAGE_VIEWED_COMPLETED",
                        conversationId,
                        messageId,
                        succeeded: false,
                        error: "Failed to set message viewed: " + error
                    });
                });
        },
    newConversationReceived:
        (incomingConversation: IncomingConversation): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            const currentUser = getState().person.person.certificateNumber;
            if (currentUser === incomingConversation.username) {
                const conversation = ConversationUtils.newInstance(
                    getState,
                    incomingConversation.conversationId
                ).conversation;

                // if we dont have the conversation or the incoming version is higher than what we have - get the conversation
                if (!conversation || incomingConversation.timestamp > conversation?.timestamp) {
                    dispatch(actionCreators.getConversation(incomingConversation.conversationId, true));
                }
            }
        },
    updatedConversationReceived:
        (incomingConversation: IncomingConversation): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            const currentUser = getState().person.person.certificateNumber;
            if (currentUser === incomingConversation.username) {
                const conversation = ConversationUtils.newInstance(
                    getState,
                    incomingConversation.conversationId
                ).conversation;

                // if we have the conversation check if the incoming version is greater than current version, else if we dont have it go get it
                const needsUpdate =
                    (!!conversation && incomingConversation.timestamp > conversation.timestamp) || !conversation;

                if (needsUpdate) {
                    dispatch(actionCreators.getConversation(incomingConversation.conversationId, true));
                }
            }
        },
    newMessageReceived:
        (incomingMessage: IncomingMessage): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            const currentUser = getState().person.person.certificateNumber;
            if (currentUser === incomingMessage.username) {
                dispatch(actionCreators.getConversation(incomingMessage.conversationId, true)).then(() => {
                    // if we dont have the message or we do but its older than the current version get the message
                    const message = ConversationUtils.newInstance(
                        getState,
                        incomingMessage.conversationId,
                        incomingMessage.messageId
                    ).conversationMessage;

                    if (!message || incomingMessage.timestamp > message.timestamp) {
                        dispatch(
                            actionCreators.getConversationMessage(
                                incomingMessage.conversationId,
                                incomingMessage.messageId,
                                true
                            )
                        );
                    }
                });
            }
        },
    updatedMessageReceived:
        (incomingMessage: IncomingMessage): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            const currentUser = getState().person.person.certificateNumber;
            if (currentUser === incomingMessage.username) {
                // message updates at this point are only related to "views" so no need to check version just get the latest version, we must always fetch
                dispatch(actionCreators.getConversation(incomingMessage.conversationId, true)).then(() => {
                    dispatch(
                        actionCreators.getConversationMessage(
                            incomingMessage.conversationId,
                            incomingMessage.messageId,
                            true
                        )
                    );
                });
            }
        },
    setSelectedConversationMessage:
        (message: string): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            return dispatch({
                type: "SET_SELECTED_CONVERSATION_MESSAGE",
                message: message
            });
        },
    uploadAttachment:
        (file: File, uploadId: string): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "ADD_MESSAGE_ATTACHMENT_INIT",
                localId: uploadId,
                file: file
            } as actions.AddMessageAttachmentInitAction);

            const task = Promise.resolve().then(() => {
                return dispatch(
                    API.inbox.uploadAttachment(file, {
                        onProgress: (percentComplete: number) => {
                            // Don't indicate fully complete until we have the token from the server
                            const shownPercentComplete = Math.min(percentComplete, 85);

                            dispatch({
                                type: "ADD_MESSAGE_ATTACHMENT_PROGRESS",
                                localId: uploadId,
                                percentUploaded: shownPercentComplete
                            });
                        }
                    })
                )
                    .then(
                        (response) => {
                            if (response?.status === 200) {
                                return response.json() as Promise<number>;
                            } else {
                                dispatch({
                                    type: "ADD_MESSAGE_ATTACHMENT_COMPLETE",
                                    localId: uploadId,
                                    uploadTokenId: null,
                                    succeeded: false,
                                    error: "Failed to upload attachment"
                                });
                            }
                        },
                        (error: ErrorEvent) => {
                            dispatch({
                                type: "ADD_MESSAGE_ATTACHMENT_COMPLETE",
                                localId: uploadId,
                                uploadTokenId: null,
                                succeeded: false,
                                error: "Failed to upload attachment"
                            });
                        }
                    )
                    .then((uploadTokenId) => {
                        if (uploadTokenId) {
                            dispatch({
                                type: "ADD_MESSAGE_ATTACHMENT_COMPLETE",
                                localId: uploadId,
                                uploadTokenId: uploadTokenId,
                                succeeded: true
                            });
                        }
                    });
            });

            return task;
        },

    deleteUploadedAttachment:
        (localId: string): AppThunkAction<DispatchedAction> =>
        (dispatch, getState) => {
            dispatch({
                type: "DELETE_MESSAGE_ATTACHMENT_INIT",
                localId: localId
            });

            const completeAction: actions.DeleteMessageAttachmentCompleteAction = {
                type: "DELETE_MESSAGE_ATTACHMENT_COMPLETE",
                localId: localId
            };

            const state = getState();
            const messageAttachment = state.inbox.conversationState.messageAttachments.filter(
                (x) => x.localId === localId
            )[0];

            if (!messageAttachment.uploadTokenId) {
                // remove the attachment from the UI, without deleting from server.
                return dispatch(completeAction);
            }

            return dispatch(API.inbox.deleteUploadedAttachment(messageAttachment.uploadTokenId))
                .then(() => {
                    // on success, remove the attachment from the UI
                    dispatch(completeAction);
                })
                .catch(() => {
                    // on failure, we still want to remove the attachment from the UI
                    dispatch(completeAction);
                });
        }
};
