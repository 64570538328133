import React from "react";

export class Background extends React.PureComponent {
    public render() {
        return (
            <div id="background">
                <div className="filter" />
            </div>
        );
    }
}
