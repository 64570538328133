import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ListItem, Icon, ListItemText, Button, ListItemIcon } from "@mui/material";

interface SettingsButtonProps {
    renderAsListItem?: boolean;
    onClick?: (event: any) => void;
}
export class SettingsButton extends React.Component<SettingsButtonProps, {}> {
    public render() {
        return this.props.renderAsListItem ? (
            <RouterLink to="/settings" onClick={this.props.onClick}>
                <ListItem button id="my-preference-link-button">
                    <ListItemIcon>
                        <Icon>settings</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItem>
            </RouterLink>
        ) : (
            <Button href={"/settings"}>Settings</Button>
        );
    }
}
