import { ApiValidationError } from "api/models";
import { BenefitType } from "hooks/api/benefitsApi";
import { LocalAttachment, SpendingAccountClaimCategory } from "modules/claims/models";
import { Claim } from "../claimDetails/models";
import { ClaimListPage } from "../claimList/models";

export interface ClaimEditorState {
    isFetching: boolean;
    claim: ClaimSubmission;
    claimModifications: ClaimModificationState;

    priorSubmission: Claim | null;
    isFetchingPriorSubmission: boolean;
    allowedSpendingAccountCategories: SpendingAccountClaimCategory[];

    validationState?: ValidationState;
    serverValidationState?: ValidationState;

    isFetchingPossibleDuplicateClaims: boolean;
    possibleDuplicateClaims: ClaimListPage;
}

export interface ClaimModificationState {
    // we can add additional properties as we need them
    // For now, we only need to track changes to receipts/attachments on resubmitted/fixable claims
    hasModifiedAttachments: boolean;
}

export interface SettableClaimSubmissionFields {
    patientCertificateNumber: string;
    patientName: string;

    productOrServiceType: ProductOrServiceType | null;
    description: string;
    serviceDate: Date | string | null;
    amount: number | null;

    attachments: LocalAttachment[];
    benefitSelection: BenefitSelection | null;
    spendingAccountClaimSubCategoryId: number | null;
    shownDuplicateWarningMessage: boolean | null;

    // These properties are only used client-side
    hasSubmittedToAnotherProvider?: boolean | null;
}

export interface ClaimSubmission extends SettableClaimSubmissionFields {
    id: number;
    referenceId: string | null;
    coveredMemberCertificateNumber: string;
    priorSubmissionReferenceId: string | null;
    createdDate: Date | string | null;
    status: ClaimSubmissionStatus;
}

export interface ClaimSubmissionResponse {
    claim: ClaimSubmission;
    validationResult: ApiValidationError;
}

export interface ValidationState {
    isValid: boolean;
    messages: { [property: string]: string };
}

export enum ProductOrServiceType {
    Dental = 10,
    Psychology = 20,
    Physiotherapy = 30,
    MassageTherapy = 40,
    Acupuncture = 50,
    Drugs = 60,
    Chiropractic = 70,
    Vision = 80,

    EverythingElse = 99
}

export enum ClaimSubmissionStatus {
    Draft = 1,
    Submitted = 2
}

export interface BenefitSelection {
    firstBenefit: BenefitType;
    secondBenefit: BenefitType;

    membershipId: number | null; // used to distinguish between two spending account lines...
}
