import * as React from "react";
import { Icon } from "@mui/material";

export interface AlertProps {
  title?: string;
  noBorder?: boolean;
  variant: "warning" | "info" | "error";
  color?: "highlight" | "dark" | "default";
  fullWidth?: boolean;
  className?: string;
}

export const Alert = (props: React.PropsWithChildren<AlertProps>) => {
  const {
    title,
    noBorder,
    variant,
    color = "default",
    className = "",
    fullWidth = false,
  } = props;

  return (
    <div
      className={`alert ${noBorder ? "no-border" : ""} ${variant} ${color} ${fullWidth ? "full-width" : ""} ${className}`}
    >
      {variant === "warning" || variant === "error" ? (
        <div className="alert-icon-wrapper">
          <Icon>{variant === "warning" ? "warning" : "error_outline"}</Icon>
        </div>
      ) : null}
      <div className="alert-content">
        {!!title ? <header>{title}</header> : null}
        <div className="body">{props.children}</div>
      </div>
    </div>
  );
};

