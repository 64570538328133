import { AppThunkAction } from "store";

import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";

import API from "api";
import { NetworkRequest } from "api/actions";

import * as actions from "./actions";
import { Feedback } from "../contact/models";

export type HandledAction =
    | actions.SubmitFeedbackInitAction
    | actions.SubmitFeedbackCompleteAction
    | actions.ShowFeedbackFormAction
    | actions.HideFeedbackFormAction;

export type FeedbackActions = HandledAction | NetworkRequest | AddNotificationAction;

export const FeedbackActionCreators = {
    showFeedbackForm: (): AppThunkAction<FeedbackActions> => (dispatch, getState) => {
        dispatch({ type: "SHOW_FEEDBACK_FORM" });
    },
    hideFeedbackForm: (): AppThunkAction<FeedbackActions> => (dispatch, getState) => {
        dispatch({ type: "HIDE_FEEDBACK_FORM" });
    },
    submitFeedback:
        (feedback: Feedback): AppThunkAction<FeedbackActions> =>
        (dispatch, getState) => {
            dispatch({ type: "SUBMIT_FEEDBACK_INIT", feedback });

            return Promise.resolve()
                .then(() => {
                    return dispatch(API.feedback.post(feedback));
                })
                .then((response) => {
                    if (response?.status >= 200 && response?.status <= 299) {
                        dispatch({ type: "SUBMIT_FEEDBACK_COMPLETE", succeeded: true });
                        dispatch({ type: "HIDE_FEEDBACK_FORM" });
                        dispatch({
                            type: "ADD_NOTIFICATION",
                            message: "Feedback sent. Thanks!",
                            style: NotificationStyle.Success,
                            autohide: true,
                            canDismiss: true
                        });
                    } else {
                        dispatch({ type: "SUBMIT_FEEDBACK_COMPLETE", succeeded: false });
                        dispatch({
                            type: "ADD_NOTIFICATION",
                            message: "Feedback could not be sent. Please try again later.",
                            style: NotificationStyle.Error,
                            autohide: true,
                            canDismiss: true
                        });
                    }
                });
        }
};
