import { AppThunkAction } from "store";

import * as actions from "./actions";
import { NotificationStyle } from "./models";

export type NotificationsActions = actions.AddNotificationAction | actions.DismissNotificationAction;

export const addNotification =
    (
        message: string,
        style: NotificationStyle = NotificationStyle.Default,
        autohide = true,
        canDismiss = true
    ): AppThunkAction<actions.AddNotificationAction> =>
    (dispatch) => {
        dispatch({ type: "ADD_NOTIFICATION", message, style, autohide, canDismiss });
    };

export const dismissNotification = (): AppThunkAction<actions.DismissNotificationAction> => (dispatch) => {
    dispatch({ type: "DISMISS_NOTIFICATION" });
};
