import * as authenticationApi from "./authenticationApi";
import * as benefitsApi from "./benefitsApi";
import * as claimsApi from "./claimsApi";
import * as documentsApi from "./documentsApi";
import * as drugsApi from "./drugsApi";
import * as featuresApi from "./featuresApi";
import * as feedbackApi from "./feedbackApi";
import * as peopleApi from "./peopleApi";
import * as profileApi from "./profileApi";
import * as registrationApi from "./registrationApi";
import * as searchApi from "./searchApi";
import * as serviceProvidersApi from "./serviceProvidersApi";
import * as spendingAccountsApi from "./spendingAccountsApi";
import * as verificationApi from "./verificationApi";

import * as alertsApi from "./alertsApi";
import * as campaignsApi from "./campaignsApi";
import * as noticesApi from "./noticesApi";
import * as inquiriesApi from "./inquiriesApi";
import * as preferencesApi from "./preferencesApi";
import * as inboxApi from "./inboxApi";

export default {
    alerts: alertsApi,
    authentication: authenticationApi,
    benefits: benefitsApi,
    campaigns: campaignsApi,
    claims: claimsApi,
    documents: documentsApi,
    drugs: drugsApi,
    features: featuresApi,
    feedback: feedbackApi,
    notices: noticesApi,
    people: peopleApi,
    profile: profileApi,
    registration: registrationApi,
    search: searchApi,
    serviceProviders: serviceProvidersApi,
    spendingAccounts: spendingAccountsApi,
    verification: verificationApi,
    inquiries: inquiriesApi,
    preferences: preferencesApi,
    inbox: inboxApi,
};
