import * as React from "react";
import {
    Drawer,
    IconButton,
    Accordion,
    AccordionSummary,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LocationOn from "@mui/icons-material/LocationOn";
import { BenefitsIcon } from "modules/common/components/icons";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";
import { routeProvider, SiteArea } from "routeProvider";
import { SignOutButton } from "./SignOutButton";
import { NavDrawerLink } from "./NavDrawerLink";
import { ProfileAvatarIcon } from "./ProfileAvatarIcon";
import { KnownFeatureToggles } from "modules/features/models";
import { FeatureToggledElement } from "../misc/featureToggledElement";

export interface NavDrawerProps {
    isOpen: boolean;
    coveredMember: CoveredMemberSummary;
    hasUnreadFeatureArticles: boolean;
    pathname: string;
    showMembershipCard: () => any;
    toggleClicked: () => any;
}

export interface NavDrawerState {
    isAvatarSectionOpen: boolean;
}

export class NavDrawer extends React.Component<NavDrawerProps, NavDrawerState> {
    public state: NavDrawerState = { isAvatarSectionOpen: false };

    public render() {
        const { isOpen, hasUnreadFeatureArticles, coveredMember, pathname } = this.props;
        const routeDefinition = routeProvider(coveredMember);

        const fullName =
            !!coveredMember.person && !!coveredMember.person.name ? coveredMember.person.name.fullName : "";

        const isHeadOffice = coveredMember?.person?.personStatus?.isHeadOffice;

        const benefitsArea = routeDefinition.benefitsArea;
        const providersArea = routeDefinition.providersArea;

        const profilePage = routeDefinition.profileArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/profile");
        const familyPage = routeDefinition.benefitsArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/benefits/family");
        const homePage = routeDefinition.dashboardArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/home");
        const claimsPage = routeDefinition.claimsArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/claims");
        const claimSubmitPage = routeDefinition.claimsArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/claims/new");
        const documentsPage = routeDefinition.documentsArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/documents");
        const resourcesPage = routeDefinition.resourcesArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/resources");
        const whatsNewPage = routeDefinition.whatsNewArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/whats-new");
        const settingsPage = routeDefinition.settingsArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/settings");

        const inboxPage = routeDefinition.inboxArea.pages
            .filter((p) => !!p.basePath)
            .find((p) => p.basePath === "/inbox");

        const enableSettingsPage = coveredMember.canAccessFeature(KnownFeatureToggles.SettingsPage);

        return (
            <Drawer anchor="left" open={isOpen} onClose={this.props.toggleClicked}>
                <div className="drawer-content">
                    <div className={`drawer-header secondary-color`}>
                        <IconButton
                            className={`nav-drawer-toggle ${hasUnreadFeatureArticles ? "alert" : ""}`}
                            onClick={this.props.toggleClicked}
                            size="large">
                            <i className="material-icons">menu</i>
                        </IconButton>
                    </div>
                    <div className="drawer-body">
                        <Accordion className="profile-panel" defaultExpanded={this.state.isAvatarSectionOpen}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="profile-summary">
                                <ListItem color="secondary">
                                    <ListItemIcon>
                                        <ProfileAvatarIcon person={coveredMember.person} size="md" hideDropdownIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={fullName} />
                                </ListItem>
                            </AccordionSummary>
                            <div className="subpage-section">
                                <NavDrawerLink
                                    page={profilePage}
                                    pathname={pathname}
                                    toggleClicked={this.toggleClicked(true)}
                                />
                                <FeatureToggledElement knownFeatureToggle={KnownFeatureToggles.SettingsPage}>
                                    {coveredMember.hasT4ASlips ? (
                                        <NavDrawerLink
                                            page={settingsPage}
                                            pathname={pathname}
                                            toggleClicked={this.toggleClicked(true)}
                                        />
                                    ) : null}
                                </FeatureToggledElement>
                                <NavDrawerLink
                                    page={familyPage}
                                    pathname={pathname}
                                    toggleClicked={this.toggleClicked(true)}
                                />
                                <ListItem button onClick={this.props.showMembershipCard} className="subpage-item">
                                    <ListItemText primary="ID Card" color="primary" />
                                </ListItem>
                               <div className="subpage-item signout">
                                    <SignOutButton renderAsListItem hideIcon />
                                </div>
                            </div>
                        </Accordion>
                        <NavDrawerLink
                            page={homePage}
                            pathname={pathname}
                            showIcon
                            toggleClicked={this.toggleClicked()}
                        />
                        <NavDrawerLink
                            page={claimsPage}
                            pathname={pathname}
                            showIcon
                            toggleClicked={this.toggleClicked()}
                        />
                        <NavDrawerLink
                            page={claimSubmitPage}
                            pathname={pathname}
                            showIcon
                            toggleClicked={this.toggleClicked()}
                        />
                        <Accordion
                            className="benefits-panel"
                            defaultExpanded={
                                this.currentPageInSiteArea(benefitsArea) && !this.state.isAvatarSectionOpen
                            }>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="benefits-summary">
                                <ListItem color="secondary">
                                    <ListItemIcon className="benefits-icon">
                                        <BenefitsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={benefitsArea.name} className="benefits-text" />
                                </ListItem>
                            </AccordionSummary>
                            <div className="subpage-section">
                                {benefitsArea.pages
                                    .filter((c) => !!c.basePath)
                                    .map((c) => {
                                        return (
                                            <NavDrawerLink
                                                key={c.basePath}
                                                page={c}
                                                pathname={pathname}
                                                toggleClicked={this.toggleClicked()}
                                            />
                                        );
                                    })}
                            </div>
                        </Accordion>
                        <NavDrawerLink
                            page={documentsPage}
                            pathname={pathname}
                            showIcon
                            toggleClicked={this.toggleClicked()}
                        />

                        {!isHeadOffice && (
                            <FeatureToggledElement knownFeatureToggle={KnownFeatureToggles.EnableMessaging}>
                                <NavDrawerLink
                                    page={inboxPage}
                                    pathname={pathname}
                                    showIcon
                                    showInboxBadge
                                    toggleClicked={this.toggleClicked()}
                                />
                            </FeatureToggledElement>
                        )}

                        <Accordion
                            className="providers-panel"
                            defaultExpanded={
                                this.currentPageInSiteArea(providersArea) && !this.state.isAvatarSectionOpen
                            }>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="providers-summary">
                                <ListItem color="secondary">
                                    <ListItemIcon className="providers-icon">
                                        <LocationOn />
                                    </ListItemIcon>
                                    <ListItemText primary={providersArea.name} className="providers-text" />
                                </ListItem>
                            </AccordionSummary>
                            <div className="subpage-section">
                                {providersArea.pages
                                    .filter((c) => !!c.basePath)
                                    .map((c) => {
                                        return (
                                            <NavDrawerLink
                                                key={c.basePath}
                                                page={c}
                                                pathname={pathname}
                                                toggleClicked={this.toggleClicked()}
                                            />
                                        );
                                    })}
                            </div>
                        </Accordion>

                        <NavDrawerLink
                            page={resourcesPage}
                            pathname={pathname}
                            showIcon
                            toggleClicked={this.toggleClicked()}
                        />
                        <NavDrawerLink
                            page={whatsNewPage}
                            pathname={pathname}
                            showIcon
                            iconColor={hasUnreadFeatureArticles ? "primary" : "secondary"}
                            hasUnreadFeatureArticles={hasUnreadFeatureArticles}
                            toggleClicked={this.toggleClicked()}
                        />
                    </div>
                </div>
            </Drawer>
        );
    }

    private currentPageInSiteArea = (area?: SiteArea): boolean => {
        return area.pages.find((a) => this.props.pathname.indexOf(a.basePath) >= 0) ? true : false;
    };

    private toggleClicked = (avatarSectionLinkClicked = false): any => () => {
        this.setState({ ...this.state, isAvatarSectionOpen: avatarSectionLinkClicked });
        return this.props.toggleClicked();
    };
}
