import API from "api";
import { NetworkRequest } from "api/actions";
import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import * as actions from "modules/profile/bankAccount/actions";
import { Bank, BankAccount, BankAccountRemoval } from "modules/profile/bankAccount/models";
import { ApplicationState as AppState, AppThunkAction } from "store";
import personalInfoVerificationActionCreators, { PersonalInfoVerificationActions } from "modules/profile/personalInfoVerification/actionCreator";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";

export type HandledAction =
    | actions.GetBanksInitAction
    | actions.GetBanksCompleteAction
    | actions.GetBankAccountsInitAction
    | actions.GetBankAccountsCompleteAction
    | actions.SaveBankAccountInitAction
    | actions.SaveBankAccountCompleteAction
    | actions.RemoveBankAccountInitAction
    | actions.RemoveBankAccountCompleteAction;

export type KnownAction = HandledAction | NetworkRequest | AddNotificationAction | PersonalInfoVerificationActions;

export const actionCreators = {
    getBanks: () => (dispatch, getState: () => AppState) => {
        const state = getState();

        if (!state.profile.bankAccountState.banks || !state.profile.bankAccountState.banks.length) {
            dispatch({ type: "GET_BANKS_INIT" });
            return dispatch(API.profile.getBanks())
                .then(
                    (response) => {
                        if (response?.status === 200) {
                            return response.json() as Promise<Bank[]>;
                        } else {
                            dispatch({
                                type: "GET_BANKS_COMPLETE",
                                banks: null,
                                succeeded: false,
                                err: "Failed to get banks"
                            });
                            return null;
                        }
                    },
                    (reason: any) => {
                        return null;
                    }
                )
                .then(
                    (banks: Bank[]) => {
                        if (banks) {
                            dispatch({ type: "GET_BANKS_COMPLETE", banks, succeeded: true });
                        }
                        return banks;
                    },
                    () => null
                );
        } else {
            return Promise.resolve(state.profile.bankAccountState.banks);
        }
    },
    getBankAccounts: () => (dispatch, getState: () => AppState) => {
        const state = getState();

        dispatch(actionCreators.getBanks()).then(() => {
            if (
                !state.profile.bankAccountState ||
                !state.profile.bankAccountState.depositBankAccountState ||
                !state.profile.bankAccountState.withdrawalBankAccountState
            ) {
                dispatch({ type: "GET_BANK_ACCOUNTS_INIT" });

                dispatch(API.profile.getBankAccounts())
                    .then(
                        (response) => {
                            if (response?.status === 200) {
                                return response.json() as Promise<BankAccount[]>;
                            } else {
                                dispatch({
                                    type: "GET_BANK_ACCOUNTS_COMPLETE",
                                    bankAccounts: null,
                                    succeeded: false,
                                    err: "Failed to get profile bank accounts"
                                });
                                return null;
                            }
                        },
                        (reason: any) => {
                            return null;
                        }
                    )
                    .then(
                        (bankAccounts: BankAccount[]) => {
                            if (bankAccounts) {
                                dispatch({ type: "GET_BANK_ACCOUNTS_COMPLETE", bankAccounts, succeeded: true });
                            }
                        },
                        () => null
                    );
            }
        });
    },
    saveBankAccount:
        (bankAccount: BankAccount): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const task = Promise.resolve()
                .then(() => dispatch({ type: "SAVE_BANK_ACCOUNT_INIT", bankAccount }))
                .then(() => {
                    return dispatch(API.profile.saveBankAccount(bankAccount)).then(
                        (response) => {
                            if (response?.status === 200 || response?.status === 204) {
                                dispatch({
                                    type: "ADD_NOTIFICATION",
                                    message: "Success! Your bank account information is updated.",
                                    style: NotificationStyle.Success,
                                    autohide: true,
                                    canDismiss: true
                                });
                                dispatch({
                                    type: "SAVE_BANK_ACCOUNT_COMPLETE",
                                    bankAccount,
                                    succeeded: true,
                                    err: null
                                });

                                const personalInfoVerificationToggle = getState().features.featureToggles.PersonalInformationVerification;
                                if (FeatureToggleChecker.isOn(personalInfoVerificationToggle)) {
                                    dispatch(personalInfoVerificationActionCreators.personalInfoVerified());
                                }
                            } else {
                                let message = "Sorry! Failed to update bank account.";

                                if (response?.status === 403 || response?.status === 401) {
                                    message = "The password you entered is incorrect";
                                }

                                dispatch({
                                    type: "ADD_NOTIFICATION",
                                    message,
                                    style: NotificationStyle.Error,
                                    autohide: true,
                                    canDismiss: true
                                });
                                dispatch({
                                    type: "SAVE_BANK_ACCOUNT_COMPLETE",
                                    bankAccount,
                                    succeeded: false,
                                    err: message
                                });
                            }
                        },
                        (reason: any) => {
                            return null;
                        }
                    );
                });
            return task;
        },
    removeBankAccount:
        (bankAccountRemoval: BankAccountRemoval): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const task = Promise.resolve()
                .then(() => dispatch({ type: "REMOVE_BANK_ACCOUNT_INIT", bankAccountRemoval }))
                .then(() => {
                    return dispatch(API.profile.removeBankAccount(bankAccountRemoval)).then(
                        (response) => {
                            if (response?.status === 200 || response?.status === 204) {
                                dispatch({
                                    type: "ADD_NOTIFICATION",
                                    message: "Bank account removed.",
                                    style: NotificationStyle.Success,
                                    autohide: true,
                                    canDismiss: true
                                });
                                dispatch({
                                    type: "REMOVE_BANK_ACCOUNT_COMPLETE",
                                    bankAccountRemoval,
                                    succeeded: true,
                                    err: null
                                });
                            } else {
                                let message = "Sorry! Failed to remove bank account.";

                                if (response?.status === 403 || response?.status === 401) {
                                    message = "The password you entered is incorrect";
                                }

                                dispatch({
                                    type: "ADD_NOTIFICATION",
                                    message,
                                    style: NotificationStyle.Error,
                                    autohide: true,
                                    canDismiss: true
                                });
                                dispatch({
                                    type: "REMOVE_BANK_ACCOUNT_COMPLETE",
                                    bankAccountRemoval,
                                    succeeded: false,
                                    err: message
                                });
                            }
                        },
                        (reason: any) => {
                            return null;
                        }
                    );
                });
            return task;
        }
};
