import * as React from "react";

import { BenefitSection } from "./benefitSection";
import { SpendingAccountSection } from "./spendingAccountSection";
import { PersonBenefits } from "modules/person/coveredMemberSummary.models";

export interface BenefitListProps {
    personBenefits: PersonBenefits;
    hidePackageName: boolean;
}

export class BenefitList extends React.PureComponent<BenefitListProps> {
    public render() {
        const { personBenefits, hidePackageName } = this.props;

        const activeIncomeReplacementBenefits = personBenefits.incomeReplacementBenefits.filter(b => b.isActive);
        const activeGeneralHealthBenefits = personBenefits.generalHealthBenefits.filter(b => b.isActive);

        const hsaAccounts = personBenefits.hsaAccounts.filter(b => b.isActive || b.isPlaceholder);
        const wsaAccounts = personBenefits.wsaAccounts.filter(b => b.isActive || b.isPlaceholder);

        const activeHsaAccount = hsaAccounts.length ? hsaAccounts[0] : null;
        const activeWsaAccount = wsaAccounts.length ? wsaAccounts[0] : null;

        const hasActiveBenefits = activeIncomeReplacementBenefits.length || activeGeneralHealthBenefits.length || hsaAccounts.length || wsaAccounts.length;

        return (
            <table>
                <tbody>
                    {hasActiveBenefits ? (
                        <>
                            <tr>
                                <th className="school-jurisdiction-info" colSpan={3}>
                                    <span className="school-jurisdiction-name">{personBenefits.schoolJurisdictionName}</span>
                                    <span className="role-name">{personBenefits.friendlyClassName}</span>
                                </th>
                            </tr>
                            <tr>
                                <th className="title benefit">Benefit</th>
                                <th className="title">Coverage</th>
                            </tr>
                            <BenefitSection benefitLines={activeIncomeReplacementBenefits} />
                            <BenefitSection benefitLines={activeGeneralHealthBenefits} />
                            {activeHsaAccount ? <SpendingAccountSection spendingAccount={activeHsaAccount} hidePackageName={hidePackageName} showExpensePlanName={false} /> : null}
                            {activeWsaAccount ? <SpendingAccountSection spendingAccount={activeWsaAccount} hidePackageName={hidePackageName} showExpensePlanName={true} /> : null}
                        </>
                    ) : null}
                </tbody>
            </table>
        );
    }
}
