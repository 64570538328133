import * as React from "react";
import { Accordion, AccordionSummary, Grid, Typography, Button, AccordionDetails } from "@mui/material";

import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";
import { AddressForm } from "./addressForm";
import { ProfileAddressView } from "./profileAddressView";
import { Address, Country } from "../models";

export interface ProfileAddressProps {
    address: Address;
    countries: Country[];
    isSaving: boolean;
    coveredMember: CoveredMemberSummary;
    saveAddress: (address: Address) => any;
    isLoading: boolean;
}

export interface ProfileAddressState {
    isExpanded: boolean;
}

export class ProfileAddressSection extends React.Component<ProfileAddressProps, ProfileAddressState> {
    public state: ProfileAddressState = {
        isExpanded: false
    };

    public componentDidUpdate(prevProps: ProfileAddressProps) {
        if (!this.props.isSaving && prevProps.isSaving !== this.props.isSaving && this.state.isExpanded) {
            this.collapsePanel();
        }
    }

    public render() {
        const { coveredMember } = this.props;

        return (
            <Accordion disabled={!coveredMember.canUpdateAddress} expanded={this.state.isExpanded} className="profile-line">
                <AccordionSummary classes={{ disabled: "panel-summary disabled", root: "panel-summary" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6">Mailing address</Typography>
                        </Grid>
                        <Grid item xs={12} md={!this.state.isExpanded ? 8 : 9}>
                            <ProfileAddressView address={this.props.address} countries={this.props.countries} isLoading={this.props.isLoading} />
                            {!coveredMember.canUpdateAddress ? (
                                <Typography>
                                    <span className="locked-address">Your employer maintains your mailing address. Please contact them if you need to make changes.</span>
                                </Typography>
                            ) : null}
                        </Grid>
                        {!this.state.isExpanded ? (
                            <Grid item xs={12} md={1} className="panel-summary-action">
                                <Button id="edit-address-button" variant="outlined" onClick={this.expandPanel} disabled={!coveredMember.canUpdateAddress || this.props.isLoading}>
                                    EDIT
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {this.props.address && this.state.isExpanded ? (
                        <AddressForm profileAddress={this.props.address} countries={this.props.countries} onSaveAddressRequested={this.props.saveAddress} onCancel={this.collapsePanel} isSaving={this.props.isSaving} />
                    ) : null}
                    <></>
                </AccordionDetails>
            </Accordion>
        );
    }

    private expandPanel = () => {
        this.setState(s => ({ ...s, isExpanded: true }));
    };

    private collapsePanel = () => {
        this.setState(s => ({ ...s, isExpanded: false }));
    };
}
