import * as React from "react";

import { SpendingAccountType } from "modules/benefits/spendingAccounts/models";
import { SpendingAccountLineSummary } from "modules/person/coveredMemberSummary.models";

export interface SpendingAccountSectionProps {
    spendingAccount: SpendingAccountLineSummary;
    hidePackageName: boolean;
    showExpensePlanName: boolean;
}

export class SpendingAccountSection extends React.PureComponent<SpendingAccountSectionProps> {
    public render() {
        const { spendingAccount, hidePackageName, showExpensePlanName } = this.props;

        return (
            <tr key={spendingAccount.id}>
                <td colSpan={2}>
                    {(spendingAccount.accountType === SpendingAccountType.HSA ? "Health Spending Account" : "Wellness Spending Account") +
                        (spendingAccount.isCoveragePeriodAllocated ? this.renderDetailsText(spendingAccount.packageName, spendingAccount.planYear.wsaExpensePlanName, hidePackageName, showExpensePlanName) : " - No allocation")}
                </td>
            </tr>
        );
    }

    private renderDetailsText = (packageName: string, wsaExpensePlanName: string, hidePackageName: boolean, showExpensePlanName: boolean) => {
        const packageNameDetails: string[] = new Array<string>();

        if (!hidePackageName) {
            packageNameDetails.push("Package " + packageName);
        }

        if (showExpensePlanName) {
            packageNameDetails.push(wsaExpensePlanName);
        }

        return packageNameDetails.length ? " - " + packageNameDetails.join(" - ") : "";
    };
}
