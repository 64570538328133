import { NetworkRequest } from "./actions";

export const verifyEmail = (taskId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/profile/email/verify`,
        body: `"${taskId}"`,
        doNotEnsureToken: true
    };
};
