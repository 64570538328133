import { NetworkRequest } from "./actions";

export const getPerson = (certificateNumber: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/people/${certificateNumber}`,
        params: {}
    };
};
