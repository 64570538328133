import * as React from "react";
import { connect } from "react-redux";
import { Grid, TextField, FormHelperText, DialogTitle, IconButton, Icon, DialogContent, DialogActions, Button } from "@mui/material";

import { SubmitButton } from "modules/common/components/forms";
import { StarRating } from "./starRating";
import { ResponsiveDialog } from "modules/common/components/layout";
import { ApplicationState } from "store";
import { FeedbackActionCreators } from "../actionCreator";
import { Feedback } from "modules/contact/models";

class FeedbackDialog extends React.Component<ComponentProps, ComponentState> {
    public state: ComponentState = {
        rating: 0,
        comment: ""
    } as ComponentState;

    public componentDidUpdate(prevProps: ComponentProps) {
        // reset feedback fields before raising dialog
        if (!prevProps.showFeedbackForm && this.props.showFeedbackForm) {
            this.selectRating(0);
            this.setComment("");
        }
    }

    private renderFeedbackForm = () => {
        return (
            <div className="feedback-form-wrapper">
                <Grid container spacing={1} className="feedback-form form-grid-container">
                    <Grid item xs={12}>
                        <StarRating ratingMax={5} value={this.state.rating} onChange={this.selectRating} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className="feedback-comment"
                            maxRows={10}
                            multiline={true}
                            value={this.state.comment}
                            onKeyPress={this.onKeyPress}
                            onChange={this.onCommentChange}
                            fullWidth
                            label="Tell us what you think about the My ASEBP website"
                        />
                        <div className="feedback-helper">
                            <FormHelperText>
                                We can&apos;t respond to your feedback. For questions or comments about benefits and claims, please email <a href="mailto:benefits@asebp.ca">benefits@asebp.ca</a>
                            </FormHelperText>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    };

    private selectRating = n => {
        this.setState(s => ({ ...s, rating: n }));
    };
    private onKeyPress = k => {
        return;
    };
    private onCommentChange = (event: object) => {
        this.setComment((event as any).target.value);
    };
    private setComment = (comment: string) => {
        this.setState(s => ({ ...s, comment }));
    };
    private onSubmitClicked = () => {
        const feedback: Feedback = {
            rating: this.state.rating,
            comment: this.state.comment,
            url: window ? window.location.href : "No Browser Present",
            browserTime: new Date(),
            browserOffset: -1 * new Date().getTimezoneOffset()
        };
        this.props.submitFeedback(feedback);
    };

    public render() {
        return this.props.isAuthenticated ? (
            <ResponsiveDialog open={this.props.showFeedbackForm}>
                <DialogTitle className="feedback-title">
                    <span>Thoughts about My ASEBP?</span>
                    <IconButton
                        className="close-button"
                        onClick={this.props.hideFeedbackForm}
                        aria-label="Close"
                        size="large">
                        <Icon>close</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>{this.renderFeedbackForm()}</DialogContent>
                <DialogActions className="feedback-actions">
                    <Button disabled={this.props.submitting} onClick={this.props.hideFeedbackForm}>
                        Cancel
                    </Button>
                    <SubmitButton
                        isSubmitting={this.props.submitting}
                        submittingMessage="Submitting"
                        className="secondary"
                        variant="contained"
                        color="primary"
                        onClick={this.onSubmitClicked}
                        disabled={this.state.rating === 0 && (this.state.comment == null || this.state.comment.length === 0)}>
                        Submit
                    </SubmitButton>
                </DialogActions>
            </ResponsiveDialog>
        ) : null;
    }
}

interface IStateToProps {
    isAuthenticated: boolean;
    submitting: boolean;
    showFeedbackForm: boolean;
}

interface IDispatchToProps {
    hideFeedbackForm: () => any;
    submitFeedback: (Feedback) => any;
}

interface IFeedbackState {
    rating: number;
    comment: string;
}

type ComponentProps = IStateToProps & IDispatchToProps;
type ComponentState = IFeedbackState;

export default connect(
    (state: ApplicationState) => {
        return {
            isAuthenticated: !!state.auth.session.userId,
            showFeedbackForm: state.feedbackForm.showFeedbackForm,
            submitting: state.feedbackForm.submitting
        };
    },
    {
        hideFeedbackForm: FeedbackActionCreators.hideFeedbackForm,
        submitFeedback: FeedbackActionCreators.submitFeedback
    }
)(FeedbackDialog);
