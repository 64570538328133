import { NetworkRequest } from "api/actions";
import * as actions from "./actions";
import { AppThunkAction } from "store";
import API from "api";
import { isPersonalInfoVerificationDueResponse } from "./models";
export type KnownAction =
    | actions.CheckIsPersonalInfoVerificationDueInitAction
    | actions.CheckIsPersonalInfoVerificationDueCompleteAction
    | actions.PersonalInfoVerifiedInitAction
    | actions.PersonalInfoVerifiedCompleteAction
    | actions.OpenDialogAction;
export type PersonalInfoVerificationActions = KnownAction | NetworkRequest;

const actionCreators = {
    checkIsPersonalInfoVerificationDue: (): AppThunkAction<PersonalInfoVerificationActions> => async (dispatch) => {
        dispatch({ type: "IS_PERSONAL_INFO_VERIFICATION_DUE_INIT" });
        try {
            const response: Response = await dispatch(API.profile.checkIsPersonalInfoVerificationDue());
            if (response?.status === 200) {
                const { isDue, lastCompleted } = (await response.json()) as isPersonalInfoVerificationDueResponse;
                dispatch({ type: "IS_PERSONAL_INFO_VERIFICATION_DUE_COMPLETE", isDue, lastCompleted, succeeded: true });
            } else {
                throw new Error(
                    `Error checking if personal info verification is due. Response status ${response.status}`
                );
            }
        } catch (error) {
            dispatch({
                type: "IS_PERSONAL_INFO_VERIFICATION_DUE_COMPLETE",
                isDue: false,
                lastCompleted: null,
                succeeded: false,
                error
            });
        }
    },
    personalInfoVerified: (): AppThunkAction<PersonalInfoVerificationActions> => async (dispatch) => {
        dispatch({ type: "PERSONAL_INFO_VERIFIED_INIT" });
        try {
            const response: Response = await dispatch(API.profile.verifyPersonalInfo());
            if (response?.status === 200) {
                dispatch({ type: "PERSONAL_INFO_VERIFIED_COMPLETED", succeeded: true });
            } else {
                throw new Error(`Error completing personal info verification. Response status ${response.status}`);
            }
        } catch (error) {
            dispatch({ 
                type: "PERSONAL_INFO_VERIFIED_COMPLETED", 
                succeeded: false, 
                error 
            });
        }
    },
    openDialog: (open: boolean) => ({ type: "OPEN_PERSONAL_INFO_VERIFICATION_DIALOG", open }),
};

export default actionCreators;
