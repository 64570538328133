import * as React from "react";
import { Grid } from "@mui/material";

import { Markdown } from "modules/common/components/misc";
import { FeatureArticle } from "../models";

export interface FeatureArticleDetailsProps {
    article: FeatureArticle;
    isFetching: boolean;
}

export class FeatureArticleDetails extends React.Component<FeatureArticleDetailsProps> {
    public render() {
        const { article, isFetching } = this.props;

        return (
            <div className="stretched-content">
                <div className="feature-article-details">
                    {isFetching ? <div>Fetching...</div> : null}
                    {!isFetching && !!article ? (
                        <Grid container key={article.id}>
                            <Grid item xs={12}>
                                <Markdown source={article.content} />
                            </Grid>
                        </Grid>
                    ) : null}

                    {!isFetching && !article ? <div>Sorry, we couldn&apos;t find this feature.</div> : null}
                </div>
            </div>
        );
    }
}
