import { DocumentPaginationState, MemberDocument } from "modules/documents/models";
import { NetworkRequest } from "./actions";

// NOTE: These strings map to routes in the Documents API as well,
// so don't change the values without considering that
export enum DocumentCollectionName {
    ExplanationsOfBenefits = "explanationsOfBenefits",
    TaxSlips = "taxSlips",
    Predeterminations = "predeterminations",
    Premiums = "premiums",
    ExtendedDisabilityBenefits = "extendedDisabilityBenefits",
    SpecialAuthorizations = "specialAuthorizations"
}

export interface DocumentCollectionPageResponse {
    documents: MemberDocument[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
}

export const getDocuments = (
    collectionName: DocumentCollectionName,
    paginationState?: DocumentPaginationState
): NetworkRequest => {
    const queryParams = {} as any;

    if (paginationState) {
        queryParams.pageNumber = paginationState.pageNumber.toString();
    }

    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/documents/${collectionName}`,
        queryParams
    };
};

export const getDocumentStats = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/documents/stats"
    };
};
