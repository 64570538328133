import { Reducer } from "redux";
import { HandledAction } from "./actionCreator";
import { PersonState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<PersonState> = (state: PersonState, action: KnownAction) => {
    switch (action.type) {
        case "GET_PERSON_INIT": {
            return {
                ...state,
                person: null,
                isFetching: true
            };
        }
        case "GET_PERSON_COMPLETE": {
            return {
                ...state,
                person: action.person,
                isFetching: false
            };
        }

        case "UPDATE_PERSON_PHONE_NUMBERS": {
            return {
                ...state,
                person: {
                    ...state.person,
                    address: {
                        ...state.person.address,
                        phoneNumber1: action.profilePhoneNumbers.primaryPhoneNumber ? action.profilePhoneNumbers.primaryPhoneNumber.number : null,
                        phoneNumber2: action.profilePhoneNumbers.secondaryPhoneNumber ? action.profilePhoneNumbers.secondaryPhoneNumber.number : null
                    }
                },
                isFetching: false
            };
        }
        case "UPDATE_PERSON_MASKED_SOCIALINSURANCENUMBER": {
            return {
                ...state,
                person: {
                    ...state.person,
                    maskedSocialInsuranceNumber: action.maskedSocialInsuranceNumber
                }
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            person: null,
            family: [],
            isFetching: false
        } as PersonState)
    );
};
