import { NetworkRequest } from "./actions";
import { Inquiry } from "modules/contact/models";

export const submitInquiry = (inquiry: Inquiry): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/inquiries",
        body: inquiry
    };
};
