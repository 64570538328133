import { BenefitCoverageState } from "./coverage/models";
import { DentalGuideState } from "./dentalGuide/models";
import { MembershipCardState } from "./membershipCard/models";
import { SpendingAccountsState } from "./spendingAccounts/models";
import { SpendingAccountsState as SpendingAccountsStateV2 } from "./spendingAccountsV2/models";
import { BenefitUtilizationState } from "./utilization/models";
import { DrugsState } from "./drugs/models";
import { BenefitType } from "hooks/api/benefitsApi";
import { DrugInquiryState } from "./drugInquiry/models";

export interface BenefitsState {
    coverage: BenefitCoverageState;
    drugs: DrugsState;
    drugInquiry: DrugInquiryState;
    utilization: BenefitUtilizationState;
    spendingAccounts: SpendingAccountsState;
    spendingAccountsV2: SpendingAccountsStateV2;
    membershipCard: MembershipCardState;
    dentalGuide: DentalGuideState;
}

export enum BenefitCode {
    AllBenefits = "*",
    EHC = "EHC",
    Dental = "DNT",
    Vision = "VIS",
    HSA = "HSA",
    WSA = "WSA",
    Life = "LIF",
    AccidentalDeathAndDismemberment = "ADD",
    ExtendedDisability = "EDB"
}

export const BenefitDisplayNames = {
    [BenefitCode.Life]: "Life Insurance",
    [BenefitCode.AccidentalDeathAndDismemberment]: "Accidental Death & Dismemberment",
    [BenefitCode.ExtendedDisability]: "Extended Disability Benefits",
    [BenefitCode.Dental]: "Dental",
    [BenefitCode.Vision]: "Vision",
    [BenefitCode.HSA]: "Health Spending Account",
    [BenefitCode.EHC]: "Extended Health Care",
    [BenefitCode.WSA]: "Wellness Spending Account"
};

export const BenefitMobileDisplayNames = {
    [BenefitCode.Life]: "Life Insurance",
    [BenefitCode.AccidentalDeathAndDismemberment]: "Accidental Death & Dismemberment",
    [BenefitCode.ExtendedDisability]: "Extended Disability Benefits",
    [BenefitCode.Dental]: "Dental",
    [BenefitCode.Vision]: "Vision",
    [BenefitCode.HSA]: "HSA",
    [BenefitCode.EHC]: "EHC",
    [BenefitCode.WSA]: "WSA"
};

export const BenefitCoverageLevel = {
    Family: "Family",
    Couple: "Couple",
    Single: "Single"
};

export const ToBenefitType = (code: BenefitCode): BenefitType => {
    switch (code) {
        case BenefitCode.Dental:
            return BenefitType.Dental;
        case BenefitCode.Vision:
            return BenefitType.Vision;
        case BenefitCode.EHC:
            return BenefitType.ExtendedHealth;
        case BenefitCode.HSA:
            return BenefitType.HSA;
        case BenefitCode.WSA:
            return BenefitType.WSA;
        default:
            return null;
    }
};

export const ToBenefitCode = (benefitType: BenefitType): BenefitCode => {
    switch (benefitType) {
        case BenefitType.Dental:
            return BenefitCode.Dental;
        case BenefitType.Vision:
            return BenefitCode.Vision;
        case BenefitType.ExtendedHealth:
            return BenefitCode.EHC;
        case BenefitType.HSA:
            return BenefitCode.HSA;
        case BenefitType.WSA:
            return BenefitCode.WSA;
        default:
            return null;
    }
};

export const IsGeneralHealthBenefit = (benefitType: BenefitType): boolean => {
    return (
        benefitType &&
        (benefitType === BenefitType.Dental ||
            benefitType === BenefitType.Vision ||
            benefitType === BenefitType.ExtendedHealth)
    );
};
