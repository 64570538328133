import { Reducer } from "redux";
import { HandledAction } from "./actionCreator";
import { BenefitUtilizationDictionary, BenefitUtilizationState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<BenefitUtilizationState> = (state: BenefitUtilizationState, action: KnownAction) => {
    switch (action.type) {
        case "GET_BENEFIT_UTILIZATION_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_BENEFIT_UTILIZATION_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetching: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetching: false,
                benefitUtilization: {
                    ...state.benefitUtilization,
                    [action.certificateNumber]: action.benefitUtilizationSummary
                },
                errorMessage: null
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            isFetching: false,
            benefitUtilization: {} as BenefitUtilizationDictionary
        } as BenefitUtilizationState)
    );
};
