import * as React from "react";
import { Box, Button } from "@mui/material";

import { ContactButton } from "modules/common/components/misc";
import TermsAndConditions from "modules/common/components/termsAndConditions";

// To be removed when cleaning up the feature toggle
// http://tfs-01p:8080/tfs/DefaultCollection/ASEBP/_workitems/edit/27526
import TermsAndConditionsPre2023 from "modules/common/components/termsAndConditionsPre2023";
import { FeatureToggles, KnownFeatureToggles, ToggleableFeature } from "modules/features/models";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";

export interface FooterContentState {
    isTermsAndConditionsModalOpen: boolean;
}
export interface FooterContentProps {
    pathname: string;
    featureToggles: FeatureToggles;
}
export class FooterContent extends React.PureComponent<FooterContentProps, FooterContentState> {
    public state = { isTermsAndConditionsModalOpen: false };
    

    public render() {
        const showUpdatedTermsAndConditions = this.props.featureToggles[KnownFeatureToggles.ShowUpdatedTermsAndConditions] as ToggleableFeature;

        return (
            <>
                <div className={`container secondary-color`} />
                <div className="content">
                    <Box sx={{ display: { xs: 'block', md:  'none'} }}>
                        <div className="button-container">
                            <ContactButton referrerUrl={this.props.pathname} />
                        </div>
                   </Box>
                    <div className="button-container">
                        <Button onClick={this.toggleTermsAndConditionsModal}>Terms {"&"} Conditions</Button>
                        {
                            FeatureToggleChecker.isOn(showUpdatedTermsAndConditions) ? (
                                <TermsAndConditions
                                    open={this.state.isTermsAndConditionsModalOpen}
                                    close={this.toggleTermsAndConditionsModal}
                                />
                            ) : (
                                    <TermsAndConditionsPre2023
                                        open={this.state.isTermsAndConditionsModalOpen}
                                        close={this.toggleTermsAndConditionsModal}
                                    />
                            )}

                    </div>
                </div>
            </>
        );
    }

    private toggleTermsAndConditionsModal = () => {
        this.setState((s) => ({ ...s, isTermsAndConditionsModalOpen: !this.state.isTermsAndConditionsModalOpen }));
    };
}
