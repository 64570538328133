import { HandledAction } from "modules/profile/phoneNumbers/actionCreator";
import { Reducer } from "redux";
import { ProfilePhoneNumbersState } from "./models";
export type KnownAction = HandledAction;
export const reducer: Reducer<ProfilePhoneNumbersState> = (state: ProfilePhoneNumbersState, action: KnownAction): ProfilePhoneNumbersState => {
    switch (action.type) {
        case "GET_PHONE_NUMBERS_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_PHONE_NUMBERS_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                isSaving: false,
                profilePhoneNumbers: action.phoneNumbers,
                errorMessage: null
            };
        }
        case "SAVE_PHONE_NUMBERS_INIT": {
            return {
                ...state,
                isSaving: true
            };
        }
        case "SAVE_PHONE_NUMBERS_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    isSaving: false,
                    profilePhoneNumbers: {
                        ...action.phoneNumbers
                    },
                    errorMessage: null
                };
            } else {
                return {
                    ...state,
                    isSaving: false,
                    errorMessage: action.err
                };
            }
        }
    }
    return (
        state ||
        ({
            isFetching: false,
            isSaving: false,
            profilePhoneNumbers: null,
            errorMessage: null
        } as ProfilePhoneNumbersState)
    );
};
