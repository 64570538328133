import { KnownFeatureToggles, ToggleableFeature } from "modules/features/models";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";
import * as React from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { ApplicationState } from "store";

interface FeatureToggledElementProps {
    knownFeatureToggle: KnownFeatureToggles;
    children: React.ReactElement<any, any>;
}

export const FeatureToggledElement = ({ knownFeatureToggle, children }: FeatureToggledElementProps) => {
    const featureToggles = useAppSelector((state: ApplicationState) => state.features.featureToggles);

    const featureToggle = featureToggles[knownFeatureToggle] as ToggleableFeature;
    if (!!featureToggle) {
        if (FeatureToggleChecker.isOn(featureToggle)) {
            return <>{children}</>;
        }
    }
    return null;
};
