import { Reducer } from "redux";

import { AlertState } from "modules/alerts/models";
import { HandledAction } from "./actionCreator";

export type KnownAction = HandledAction;

export const reducer: Reducer<AlertState> = (state: AlertState, action: KnownAction) => {
    switch (action.type) {
        case "GET_ALERTS_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_ALERTS_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                alerts: action.alerts
            };
        }
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return (
        state || {
            isFetching: false,
            alerts: null
        }
    );
};
