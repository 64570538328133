import * as React from "react";
import { Icon, Typography } from "@mui/material";

import { Alert } from "modules/alerts/models";
import { Page } from "modules/common/components/layout";

export interface AlertCardProps {
    alerts: Alert[];
}

export class AlertCard extends React.Component<AlertCardProps> {
    public render() {
        const { alerts } = this.props;

        return (
            <Page title="Alert" className="alert-notification">
                <div className="icon-wrapper">
                    <Icon>error_outline</Icon>
                </div>

                <div className="alert-message">
                    <Typography variant="h6">
                        <span>{alerts[0].title}</span>
                    </Typography>
                    <Typography component="p">
                        <span>{alerts[0].content}</span>
                    </Typography>
                </div>
            </Page>
        );
    }
}
