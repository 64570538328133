import * as React from "react";

import { BenefitCode } from "modules/benefits/models";
import { BenefitLineSummary } from "modules/person/coveredMemberSummary.models";

export interface BenefitSectionProps {
    benefitLines: BenefitLineSummary[];
}

export class BenefitSection extends React.PureComponent<BenefitSectionProps, NonNullable<unknown>> {
    public render() {
        return this.props.benefitLines.map(benefit => {
            return (
                <tr key={benefit.id}>
                    <td> {benefit.displayName + " - Plan " + benefit.planNumber} </td>
                    <td>{benefit.benefitCode === BenefitCode.Life || benefit.benefitCode === BenefitCode.AccidentalDeathAndDismemberment || benefit.benefitCode === BenefitCode.ExtendedDisability ? "Single" : benefit.coverageLevel}</td>
                </tr>
            );
        });
    }
}
