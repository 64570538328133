import React, { useRef } from "react";
import { connect } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { ApplicationState } from "store";
import { AuthActionCreators } from "modules/auth/actionCreator";
import { SessionTimeoutDialog } from "modules/auth/components";
import throttle from "lodash/throttle";

interface IStateToProps {
  inactiveReauthRequiredMs: number;
  inactiveForcedLogoutMs: number;
  isAuthenticated: boolean;
  loginMessage: string;
  idle: boolean;
  isFetching: boolean;
}

interface IDispatchToProps {
  logOutSilently: () => any;
  setIdle: (idle: boolean) => any;
  reauthenticate: (password: string) => any;
  extendSession: () => any;
}

type ComponentProps = IStateToProps & IDispatchToProps;

const SessionTimer = (props: React.PropsWithChildren<ComponentProps>) => {

  const onIdleTimeoutReached = () => {
    if (!props.idle) {
      props.setIdle(true);
    }
    if (props.isAuthenticated) {
      props.logOutSilently();
    }
  };

  const onLogInRequested = (password: string) => {
    props.reauthenticate(password);
  };

  const onAction = throttle(() => {
    if (props.isAuthenticated) {
      props.extendSession();
    }
  }, props.inactiveReauthRequiredMs / 2);

  useIdleTimer({
    timeout: props.inactiveReauthRequiredMs,
    onIdle: onIdleTimeoutReached,
    onAction: onAction
  });

  return (
    <>
      <div className={props.isAuthenticated && props.idle ? "blurred-content" : ""}>{props.children}</div>
      {props.isAuthenticated ? (
        <SessionTimeoutDialog
          isIdle={props.idle}
          loginMessage={props.loginMessage}
          onLogInRequested={onLogInRequested}
          isFetching={props.isFetching}
          secondsAllottedForReauth={(props.inactiveForcedLogoutMs - props.inactiveReauthRequiredMs) / 1000}
        />
      ) : null}
    </>
  );
};

export default connect(
  (state: ApplicationState) => {
    return {
      inactiveReauthRequiredMs: state.auth.session.inactiveReauthRequiredMs,
      inactiveForcedLogoutMs: state.auth.session.inactiveForcedLogoutMs,
      isAuthenticated: !!state.auth.session.userId,
      loginMessage: state.auth.login ? state.auth.login.message : null,
      idle: state.auth.session.idle,
      isFetching: state.auth.login.isFetching
    };
  },
  {
    logOutSilently: AuthActionCreators.logOutSilently,
    setIdle: AuthActionCreators.setUserIdle,
    reauthenticate: AuthActionCreators.reauthenticate,
    extendSession: AuthActionCreators.extendSession
  }
)(SessionTimer);
