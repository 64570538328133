import * as React from "react";
import { FormControl, FormLabel, Select, MenuItem, FormHelperText } from "@mui/material";

import { PlaceholderLine } from "modules/common/components/layout";
import { ToolTip } from "modules/common/components/misc";
import { SelectOption } from "./models";

export interface FormDropdownFieldProps {
    tombstone?: boolean;
    onChanged?: (e: any) => void;
    options: SelectOption[];
    placeholder?: string;
    errorText?: string;
    helperText?: string;
    title?: string;
    displayText?: string;
    value?: number | string;
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    id?: string;
    tooltipTitle?: string;
    tooltipContent?: JSX.Element;
    "data-testid"?: string;
    variant?: "standard" | "outlined" | "filled";
}

export class FormDropdownField extends React.PureComponent<FormDropdownFieldProps> {
    public render() {
        const hasError = !!this.props.errorText;
        return (
            <>
                {this.props.tombstone ? (
                    <div>
                        <PlaceholderLine thickness="thin" />
                        <PlaceholderLine thickness="thick" color="dark" />
                    </div>
                ) : (
                    <FormControl
                        fullWidth={this.props.fullWidth}
                        error={hasError}
                        className={"form-dropdown " + this.props.className}>
                        <div className="label-tooltip">
                            <FormLabel htmlFor={this.props.id} className="control-label">
                                {this.props.title}
                            </FormLabel>
                            {this.props.tooltipContent || this.props.tooltipTitle ? (
                                <ToolTip title={this.props.tooltipTitle}>{this.props.tooltipContent}</ToolTip>
                            ) : null}
                        </div>
                        <Select
                            variant={this.props.variant || "standard"}
                            className="control-dropdown"
                            fullWidth={this.props.fullWidth}
                            value={this.props.value}
                            onChange={this.props.onChanged}
                            disabled={this.props.disabled}
                            id={this.props.id}
                            {...(this.props["data-testid"]
                                ? { inputProps: { "data-testid": this.props["data-testid"] } }
                                : {})}>
                            <MenuItem selected={!this.props.value} value={0}>
                                <em>- select -</em>
                            </MenuItem>
                            {this.props.options.map((option) => {
                                return (
                                    <MenuItem
                                        selected={this.props.value === option.value}
                                        value={option.value}
                                        key={option.value}
                                        className="item">
                                        <span>{option.text}</span>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {hasError ? (
                            <FormHelperText className="error-text">{this.props.errorText}</FormHelperText>
                        ) : null}
                        {this.props.helperText && !hasError ? (
                            <FormHelperText>{this.props.helperText}</FormHelperText>
                        ) : null}
                    </FormControl>
                )}
            </>
        );
    }
}
