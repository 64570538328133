import { SpendingAccountType } from "modules/benefits/spendingAccounts/models";
import { ProductOrServiceType } from "modules/claims/claimSubmission/models";
import { BenefitType } from "hooks/api/benefitsApi";
import { Relative } from "modules/person/models";
import { getYear, parseISO } from "date-fns";
import { OnlineClaimProductOrServiceType } from "hooks/api/claimsApi";

const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function formatDollar(num: number) {
    const p = num.toFixed(2).split(".");
    const chars = p[0].split("").reverse();
    let newstr = "";
    let count = 0;
    for (let i = 0; i < chars.length; i++) {
        count++;
        if (count % 3 === 1 && count !== 1) {
            newstr = chars[i] + "," + newstr;
        } else {
            newstr = chars[i] + newstr;
        }
    }
    return newstr + "." + p[1];
}

const Formatter = {
    isValidDate: (date: Date | string | null): boolean => {
        if (date == null) {
            return false;
        }
        const dateObj = new Date(date);
        return dateObj != null && dateObj.getTime() === dateObj.getTime();
    },
    formatCurrency: (amount: number, dollarSignRequired = true, truncateDecimal = false): string => {
        if (amount === null) {
            return "";
        }

        let formattedDollarAmount = formatDollar(Math.abs(amount));
        if (formattedDollarAmount.endsWith(".00") && truncateDecimal) {
            formattedDollarAmount = formattedDollarAmount.substr(0, formattedDollarAmount.length - 3);
        }

        // Floating point numbers can have precision issues.
        // A tolerance is used to make sure 0 will always be counted as positive.
        const floatTolerance = 0.0001;

        return dollarSignRequired
            ? amount >= -floatTolerance
                ? "$" + formattedDollarAmount
                : "($" + formattedDollarAmount + ")"
            : formattedDollarAmount;
    },
    formatNumber: (amount: number, truncateDecimal?: boolean): string => {
        if (amount === null) {
            return "";
        }

        let formattedDollarAmount = formatDollar(Math.abs(amount));
        if (formattedDollarAmount.endsWith(".00") && truncateDecimal) {
            formattedDollarAmount = formattedDollarAmount.substr(0, formattedDollarAmount.length - 3);
        }

        return `${amount < 0 ? "-" : ""}${formattedDollarAmount}`;
    },
    formatInt: (value: number) => {
        return Formatter.formatNumber(value, true);
    },
    formatLocalDate: (date: Date | string | null, includeYear = true, includeTime = false): string => {
        if (date) {
            const formatAmPm = (dateToAmPm: Date): string => {
                let hours = dateToAmPm.getHours();
                const minutes = dateToAmPm.getMinutes();
                const ampm = hours >= 12 ? "p.m." : "a.m.";
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                const minutesStr = minutes < 10 ? "0" + minutes : minutes;
                const strTime = hours + ":" + minutesStr + " " + ampm;
                return strTime;
            };

            const dateToConvert = Formatter.convertToDateObject(date);

            const convertedDate =
                dateToConvert.getDate() +
                " " +
                monthShortNames[dateToConvert.getMonth()] +
                (includeYear ? " " + dateToConvert.getFullYear() : "") +
                (includeTime ? " " + formatAmPm(dateToConvert) : "");

            return convertedDate;
        }

        return "";
    },
    /**
     * Format a local date to iso standard using the date parts, and dropping local time or timezone information
     * Does not accept string because that cast to date may alter the parts while adjusting to local time
     * @param date
     */
    formatLocalDateToISODateOnlyString: (date: Date): string => {
        if (date) {
            const toTwoDigits = (v: number): string => ("0" + v.toString()).slice(-2);
            return `${date.getFullYear()}-${toTwoDigits(date.getMonth() + 1)}-${toTwoDigits(date.getDate())}`;
        }

        return "";
    },
    subtractMinutesFromDate: (date: Date | string | null, minutes = 1): Date => {
        if (date) {
            let tempDate = new Date(date);
            tempDate = new Date(tempDate.setMinutes(tempDate.getMinutes() - minutes));
            return tempDate;
        }
        return null;
    },
    convertToDateObject: (date: Date | string | null): Date => {
        if (date === null) {
            return null;
        }
        let convertedDate = null;

        if (typeof date === "string" && !Formatter.dateStringHasTimezone(date)) {
            convertedDate = Formatter.convertUtcDateToLocalWithSameTime(date + "Z");

            // browser inconsistencies
            if (isNaN(convertedDate)) {
                convertedDate = Formatter.convertUtcDateToLocalWithSameTime(date);
            }
        } else {
            convertedDate = new Date(date);
        }
        return convertedDate;
    },
    getUTCNow: (): Date => {
        const date = new Date();
        const now = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes()
        );
        return new Date(now);
    },
    convertUtcDateToLocalWithSameTime: (utcDate: Date | string | null): Date | null => {
        if (utcDate === null) {
            return null;
        }

        const utcDateObj = utcDate != null ? new Date(utcDate) : null;
        let localDate: Date = null;

        if (utcDateObj != null) {
            const userTimezoneOffset = utcDateObj.getTimezoneOffset() * 60000;
            localDate = new Date(utcDateObj.getTime() + userTimezoneOffset);
        }

        return localDate;
    },
    convertLocalDateToUtcDateWithSameTime: (localDate: Date | string | null): Date | null => {
        let utcDate: Date = null;
        const localDateObj = localDate != null ? new Date(localDate) : null;
        if (localDateObj != null) {
            const userTimezoneOffset = localDateObj.getTimezoneOffset() * 60000;
            utcDate = new Date(localDateObj.getTime() - userTimezoneOffset);
        }
        return utcDate;
    },
    formatSecondsToMinutes: (seconds: number): string => {
        const milliseconds = seconds * 1000;
        const minutes = Math.floor(milliseconds / 60000);
        const formattedSeconds = (milliseconds % 60000) / 1000;
        return formattedSeconds === 60
            ? minutes + 1 + ":00"
            : minutes + ":" + (formattedSeconds < 10 ? "0" : "") + formattedSeconds.toFixed(0);
    },
    formatOnlineClaimProductOrServiceType: (value: OnlineClaimProductOrServiceType): string => {
        switch (value) {
            case OnlineClaimProductOrServiceType.Dental:
                return "Dental / Orthodontics";
            case OnlineClaimProductOrServiceType.MassageTherapy:
                return "Massage Therapy";
            case OnlineClaimProductOrServiceType.Chiropractic:
                return "Chiropractic Care";
            case OnlineClaimProductOrServiceType.Psychology:
                return "Psychology";
            case OnlineClaimProductOrServiceType.Acupuncture:
                return "Acupuncture";
            case OnlineClaimProductOrServiceType.Vision:
                return "Vision";
            case OnlineClaimProductOrServiceType.Physiotherapy:
                return "Physiotherapy";
            case OnlineClaimProductOrServiceType.Drugs:
                return "Drugs";
            case OnlineClaimProductOrServiceType.EverythingElse:
                return "Something Else";
            default:
                return "[Unknown product]";
        }
    },
    formatProductOrServiceType: (value: ProductOrServiceType): string => {
        switch (value) {
            case ProductOrServiceType.Dental:
                return "Dental / Orthodontics";
            case ProductOrServiceType.MassageTherapy:
                return "Massage Therapy";
            case ProductOrServiceType.Chiropractic:
                return "Chiropractic Care";
            case ProductOrServiceType.Psychology:
                return "Psychology";
            case ProductOrServiceType.Acupuncture:
                return "Acupuncture";
            case ProductOrServiceType.Vision:
                return "Vision";
            case ProductOrServiceType.Physiotherapy:
                return "Physiotherapy";
            case ProductOrServiceType.Drugs:
                return "Drugs";
            case ProductOrServiceType.EverythingElse:
                return "Something Else";
            default:
                return "[Unknown product]";
        }
    },
    dateStringHasTimezone: (date: string): boolean => {
        return (
            date.indexOf("Z") > 0 ||
            date.lastIndexOf("-") > 7 || // we need to ignore the date part as we are only looking for dash near the end of the string
            date.indexOf("+") > 0
        );
    },
    makeUtcDateSafe(date: Date): string {
        if (date != null) {
            if (!date.toString().endsWith("Z")) {
                return date + "Z";
            }
            return date.toString();
        }

        return null;
    },
    formatBenefitType: (benefitType?: BenefitType): string => {
        switch (benefitType) {
            case BenefitType.HSA: {
                return "HSA";
            }
            case BenefitType.ExtendedHealth: {
                return "Extended Health";
            }
            case BenefitType.Dental: {
                return "Dental";
            }
            case BenefitType.Vision: {
                return "Vision";
            }
            case BenefitType.WSA: {
                return "WSA";
            }
        }
        return "";
    },
    formatPhoneNumber: (phoneNumber: string): string => {
        // removing symbols from phone number
        const cleanPhoneNumber = phoneNumber.replace(/[\(\)\s\-]/g, "");

        let formattedPhoneNumber = "";
        let internationalCode = "";
        let areaCode = "";
        let prefix = "";
        let lineNumber = "";

        if (cleanPhoneNumber) {
            const phoneNumberLength = cleanPhoneNumber.length;
            lineNumber = phoneNumberLength > 3 ? cleanPhoneNumber.substring(phoneNumberLength - 4) : cleanPhoneNumber;

            if (phoneNumberLength > 4) {
                prefix = cleanPhoneNumber.slice(0, phoneNumberLength - 4);
                prefix = prefix.slice(prefix.length - 3);
            }
            if (phoneNumberLength > 7) {
                areaCode = cleanPhoneNumber.slice(0, phoneNumberLength - 7);
                areaCode = areaCode.slice(areaCode.length - 3);
            }
            if (phoneNumberLength > 10) {
                internationalCode = cleanPhoneNumber.slice(0, phoneNumberLength - 10);
            }
        }
        if (internationalCode) {
            formattedPhoneNumber = internationalCode + "-";
        }
        if (areaCode) {
            formattedPhoneNumber = formattedPhoneNumber + areaCode + "-";
        }
        if (prefix) {
            formattedPhoneNumber = formattedPhoneNumber + prefix + "-";
        }
        if (lineNumber) {
            formattedPhoneNumber = formattedPhoneNumber + lineNumber;
        }

        return formattedPhoneNumber;
    },
    maskSocialInsuranceNumber: (socialInsuranceNumber: string): string => {
        if (!socialInsuranceNumber || socialInsuranceNumber.length < 3) {
            return "";
        }

        return socialInsuranceNumber.substring(socialInsuranceNumber.length - 3).padStart(9, "*");
    },
    getSpendingAccountName: (spendingAccountType: SpendingAccountType): string => {
        switch (spendingAccountType) {
            case SpendingAccountType.HSA:
                return "Health Spending Account";
            case SpendingAccountType.WSA:
                return "Wellness Spending Account";
            case SpendingAccountType.Unallocated:
                return "Unallocated";
            default:
                return "";
        }
    },
    getSpendingAccountNameMobile: (spendingAccountType: SpendingAccountType): string => {
        switch (spendingAccountType) {
            case SpendingAccountType.HSA:
                return "HSA";
            case SpendingAccountType.WSA:
                return "WSA";
            case SpendingAccountType.Unallocated:
                return "Unallocated";
            default:
                return "";
        }
    },
    formatFamilyMemberName: (relative: Relative, includeAgeIfDuplicate?: boolean) => {
        return includeAgeIfDuplicate && relative.hasIdenticalFirstNameAsFamilyMember
            ? `${relative.name.first} (born ${getYear(parseISO(relative.birthDate))})`
            : relative.name.first;
    }
};
export default Formatter;
