import { Reducer } from "redux";
import { ClaimAttachmentCategory } from "hooks/api/claimsApi";
import { LocalAttachment } from "modules/claims/models";
import { HandledAction } from "./actionCreator";
import { ClaimEditorState, ClaimSubmission, ClaimSubmissionStatus } from "./models";

export type KnownAction = HandledAction;

export function getEmptyClaim(): ClaimSubmission {
    return {
        id: 0,
        referenceId: null,
        priorSubmissionReferenceId: null,
        coveredMemberCertificateNumber: null,
        patientCertificateNumber: null,
        patientName: null,
        productOrServiceType: null,
        description: null,
        serviceDate: null,
        amount: null,
        createdDate: new Date(),
        attachments: [],
        status: ClaimSubmissionStatus.Draft,
        benefitSelection: null,
        spendingAccountClaimSubCategoryId: null,
        hasSubmittedToAnotherProvider: null,
        shownDuplicateWarningMessage: null
    };
}

export const reducer: Reducer<ClaimEditorState> = (state: ClaimEditorState, action: KnownAction) => {
    switch (action.type) {
        case "INIT_CLAIM_EDITOR": {
            const newClaim = getEmptyClaim();
            return {
                ...state,
                isFetching: false,
                priorSubmission: null,
                isFetchingPriorSubmission: false,
                claim: {
                    ...newClaim,
                    coveredMemberCertificateNumber: action.coveredMemberCertificateNumber,
                    patientCertificateNumber: action.patientCertificateNumber,
                    priorSubmissionReferenceId: action.priorSubmissionReferenceId,
                    shownDuplicateWarningMessage: false
                },
                claimModifications: {
                    hasModifiedAttachments: false
                },
                validationState: {
                    isValid: true,
                    messages: {}
                },
                serverValidationState: null
            };
        }
        case "GET_CLAIM_WORKING_COPY_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_CLAIM_WORKING_COPY_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                claim: {
                    ...action.claim,
                    hasSubmittedToAnotherProvider: action.claim.attachments
                        ? action.claim.attachments.some(
                              (a) => a.categoryId === ClaimAttachmentCategory.ExplanationOfBenefits
                          )
                        : false
                },
                validationState: {
                    isValid: false,
                    messages: {}
                },
                serverValidationState: action.validationState
            };
        }
        case "GET_PRIOR_SUBMISSION_INIT": {
            return {
                ...state,
                priorSubmission: null,
                isFetchingPriorSubmission: true
            };
        }
        case "GET_PRIOR_SUBMISSION_COMPLETE": {
            const priorSubmission = action.succeeded
                ? action.priorSubmissionGroup.claimSubmissions.find((c) => c.referenceId === action.referenceId)
                : null;
            return {
                ...state,
                priorSubmission: action.succeeded
                    ? {
                          ...priorSubmission,
                          hasSubmittedToAnotherProvider: priorSubmission.attachments
                              ? priorSubmission.attachments.some(
                                    (a) => a.categoryId === ClaimAttachmentCategory.ExplanationOfBenefits
                                )
                              : false
                      }
                    : state.priorSubmission,
                isFetchingPriorSubmission: false
            };
        }
        case "SET_CLAIM_FIELD_VALUE": {
            const newClaim = {
                ...state.claim,
                [action.name]: action.value
            };

            return {
                ...state,
                claim: newClaim
            };
        }
        case "SET_ALLOWED_SPENDING_ACCCOUNT_CATEGORIES": {
            return {
                ...state,
                allowedSpendingAccountCategories: action.categories
            };
        }
        case "UPDATE_VALIDATION_STATE": {
            return {
                ...state,
                validationState: action.validationState
            };
        }
        case "ADD_CLAIM_ATTACHMENT_INIT": {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    attachments: state.claim.attachments.concat({
                        referenceId: null,
                        localId: action.localId,
                        fileName: action.file.name,
                        file: { ...action.file },
                        categoryId: action.categoryId,
                        percentUploaded: 0,
                        isFetching: true
                    } as LocalAttachment)
                }
            };
        }
        case "ADD_CLAIM_ATTACHMENT_PROGRESS": {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    attachments: state.claim.attachments.map((attachment) => {
                        if (attachment.localId === action.localId) {
                            return {
                                ...attachment,
                                percentUploaded: action.percentUploaded
                            };
                        }
                        return attachment;
                    })
                }
            };
        }
        case "ADD_CLAIM_ATTACHMENT_COMPLETE": {
            if (action.succeeded) {
                const newClaim = {
                    ...state.claim,
                    attachments: state.claim.attachments.map((attachment) => {
                        if (attachment.localId === action.localId) {
                            return {
                                ...attachment,
                                referenceId: action.referenceId,
                                percentUploaded: 100,
                                isFetching: false
                            };
                        }
                        return attachment;
                    })
                };

                return {
                    ...state,
                    claim: newClaim,
                    claimModifications: {
                        hasModifiedAttachments: true
                    }
                };
            } else {
                return {
                    ...state,
                    claim: {
                        ...state.claim,
                        attachments: state.claim.attachments.filter(
                            (attachment) => attachment.localId !== action.localId
                        )
                    }
                };
            }
        }
        case "DELETE_CLAIM_ATTACHMENT_INIT": {
            return {
                ...state,
                claim: {
                    ...state.claim,
                    attachments: state.claim.attachments.map((attachment) => {
                        if (attachment.referenceId === action.attachmentReferenceId) {
                            return {
                                ...attachment,
                                isFetching: true
                            };
                        }
                        return attachment;
                    })
                }
            };
        }
        case "DELETE_CLAIM_ATTACHMENT_COMPLETE": {
            if (action.succeeded) {
                const newClaim = {
                    ...state.claim,
                    attachments: state.claim.attachments.filter(
                        (attachment) => attachment.referenceId !== action.attachmentReferenceId
                    )
                };

                return {
                    ...state,
                    claim: newClaim,
                    claimModifications: {
                        hasModifiedAttachments: true
                    }
                };
            } else {
                return {
                    ...state,
                    claim: {
                        ...state.claim,
                        attachments: state.claim.attachments.map((attachment) => {
                            if (attachment.referenceId === action.attachmentReferenceId) {
                                return {
                                    ...attachment,
                                    isFetching: false
                                };
                            }
                            return attachment;
                        })
                    }
                };
            }
        }
        case "DISCARD_CLAIM_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "DISCARD_CLAIM_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                claim: null,
                claimModifications: null,
                priorSubmission: null
            };
        }
        case "SAVE_CLAIM_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "SAVE_CLAIM_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    claim: action.synchronizeWorkingCopy
                        ? {
                              ...action.claim,
                              // This property is only used client side, but we can infer the value from the prior submission
                              // by checking to see if an EOB was attached to the claim
                              hasSubmittedToAnotherProvider:
                                  action.synchronizeWorkingCopy &&
                                  action.claim.attachments &&
                                  action.claim.attachments.length
                                      ? action.claim.attachments.some(
                                            (a) => a.categoryId === ClaimAttachmentCategory.ExplanationOfBenefits
                                        )
                                      : state.claim.hasSubmittedToAnotherProvider
                          }
                        : state.claim,
                    serverValidationState: action.validationState,
                    isFetching: false
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    serverValidationState: action.validationState
                };
            }
        }
        case "SUBMIT_CLAIM_INIT": {
            return {
                ...state,
                isFetching: true,
                claim: {
                    ...state.claim,
                    status: ClaimSubmissionStatus.Submitted
                }
            };
        }
        case "SUBMIT_CLAIM_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    isFetching: false,
                    claim: null,
                    claimModifications: {
                        hasModifiedAttachments: false
                    },
                    validationState: {
                        isValid: false,
                        messages: {}
                    },
                    priorSubmission: null
                };
            } else {
                return {
                    ...state,
                    isFetching: false
                };
            }
        }
        case "GET_SUBMIT_DUPLICATE_CLAIMS_INIT": {
            return {
                ...state,
                isFetchingPossibleDuplicateClaims: true
            };
        }
        case "GET_SUBMIT_DUPLICATE_CLAIMS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetchingPossibleDuplicateClaims: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetchingPossibleDuplicateClaims: false,
                possibleDuplicateClaims: action.claimsResponse,
                claim: {
                    ...state.claim,
                    shownDuplicateWarningMessage: action.claimsResponse && action.claimsResponse.claimsTotal > 0
                },
                errorMessage: null
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            isFetching: false,
            priorSubmission: null,
            isFetchingPriorSubmission: false,
            claim: null,
            claimModifications: null,
            validationState: {
                isValid: false,
                messages: {}
            },
            serverValidationState: null,
            allowedSpendingAccountCategories: [],
            isFetchingPossibleDuplicateClaims: false,
            possibleDuplicateClaims: null
        } as ClaimEditorState)
    );
};
