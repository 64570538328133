import queryString from "qs";
import { addDays, subMonths, subYears } from "date-fns";
import { ClaimsFilterState } from "../models";
import { parseISO } from "date-fns";
import { Formatter } from "modules/utils";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

export const claimServiceDateFilters = [
    {
        key: "currentYear",
        name: String(today.getFullYear()),
        startDate: new Date(today.getFullYear(), 0, 1),
        endDate: null
    } as ClaimServiceDateFilterOption,
    {
        key: "previousYear",
        name: String(today.getFullYear() - 1),
        startDate: new Date(today.getFullYear() - 1, 0, 1),
        endDate: new Date(today.getFullYear() - 1, 11, 31)
    } as ClaimServiceDateFilterOption,
    {
        key: "last6months",
        name: "Last 6 months",
        startDate: addDays(subMonths(today, 6), 1),
        endDate: null
    } as ClaimServiceDateFilterOption,
    {
        key: "last12months",
        name: "Last 12 months",
        startDate: addDays(subMonths(today, 12), 1),
        endDate: null
    } as ClaimServiceDateFilterOption,
    {
        key: "last24months",
        name: "Last 24 months",
        startDate: addDays(subMonths(today, 24), 1),
        endDate: null
    } as ClaimServiceDateFilterOption,
    {
        key: "last48months",
        name: "Last 48 months",
        startDate: addDays(subMonths(today, 48), 1),
        endDate: null
    } as ClaimServiceDateFilterOption,
    {
        key: "last5years",
        name: "Last 5 years",
        startDate: addDays(subYears(today, 5), 1),
        endDate: null
    } as ClaimServiceDateFilterOption
];

export type ClaimFilterKey =
    | "currentYear"
    | "previousYear"
    | "last6months"
    | "last12months"
    | "last24months"
    | "last48months"
    | "last5years"
    | "custom";

export interface ClaimServiceDateFilterOption {
    key: ClaimFilterKey;
    name: string;
    startDate: Date;
    endDate: Date;
}

export const ToClaimServiceDateFilterOption = (
    periodLengthMonths: number | "currentYear"
): ClaimServiceDateFilterOption => {
    switch (periodLengthMonths) {
        case "currentYear":
            return claimServiceDateFilters.find((c) => c.key === "currentYear");
        case 9:
            return claimServiceDateFilters.find((c) => c.key === "last6months");
        case 12:
            return claimServiceDateFilters.find((c) => c.key === "last12months");
        case 24:
            return claimServiceDateFilters.find((c) => c.key === "last24months");
        case 48:
            return claimServiceDateFilters.find((c) => c.key === "last48months");
        case 60:
            return claimServiceDateFilters.find((c) => c.key === "last5years");
        default:
            return null;
    }
};

export const CreateFilterStateFromQueryString = (
    query: string,
    coveredMember: CoveredMemberSummary
): ClaimsFilterState => {
    const params = queryString.parse((query || "?").substring(1));
    let serviceDateFilterOption = null;
    if (!!params.serviceDate) {
        if (params.serviceDate === "custom") {
            serviceDateFilterOption = {
                key: params.serviceDate,
                startDate: params.from ? parseISO(params.from as string) : null,
                endDate: params.to ? parseISO(params.to as string) : null
            } as ClaimServiceDateFilterOption;
        } else {
            serviceDateFilterOption = claimServiceDateFilters.find((f) => f.key === params.serviceDate);
        }
    }

    const filterState = {
        view: params.view || "All",
        membershipType: params.membershipType || "All Plans",
        pageNumber: params.page ? parseInt(params.page as string, 10) : 1,
        patientCertificateNumber: params.patient || null,
        benefitType: params.benefit ? parseInt(params.benefit as string, 10) : null,
        claimCategory: params.category ? parseInt(params.category as string, 10) : null,
        productMaximumGroupCode: params.product ? params.product : null,
        includeCoveredOnly: params.coveredOnly ? params.coveredOnly : false,
        serviceDateFilterOption: serviceDateFilterOption,
        spendingAccountId: params.spendingAccountId ? parseInt(params.spendingAccountId as string, 10) : null,
        format: params.format ? params.format : null
    } as ClaimsFilterState;

    return filterState;
};

export const CreateQueryStringFromFilterState = (filterState: Partial<ClaimsFilterState>): string => {
    const params = [];
    if (!!filterState) {
        if (filterState.view && filterState.view !== "All") {
            params.push(`view=${filterState.view}`);
        }
        if (filterState.patientCertificateNumber) {
            params.push(`patient=${filterState.patientCertificateNumber}`);
        }
        if (filterState.benefitType) {
            params.push(`benefit=${filterState.benefitType}`);
        }
        if (filterState.membershipType) {
            params.push(`membershipType=${filterState.membershipType}`);
        }
        if (filterState.claimCategory) {
            params.push(`category=${filterState.claimCategory}`);
        }
        if (filterState.productMaximumGroupCode) {
            params.push(`product=${filterState.productMaximumGroupCode}`);
        }
        if (filterState.includeCoveredOnly) {
            params.push(`coveredOnly=${filterState.includeCoveredOnly}`);
        }
        if (filterState.spendingAccountId) {
            params.push(`spendingAccountId=${filterState.spendingAccountId}`);
        }
        if (filterState.format) {
            params.push(`format=${filterState.format}`);
        }
        if (filterState.serviceDateFilterOption) {
            params.push(`serviceDate=${filterState.serviceDateFilterOption.key}`);
            if (filterState.serviceDateFilterOption.key === "custom") {
                if (
                    filterState.serviceDateFilterOption.startDate &&
                    Formatter.isValidDate(filterState.serviceDateFilterOption.startDate)
                ) {
                    const startDate = filterState.serviceDateFilterOption.startDate.toJSON().split("T")[0];

                    params.push(`from=${startDate}`);
                }
                if (
                    filterState.serviceDateFilterOption.endDate &&
                    Formatter.isValidDate(filterState.serviceDateFilterOption.endDate)
                ) {
                    const endDate = filterState.serviceDateFilterOption.endDate.toJSON().split("T")[0];
                    params.push(`to=${endDate}`);
                }
            }
        }
        if (filterState.pageNumber && filterState.pageNumber !== 1) {
            params.push(`page=${filterState.pageNumber}`);
        }
    }
    return params.length ? `?${params.join("&")}` : "";
};

export const AreFilterStatesEqual = (one: ClaimsFilterState, two: ClaimsFilterState): boolean => {
    return CreateQueryStringFromFilterState(one) === CreateQueryStringFromFilterState(two);
};

export const AreFilterStatesEqualExceptForPaging = (one: ClaimsFilterState, two: ClaimsFilterState): boolean => {
    return (
        CreateQueryStringFromFilterState({
            ...one,
            pageNumber: null
        }) ===
        CreateQueryStringFromFilterState({
            ...two,
            pageNumber: null
        })
    );
};
