import { ApplicationState as AppState } from "store";

import { BenefitCoverageActionCreators } from "modules/benefits/coverage/actionCreator";
import * as actions from "./actions";

export type HandledAction = actions.ShowMembershipCard | actions.HideMembershipCard | actions.DownloadMembershipCard;

export type MembershipCardActions = HandledAction;

export const MembershipCardActionCreators = {
    showMembershipCard: () => (dispatch, getState: () => AppState) => {
        const certificateNumber = getState().auth.session.userId;

        dispatch(BenefitCoverageActionCreators.getBenefitHistory(certificateNumber)).then(() => {
            dispatch({ type: "SHOW_MEMBERSHIP_CARD" });
        });
    },
    hideMembershipCard: () => (dispatch, getState: () => AppState) => {
        dispatch({ type: "HIDE_MEMBERSHIP_CARD" });
    },
    downloadMembershipCard: () => (dispatch, getState: () => AppState) => {
        dispatch({ type: "DOWNLOAD_MEMBERSHIP_CARD" });
    }
};
