import React, {useEffect, useState} from "react";
import { Link as RouterLink, Redirect, useLocation } from "react-router-dom";
import { Grid, FormGroup, FormControl, FormLabel, TextField, FormHelperText, Button } from "@mui/material";
import { actionCreators as AlertsActionCreator } from "modules/alerts/actionCreator";
import { AuthActionCreators } from "modules/auth/actionCreator";
import { actionCreators as ProfileActionCreators } from "modules/profile/registration/actionCreator";
import { AlertCard } from "modules/alerts/components";
import { FormPasswordField, SubmitButton } from "modules/common/components/forms";
import { MyAsebpIcon } from "modules/common/components/icons";
import { Page } from "modules/common/components/layout";
import TermsAndConditions from "modules/common/components/termsAndConditions";
import { Location } from "history";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

type LocationState = {
    from?: Location;
}

export default function LoginPage () {
    const [usernameInput, setUsernameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const {
        login: { message, secondaryMessage, isFetching, nonPilotUserLoggedIn, artaLoginUrl },
        session: { userId }
    } = useAppSelector((state) => state.auth);
    const { alerts } = useAppSelector((state) => state.alerts);
    const { classicAppUrl: classicUrl } = useAppSelector((state) => state.config);
    const location = useLocation();
    
    const isAuthenticated = Boolean(userId);
    const hasAlerts = alerts && alerts.length > 0;
    const { from: returnTo } = location.state as LocationState ?? {};

    useEffect(() => {
        if (!alerts) {
            dispatch(AlertsActionCreator.getAlerts());
        }
    }, [alerts, dispatch]);

    const logIn = () => {
        dispatch(AuthActionCreators.logIn(
            usernameInput.trim(),
            passwordInput.trim()
        ));
    };

    const toggleTermsAndConditionsModal = () => {
        setIsTermsAndConditionsModalOpen(prevState => !prevState);
    };

    const initializeNewMemberRegistration = () => {
        dispatch(ProfileActionCreators.initializeNewMemberRegistration());
    };

    if (isAuthenticated) {
        return <Redirect to={returnTo ?? "/home"} />;
    }

    return (
        <>
            {hasAlerts ? <AlertCard alerts={alerts} /> : null}

            <Page title="Sign In" className="sign-in" withOutageMessage={hasAlerts} noMinHeight>
                <Grid container>
                    <Grid item xs={12}>
                        <MyAsebpIcon />
                    </Grid>
                    <Grid item xs={12}>
                        <form>
                            <FormGroup>
                                <FormControl className="form-row">
                                    <FormLabel>ASEBP ID or Email</FormLabel>
                                    <TextField
                                        variant="standard"
                                        value={usernameInput}
                                        onChange={(evt) => setUsernameInput(evt.target.value)}
                                        autoFocus
                                    />
                                    <FormHelperText className="personal-email-suggestion">Don't lose access to your account. Always use a personal email address.</FormHelperText>
                                </FormControl>
                                <FormPasswordField
                                    className="form-row"
                                    title="Password"
                                    autocomplete="nope"
                                    id="password"
                                    value={passwordInput}
                                    onChanged={(evt) => setPasswordInput(evt.target.value)}
                                    showHidePasswordButtonClassName="show-hide-password-btn-background"
                                    extraHelperTextComponent={
                                        <RouterLink className="register-link" to="/forgotPassword">
                                            Forgot your password?
                                        </RouterLink>
                                    }
                                />
                                {nonPilotUserLoggedIn ? (
                                    <FormHelperText className="error-text">
                                        <span>
                                            Sorry, this site isn&apos;t accessible to all members at this time.
                                            Please return to <a href={classicUrl}>My ASEBP Classic</a>.
                                        </span>
                                    </FormHelperText>
                                ) : null}
                                {artaLoginUrl ? (
                                    <FormHelperText className="error-text">
                                        <span>
                                            Oops! It looks like you might have an ARTA login. Please try logging in
                                            via the <a href={artaLoginUrl}>ARTA website</a>.
                                        </span>
                                    </FormHelperText>
                                ) : null}
                                {message ? (
                                    <FormHelperText id="login-error" className="error-text">
                                        {message}
                                    </FormHelperText>
                                ) : null}
                                {secondaryMessage ? (
                                    <FormHelperText className="error-text">{secondaryMessage}</FormHelperText>
                                ) : null}
                                <FormControl className="form-row">
                                    <SubmitButton
                                        type="submit"
                                        className="sign-in-btn"
                                        color="primary"
                                        variant="contained"
                                        isSubmitting={isFetching}
                                        submittingMessage="Signing in"
                                        onClick={logIn}>
                                        Sign In
                                    </SubmitButton>
                                </FormControl>
                                <FormHelperText>
                                    Don&apos;t have an account yet?{" "}
                                    <RouterLink
                                        id="register-link"
                                        className="register-link"
                                        to="/register"
                                        onClick={initializeNewMemberRegistration}>
                                        Register now.
                                    </RouterLink>
                                </FormHelperText>
                            </FormGroup>
                        </form>
                    </Grid>
                </Grid>
            </Page>
            <div className="term-conditions-container">
                <Button onClick={toggleTermsAndConditionsModal}>Terms {"&"} Conditions</Button>
                <TermsAndConditions
                    open={isTermsAndConditionsModalOpen}
                    close={toggleTermsAndConditionsModal}
                />
            </div>
        </>
    );
}