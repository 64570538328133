import * as React from "react";
import { connect } from "react-redux";

import { Box, ButtonBase } from "@mui/material";

import { MyAsebpCardFace } from "./MyAsebpCardFace";
import { ApplicationState } from "store";
import { MembershipCardActionCreators } from "../actionCreator";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";
import { getCoveredMemberSummary } from "modules/person/coveredMemberSummary";

class MyAsebpCardPreview extends React.Component<ComponentProps> {
    public render() {
        const { isFetching, isViewingFullCard, coveredMember } = this.props;

        const benefitsLoaded = !isFetching && coveredMember && coveredMember.person && coveredMember.benefits;

        return benefitsLoaded ? (
            <Box sx={{ display: { xs: 'none', sm:  'block'} }}>
                <div className={`myasebpcard-wrapper ${isViewingFullCard ? "viewing-full-card" : ""}`}>
                    <ButtonBase className="myasebpcard-preview" onClick={this.props.showMembershipCard}>
                        <MyAsebpCardFace coveredMember={coveredMember} isFetching={isFetching} />
                    </ButtonBase>
                </div>
            </Box>
        ) : null;
    }
}

interface IStateToProps {
    coveredMember: CoveredMemberSummary;
    isViewingFullCard: boolean;
    isFetching: boolean;
}

interface IDispatchToProps {
    showMembershipCard: () => any;
}

type ComponentProps = IStateToProps & IDispatchToProps;

export default connect(
    (state: ApplicationState) => {
        return {
            coveredMember: getCoveredMemberSummary(state),
            isViewingFullCard: state.benefits.membershipCard.showMembershipCard,
            isFetching: state.person.isFetching || state.benefits.coverage.isFetchingMemberBenefitHistory || state.benefits.coverage.isFetchingMemberBenefits
        };
    },
    {
        showMembershipCard: MembershipCardActionCreators.showMembershipCard
    }
)(MyAsebpCardPreview);
