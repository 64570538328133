export interface InboxState {
    conversationsState: ConversationsState;
    conversationState: ConversationState;
    newConversationState: NewConversationState;
    categoryState: CategoryState;
}

export interface ErrorState {
    errorMessage?: string;
}

export interface SubmittingState {
    isSubmitting: boolean;
}
export interface DeletingState {
    isDeleting: boolean;
}

export interface FetchingState {
    isFetching: boolean;
}

export interface BackgroundFetchingState extends FetchingState {
    isBackgroundFetching: boolean;
}

export interface ConversationsState extends BackgroundFetchingState, ErrorState {
    conversations: ConversationModel[];
}

export interface ConversationState extends BackgroundFetchingState, NewConversationState {
    selectedConversationId: number;
    message: string;
    messageAttachments: LocalMessageAttachment[];
}

export interface NewConversationState extends ErrorState, SubmittingState {
    isOpen: boolean;
}

// https://github.com/justeat/ZendeskApiClient/blob/06e8b2406848b072628f6f2e5d08defd9ca89565/src/ZendeskApi.Client/Models/TicketStatus.cs
export enum ConversationStatus {
    New = 0,
    Open = 1,
    Closed = 2,
    Pending = 3,
    Resolved = 4,
    Hold = 5,
    Deleted = 6
}

export const getConversationStatusClassName = (status: ConversationStatus) => {
    const regex = /(?=[A-Z])/;
    const statusClassName = ConversationStatus[status].split(regex).join("-").toLowerCase();
    return statusClassName;
};

export const getConversationStatusDisplay = (status: ConversationStatus) => {
    const regex = /(?=[A-Z])/;
    const statusDisplay = ConversationStatus[status].split(regex).join(" ");
    return statusDisplay;
};

export interface PagedConversationsModel {
    totalCount: number;
    results: ConversationModel[];
    page: number;
    pageSize: number;
    sortColumn: string;
    sortDescending: boolean;
}

export interface VersionState {
    timestamp: number;
}

export interface ConversationModel extends BackgroundFetchingState, SubmittingState, ErrorState, VersionState {
    id: number;
    status: ConversationStatus;
    unreadMessageCount: number;
    categoryId: number;
    subject: string;
    username: string;
    createdOnUtc: Date;
    updatedAtUtc: Date;
    messages: MessageModel[];
    resolveAttemptedOnUtc: Date;
    resolvedOnUtc: Date;
}

export interface MessageModel extends BackgroundFetchingState, SubmittingState, ErrorState, VersionState {
    id: number;
    conversationId: number;
    body: string;
    htmlBody?: string;
    createdOnUtc: Date;
    viewedOnUtc?: Date;
    isCreatedByProfileUser: boolean;
    attachments: AttachmentModel[];
    messageViews: MessageViewModel[];
}

export interface AttachmentModel {
    id: number;
    name: string;
}

export interface MessageViewModel {
    viewedOnUtc?: Date;
    isCoveredMemberView: boolean;
}

export interface NewConversationModel {
    username: string;
    subject: string;
    categoryId?: number;
    message: NewMessageModel;
}

export interface LocalMessageAttachment extends SubmittingState, DeletingState, ErrorState {
    uploadTokenId?: number;
    localId: string;
    fileName: string;
    file: File;
    percentUploaded: number;
}

export interface NewMessageModel {
    body: string;
    uploadTokenIds: number[];
}

export interface IncomingConversation extends VersionState {
    assignedTo?: string;
    conversationId: number;
    username: string;
}

export interface IncomingMessage extends VersionState {
    messageId: number;
    conversationId: number;
    username: string;
}

export interface CategoryState extends FetchingState, ErrorState {
    categories: CategoryModel[];
}

export interface CategoryModel {
    id: number;
    name: string;
}
