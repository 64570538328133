import * as React from "react";

import { ColoredAvatar, colorVariant } from "modules/common/components/misc";
import { PersonDetails } from "modules/person/models";

export interface ProfileAvatarIconProps {
    person: PersonDetails;
    size: "sm" | "md" | "lg" | "xl";
    handleOpen?: (event: any) => any;
    hideDropdownIcon?: boolean;
    anchorEl?: HTMLElement;
}

export class ProfileAvatarIcon extends React.Component<ProfileAvatarIconProps> {
    public render() {
        const { anchorEl, size, handleOpen, hideDropdownIcon } = this.props;
        const initials = this.getInitials();

        return (
            <div className="colored-avatar">
                <ColoredAvatar className="person-avatar active" color={this.toColor(initials)} size={size} onClick={handleOpen} aria-owns={Boolean(anchorEl) ? "action-menu" : null} aria-haspopup="true">
                    <div className="person-avatar-inner">
                        <span>{initials}</span>
                        {hideDropdownIcon ? null : <i className={`material-icons ${Boolean(anchorEl) ? "flip" : ""}`}>arrow_drop_down</i>}
                    </div>
                </ColoredAvatar>
            </div>
        );
    }

    private getInitials = (): string => {
        if (!!this.props.person && !!this.props.person.name) {
            const first = this.props.person.name.first;
            const last = this.props.person.name.last;

            return first[0] + last[0];
        }
        return "...";
    };

    private toColor = (initials: string): colorVariant => {
        let sum = 0;
        for (const c of initials) {
            sum += c.charCodeAt(0);
        }
        return `${(sum % 16) + 1}` as colorVariant;
    };
}
