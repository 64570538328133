import { Badge } from "@mui/material";
import { ConversationStatus, ConversationModel } from "modules/inbox/models";
import React from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { ApplicationState } from "store";

interface InboxBadgeProps {
    children: any;
}

const InboxBadge = ({ children }: InboxBadgeProps) => {
    const conversations: ConversationModel[] = useAppSelector((state: ApplicationState) => state.inbox.conversationsState.conversations);

    const hasConversationsThatNeedAction =
        conversations?.some(
            (c) => c.unreadMessageCount > 0
                || c.status === ConversationStatus.Pending
                || c.status === ConversationStatus.Resolved
        ) ?? false;

    return (
        <Badge badgeContent={0}
            showZero={hasConversationsThatNeedAction}
            color="primary"
            overlap="circular"
            variant="dot">
            {children}
        </Badge>
    );
};

export default InboxBadge;
