import React from "react";
import { Icon } from "@mui/material";

export interface ExternalLinkProps {
  to: string;
  color?: "primary" | "secondary" | "info" | "default";
  openInSameWindow?: boolean;
  className?: string;
}

export function ExternalLink(props: React.PropsWithChildren<ExternalLinkProps>) {
  const { to, openInSameWindow = false, color = "default", className = "" } = props;

  const handleAnchorClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!openInSameWindow) {
      event.stopPropagation();
    }
  };

  return (
    <a
      className={`external-link ${color} ${className}`}
      onClick={handleAnchorClicked}
      target={openInSameWindow ? "" : "_blank"}
      href={to}
      rel="noopener"
    >
      <Icon>open_in_new</Icon>
      &nbsp;
      <span>{props.children}</span>
    </a>
  );
}
