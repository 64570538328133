import { Reducer } from "redux";

import { HandledAction } from "./actionCreator";
import { DocumentsState, DocumentCollectionState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<DocumentsState> = (state: DocumentsState, action: KnownAction) => {
    switch (action.type) {
        case "GET_DOCUMENTS_INIT": {
            let collection = state.documentCollections[action.collectionName];
            if (!collection) {
                collection = {
                    collectionName: action.collectionName,
                    currentPageNumber: action.pageNumber,
                    pages: [],
                    isFetching: true
                } as DocumentCollectionState;
            }

            let page = collection.pages && collection.pages[action.pageNumber] ? collection.pages[action.pageNumber] : null;
            if (!page) {
                page = {
                    documents: [],
                    pageNumber: action.pageNumber
                };
            }

            return {
                ...state,
                documentCollections: {
                    ...state.documentCollections,
                    [action.collectionName]: {
                        ...collection,
                        pages: {
                            ...collection.pages,
                            [action.pageNumber]: {
                                ...page,
                                isFetching: true
                            }
                        }
                    }
                }
            };
        }
        case "GET_DOCUMENTS_COMPLETE": {
            let collection = state.documentCollections[action.collectionName];
            if (!collection) {
                collection = {
                    collectionName: action.collectionName,
                    currentPageNumber: action.pageNumber,
                    pages: []
                } as DocumentCollectionState;
            }

            let page = collection.pages && collection.pages[action.pageNumber] ? collection.pages[action.pageNumber] : null;
            if (!page) {
                page = {
                    documents: [],
                    pageNumber: action.pageNumber
                };
            }

            return {
                ...state,
                documentCollections: {
                    ...state.documentCollections,
                    [action.collectionName]: {
                        ...collection,
                        currentPageNumber: action.pageNumber,
                        pages: {
                            ...collection.pages,
                            [action.pageNumber]: {
                                ...page,
                                isFetching: false,
                                documents: action.succeeded ? action.response.documents : page.documents,
                                totalCount: action.succeeded ? action.response.totalCount : page.totalCount
                            }
                        },
                        pageSize: action.succeeded ? action.response.pageSize : collection.pageSize,
                        totalCount: action.succeeded ? action.response.totalCount : collection.totalCount
                    }
                }
            };
        }
        case "TRACK_DOCUMENT_DOWNLOAD": {
            return state;
        }
        case "GET_DOCUMENT_STATS_INIT": {
            return {
                ...state,
                isFetchingDocumentStats: true
            };
        }
        case "GET_DOCUMENT_STATS_COMPLETE": {
            return {
                ...state,
                documentStats: action.succeeded ? action.documentStats : state.documentStats,
                isFetchingDocumentStats: false
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            documentCollections: {}
        } as DocumentsState)
    );
};
