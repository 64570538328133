import { KnownAction } from "modules/dashboard/actionCreator";
import { BenefitUtilizationState, DashboardState } from "modules/dashboard/models";
import { Reducer } from "redux";

export const reducer: Reducer<DashboardState> = (state: Readonly<DashboardState>, action: KnownAction) => {
    switch (action.type) {
        case "SET_BENEFIT_UTILIZATION_USER_SELECTION": {
            return {
                ...state,
                benefitUtilization: {
                    ...state.benefitUtilization,
                    certificateNumber: action.certificateNumber
                }
            };
        }
    }

    return (
        state ||
        ({
            benefitUtilization: {
                certificateNumber: ""
            } as BenefitUtilizationState
        } as DashboardState)
    );
};
