import { ConfigState } from "./models";

export const reducer = (state: ConfigState, action): ConfigState => {
    return (state || {
        appUrl: "not-set",
        appInsightsKey: "not-set",
        classicAppUrl: "not-set",
        googleAnalyticsTrackingId: "not-set",
        googleAnalyticsFourTrackingID: "not-set",
        googleAnalyticsTagID: "not-set",
        googleMapsApiKey: "not-set",
        googleMapsGeocodingServiceUrl: "not-set"
    }
    );
};
