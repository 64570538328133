import * as React from "react";

export interface ClickAwayProps {
    onClick: () => any;
    onEscPress: () => any;
    zIndex: number;
}
export class ClickAway extends React.PureComponent<ClickAwayProps> {
    public constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    private escFunction(event) {
        if (event.keyCode === 27) {
            this.props.onEscPress();
        }
    }
    public componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    public componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }
    public render() {
        const divStyle = {
            zIndex: this.props.zIndex
        };
        return <div className="click-away-layer" style={divStyle} onClick={this.props.onClick}></div>;
    }
}
