import * as ReactGUA from "react-ga";
import ReactGA from "react-ga4";

export interface IGoogleAnalyticsLoggerConfig {
    trackingId: string;
    googleAnalyticsFourTrackingID: string;
}

export const GoogleAnalyticsLogger = {
    enabled: false,
    init: (config: IGoogleAnalyticsLoggerConfig) => {
        if (typeof window !== "undefined") {
            //Google Universal Analytics
            ReactGUA.initialize(config.trackingId);

            //Google Analytics 4
            ReactGA.initialize(config.googleAnalyticsFourTrackingID);

            GoogleAnalyticsLogger.enabled = true;
            GoogleAnalyticsLogger.trackPageView();
        }
    },
    trackPageView: () => {
        const path = window.location.pathname;
        //Google Universal Analytics
        ReactGUA.pageview(path);

        //Google Analytics 4
        ReactGA.send({ hitType: "pageview", page: path });
    },
    trackEvent: (category: string, action: string, label?: string, value?: number, nonInteraction?: boolean) => {
        if (GoogleAnalyticsLogger.enabled) {
            //Google Universal Analytics
            ReactGUA.event({ category: category, action: action, label: label, value: value, nonInteraction: nonInteraction });

            //Google Analytics 4
            ReactGA.event({ category: category, action: action, label: label, value: value, nonInteraction: nonInteraction });
        }
    },
    trackException: (description?: string, fatal?: string) => {
        if (GoogleAnalyticsLogger.enabled) {
            //Google Universal Analytics
            ReactGUA.exception({ description: description, fatal: fatal });

            //Google Analytics 4
            ReactGA.gtag("event", "exception", { "description": description, "fatal": fatal });
        }
    },
    setUserId: (authenticatedUserId: string) => {
        if (GoogleAnalyticsLogger.enabled) {
            //Google Universal Analytics
            ReactGUA.set({ userId: authenticatedUserId });

            //Google Analytics 4
            ReactGA.set({ userId: authenticatedUserId });
        }
    },
    trackModalView: () => {
        //Google Universal Analytics
        const path = window.location.pathname;
        ReactGUA.modalview(path);

        //Google Analytics 4
        if (window.location.pathname.substring(0, 1) === '/' &&
            window.location.pathname.substring(1) !== '') {
            const path = '/modal/' + window.location.pathname.substring(1);
            ReactGA.send({ hitType: "pageview", page: path });
        }
    }
};