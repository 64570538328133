import { Reducer } from "redux";
import { HandledAction } from "./actionCreator";
import { DrugInquiryState } from "./models";

export const reducer: Reducer<DrugInquiryState> = (state: DrugInquiryState, action: HandledAction) => {
    switch (action.type) {
        case "DRUG_INQUIRY_SEARCH_INIT": {
            return {
                ...state,
                query: action.query,
                isSearching: true
            };
        }
        case "DRUG_INQUIRY_SEARCH_COMPLETE": {
            if (action.query === state.query) {
                return {
                    ...state,
                    isSearching: false,
                    searchResults: action.searchResults
                };
            }
            return state;
        }
        case "DRUG_INQUIRY_GET_DRUG_INIT": {
            return {
                ...state,
                isFetchingDrug: true
            };
        }
        case "DRUG_INQUIRY_GET_DRUG_COMPLETE": {
            return {
                ...state,
                isFetchingDrug: false,
                drug: action.drugDetail
            };
        }
        case "DRUG_INQUIRY_GET_DRUG_PRODUCT_INIT": {
            return {
                ...state,
                isFetchingDrugProduct: true
            };
        }
        case "DRUG_INQUIRY_GET_DRUG_PRODUCT_COMPLETE": {
            return {
                ...state,
                isFetchingDrugProduct: false,
                drugProduct: action.drugProduct
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            query: null,
            isSearching: false,
            searchResults: [],
            isFetchingDrug: false,
            drug: null,
            isFetchingDrugProduct: false,
            drugProduct: null
        } as DrugInquiryState)
    );
};
