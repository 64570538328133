import { Formatter } from "modules/utils";
import { NewMessageCompleteAction, NewMessageInitAction } from "./actions";
import { InboxState, MessageModel, AttachmentModel } from "./models";

const DefaultMessageModel = {
    get: (): MessageModel => {
        return {
            id: -1,
            conversationId: null,
            body: "",
            isCreatedByProfileUser: true,
            createdOnUtc: Formatter.getUTCNow(),
            viewedOnUtc: Formatter.getUTCNow(),
            attachments: [],
            isFetching: false,
            isBackgroundFetching: false,
            isSubmitting: false
        } as MessageModel;
    }
};

const StateAdjuster = {
    newConversationMessageInit: (state: InboxState, action: NewMessageInitAction): InboxState => {
        const conversations = state.conversationsState.conversations.map((conversation) => {
            if (conversation.id === action.conversationId) {
                const messages = [...conversation.messages];
                // ok we found the conversation lets push a dummy message to the list!
                messages.push({
                    ...DefaultMessageModel.get(),
                    body: action.message.body,
                    conversationId: action.conversationId,
                    isSubmitting: true
                } as MessageModel);

                return {
                    ...conversation,
                    messages: messages
                };
            }
            return conversation;
        });

        return {
            ...state,
            conversationsState: {
                ...state.conversationsState,
                conversations: conversations
            },
            conversationState: {
                ...state.conversationState,
                isSubmitting: true
            }
        };
    },

    newConversationMessageComplete: (state: InboxState, action: NewMessageCompleteAction): InboxState => {
        const conversations = state.conversationsState.conversations.map((conversation) => {
            if (conversation.id === action.conversationId) {
                let messages = [...conversation.messages];
                if (action.succeeded) {
                    // lets filter out the temporary message to be replaced!
                    messages = messages.filter((m) => m.id !== -1);
                    // if we have a message id it means it was created lets push a temp message to the list
                    // but only push it to the list if it wasn't already added via websocket or other request aka its a race!
                    if (!messages.some((m) => m.id === action.messageId)) {
                        if (action.messageId) {
                            messages.push({
                                ...DefaultMessageModel.get(),
                                id: action.messageId,
                                body: action.message.body,
                                conversationId: action.conversationId
                            } as MessageModel);
                        }
                    }
                } else {
                    // it failed update the placeholder message
                    messages = messages.map((message) => {
                        if (message.id === action.messageId) {
                            return {
                                ...message,
                                errorMessage: action.error,
                                isSubmitting: false
                            };
                        }
                        return message;
                    });
                }

                return {
                    ...conversation,
                    messages: messages
                };
            }
            return conversation;
        });

        return {
            ...state,
            conversationsState: {
                ...state.conversationsState,
                conversations: conversations
            },
            conversationState: {
                ...state.conversationState,
                isSubmitting: false,
                message: action.succeeded ? "" : state.conversationState.message,
                messageAttachments: action.succeeded ? [] : state.conversationState.messageAttachments
            }
        };
    }
};

export default StateAdjuster;
