import * as React from "react";
import { ListItem, Icon, ListItemText, Button, ListItemIcon } from "@mui/material";

interface SignOutButtonProps {
    renderAsListItem?: boolean;
    hideIcon?: boolean;
}

export class SignOutButton extends React.PureComponent<SignOutButtonProps> {
    public render() {
        return this.props.renderAsListItem ? (
            <a href="/logout">
                <ListItem button>
                    {this.props.hideIcon ? null : (
                        <ListItemIcon>
                            <Icon>exit_to_app</Icon>
                        </ListItemIcon>
                    )}
                    <ListItemText primary="Sign Out" />
                </ListItem>
            </a>
        ) : (
            <Button href={"/logout"} className="logout-btn">
                Sign Out
            </Button>
        );
    }
}
