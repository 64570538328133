import { Reducer } from "redux";

import { NoticesState } from "modules/notices/models";
import { HandledAction } from "./actionCreator";

export type KnownAction = HandledAction;

export const reducer: Reducer<NoticesState> = (state: NoticesState, action: KnownAction) => {
    switch (action.type) {
        case "GET_NOTICES_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_NOTICES_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                notices: action.notices
            };
        }
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return (
        state || {
            isFetching: false,
            notices: null
        }
    );
};
