import * as React from "react";
import PropTypes from "prop-types";

interface ButtonGroupProps {
    children: React.ReactNode;
  }

export class ButtonGroup extends React.PureComponent<ButtonGroupProps> {
    public static propTypes = {
        children: PropTypes.node.isRequired
    };

    public render() {
        return <div className="button-group">{this.props.children}</div>;
    }
}
