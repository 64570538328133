import { AppThunkAction } from "store";

import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";

import API from "api";
import { NetworkRequest } from "api/actions";

import * as actions from "./actions";
import { ToggleableFeature, FeatureArticle, FeatureArticlePreview, UserActivity } from "./models";

export type HandledAction =
    | actions.GetToggleableFeaturesInitAction
    | actions.GetToggleableFeaturesCompleteAction
    | actions.GetFeatureArticlePreviewsInitAction
    | actions.GetFeatureArticlePreviewsCompleteAction
    | actions.GetFeatureArticleInitAction
    | actions.GetFeatureArticleCompleteAction
    | actions.GetUserActivityInitAction
    | actions.GetUserActivityCompleteAction
    | actions.SaveUserActivityInitAction
    | actions.SaveUserActivityCompleteAction;

export type FeaturesActions = HandledAction | NetworkRequest | AddNotificationAction;

export const FeaturesActionCreators = {
    getToggleableFeatures: (): AppThunkAction<FeaturesActions> => (dispatch, getState) => {
        dispatch({ type: "GET_TOGGLEABLE_FEATURES_INIT" });

        return dispatch(API.features.getToggleableFeatures())
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<ToggleableFeature[]>;
                } else {
                    dispatch({ type: "GET_TOGGLEABLE_FEATURES_COMPLETE", toggleableFeatures: null, succeeded: false });
                }
            })
            .then((toggleableFeatures: ToggleableFeature[]) => {
                if (toggleableFeatures) {
                    dispatch({ type: "GET_TOGGLEABLE_FEATURES_COMPLETE", toggleableFeatures, succeeded: true });
                }
                return toggleableFeatures;
            });
    },
    getArticlePreviews: (): AppThunkAction<FeaturesActions> => (dispatch, getState) => {
        dispatch({ type: "GET_FEATURE_ARTICLE_PREVIEWS_INIT" });

        return Promise.resolve()
            .then(() => {
                return dispatch(API.features.getArticlePreviews());
            })
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<FeatureArticlePreview[]>;
                } else {
                    dispatch({ type: "GET_FEATURE_ARTICLE_PREVIEWS_COMPLETE", articles: null, succeeded: false });
                    dispatch({ type: "ADD_NOTIFICATION", message: "Failed to get articles.", style: NotificationStyle.Error, autohide: true, canDismiss: true });
                }
            })
            .then((articles: FeatureArticlePreview[]) => {
                if (articles) {
                    dispatch({ type: "GET_FEATURE_ARTICLE_PREVIEWS_COMPLETE", articles, succeeded: true });
                }
                return articles;
            });
    },
    getArticle: (id: number): AppThunkAction<FeaturesActions> => (dispatch, getState) => {
        dispatch({ type: "GET_FEATURE_ARTICLE_INIT", id });

        return Promise.resolve()
            .then(() => {
                return dispatch(API.features.getArticle(id));
            })
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<FeatureArticle>;
                } else {
                    dispatch({ type: "GET_FEATURE_ARTICLE_COMPLETE", id, article: null, succeeded: false });
                    dispatch({ type: "ADD_NOTIFICATION", message: "Failed to get feature.", style: NotificationStyle.Error, autohide: true, canDismiss: true });
                }
            })
            .then((article: FeatureArticle) => {
                if (article) {
                    dispatch({ type: "GET_FEATURE_ARTICLE_COMPLETE", id, article, succeeded: true });
                }
                return article;
            });
    },
    getUserActivity: (key: string): AppThunkAction<FeaturesActions> => (dispatch, getState) => {
        dispatch({ type: "GET_USER_ACTIVITY_INIT", key });

        return dispatch(API.features.getActivity(key))
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<UserActivity>;
                } else if (response?.status === 204) {
                    return Promise.resolve({
                        key,
                        value: null,
                        createdDateTime: null,
                        modifiedDateTime: null
                    } as UserActivity);
                } else {
                    dispatch({ type: "GET_USER_ACTIVITY_COMPLETE", key, activity: null, succeeded: false });
                }
            })
            .then((activity: UserActivity) => {
                if (activity) {
                    dispatch({ type: "GET_USER_ACTIVITY_COMPLETE", key, activity, succeeded: true });
                }
                return activity;
            });
    },
    saveUserActivity: (activity: UserActivity): AppThunkAction<FeaturesActions> => (dispatch, getState) => {
        dispatch({ type: "SAVE_USER_ACTIVITY_INIT", key: activity.key, activity });

        return dispatch(API.features.saveActivity(activity))
            .then(response => {
                if (response?.status === 200) {
                    return response.json() as Promise<UserActivity>;
                } else {
                    dispatch({ type: "SAVE_USER_ACTIVITY_COMPLETE", key: activity.key, activity: null, succeeded: false });
                }
            })
            .then((savedActivity: UserActivity) => {
                if (savedActivity) {
                    dispatch({ type: "SAVE_USER_ACTIVITY_COMPLETE", key: activity.key, activity: savedActivity, succeeded: true });
                }
                return savedActivity;
            });
    }
};
