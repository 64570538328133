import { Reducer } from "redux";
import { HandledAction } from "./actionCreator";
import { ClaimListState, ClaimsFilterState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<ClaimListState> = (state: ClaimListState, action: KnownAction) => {
    switch (action.type) {
        case "GET_CLAIM_STATS_INIT": {
            return {
                ...state,
                isFetchingClaimStats: true
            };
        }
        case "GET_CLAIM_STATS_COMPLETE": {
            return {
                ...state,
                claimStats: action.succeeded ? action.claimStats : state.claimStats,
                isFetchingClaimStats: false
            };
        }
        case "GET_CLAIM_BENEFIT_FILTERS_INIT": {
            return {
                ...state,
                isFetchingAvailableBenefitFilters: true
            };
        }
        case "GET_CLAIM_BENEFIT_FILTERS_COMPLETE": {
            return {
                ...state,
                availableBenefitFilters: action.succeeded ? action.filters : state.availableBenefitFilters,
                isFetchingAvailableBenefitFilters: false
            };
        }
        case "GET_CLAIMS_INIT": {
            return {
                ...state,
                isFetching: true,
                claimsFilterState: state.claimsFilterState
                    ? state.claimsFilterState
                    : ({
                          searchText: "",
                          view: "All",
                          format: "Grouped",
                          pageNumber: 1
                      } as ClaimsFilterState)
            };
        }
        case "GET_CLAIMS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetching: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetching: false,
                pages: {
                    ...state.pages,
                    [action.claimsResponse.pageNumber]: action.claimsResponse
                },
                totalClaimCount: action.claimsResponse.claimsTotal,
                pageSize: action.claimsResponse.pageSize,

                errorMessage: null
            };
        }
        case "CLEAR_CLAIMS": {
            return {
                ...state,
                isFetching: false,
                claimsResponse: null
            } as ClaimListState;
        }
        case "UPDATE_CLAIM_SUMMARY": {
            return {
                ...state,
                pages: state.pages
                    ? {
                          ...state.pages,
                          [state.claimsFilterState.pageNumber]: {
                              ...state.pages[state.claimsFilterState.pageNumber],
                              claims: state.pages[state.claimsFilterState.pageNumber].claims.map(claim => {
                                  if (claim.claimSubmissions && claim.claimSubmissions.length && claim.claimSubmissions[0].referenceId === action.claim.claimSubmissions[0].referenceId) {
                                      return action.claim;
                                  }
                                  return claim;
                              })
                          }
                      }
                    : null
            };
        }
        case "UPDATE_CLAIMS_FILTER": {
            return {
                ...state,
                claimsFilterState: action.filter
            };
        }
        case "DISMISS_CLAIM_INIT": {
            return {
                ...state,
                isDismissing: true
            };
        }
        case "DISMISS_CLAIM_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    isDismissing: false,
                    pages: {
                        ...state.pages,
                        [state.claimsFilterState.pageNumber]: {
                            ...state.pages[state.claimsFilterState.pageNumber],
                            claims: state.pages[state.claimsFilterState.pageNumber].claims.map(claim => {
                                const sub = claim.claimSubmissions.map(submission => {
                                    if (submission.referenceId === action.referenceId) {
                                        return { ...submission, isDismissed: true };
                                    }
                                    return submission;
                                });
                                return { ...claim, claimSubmissions: sub };
                            })
                        }
                    },
                    totalClaimCount: state.totalClaimCount - 1
                };
            }

            return {
                ...state,
                isDismissing: false
            };
        }
        case "SAVE_MARKING_AS_CLAIM_ALREADY_TRANSFERRED_TO_HSA_INIT": {
            return state;
        }
        case "SAVE_MARKING_AS_CLAIM_ALREADY_TRANSFERRED_TO_HSA_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    pages: {
                        ...state.pages,
                        [state.claimsFilterState.pageNumber]: {
                            ...state.pages[state.claimsFilterState.pageNumber],
                            claims: state.pages[state.claimsFilterState.pageNumber].claims.map(claim => {
                                const sub = claim.claimSubmissions.map(submission => {
                                    if (submission.referenceId === action.referenceId) {
                                        return { ...submission, hasMemberIndicatedTransferredToHsa: true };
                                    }
                                    return submission;
                                });
                                return { ...claim, claimSubmissions: sub };
                            })
                        }
                    }
                };
            }

            return state;
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            isFetching: false,
            pages: null,
            claimsFilterState: null,
            claimStats: null,
            isFetchingClaimStats: false,

            availableBenefitFilters: null,
            isFetchingAvailableBenefitFilters: false
        } as ClaimListState)
    );
};
