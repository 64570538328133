import React, { useState, PropsWithChildren } from "react";
import { IconButton, Icon, DialogTitle, DialogContent } from "@mui/material";

import { ResponsiveDialog } from "modules/common/components/layout";

export interface ToolTipProps {
  title?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  className?: string;
}

export const ToolTip = (props: PropsWithChildren<ToolTipProps>) => {
  const { title, maxWidth = "xs", className = "", children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={open}
        className={"tool-tip-button " + className}
        aria-label="Info"
        size="large"
      >
        <Icon>info_outline</Icon>
      </IconButton>
      <ResponsiveDialog
        open={isOpen}
        onClose={close}
        className="tool-tip-dialog"
        maxWidth={maxWidth}
      >
        <DialogTitle className="dialog-title">
          {title ? <div className="title">{title}</div> : null}
          <div className="close">
            <IconButton
              className="close-button"
              onClick={close}
              aria-label="Close"
              size="large"
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </ResponsiveDialog>
    </>
  );
};
