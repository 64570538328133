import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Popover, Paper, ListItem, ListItemText, Icon, Divider, ListItemIcon } from "@mui/material";

import { PrintMembershipCardButton } from "modules/benefits/membershipCard/components";
import { SignOutButton } from "./SignOutButton";
import { MyProfileButton } from "./MyProfileButton";
import { ProfileAvatarIcon } from "./index";
import { PersonDetails } from "modules/person/models";
import { SettingsButton } from "./SettingsButton";

export interface ProfileAvatarProps {
    person: PersonDetails;
    certificateNumber: string;
    showMembershipCard: () => any;
    hasActiveCoverage: boolean;
    hasT4ASlips: boolean;
    enableSettingsPage: boolean;
}

export interface ProfileAvatarState {
    anchorEl: HTMLElement;
}

export class ProfileAvatar extends React.Component<ProfileAvatarProps, ProfileAvatarState> {
    public state: ProfileAvatarState = { anchorEl: null };

    public render() {
        const { person, showMembershipCard, certificateNumber, hasT4ASlips, hasActiveCoverage, enableSettingsPage } = this.props;
        const { anchorEl } = this.state;

        return (
            <div className="user">
                <ProfileAvatarIcon person={person} size="xl" handleOpen={this.handleOpen} anchorEl={anchorEl} />
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}>
                    <Paper onClick={this.handleClose} className="action-menu">
                        <ListItem button onClick={showMembershipCard}>
                            <ListItemIcon>
                                <Icon>card_membership</Icon>
                            </ListItemIcon>
                            <ListItemText className="id-card-link">
                                <div className="title">ASEBP ID</div>
                                <div className="number">
                                    <b>{certificateNumber}</b>
                                </div>
                            </ListItemText>
                        </ListItem>
                        <MyProfileButton renderAsListItem />
                        {enableSettingsPage ? hasT4ASlips ? <SettingsButton renderAsListItem /> : null : null}
                        <RouterLink to="/benefits/family">
                            <ListItem button>
                                <ListItemIcon>
                                    <Icon>group</Icon>
                                </ListItemIcon>
                                <ListItemText primary="My Family" />
                            </ListItem>
                        </RouterLink>
                        {hasActiveCoverage ? <PrintMembershipCardButton renderAsListItem /> : null}
                        <Divider />
                        <SignOutButton renderAsListItem />
                    </Paper>
                </Popover>
            </div>
        );
    }

    private handleOpen = event => {
        const { currentTarget } = event;
        this.setState(s => ({ ...s, anchorEl: currentTarget }));
    };

    private handleClose = event => {
        this.setState(s => ({ ...s, anchorEl: null }));
    };
}
