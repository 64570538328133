import { NetworkRequest } from "./actions";

export const getPreferences = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/preferences`,
        params: {}
    };
};

export const getPreference = (name: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/preferences/{name}`,
        params: {
            name
        }
    };
};

export const savePreference = (memberPreferenceName: string, isEnabled: boolean): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/preferences`,
        body: {
            memberPreferenceName,
            isEnabled
        }
    };
};

export const validateTaxDate = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/preferences/validateTaxDate`,
        params: {}
    };
};
