export interface FeaturesState {
    featureToggles: FeatureToggles;
    featureArticleList: FeatureArticleCollection;

    userActivities: UserActivities;
    hasUnreadFeatureArticles: boolean;
}

export interface FeatureArticleCollection {
    articles: FeatureArticle[];
    isFetching: boolean;
}

export type FeatureToggles = { [key in KnownFeatureToggles]: ToggleableFeature };

export interface ToggleableFeature {
    key: string;
    toggleValue: FeatureToggleValue;
    data: string;
    description: string;
    lastActivatedDateTime?: string;
    isPilotOnly: boolean;
}

export interface FeatureArticlePreview {
    id: number;
    toggleableFeatureKey: string;
    title: string;
    preview: string;
    isPublished: boolean;
    lastPublishedDateTime: string | null;
}

export interface FeatureArticle extends FeatureArticlePreview {
    content?: string;
}

export enum FeatureToggleValue {
    Off = 0,
    On = 1
}

export enum KnownFeatureToggles {
    AddressUpdatesAllowedForAllMembers = "AddressUpdatesAllowedForAllMembers",
    AddressUpdatesAllowedForSomeMembers = "AddressUpdatesAllowedForSomeMembers",
    AllowFilterByBenefitTypeSubcategory = "AllowFilterByBenefitTypeSubcategory",
    AllowFilteringClaimHistoryByCustomDateRange = "AllowFilteringClaimHistoryByCustomDateRange",
    FormsLinkCardOnDocumentsPage = "FormsLinkCardOnDocumentsPage",
    SpecialAuthorizationCardOnDocumentsPage = "SpecialAuthorizationCardOnDocumentsPage",
    IncludeClaimStatusInNaturalLanguageFilter = "IncludeClaimStatusInNaturalLanguageFilter",
    NaturalLanguageClaimsFilter = "NaturalLanguageClaimsFilter", // linked
    RateServiceProvidersOnClaimDetails = "RateServiceProvidersOnClaimDetails",
    RateServiceProvidersOnProviderDetails = "RateServiceProvidersOnProviderDetails",
    ServiceProvidersPage = "ServiceProvidersPage",
    IneligibleServiceProviders = "IneligibleServiceProviders",
    ShowAllEHCProductGroups = "ShowAllEHCProductGroups",
    ShowProductMaximumGroupFilterOptions = "ShowProductMaximumGroupFilterOptions",
    HidePackageName = "HidePackageName",
    DrugInquiryWithDin = "DrugInquiryWithDin",
    ShowDeeplinkingFromConfirmationPages = "ShowDeeplinkingFromConfirmationPages",
    SettingsPage = "Settings",
    ExplanationsOfBenefitsPage = "ExplanationsOfBenefitsPage",
    EnableMyRetiree = "EnableMyRetiree",
    EnableMessaging = "EnableMessaging",
    EnableGOCDrugProductDatabase = "EnableGOCDrugProductDatabase",
    InkblotAsEfapProvider = "InkblotAsEfapProvider",
    EnableDentalGuide = "EnableDentalGuide",
    ResourcesPage = "ResourcesPage",
    EnableUpdatedPsychologicalServicesVerbiage = "EnableUpdatedPsychologicalServicesVerbiage",

    // To be removed when cleaning up the feature toggle
    // http://tfs-01p:8080/tfs/DefaultCollection/ASEBP/_workitems/edit/27526
    ShowUpdatedTermsAndConditions = "ShowUpdatedTermsAndConditions",

    EnableTemporaryUsageSummaryBanner = "EnableTemporaryUsageSummaryBanner",
    PersonalInformationVerification = "PersonalInformationVerification",
    UpdateToClaimsApiV5OnWeb = "UpdateToClaimsApiV5OnWeb",
    EnableSavingsAccount = "EnableSavingsAccount",
    RestrictFeaturesForDeceased = "RestrictFeaturesForDeceased"
}

export type UserActivities = { [key in UserActivityKeys]: UserActivity };

export interface UserActivity {
    key: string;
    value: string | null;
    createdDateTime?: Date | string | null;
    modifiedDateTime?: Date | string | null;
}

export enum UserActivityKeys {
    LastViewedFeatureArticles = "LastViewedFeatureArticles",
    DismissedPersonalInfoVerification = "DismissedPersonalInfoVerification"
}
