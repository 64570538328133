import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Slide, Dialog, DialogContent, SlideProps } from "@mui/material";

import { MyAsebpCardBack } from "./MyAsebpCardBack";
import { MyAsebpCardFace } from "./MyAsebpCardFace";
import { ApplicationState } from "store";
import { MembershipCardActionCreators } from "../actionCreator";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";
import { getCoveredMemberSummary } from "modules/person/coveredMemberSummary";
import { KnownFeatureToggles } from "modules/features/models";

interface MyAsebpCardDialogState {
    showIndex: number;
    open: boolean;
}

const slideTransition = React.forwardRef<unknown, SlideProps>((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

class MyAsebpCardDialog extends React.Component<ComponentProps, MyAsebpCardDialogState> {
    public state = { showIndex: 0, open: false };
    private intervalTime = 350;

    private incrementShowIndex = () => {
        if (this.state.showIndex < 10 && this.props.showFullCard) {
            this.setState((s) => ({ ...s, showIndex: this.state.showIndex + 1 }));
            if (typeof window !== "undefined") {
                window.setTimeout(this.incrementShowIndex, this.intervalTime);
            }
        }
    };

    private handleClose = (event, reason) => {
        if (reason == "backdropClick" || reason == "escapeKeyDown") {
            this.props.hideMembershipCard();
        }
    };

    public componentDidUpdate(prevProps: ComponentProps, prevState: MyAsebpCardDialogState) {
        if (
            typeof window !== "undefined" &&
            this.props.showFullCard &&
            this.props.showFullCard !== prevProps.showFullCard
        ) {
            window.setTimeout(this.incrementShowIndex, this.intervalTime);
            this.setState((s) => ({ ...s, open: true }));
        } else if (typeof window !== "undefined" && !this.props.showFullCard && this.state.showIndex !== 0) {
            this.setState((s) => ({ ...s, showIndex: 0, open: false }));
        }
    }

    public render() {
        const { isFetching, coveredMember } = this.props;
        const benefitsLoaded = !isFetching && coveredMember && coveredMember.person && coveredMember.benefits;
        const hidePackageName = coveredMember.canAccessFeature(KnownFeatureToggles.HidePackageName);

        return coveredMember && coveredMember.benefits ? (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={slideTransition}
                scroll="body"
                PaperProps={{
                    style: {
                        background: "none",
                        margin: "0 auto",
                        boxShadow: "none",
                        overflowY: "visible",
                        maxWidth: "420px",
                        marginTop: "50px",
                        marginBottom: "50px"
                    },
                    onClick: (e) => {
                        e.stopPropagation();
                        return false;
                    }
                }}>
                <DialogContent style={{ padding: 8 }}>
                    <div className="myasebpcard dialog-content">
                        <MyAsebpCardFace coveredMember={coveredMember} isFetching={isFetching} />
                        {benefitsLoaded && coveredMember.hasActiveCoverage
                            ? coveredMember.benefits
                                  .filter((b) => b.hasActiveCoverage)
                                  .map((benefits, i) => {
                                      return (
                                          <MyAsebpCardBack
                                              key={benefits.membershipId}
                                              benefits={benefits}
                                              show={this.state.showIndex > i}
                                              hidePackageName={hidePackageName}
                                          />
                                      );
                                  })
                            : null}
                    </div>
                </DialogContent>
            </Dialog>
        ) : null;
    }
}

interface IStateToProps {
    coveredMember: CoveredMemberSummary;
    showFullCard: boolean;
    isFetching: boolean;
}

interface IDispatchToProps {
    hideMembershipCard: () => any;
}

type ComponentProps = IStateToProps & IDispatchToProps;

export default connect(
    (state: ApplicationState) => {
        return {
            coveredMember: getCoveredMemberSummary(state),
            showFullCard: state.benefits.membershipCard.showMembershipCard,
            isFetching:
                state.person.isFetching ||
                state.benefits.coverage.isFetchingMemberBenefitHistory ||
                state.benefits.coverage.isFetchingMemberBenefits
        };
    },
    {
        hideMembershipCard: MembershipCardActionCreators.hideMembershipCard
    }
)(MyAsebpCardDialog);
