import { Reducer } from "redux";

import { HandledAction } from "./actionCreator";
import { SearchState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<SearchState> = (state: SearchState, action: KnownAction) => {
    switch (action.type) {
        case "SEARCH_CURATED_SEARCH_RESULTS_INIT": {
            return {
                ...state,
                searchText: action.text,
                isFetching: true
            };
        }
        case "SEARCH_CURATED_SEARCH_RESULTS_COMPLETE": {
            return {
                ...state,
                isFetching: action.succeeded && action.text === state.searchText ? false : state.isFetching,
                response: action.succeeded && action.text === state.searchText ? action.response : state.response
            };
        }
        case "TRACK_CURATED_SEARCH_RESULT_SELECTION_INIT": {
            return state;
        }
        case "TRACK_CURATED_SEARCH_RESULT_SELECTION_COMPLETE": {
            return state;
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return (
        state || {
            searchText: "",
            response: null,
            isFetching: false
        }
    );
};
