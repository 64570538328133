import * as React from "react";
import { Grid, Button } from "@mui/material";

export interface PaginationProps {
    itemsTotal: number;
    itemsCount: number;
    pageNumber: number;
    pageSize: number;
    loadPage: (pageNumber: number, replacePreviousResults: boolean) => any;
    loading: boolean;
    loadMoreItemsButtonText: string;
    noItemsToLoadText: string;
}

export class MobilePagination extends React.PureComponent<PaginationProps> {
    public render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} className="mobile-pagination-component">
                    {this.doesNextPageExist() || this.props.loading ? (
                        <Button variant="contained" className="secondary" color="primary" fullWidth={true} disabled={this.props.loading} onClick={this.nextPageClick}>
                            {this.props.loading ? "Loading ..." : this.props.loadMoreItemsButtonText}
                        </Button>
                    ) : null}
                    {!this.doesNextPageExist() && !this.props.loading ? <div className="no-more-items-text">{this.props.noItemsToLoadText}</div> : null}
                </Grid>
            </Grid>
        );
    }

    private getLastItemIndex(): number {
        if (this.props.pageNumber && this.props.pageSize) {
            if (this.props.itemsCount > 0) {
                if (this.props.itemsTotal > this.props.pageSize) {
                    if (this.props.itemsCount > this.props.pageSize) {
                        // in case we add items and not replace
                        return this.props.itemsCount;
                    } else {
                        return (this.props.pageNumber - 1) * this.props.pageSize + this.props.itemsCount;
                    }
                } else {
                    return this.props.itemsTotal;
                }
            } else {
                return 0;
            }
        } else {
            if (this.props.itemsTotal && this.props.itemsTotal > 0) {
                return this.props.itemsTotal;
            } else {
                return 0;
            }
        }
    }

    private doesNextPageExist(): boolean {
        return this.props.pageNumber && this.getLastItemIndex() < this.props.itemsTotal;
    }

    private nextPageClick = () => {
        this.props.loadPage(this.props.pageNumber + 1, false);
    };
}
