import { combineReducers } from "redux";

import { reducer as benefitCoverageReducer } from "./coverage/reducer";
import { reducer as dentalGuideReducer } from "./dentalGuide/reducer";
import { reducer as drugsReducer } from "./drugs/reducer";
import { reducer as drugInquiryReducer } from "./drugInquiry/reducer";
import { reducer as membershipCardReducer } from "./membershipCard/reducer";
import { BenefitsState } from "./models";
import { reducer as spendingAccountsReducer } from "./spendingAccounts/reducer";
import { reducer as spendingAccountsReducerV2 } from "./spendingAccountsV2/reducer";
import { reducer as benefitUtilizationReducer } from "./utilization/reducer";

export const reducer = combineReducers<BenefitsState>({
    coverage: benefitCoverageReducer,
    drugs: drugsReducer,
    drugInquiry: drugInquiryReducer,
    utilization: benefitUtilizationReducer,
    spendingAccounts: spendingAccountsReducer,
    spendingAccountsV2: spendingAccountsReducerV2,
    membershipCard: membershipCardReducer,
    dentalGuide: dentalGuideReducer
});
