import { Reducer } from "redux";
import { KnownAction } from "./actionCreator";

export interface PersonalInfoVerificationState {
    isDue: boolean;
    lastCompleted: Date;
    isFetching: boolean;
    isSaving: boolean;
    succeeded: boolean;
    error?: any;
    openDialog: boolean;
}

const initState: PersonalInfoVerificationState = {
    isDue: false,
    lastCompleted: null,
    isFetching: false,
    isSaving: false,
    succeeded: false,
    openDialog: false
};

export const personalInfoVerificationReducer: Reducer<PersonalInfoVerificationState> = (
    state: PersonalInfoVerificationState = initState,
    action: KnownAction
) => {
    switch (action.type) {
        case "IS_PERSONAL_INFO_VERIFICATION_DUE_INIT":
            return {
                ...state,
                isFetching: true,
                error: null
            };
        case "IS_PERSONAL_INFO_VERIFICATION_DUE_COMPLETE":
            return {
                ...state,
                isDue: action.isDue,
                lastCompleted: action.lastCompleted,
                isFetching: false,
                succeeded: action.succeeded,
                error: action.error
            };
        case "PERSONAL_INFO_VERIFIED_INIT":
            return {
                ...state,
                isSaving: true,
                error: null
            };
        case "PERSONAL_INFO_VERIFIED_COMPLETED":
            return {
                ...state,
                isDue: action.succeeded ? false : state.isDue,
                lastCompleted: action.succeeded ? new Date() : state.lastCompleted,
                isSaving: false,
                succeeded: action.succeeded,
                error: action.error
            };
        case "OPEN_PERSONAL_INFO_VERIFICATION_DIALOG":
            return {
                ...state,
                openDialog: action.open
            };
        default:
            return state;
    }
};
