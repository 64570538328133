export type AttachmentFileType = "Image" | "PDF";

export enum ClaimAttachmentCategory {
    Receipt = 0,
    ExplanationOfBenefits = 1,
    Other = 2
}

export type ClaimAttachmentStatus = "Uploaded" | "Approved" | "Rejected" | "Deleted";

export enum ClaimGroupingOption {
    /** ABC-adjudicated claims are grouped with their expense claim(s), if transferred. */
    Transferred = 0,

    /** Each ABC or expense claim is in its own group. */
    None = 1,

    /** Multiple adjudications of the same claim line are grouped together; along with re-submissions */
    ClaimLine = 2
}

export enum ClaimSortColumn {
    ServiceDate = 1,
    SubmittedDate = 2,
    IsFixable = 3
}

export type ClaimStatusName = "Submitted" | "Processed";

export enum ClaimSourceType {
    NotSet = 0,
    Adjustments = 1,
    DirectBillDrugs = 2,
    EDI = 3,
    Paper = 4,
    OnlineClaim = 5,
    HSA = 6,
    WSA = 7
}

export const getClaimSourceTypeDisplayName = (type: ClaimSourceType): string => {
    switch (type) {
        case ClaimSourceType.HSA:
        case ClaimSourceType.WSA:
        case ClaimSourceType.OnlineClaim:
            return "Online";
        case ClaimSourceType.Adjustments:
            return "Adjustment";
        case ClaimSourceType.DirectBillDrugs:
        case ClaimSourceType.EDI:
            return "Direct Bill";
        case ClaimSourceType.Paper:
            return "Paper";
        default:
            return null;
    }
};

export enum OnlineClaimProductOrServiceType {
    Dental = 10,
    Psychology = 20,
    Physiotherapy = 30,
    MassageTherapy = 40,
    Acupuncture = 50,
    Drugs = 60,
    Chiropractic = 70,
    Vision = 80,
    EverythingElse = 99
}

export enum OnlineClaimSubmissionStatus {
    Draft = 1,
    Submitted = 2
}

export enum PaymentMedium {
    Cheque,
    DirectDeposit,
    PaidToProvider
}

export enum SubmissionTransferStatus {
    NotTransferred,
    Transferred
}

export type UnifiedClaimDetailType = "AbcClaim" | "ExpenseClaim";
