import { combineReducers } from "redux";

import { reducer as claimDetailsReducer } from "./claimDetails/reducer";
import { reducer as claimListReducer } from "./claimList/reducer";
import { reducer as claimSubmissionReducer } from "./claimSubmission/reducer";
import { ClaimsState } from "./models";
import { reducer as spendingAccountClaimCategoriesReducer } from "./spendingAccountClaimCategories/reducer";

export const reducer = combineReducers<ClaimsState>({
    claimList: claimListReducer,
    claimEditor: claimSubmissionReducer,
    claimDetails: claimDetailsReducer,
    spendingAccountClaimCategories: spendingAccountClaimCategoriesReducer
});
