import { OverAgeDependant } from "modules/benefits/coverage/models";

export interface PersonState {
    person: PersonDetails | null;
    isFetching: boolean;
}
export interface Person {
    certificateNumber: string;
    name: PersonName;
    contactInfo: PersonContactInfo;
    birthDate: string;
    genderCode: number;
    sectionCode: string;
    personStatus: PersonStatus;
    maskedSocialInsuranceNumber?: string;

    hasIdenticalFirstNameAsFamilyMember: boolean;
}

export interface PersonDetails extends Person {
    address: Address;
    ownedMemberships: OwnedMembership[];
    inheritedMemberships: InheritedMembership[];
    linkedPeople: Person[];
    deathDetails: DeathDetails;
}

export interface Address {
    addressId: number;
    street: string;
    suiteNumber: string | null;
    city: string;
    province: string;
    country: string;
    postalCode: string;
}

export interface PersonContactInfo {
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
    memberProvidedEmailAddress: string;
    faxNumber: string;
}

export interface PersonStatus {
    isMember: boolean;
    hasNoActiveOwnedMembership: boolean;
    isDependant: boolean;
    isHeadOffice: boolean;
    isPermanentlyDisabled: boolean;
    isTemporarilyDisabled: boolean;
    isCanceled: boolean;
    isEfapMember: boolean;
    isMyAsebpAccessExpired: boolean;
    myAsebpAccessExpiresAtEndOfThisDay?: string;
}

export interface PersonName {
    fullName: string;
    first: string;
    middleInitial: string;
    last: string;
    lastNameFirstOrderingString: string;
}

export interface Membership {
    membershipId: number;
    certificateNumber: string;
    sectionCode: string;
    locationNumber: string;
    schoolJurisdictionName: string;
    className: string;
    friendlyClassName: string;
    friendlyEmployeeClassName: string;
    effectiveDate: Date | string;
    isTerminated: boolean;
    hasUpcomingTermination: boolean;
    terminationDate: Date | string | null;
    statusCode: number;
    isMyRetiree: boolean;
}

export interface OwnedMembership extends Membership {
    dependants: PersonLink[];
    isSurvivorMembership: boolean;
}

export interface InheritedMembership extends Membership {
    coveredMember: PersonLink;
}

export interface PersonLink {
    personLinkId: number;
    relationshipTypeCode: number;
    relationshipType: string;
    certificateNumber: string;
    personLinkStatusCode: number;
    hasPersonLinkUpcomingTermination: boolean;
    relationshipEffectiveDate: Date | string;
    relationshipTerminationDate: Date | string | null;
}

export interface Relative extends Person {
    isSpouse: boolean;
    isChild: boolean;
    isOtherDependant: boolean;
    isOverAgeDependant: boolean;
    overAgeDependantRecord: OverAgeDependant | null;
    isDependantOfMember: boolean;
    combinedStatus: CombinedStatus;
}

export interface CombinedStatus {
    statusCode: VoStatusEnum;
    statusEffectiveDate: Date | string;

    hasUpcomingTermination: boolean;
    isTerminated: boolean;
    terminationDate: Date | string | null;
}

export interface DeathDetails {
    isDeceased: boolean;
    deathDate: string;
    deathNote: string;
}

export enum VoRelationshipTypeEnum {
    Spouse = 2,
    Child = 3,
    Other = 6
}

export enum VoStatusEnum {
    Active = 1,
    ActiveWithNoBenefits = 2,
    Terminated = 3,
    Pending = 4
}
