import { HandledAction } from "modules/profile/bankAccount/actionCreator";
import { BankAccountType, ProfileBankAccountsState } from "modules/profile/bankAccount/models";
import { Reducer } from "redux";
export type KnownAction = HandledAction;
export const reducer: Reducer<ProfileBankAccountsState> = (state: ProfileBankAccountsState, action: KnownAction): ProfileBankAccountsState => {
    switch (action.type) {
        case "GET_BANKS_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_BANKS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetching: false,
                    banks: null,
                    errorMessage: action.err
                };
            }
            return {
                ...state,
                isFetching: false,
                banks: action.banks,
                errorMessage: null
            };
        }
        case "GET_BANK_ACCOUNTS_INIT": {
            return {
                ...state,
                depositBankAccountState: {
                    ...state.depositBankAccountState,
                    isFetching: true
                },
                withdrawalBankAccountState: {
                    ...state.withdrawalBankAccountState,
                    isFetching: true
                }
            };
        }
        case "GET_BANK_ACCOUNTS_COMPLETE": {
            const depositBankAccount = (action.bankAccounts || []).find(a => a.accountTypeId === BankAccountType.Deposit);
            const withdrawalBankAccount = (action.bankAccounts || []).find(a => a.accountTypeId === BankAccountType.Withdrawal);

            return {
                ...state,
                depositBankAccountState: {
                    ...state.depositBankAccountState,
                    bankAccount: depositBankAccount,
                    isFetching: false
                },
                withdrawalBankAccountState: {
                    ...state.withdrawalBankAccountState,
                    bankAccount: withdrawalBankAccount,
                    isFetching: false
                }
            };
        }
        case "SAVE_BANK_ACCOUNT_INIT": {
            if (action.bankAccount.accountTypeId === BankAccountType.Deposit) {
                return {
                    ...state,
                    depositBankAccountState: {
                        ...state.depositBankAccountState,
                        isSaving: true
                    }
                };
            } else {
                return {
                    ...state,
                    withdrawalBankAccountState: {
                        ...state.withdrawalBankAccountState,
                        isSaving: true
                    }
                };
            }
        }
        case "SAVE_BANK_ACCOUNT_COMPLETE": {
            if (action.bankAccount.accountTypeId === BankAccountType.Deposit) {
                if (action.succeeded) {
                    return {
                        ...state,
                        depositBankAccountState: {
                            ...state.depositBankAccountState,
                            isSaving: false,
                            bankAccount: action.bankAccount,
                            errorMessage: null
                        }
                    };
                } else {
                    return {
                        ...state,
                        depositBankAccountState: {
                            ...state.depositBankAccountState,
                            isSaving: false,
                            errorMessage: action.err
                        }
                    };
                }
            } else {
                if (action.succeeded) {
                    return {
                        ...state,
                        withdrawalBankAccountState: {
                            ...state.withdrawalBankAccountState,
                            isSaving: false,
                            bankAccount: action.bankAccount,
                            errorMessage: null
                        }
                    };
                } else {
                    return {
                        ...state,
                        withdrawalBankAccountState: {
                            ...state.withdrawalBankAccountState,
                            isSaving: false,
                            errorMessage: action.err
                        }
                    };
                }
            }
        }
        case "REMOVE_BANK_ACCOUNT_INIT": {
            if (action.bankAccountRemoval.bankAccountTypeId === BankAccountType.Deposit) {
                return {
                    ...state,
                    depositBankAccountState: {
                        ...state.depositBankAccountState,
                        isRemoving: true
                    }
                };
            } else {
                return {
                    ...state,
                    withdrawalBankAccountState: {
                        ...state.withdrawalBankAccountState,
                        isRemoving: true
                    }
                };
            }
        }
        case "REMOVE_BANK_ACCOUNT_COMPLETE": {
            if (action.bankAccountRemoval.bankAccountTypeId === BankAccountType.Deposit) {
                if (action.succeeded) {
                    return {
                        ...state,
                        depositBankAccountState: {
                            ...state.depositBankAccountState,
                            isRemoving: false,
                            bankAccount: null,
                            errorMessage: null
                        }
                    };
                } else {
                    return {
                        ...state,
                        depositBankAccountState: {
                            ...state.depositBankAccountState,
                            isRemoving: false,
                            errorMessage: action.err
                        }
                    };
                }
            } else {
                if (action.succeeded) {
                    return {
                        ...state,
                        withdrawalBankAccountState: {
                            ...state.withdrawalBankAccountState,
                            isRemoving: false,
                            bankAccount: null,
                            errorMessage: null
                        }
                    };
                } else {
                    return {
                        ...state,
                        withdrawalBankAccountState: {
                            ...state.withdrawalBankAccountState,
                            isRemoving: false,
                            errorMessage: action.err
                        }
                    };
                }
            }
        }
    }
    return (
        state ||
        ({
            isFetching: false,
            banks: [],
            depositBankAccountState: null,
            withdrawalBankAccountState: null,
            errorMessage: null
        } as ProfileBankAccountsState)
    );
};
