import { createSelector } from "reselect";
import { addMonths } from "date-fns";
import cloneDeep from "clone-deep";

import { Formatter } from "modules/utils";

import { ApplicationState } from "store";

import { Session } from "modules/auth/models";

import { BenefitStatusCalculator } from "modules/benefits/coverage/benefitCalculator";
import {
    BenefitCoverageDictionary,
    BenefitCoverage,
    BenefitHistoryDictionary,
    OverAgeDependant
} from "modules/benefits/coverage/models";
import { DentalPlan } from "modules/benefits/dentalGuide/models";
import { BenefitCode, BenefitCoverageLevel, BenefitDisplayNames } from "modules/benefits/models";
import {
    SpendingAccountListState,
    SpendingAccountType,
    SpendingAccountSummary
} from "modules/benefits/spendingAccounts/models";

import {
    CoveragePeriodListState,
    SpendingAccountListState as SpendingAccountListStateV2,
    SpendingAccountSummary as SpendingAccountSummaryV2
} from "modules/benefits/spendingAccountsV2/models";

import { DocumentStats } from "modules/documents/models";
import { FeatureToggles, FeatureToggleValue, KnownFeatureToggles } from "modules/features/models";

import { Membership, OwnedMembership, PersonDetails, VoStatusEnum } from "modules/person/models";
import FamilyTreeBuilder from "modules/person/familyTreeBuilder";

import { CoveredMemberSummary, PersonBenefits } from "./coveredMemberSummary.models";
import { useAppSelector } from "hooks/reduxHooks";

interface OwnedMembershipDictionary {
    [key: string]: OwnedMembership;
}

function compareEffectiveDate(a: Membership, b: Membership): number {
    const aEffectiveDate = new Date(a.effectiveDate);
    const bEffectiveDate = new Date(b.effectiveDate);

    if (aEffectiveDate > bEffectiveDate) {
        return -1;
    } else if (aEffectiveDate < bEffectiveDate) {
        return 1;
    }
    return 0;
}

function compareTerminationDate(a: Membership, b: Membership): number {
    const aTerminationDate = a.terminationDate ? new Date(a.terminationDate) : null;
    const bTerminationDate = b.terminationDate ? new Date(b.terminationDate) : null;

    if (!!aTerminationDate && !!bTerminationDate) {
        if (aTerminationDate > bTerminationDate) {
            return -1;
        } else if (aTerminationDate < bTerminationDate) {
            return 1;
        }
    } else {
        if (!!bTerminationDate) {
            return -1;
        } else {
            return 1;
        }
    }
    return 0;
}
function compareSectionCode(a: Membership, b: Membership): number {
    if (a.sectionCode !== "001" && b.sectionCode === "001") {
        return -1;
    } else if (a.sectionCode === "001" && b.sectionCode !== "001") {
        return 1;
    }
    return 0;
}

function compareStatus(a: Membership, b: Membership): number {
    if (!a.isTerminated && b.isTerminated) {
        return -1;
    } else if (a.isTerminated && !b.isTerminated) {
        return 1;
    }
    return 0;
}

/*
  Memberships should be shown in the following order:

  Active memberships rank higher than terminated memberships
  Memberships with section codes that are not 001 rank higher than those with section codes which are 001
  Active memberships with later effective dates rank higher than those with an earlier effective dates
  Terminated memberships with later termination dates rank higher than those with an earlier termination dates
*/
function compareMembership(a: Membership, b: Membership): number {
    const statusComparisonResult = compareStatus(a, b);
    if (statusComparisonResult !== 0) {
        return statusComparisonResult;
    }

    const sectionCodeComparisonResult = compareSectionCode(a, b);
    if (sectionCodeComparisonResult !== 0) {
        return sectionCodeComparisonResult;
    }

    const effectiveDateComparisonResult = compareEffectiveDate(a, b);
    if (effectiveDateComparisonResult !== 0) {
        return effectiveDateComparisonResult;
    }

    const terminationDateComparisonResult = compareTerminationDate(a, b);
    if (terminationDateComparisonResult !== 0) {
        return terminationDateComparisonResult;
    }

    return 0;
}

function comparePersonBenefit(a: PersonBenefits, b: PersonBenefits): number {
    return compareMembership(a.membership, b.membership);
}

function getGeneralHealthCoverageLevelString(benefit: BenefitCoverage): string {
    if (benefit.isCoupleCoverage) {
        return BenefitCoverageLevel.Couple;
    } else if (benefit.isFamilyCoverage) {
        return BenefitCoverageLevel.Family;
    } else if (benefit.isSingleCoverage) {
        return BenefitCoverageLevel.Single;
    }
    return "";
}

const sessionSelector = (state: ApplicationState) => state.auth.session;

const benefitsSelector = (state: ApplicationState) =>
    state.benefits && state.benefits.coverage ? state.benefits.coverage.benefitCoverage : null;

const benefitHistoriesSelector = (state: ApplicationState) =>
    state.benefits && state.benefits.coverage ? state.benefits.coverage.benefitCoverageHistory : null;

const coveragePeriodsSelector = (state: ApplicationState) =>
    state.benefits && state.benefits.spendingAccountsV2 ? state.benefits.spendingAccountsV2.coveragePeriodList : null;

const spendingAccountsSelector = (state: ApplicationState) =>
    state.benefits && state.benefits.spendingAccounts ? state.benefits.spendingAccounts.spendingAccountList : null;

const spendingAccountsSelectorV2 = (state: ApplicationState) =>
    state.benefits && state.benefits.spendingAccountsV2 ? state.benefits.spendingAccountsV2.spendingAccountList : null;

const currentPersonSelector = (state: ApplicationState) => (state.person ? state.person.person : null);

const overAgeDependantSelector = (state: ApplicationState) =>
    state.benefits && state.benefits.coverage && state.benefits.coverage.overAgeDependantInfo
        ? state.benefits.coverage.overAgeDependantInfo.dependants || []
        : [];

const featureToggleSelector = (state: ApplicationState) =>
    state.features && state.features.featureToggles ? state.features.featureToggles : ({} as FeatureToggles);

const documentStatsSelector = (state: ApplicationState) =>
    state.documents && state.documents.documentStats ? state.documents.documentStats : null;

const getSpendingAccountList = (
    state: SpendingAccountListState | SpendingAccountListStateV2,
    ownedMemberships: OwnedMembershipDictionary
) => {
    if (!Boolean(state?.membershipSpendingAccounts)) {
        return [];
    }

    return state.membershipSpendingAccounts
        .flatMap((l) =>
            l.spendingAccounts.map((s) => {
                return {
                    ...s,
                    paymentExclusion: l.paymentExclusion
                };
            })
        )
        .filter((sa) => {
            // accounts that are allocated at 0% can still have rollover credits added to them,
            // so even though the member has chosen not to allocate, they may still submit claims against them
            return sa.accountType === SpendingAccountType.HSA || sa.accountType === SpendingAccountType.WSA;
        })
        .map((sa) => {
            const matchingMembership = ownedMemberships[sa.membershipId];

            return matchingMembership
                ? {
                      ...sa,
                      schoolJurisdictionName: matchingMembership.schoolJurisdictionName,
                      friendlyClassName: matchingMembership.friendlyClassName,
                      isSubmittable: (sa.isActive || sa.isInRunoff) && !sa.isPlaceholder
                  }
                : null;
        })
        .filter((sa) => sa != null);
};

const buildCoveredMemberSummary = (
    session: Session,
    benefitCoverage: BenefitCoverageDictionary,
    benefitCoverageHistory: BenefitHistoryDictionary,
    coveragePeriods: CoveragePeriodListState,
    spendingAccountListsState: SpendingAccountListState,
    spendingAccountListsStateV2: SpendingAccountListStateV2,
    person: PersonDetails,
    overAgeDependants: OverAgeDependant[],
    featureToggles: FeatureToggles,
    documentStats: DocumentStats
): CoveredMemberSummary => {
    const over85PlanNumbers = ["C3", "E3"];
    const benefitCalculator = new BenefitStatusCalculator(benefitCoverageHistory);
    const summary = {
        certificateNumber: session.userId,
        maskedSocialInsuranceNumber: person ? person.maskedSocialInsuranceNumber : null,
        person: cloneDeep(person),
        family: [],
        familyLookup: {},
        activeDependants: [],
        group: "19930",
        canSubmitGeneralHealthClaims: false,
        canSubmitSpendingAccountClaims: false,
        benefits: [] as PersonBenefits[],
        allSpendingAccounts: [],
        submittableSpendingAccounts: [],
        canUpdateAddress: false,
        canAccessFeature: (key: KnownFeatureToggles) => {
            return featureToggles && featureToggles[key] && featureToggles[key].toggleValue === FeatureToggleValue.On;
        },
        hasEverHadBenefit: (benefitCode: BenefitCode) => {
            if (benefitCode === BenefitCode.HSA) {
                return summary.allSpendingAccounts.some((s) => s.accountType === SpendingAccountType.HSA);
            }
            if (benefitCode === BenefitCode.WSA) {
                return summary.allSpendingAccounts.some((s) => s.accountType === SpendingAccountType.WSA);
            }
            return benefitCalculator.didPersonEverHaveActiveBenefit(session.userId, benefitCode);
        },
        hasT4ASlips: false
    } as CoveredMemberSummary;

    if (!!person) {
        const builder = new FamilyTreeBuilder(
            person.certificateNumber,
            person.linkedPeople,
            person.ownedMemberships,
            person.inheritedMemberships,
            overAgeDependants
        );

        summary.family = builder.build();

        for (const familyMember of summary.family) {
            summary.familyLookup[familyMember.certificateNumber] = familyMember;
        }

        summary.sectionCode = person.sectionCode;

        const hadEhcBenefitWithinLast18Months =
            benefitCalculator.didPersonHaveBenefitInLast18Months(person.certificateNumber, BenefitCode.EHC) ||
            benefitCalculator.didPersonHaveBenefitInLast18Months(person.certificateNumber, BenefitCode.Dental) ||
            benefitCalculator.didPersonHaveBenefitInLast18Months(person.certificateNumber, BenefitCode.Vision);

        const hadSpendingAccountWithinLast18Months =
            benefitCalculator.didPersonHaveBenefitInLast18Months(person.certificateNumber, BenefitCode.HSA) ||
            benefitCalculator.didPersonHaveBenefitInLast18Months(person.certificateNumber, BenefitCode.WSA);

        summary.canSubmitGeneralHealthClaims = hadEhcBenefitWithinLast18Months;
        summary.canSubmitSpendingAccountClaims = hadSpendingAccountWithinLast18Months;

        const ownedMemberships = {} as { [key: string]: OwnedMembership };
        for (const ownedMembership of person.ownedMemberships) {
            ownedMemberships[ownedMembership.membershipId] = ownedMembership;
        }

        const eighteenMonthsAgo = addMonths(new Date(), -18);

        // EnableSavingsAccount depends on UpdateToClaimsApiV5OnWeb toggle
        const enabledSavingsAccountToggle =
            summary.canAccessFeature(KnownFeatureToggles.UpdateToClaimsApiV5OnWeb) &&
            summary.canAccessFeature(KnownFeatureToggles.EnableSavingsAccount);

        const spendingAccountState = enabledSavingsAccountToggle
            ? spendingAccountListsStateV2
            : spendingAccountListsState;

        if (enabledSavingsAccountToggle && coveragePeriods) {
            summary.coveragePeriods = coveragePeriods.membershipCoveragePeriods;
        }

        summary.allSpendingAccounts = getSpendingAccountList(spendingAccountState, ownedMemberships);

        summary.allSpendingAccounts.sort(
            (
                a: SpendingAccountSummary | SpendingAccountSummaryV2,
                b: SpendingAccountSummary | SpendingAccountSummaryV2
            ): number => {
                const aStartDate = new Date(a.startDate);
                const bStartDate = new Date(b.startDate);

                if (aStartDate > bStartDate) {
                    return -1;
                } else if (aStartDate < bStartDate) {
                    return 1;
                }
                if (a.accountType === SpendingAccountType.HSA && b.accountType !== SpendingAccountType.HSA) {
                    return -1;
                } else if (a.accountType !== SpendingAccountType.HSA && b.accountType === SpendingAccountType.HSA) {
                    return 1;
                }
                const aEndDate = new Date(a.lastActiveDate);
                const bEndDate = new Date(b.lastActiveDate);

                if (aEndDate > bEndDate) {
                    return -1;
                } else if (aEndDate < bEndDate) {
                    return 1;
                }
                return compareMembership(ownedMemberships[a.membershipId], ownedMemberships[b.membershipId]);
            }
        );

        const recentSpendingAccounts = summary.allSpendingAccounts.filter((account) => {
            const lastActiveDate = new Date(account.lastActiveDate);
            return lastActiveDate >= eighteenMonthsAgo;
        });

        const benefits = benefitCoverage ? benefitCoverage[person.certificateNumber] : null;
        if (!!benefits) {
            summary.benefits = benefits.membershipBenefits.map((b) => {
                const ownedMembership = ownedMemberships[b.membershipId];

                const personBenefits = {
                    membershipId: b.membershipId,
                    membership: ownedMembership,
                    schoolJurisdictionName: `${ownedMembership.schoolJurisdictionName}`,
                    className: `${ownedMembership.className}`,
                    friendlyClassName: `${ownedMembership.friendlyClassName}`,
                    benefitStatus: ownedMembership.isTerminated ? "Inactive" : "Active",
                    incomeReplacementBenefits: b.benefits
                        .filter((ben) => ben.isIncomeReplacementBenefit)
                        .map((ben) => {
                            return {
                                id: ben.id,
                                name: ben.name,
                                displayName: BenefitDisplayNames[ben.benefitType],
                                benefitCode: ben.benefitType,
                                planName: ben.planName,
                                coverageLevel: Formatter.formatCurrency(ben.coverageLevel),
                                status: ben.calculatedStatus.status,
                                isActive: ben.calculatedStatus.isActive,
                                since: ben.calculatedStatus.statusDate,
                                planNumber: ben.planNumber,
                                excludedDependantCertificateNumbers: [],
                                isMyRetiree: ownedMembership?.isMyRetiree ?? false
                            };
                        }),
                    generalHealthBenefits: b.benefits
                        .filter((ben) => ben.isGeneralHealthBenefit)
                        .map((ben) => {
                            return {
                                id: ben.id,
                                name: ben.name,
                                displayName: BenefitDisplayNames[ben.benefitType],
                                benefitCode: ben.benefitType,
                                planName: ben.planName,
                                coverageLevel: getGeneralHealthCoverageLevelString(ben),
                                status: ben.calculatedStatus.status,
                                isActive: ben.calculatedStatus.isActive,
                                since: ben.calculatedStatus.statusDate,
                                planNumber: ben.planNumber,
                                excludedDependantCertificateNumbers: ben.excludedDependantCertificateNumbers,
                                isMyRetiree: ownedMembership?.isMyRetiree ?? false
                            };
                        }),
                    hsaAccounts: recentSpendingAccounts.filter(
                        (sa) => sa.accountType === SpendingAccountType.HSA && sa.membershipId === b.membershipId
                    ),
                    wsaAccounts: recentSpendingAccounts.filter(
                        (sa) => sa.accountType === SpendingAccountType.WSA && sa.membershipId === b.membershipId
                    ),
                    hasActiveCoverage: false,
                    isMyRetiree: ownedMembership?.isMyRetiree ?? false
                } as PersonBenefits;

                personBenefits.hasActiveCoverage =
                    personBenefits.incomeReplacementBenefits.some((ben) => ben.isActive) ||
                    personBenefits.generalHealthBenefits.some((ben) => ben.isActive) ||
                    personBenefits.hsaAccounts.some((ben) => ben.isActive) ||
                    personBenefits.wsaAccounts.some((ben) => ben.isActive);

                return personBenefits;
            });

            summary.benefits.sort(comparePersonBenefit);
        }

        summary.submittableSpendingAccounts = summary.allSpendingAccounts.filter((sa) => sa.isSubmittable);
    }

    summary.isMyRetiree =
        summary.person && summary.person.ownedMemberships && summary.person.ownedMemberships.some((m) => m.isMyRetiree);

    summary.hasActiveFamilyGeneralHealthCoverage = summary.benefits.some(
        (b) =>
            b.generalHealthBenefits &&
            b.generalHealthBenefits.some(
                (g) =>
                    (g.coverageLevel === BenefitCoverageLevel.Family ||
                        g.coverageLevel === BenefitCoverageLevel.Couple) &&
                    g.isActive
            )
    );

    summary.hasActiveGeneralHealthCoverage = summary.benefits.some(
        (b) => b.generalHealthBenefits && b.generalHealthBenefits.some((g) => g.isActive)
    );

    summary.hasActiveExtendedHealthCoverage =
        summary.hasActiveGeneralHealthCoverage &&
        summary.benefits.some(
            (b) =>
                b.generalHealthBenefits &&
                b.generalHealthBenefits.some((g) => g.isActive && g.benefitCode === BenefitCode.EHC)
        );

    summary.hasTravelPlan =
        summary.hasActiveGeneralHealthCoverage &&
        summary.benefits.some(
            (b) =>
                b.generalHealthBenefits &&
                b.generalHealthBenefits.some(
                    (g) =>
                        g.isActive &&
                        g.benefitCode === BenefitCode.EHC &&
                        !over85PlanNumbers.some((p) => p === g.planNumber)
                )
        );

    summary.hasActiveDentalCoverage =
        summary.hasActiveGeneralHealthCoverage &&
        summary.benefits.some(
            (b) =>
                b.generalHealthBenefits &&
                b.generalHealthBenefits.some((g) => g.isActive && g.benefitCode === BenefitCode.Dental)
        );

    summary.hasActiveVisionCoverage =
        summary.hasActiveGeneralHealthCoverage &&
        summary.benefits.some(
            (b) =>
                b.generalHealthBenefits &&
                b.generalHealthBenefits.some((g) => g.isActive && g.benefitCode === BenefitCode.Vision)
        );

    summary.hasActiveIncomeReplacementCoverage = summary.benefits.some(
        (b) => b.incomeReplacementBenefits && b.incomeReplacementBenefits.some((i) => i.isActive)
    );

    summary.hasActiveHsaCoverage = summary.benefits.some(
        (b) => b.hsaAccounts && b.hsaAccounts.some((h) => h.isActive || h.isPlaceholder)
    );

    summary.hasActiveWsaCoverage = summary.benefits.some(
        (b) => b.wsaAccounts && b.wsaAccounts.some((w) => w.isActive || w.isPlaceholder)
    );

    summary.hasSavingsAccount = summary.coveragePeriods?.some((cp) =>
        cp.coveragePeriodSummaries?.some((c) => c.planYear?.allowsSavings)
    );

    summary.hasSavingsAllocation = summary.coveragePeriods?.some((cp) =>
        cp.coveragePeriodSummaries?.some(
            (c) => c.isCoveragePeriodAllocated && (c.rrspAllocation > 0 || c.tfsaAllocation > 0)
        )
    );

    summary.hasActiveCoverage =
        summary.hasActiveGeneralHealthCoverage ||
        summary.hasActiveIncomeReplacementCoverage ||
        summary.hasActiveHsaCoverage ||
        summary.hasActiveWsaCoverage;

    summary.activeDependants = summary.family.filter(
        (d) =>
            d.personStatus?.isDependant &&
            d.combinedStatus &&
            (d.combinedStatus.statusCode === VoStatusEnum.Active ||
                d.combinedStatus.hasUpcomingTermination ||
                d.combinedStatus.statusCode === VoStatusEnum.Pending)
    );

    const employeeClassesAllowedToUpdateAddress =
        summary.canAccessFeature(KnownFeatureToggles.AddressUpdatesAllowedForSomeMembers) &&
        featureToggles.AddressUpdatesAllowedForSomeMembers.data
            ? JSON.parse(featureToggles.AddressUpdatesAllowedForSomeMembers.data)
            : [];

    if (summary.canAccessFeature(KnownFeatureToggles.EnableMyRetiree)) {
        summary.canViewDentalGuide =
            summary.hasActiveDentalCoverage &&
            !(
                summary.person &&
                summary.person.ownedMemberships &&
                summary.person.ownedMemberships.some((m) => m.isMyRetiree)
            ) &&
            summary.benefits.some(
                (b) =>
                    b.generalHealthBenefits &&
                    b.generalHealthBenefits.some(
                        (g) =>
                            g.isActive &&
                            g.benefitCode === BenefitCode.Dental &&
                            (g.planNumber === DentalPlan.Plan2 || g.planNumber === DentalPlan.Plan3)
                    )
            );

        summary.canUpdateAddress =
            summary.canAccessFeature(KnownFeatureToggles.AddressUpdatesAllowedForAllMembers) ||
            (summary.person &&
                summary.person.ownedMemberships.length &&
                summary.person.ownedMemberships.some((x) => x.isMyRetiree)) ||
            (summary.person && summary.person.ownedMemberships.length
                ? (employeeClassesAllowedToUpdateAddress || []).some(
                      (className) => summary.person.ownedMemberships[0].className.indexOf(className) >= 0
                  )
                : false);
    } else {
        summary.canViewDentalGuide =
            summary.hasActiveDentalCoverage &&
            summary.benefits.some(
                (b) =>
                    b.generalHealthBenefits &&
                    b.generalHealthBenefits.some(
                        (g) =>
                            g.isActive &&
                            g.benefitCode === BenefitCode.Dental &&
                            (g.planNumber === DentalPlan.Plan2 || g.planNumber === DentalPlan.Plan3)
                    )
            );

        summary.canUpdateAddress =
            summary.canAccessFeature(KnownFeatureToggles.AddressUpdatesAllowedForAllMembers) ||
            (summary.person && summary.person.ownedMemberships.length
                ? (employeeClassesAllowedToUpdateAddress || []).some(
                      (className) => summary.person.ownedMemberships[0].className.indexOf(className) >= 0
                  )
                : false);
    }

    if (!!documentStats) {
        summary.hasT4ASlips = documentStats.t4ASlipsCount > 0 || documentStats.requireT4AConsent;
    }

    return summary;
};

export const getCoveredMemberSummary = createSelector(
    sessionSelector,
    benefitsSelector,
    benefitHistoriesSelector,
    coveragePeriodsSelector,
    spendingAccountsSelector,
    spendingAccountsSelectorV2,
    currentPersonSelector,
    overAgeDependantSelector,
    featureToggleSelector,
    documentStatsSelector,
    buildCoveredMemberSummary
);

export const useGetCoveredMemberSummarySelector = () => {
    const session = useAppSelector(sessionSelector);
    const benefits = useAppSelector(benefitsSelector);
    const benefitHistories = useAppSelector(benefitHistoriesSelector);
    const coveragePeriods = useAppSelector(coveragePeriodsSelector);
    const spendingAccounts = useAppSelector(spendingAccountsSelector);
    const spendingAccountsV2 = useAppSelector(spendingAccountsSelectorV2);
    const currentPerson = useAppSelector(currentPersonSelector);
    const overAgeDependant = useAppSelector(overAgeDependantSelector);
    const featureToggle = useAppSelector(featureToggleSelector);
    const documents = useAppSelector(documentStatsSelector);

    const coveredMember = buildCoveredMemberSummary(
        session,
        benefits,
        benefitHistories,
        coveragePeriods,
        spendingAccounts,
        spendingAccountsV2,
        currentPerson,
        overAgeDependant,
        featureToggle,
        documents
    );

    const isFetchingCoveredMember = useAppSelector(
        (state) =>
            state.person.isFetching ||
            state.benefits.coverage.isFetchingMemberBenefits ||
            state.benefits.coverage.isFetchingMemberBenefitHistory ||
            (state.benefits.spendingAccounts?.spendingAccountList?.isFetching ?? false) ||
            (state.benefits.spendingAccountsV2?.spendingAccountList?.isFetching ?? false) ||
            state.documents.isFetchingDocumentStats
    );

    const isFetchingDependantBenefitHistory = useAppSelector(
        (state) => state.benefits.coverage.isFetchingDependantBenefitHistory
    );

    return { coveredMember, isFetchingCoveredMember, isFetchingDependantBenefitHistory };
};
