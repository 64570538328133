import RequestBuilder from "./requestBuilder";
import { ConfigState } from "modules/config/models";
import { AuthActionCreators } from "modules/auth/actionCreator";
import { NetworkRequest } from "./actions";
import { XhrUpload } from "api/xhrUpload";

export const handleNetworkRequest = (store: any, next: any, action: NetworkRequest) => {
    const config = store.getState().config as ConfigState;
    const antiforgeryToken = store.getState().auth.session.antiforgeryToken;

    if (action.isXhr) {
        return new XhrUpload(action, config)
            .addAntiforgeryToken(antiforgeryToken)
            .upload()
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    } else {
        const req = new RequestBuilder(action).addAntiforgeryToken(antiforgeryToken).setBaseUrl(config.appUrl || "");

        const { url, init } = req.build();

        return fetch(url, init)
            .then((response) => {
                switch (response?.status) {
                    case 200: {
                        return response;
                    }
                    case 401: {
                        return response;
                    }
                    default:
                        // should handle error codes here
                        return response;
                }
            })
            .catch((error) => {
                // could not complete request
                // something terrible went wrong? no network?
                throw error;
            });
    }
};

function createRequestMiddleware() {
    return (store: any) => (next: any) => (action: any) => {
        if (action.type !== "NETWORK_REQUEST") {
            return next(action);
        } else if (action.doNotEnsureToken) {
            next(action);
            return handleNetworkRequest(store, next, action);
        } else {
            next(action);
            return store.dispatch(AuthActionCreators.refresh()).then((succeeded) => {
                // if we weren't able to refresh the access token
                // don't bother making the request because it will fail anyway
                if (succeeded) {
                    return handleNetworkRequest(store, next, action);
                }
                return Promise.reject("Couldn't refresh token");
            });
        }
    };
}

const middleware = createRequestMiddleware();
export default middleware;
