import { NetworkRequest } from "api/actions";
import { GeoCoordinate } from "modules/serviceProviders/models";

export const getProviderClasses = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/serviceProviders/classes"
    };
};

export const getProviders = (
    providerClassId: number,
    pageNumber: number,
    location: GeoCoordinate,
    pageSize: number
): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/serviceProviders",
        queryParams: {
            providerClassId,
            pageNumber,
            pageSize,
            latitude: location ? location.latitude : null,
            longitude: location ? location.longitude : null
        }
    };
};

export const getIneligibleProviders = (pageNumber: number, pageSize: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/serviceProviders/ineligibleProviderDetails",
        queryParams: {
            pageNumber,
            pageSize
        }
    };
};

export const getRatingRequest = (requestToken: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/serviceProviders/ratingRequests/${requestToken}`,
        doNotEnsureToken: true
    };
};

export const getRating = (providerId: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/serviceProviders/ratings/?providerId=${providerId}`
    };
};

export const submitRating = (providerId: string, isProviderRecommended: boolean): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/serviceProviders/ratings`,
        body: {
            providerId,
            isProviderRecommended
        }
    };
};

export const saveRatingRequestResponse = (requestToken: string, isProviderRecommended: boolean): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/serviceProviders/ratingRequests/${requestToken}/responses`,
        body: {
            isProviderRecommended
        },
        doNotEnsureToken: true
    };
};

export const saveRatingRequestCommunicationPreference = (
    requestToken: string,
    shouldReceiveFeedbackRequests: boolean
): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/serviceProviders/ratingRequests/communicationPreferences",
        body: {
            requestToken,
            shouldReceiveFeedbackRequests
        },
        doNotEnsureToken: true
    };
};
