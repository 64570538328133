import * as React from "react";

export interface FailedComponentMessageProps {
    error: Error;
}

export class FailedComponentMessage extends React.Component<FailedComponentMessageProps> {
    public render() {
        return (
            <div className="failed-component-message">
                <img src="img/error_journey.png" />
                <div>
                    <span className="muted">Oops!</span>
                    <br />
                    <span>We weren&apos;t able to load a component required for this page.</span>
                    <br />
                    <br /> <span>Please reload the page to try again.</span>
                </div>
            </div>
        );
    }
}
