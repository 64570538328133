import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme, useMediaQuery } from "@mui/material";

interface PreAuthorizedDebitsAgreementProps {
    open: boolean;
    close: () => any;
}

const PreAuthorizedDebitsAgreement = (props: PreAuthorizedDebitsAgreementProps) => {
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
        return (
            <Dialog onClose={props.close} open={props.open} scroll="paper" fullScreen={fullScreen} aria-labelledby="scroll-dialog-title">
                <DialogTitle id="scroll-dialog-title">PERSONAL PRE-AUTHORIZED DEBITS AGREEMENT</DialogTitle>
                <DialogContent className="agreement-content">
                    <p>I authorize ASEBP to begin monthly automated withdrawals for payment of my benefit premiums from the bank account information provided.</p>
                    <p>I understand that the following conditions apply:</p>
                    <ul className="no-list-type">
                        <li>a) I will pay the monthly premium amount noted in my approval letter and a monthly statement will not be issued</li>
                        <li>
                            b) I will receive at least 10 days prior notification of changes in the monthly amount payable due to:
                            <ul className="disc-list-type">
                                <li>Premium rate adjustments, which typically occur in September as authorized by ASEBP Trustees</li>
                                <li>A change in benefit coverage (e.g., from &quot;single&quot; to &quot;family&quot; coverage)</li>
                            </ul>
                        </li>
                        <li>c) My premium payment will automatically be withdrawn from my bank account on the *15th* of each month. If the 15th falls on a weekend, the withdrawal will occur on the next business day</li>
                        <li>d) Premiums are billed in complete months and if my benefits terminate prior to the last day of the month, I will remain responsible for the full month&apos;s premium</li>
                        <li>
                            e) If there is a change in coverage that takes effect part way through a month (e.g. a change from &quot;family&quot; to &quot;single&quot; status), the premium and coverage in effect at the beginning of the
                            month will stay in effect until the end of that month. On the first day of the following month, the new coverage will come into effect and ASEBP will charge me the new premium
                        </li>
                        <li>f) I understand that I will not receive credits or refunds for premiums already paid</li>
                        <li>g) I will notify ASEBP of any changes to my banking information</li>
                    </ul>
                    <p>
                        My authorization will remain in effect until there is 30 days written notification of termination from either myself or ASEBP. To obtain a sample cancellation form, or for more information on my right to cancel this
                        pre-authorized debit (PAD) agreement, I may contact my financial institution or visit{" "}
                        <a title="www.cdnpay.ca" href="http://www.cdnpay.ca" target="_blank" rel="noopener noreferrer">
                            www.cdnpay.ca
                        </a>
                        .
                    </p>
                    <p>If ASEBP makes a withdrawal in error or for the incorrect amount, I will notify ASEBP as soon as possible. If ASEBP is aware of an error, ASEBP will correct the error and notify me as soon as possible.</p>
                    <p>
                        I have certain recourse rights if any debit does not comply with this agreement. For example, I have the right to receive reimbursement for any debit that is not authorized or is not consistent with this PAD
                        Agreement. To obtain more information on my recourse rights, I may contact my financial institution or visit{" "}
                        <a title="www.cdnpay.ca" href="http://www.cdnpay.ca" target="_blank" rel="noopener noreferrer">
                            www.cdnpay.ca
                        </a>
                        .
                    </p>
                    <p>If you have any questions about this PAD Agreement, please contact ASEBP:</p>
                    <p>
                        Suite 301 Allendale Centre East
                        <br />
                        6104 104 Street NW
                        <br />
                        Edmonton, AB T6H 2K7
                        <br />
                        Toll-free: 1-877-431-4786
                        <br />
                        Fax: 780-438-5304
                        <br />
                        Email:{" "}
                        <a title="benefits@asebp.ab.ca" href="mailto:benefits@asebp.ab.ca" target="_blank" rel="noopener noreferrer">
                            benefits@asebp.ab.ca
                        </a>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
}

export default PreAuthorizedDebitsAgreement;
