import { AxiosError } from "axios";
import { ApiValidationErrorMessage } from "api/models";
import {
    ApiResponseWithValidation,
    useBaseAxiosDeleteRequest,
    useBaseAxiosGetRequest,
    useBaseAxiosPostRequest,
    useBaseAxiosPutRequest
} from "./axiosHooks";

export const createMockAxiosGetHookResponse = <TData,>(
    data: TData | null,
    error: AxiosError<any, any> = null,
    status = 200
): ReturnType<typeof useBaseAxiosGetRequest<TData>> => ({
    data,
    loading: false,
    error,
    call: () => Promise.resolve(data),
    cancel: () => null,
    response: { config: null, data, headers: null, status, statusText: status === 200 ? "OK" : "Failed" }
});

/** Creates a resolved ApiResponseWithValidation promise with data. */
export const createDefaultApiResponsePromise = <TOut,>(data: TOut) =>
    Promise.resolve({
        isSuccess: true,
        axiosError: null,
        response: { config: null, data, headers: null, status: 200, statusText: "" },
        validationErrorMessages: []
    } as ApiResponseWithValidation<TOut>);

/** Creates a resolved ApiResponseWithValidation promise, optionally with data. */
export const createFailedApiResponsePromise = <TOut,>(
    message: string,
    validationErrorMessages: ApiValidationErrorMessage[] = []
) =>
    Promise.resolve({
        isSuccess: false,
        axiosError: { message },
        response: { config: null, data: null, headers: null, status: 200, statusText: "" },
        validationErrorMessages: validationErrorMessages
    } as ApiResponseWithValidation<TOut>);

/** Use createDefaultApiResponsePromise and/or createFailedApiResponsePromise */
export const createMockAxiosPostHookResponse = <TIn, TOut>(
    call: (input: TIn) => Promise<ApiResponseWithValidation<TOut>>
): ReturnType<typeof useBaseAxiosPostRequest<TIn, TOut>> => ({
    loading: false,
    call,
    cancel: () => null
});

/** Use createDefaultApiResponsePromise and/or createFailedApiResponsePromise */
export const createMockAxiosPutHookResponse = <TIn, TOut>(
    call: (input: TIn) => Promise<ApiResponseWithValidation<TOut>>
): ReturnType<typeof useBaseAxiosPutRequest<TIn, TOut>> => {
    return {
        loading: false,
        call,
        cancel: () => null
    };
};

/** Use createDefaultApiResponsePromise and/or createFailedApiResponsePromise */
export const createMockAxiosDeleteHookResponse = <TId,>(
    call: (input: TId) => Promise<ApiResponseWithValidation<void>>,
    error: AxiosError<any, any> = null
): ReturnType<typeof useBaseAxiosDeleteRequest<TId>> => ({
    loading: false,
    error,
    call,
    cancel: () => null
});
