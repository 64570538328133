import * as React from "react";
import { IconButton } from "@mui/material";

export interface StarRatingProps {
    ratingMax: number;
    value: number;
    onChange: (value: number | null) => any;
}
interface StarRatingState {
    hoverValue: number;
    hovering: boolean;
}

export class StarRating extends React.PureComponent<StarRatingProps, StarRatingState> {
    public state: StarRatingState = {} as StarRatingState;

    public render() {
        return (
            <div className={`star-rating ${this.state.hovering ? "hover" : ""}`} onMouseLeave={this.clearHover}>
                {Array.from(Array(this.props.ratingMax).keys()).map(n => {
                    const selected = n < (this.state.hovering ? this.state.hoverValue : this.props.value);
                    return (
                        <IconButton
                            key={n}
                            onMouseEnter={this.hoverRating(n + 1)}
                            onClick={this.selectRating(n + 1)}
                            size="large">
                            <i
                                className={`material-icons
                                                ${selected ? "selected" : ""}
                                                ${this.state.hovering ? "hover" : ""}
                                                `}>
                                {selected ? "star" : "star_border"}
                            </i>
                        </IconButton>
                    );
                })}
            </div>
        );
    }

    private hoverRating = (rating: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState(s => ({
            ...s,
            hoverValue: rating,
            hovering: true
        }));
    };

    private clearHover = (e: React.MouseEvent<HTMLDivElement>) => {
        this.setState(s => ({
            ...s,
            hoverValue: 0,
            hovering: false
        }));
    };

    private selectRating = (rating: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onChange(rating);
        this.setState(s => ({
            ...s,
            hovering: false
        }));
    };
}
