import { NetworkRequest } from "api/actions";

export const getCampaigns = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/campaigns"
    };
};

export const saveCampaignResponse = (campaignId: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/campaigns/{campaignId}/responses",
        params: { campaignId }
    };
};
