import React, { useState, useEffect } from "react";
import { Grid, useTheme, GridSpacing, useMediaQuery } from "@mui/material";
import { Breakpoint } from "@mui/system";
import { Option, OptionListItem } from "modules/common/components/forms";

type columnSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface OptionListProps {
    options: Option[];
    value: any | null;
    valueComparer?: (valueOne: any, valueTwo: any) => boolean;
    onOptionSelected?: (value: any) => any;

    xsNumColumns?: number;
    smNumColumns?: number;
    mdNumColumns?: number;
    lgNumColumns?: number;
    xlNumColumns?: number;

    xsColumnSpan?: columnSpan;
    smColumnSpan?: columnSpan;
    mdColumnSpan?: columnSpan;
    lgColumnSpan?: columnSpan;
    xlColumnSpan?: columnSpan;

    rowSpacing?: GridSpacing;
    colSpacing?: GridSpacing;

    animateVisibility?: boolean;

    float?: boolean;
    className?: string;
    itemVariant?: "box" | "chip";
    disabled?: boolean;
}

const buildNumColumnsMap = (props: OptionListProps, currentWidth: Breakpoint): { [screenWidth: string]: number } => {
    const xsNumColumns = props.xsNumColumns || 1;
    const smNumColumns = props.smNumColumns || xsNumColumns;
    const mdNumColumns = props.mdNumColumns || smNumColumns;
    const lgNumColumns = props.lgNumColumns || mdNumColumns;
    const xlNumColumns = props.xlNumColumns || lgNumColumns;

    return {
        xs: xsNumColumns,
        sm: smNumColumns,
        md: mdNumColumns,
        lg: lgNumColumns,
        xl: xlNumColumns
    };
};

const buildColumnSpanMap = (
    props: OptionListProps,
    currentWidth: Breakpoint
): { [screenWidth: string]: columnSpan } => {
    const xsColumnSpan = props.xsColumnSpan || 12;
    const smColumnSpan = props.smColumnSpan || xsColumnSpan;
    const mdColumnSpan = props.mdColumnSpan || smColumnSpan;
    const lgColumnSpan = props.lgColumnSpan || mdColumnSpan;
    const xlColumnSpan = props.xlColumnSpan || lgColumnSpan;

    return {
        xs: xsColumnSpan,
        sm: smColumnSpan,
        md: mdColumnSpan,
        lg: lgColumnSpan,
        xl: xlColumnSpan
    };
};

const OptionList = (props: OptionListProps) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("xs"));
    const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    const getCurrentWidth = (): Breakpoint => {
        if (isXs) {
            return "xs";
        } else if (isSm) {
            return "sm";
        } else if (isMd) {
            return "md";
        } else if (isLg) {
            return "lg";
        } else if (isXl) {
            return "xl";
        }
        return "xs";
    };

    const [currentWidth, setCurrentWidth] = React.useState<Breakpoint>(getCurrentWidth());

    const [numColumnsMap, setNumColumnsMap] = React.useState<{ [screenWidth: string]: number }>(
        buildNumColumnsMap(props, currentWidth)
    );

    const [columnSpanMap, setColumnSpanMap] = React.useState<{ [screenWidth: string]: columnSpan }>(
        buildColumnSpanMap(props, currentWidth)
    );

    useEffect(() => {
        setCurrentWidth(getCurrentWidth());
        setNumColumnsMap(buildNumColumnsMap(props, currentWidth));
        setColumnSpanMap(buildColumnSpanMap(props, currentWidth));
    }, [props, isXs, isSm, isMd, isLg]);

    const onOptionSelected = (option: Option) => () => {
        if (props.onOptionSelected) {
            props.onOptionSelected(option ? option.value : null);
        }
    };

    const optionIsValidSelection = (
        options: Option[],
        value: any,
        valueComparer?: (valueOne: any, valueTwo: any) => boolean
    ) => {
        const match = options.find((option) =>
            valueComparer ? valueComparer(option.value, value) : option.value === value
        );
        return match != null && match.visible && !match.disabled;
    };

    const splitOptionsIntoColumns = (options: Option[], columnSize: number): Option[][] => {
        const columns = [];

        for (let i = 0; i < options.length; i++) {
            if (i % columnSize === 0) {
                columns.push([]);
            }
        }

        let columnIndex = 0;
        for (let i = 0; i < options.length; i++) {
            if (i > 0 && i % columnSize === 0) {
                columnIndex++;
            }

            columns[columnIndex].push(options[i]);
        }
        return columns;
    };

    useEffect(() => {
        if (
            props.options !== options &&
            !!props.value &&
            !optionIsValidSelection(props.options, props.value, props.valueComparer)
        ) {
            onOptionSelected(null);
        }
    }, [props.options, props.value, props.valueComparer]);

    const { colSpacing, rowSpacing, valueComparer, value, options, float, className = "", itemVariant = "box" } = props;

    const numColumns = numColumnsMap[currentWidth];
    const columnSize = Math.ceil(options.length / numColumns);
    const columns = splitOptionsIntoColumns(options, columnSize);

    return (
        <Grid container spacing={colSpacing || 2} className={`option-list  ${className} ${float ? "floated" : ""}`}>
            {float
                ? columns.map((column, i) => {
                      return column.map((option) => {
                          if (props.disabled && option.accountType === "WSA") {
                              option.disabled = true;
                          }
                          const isSelected = valueComparer
                              ? valueComparer(option.value, value)
                              : option.value === value;
                          return option.visible ? (
                              <span key={option.key} className={`${option.fullWidth ? "full-width" : ""}`}>
                                  <OptionListItem
                                      option={option}
                                      selected={isSelected}
                                      onClick={onOptionSelected(option)}
                                      variant={itemVariant}
                                  />
                              </span>
                          ) : null;
                      });
                  })
                : columns.map((column, i) => {
                      return (
                          <Grid key={i} item xs={columnSpanMap[currentWidth]} className="option-list-column">
                              <Grid container spacing={rowSpacing || 1}>
                                  {column.map((option) => {
                                      if (props.disabled && option.accountType === "WSA") {
                                          option.disabled = true;
                                      }
                                      const isSelected = valueComparer
                                          ? valueComparer(option.value, value)
                                          : option.value === value;

                                      return option.visible ? (
                                          <Grid
                                              key={option.key}
                                              item
                                              xs={12}
                                              className={option.indent ? "option-indent" : ""}>
                                              <OptionListItem
                                                  option={option}
                                                  selected={isSelected}
                                                  onClick={onOptionSelected(option)}
                                                  variant={itemVariant}
                                              />
                                          </Grid>
                                      ) : null;
                                  })}
                              </Grid>
                          </Grid>
                      );
                  })}
        </Grid>
    );
};

export default OptionList;
