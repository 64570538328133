import { NetworkRequest } from "./actions";

export const logIn = (username: string, password: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/authentication/login",
        body: {
            username,
            password
        },
        doNotEnsureToken: true
    };
};

export const extendSession = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/authentication/extendSession"
    };
};

export const logOut = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/authentication/logout",
        doNotEnsureToken: true
    };
};

export const reauthenticate = (username: string, password: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/authentication/reauthenticate",
        body: {
            username,
            password
        },
        doNotEnsureToken: true
    };
};

export const refreshToken = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/authentication/refresh",
        doNotEnsureToken: true
    };
};

export const forgotPassword = (email: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/profile/password/forgot`,
        body: `"${email}"`,
        doNotEnsureToken: true
    };
};

export const resetPassword = (resetGuid: string, password: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/profile/password/reset`,
        body: {
            resetGuid,
            password
        },
        doNotEnsureToken: true
    };
};

export const checkResetToken = (resetGuid: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/profile/password/checkResetToken`,
        queryParams: {
            tokenId: resetGuid
        },
        doNotEnsureToken: true
    };
};

export const updatePassword = (currentPassword, newPassword): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/profile/password/update`,
        body: {
            currentPassword,
            newPassword
        },
        doNotEnsureToken: true
    };
};
