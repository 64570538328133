import * as React from "react";
import { Grid, Button, Fade, FormLabel, TextField, FormHelperText } from "@mui/material";

import { OptionList } from "modules/common/components/forms";
import { Relative } from "modules/person/models";
import { GroupedPersonOptions, getGroupedPersonOptions } from "modules/person/groupedPersonOptionsModels";
import { EverythingElseIcon as SomeoneElseIcon } from "../icons";

interface PersonSelectorProps {
    memberCertificateNumber: string;
    people: Relative[];

    selectedCertificateNumber: string;
    customValue?: string;

    allowCustomValues?: boolean;
    customValueLabel?: string;
    customValueHelperText?: string;

    onValueChanged: (certificateNumber: string, customValue: string) => any;

    showActiveOptionsOnly?: boolean;

    showErrorMessage?: boolean;
    errorMessage?: string;

    hideLongTerminated?: boolean;

    showClearOption?: boolean;
    clearOptionLabel?: string;

    isLocked?: boolean;
    fullWidth?: boolean;
    float?: boolean;
    itemVariant?: "box" | "chip";
}

export interface PersonSelectorState {
    people: Relative[];
    showAllOptions: boolean;
    options: GroupedPersonOptions;
    isSomeoneElseSelected: boolean;
}

export class PersonSelector extends React.Component<PersonSelectorProps, PersonSelectorState> {
    private static someoneElseKey = "SOMEONE_ELSE";

    public state: PersonSelectorState = {
        people: [],
        showAllOptions: false,
        options: {
            activeOptions: [],
            allOptions: []
        } as GroupedPersonOptions,
        isSomeoneElseSelected: false
    };

    public static getDerivedStateFromProps(props: PersonSelectorProps, state: PersonSelectorState) {
        if (props.people !== state.people) {
            const isSomeoneElseSelected =
                state.isSomeoneElseSelected || (!props.selectedCertificateNumber && !!props.customValue);
            const currentValue = isSomeoneElseSelected
                ? PersonSelector.someoneElseKey
                : props.selectedCertificateNumber;

            return {
                ...state,
                people: props.people,
                options: getGroupedPersonOptions(
                    props.people,
                    props.memberCertificateNumber,
                    props.hideLongTerminated,
                    props.allowCustomValues,
                    !!props.isLocked,
                    currentValue,
                    props.showClearOption,
                    props.clearOptionLabel,
                    PersonSelector.someoneElseKey,
                    <SomeoneElseIcon />
                ),
                isSomeoneElseSelected:
                    state.isSomeoneElseSelected || (!props.selectedCertificateNumber && !!props.customValue)
            };
        }
        return state;
    }

    public render() {
        const { options, isSomeoneElseSelected } = this.state;
        const {
            selectedCertificateNumber,
            showActiveOptionsOnly,
            isLocked,
            customValueLabel,
            customValueHelperText,
            customValue,
            showErrorMessage,
            errorMessage,
            fullWidth = false,
            float = false,
            itemVariant = "box"
        } = this.props;

        const value = isSomeoneElseSelected ? PersonSelector.someoneElseKey : selectedCertificateNumber;
        const visibleOptions = this.state.showAllOptions ? options.allOptions : options.activeOptions;

        return (
            <Grid container className="person-selector">
                <Grid item xs={12}>
                    <OptionList
                        float={float}
                        options={visibleOptions}
                        value={value}
                        onOptionSelected={this.onSelectedCertificateNumberChanged}
                        xsColumnSpan={12}
                        xsNumColumns={1}
                        smColumnSpan={fullWidth ? 12 : 6}
                        smNumColumns={fullWidth ? 1 : 2}
                        mdColumnSpan={fullWidth ? 12 : 3}
                        mdNumColumns={fullWidth ? 1 : 4}
                        colSpacing={1}
                        rowSpacing={1}
                        animateVisibility={true}
                        itemVariant={itemVariant}
                    />
                </Grid>
                {!showActiveOptionsOnly && !isLocked && options.allOptions.length > options.activeOptions.length ? (
                    <Grid item xs={12}>
                        <div className="person-selector-toggle-wrapper">
                            <Button id="more-options-button" onClick={this.onToggleButtonClicked} color="primary">
                                {this.state.showAllOptions ? "Show fewer options" : "Show more options"}
                            </Button>
                        </div>
                    </Grid>
                ) : null}
                {isSomeoneElseSelected ? (
                    <Grid item xs={12} sm={6}>
                        <Fade in>
                            <div className="someone-else-wrapper">
                                {customValueLabel ? (
                                    <FormLabel className="control-label" error={showErrorMessage}>
                                        {customValueLabel}
                                    </FormLabel>
                                ) : null}
                                <TextField
                                    id="someone-else-field"
                                    fullWidth
                                    autoComplete="off"
                                    onKeyPress={this.onKeyPressIgnoreEnter}
                                    value={customValue}
                                    onChange={this.onTextChanged}
                                    autoFocus
                                    error={showErrorMessage}
                                    helperText={customValueHelperText}
                                />
                            </div>
                        </Fade>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    {showErrorMessage ? <FormHelperText className="error-text">{errorMessage}</FormHelperText> : null}
                </Grid>
            </Grid>
        );
    }

    private onKeyPressIgnoreEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    private onTextChanged = (e: any) => {
        if (this.state.isSomeoneElseSelected) {
            this.props.onValueChanged(null, e.target.value);
        }
    };

    private onSelectedCertificateNumberChanged = (value: string) => {
        if (value === PersonSelector.someoneElseKey) {
            this.props.onValueChanged(null, "");
        } else {
            this.props.onValueChanged(value, null);
        }

        const newIsSomeoneElseSelected = value === PersonSelector.someoneElseKey;
        if (newIsSomeoneElseSelected !== this.state.isSomeoneElseSelected) {
            this.setState((s) => ({
                ...s,
                isSomeoneElseSelected: value === PersonSelector.someoneElseKey
            }));
        }
    };

    private onToggleButtonClicked = () => {
        this.setState((s) => ({ ...s, showAllOptions: !this.state.showAllOptions }));
    };
}
