import { useBaseAxiosGetRequest, useBaseAxiosPostRequest } from "hooks/axiosHooks";
import {
    ExpenseClaimCreatedWebResponse,
    SpendingAccountClaimCategory,
    SubmitTransferredExpenseClaimRequest
} from "./expenseClaims.models";
import { Log } from "modules/logging";

const controllerEndpoint = "/api/expenseClaims/v5";

export const useGetSpendingAccountExpenseClaimCategoriesRequest = (spendingAccountIds: number[]) => {
    const queryString = spendingAccountIds
        .map((spendingAccountId) => `spendingAccountId=${spendingAccountId}`)
        .join("&");

    return useBaseAxiosGetRequest<SpendingAccountClaimCategory[]>(
        `${controllerEndpoint}/spendingAccountCategories?${queryString}`,
        { axios: { manual: !Boolean(spendingAccountIds) } }
    );
};

export const useTransferToExpenseClaimRequest = () =>
    useBaseAxiosPostRequest<SubmitTransferredExpenseClaimRequest, ExpenseClaimCreatedWebResponse>(
        `${controllerEndpoint}/transfer`,
        {
            onRequestCompleted: (result, input) => {
                if (result.isSuccess) {
                    Log.transfertoHsaClaimSucceeded(input.reference, input.duplicateWarningShown);
                } else {
                    Log.transferToHsaClaimFailed(
                        input.reference,
                        input.duplicateWarningShown,
                        result.axiosError?.message
                    );
                }
            }
        }
    );
