import API from "api";
import { NetworkRequest } from "api/actions";
import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import * as actions from "modules/profile/address/actions";
import { Address, Country } from "modules/profile/address/models";
import { ApplicationState as AppState, AppThunkAction } from "store";
import personalInfoVerificationActionCreators, { PersonalInfoVerificationActions } from "modules/profile/personalInfoVerification/actionCreator";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";

export type HandledAction = actions.GetCountriesInitAction | actions.GetCountriesCompleteAction | actions.GetAddressInitAction | actions.GetAddressCompleteAction | actions.SaveAddressInitAction | actions.SaveAddressCompleteAction;
export type KnownAction = HandledAction | NetworkRequest | AddNotificationAction | PersonalInfoVerificationActions;

let countryPromise = null;
let addressPromise = null;

export const actionCreators = {
    getCountries: (forceRefresh = false) => (dispatch, getState: () => AppState) => {
        if (countryPromise != null && !forceRefresh) {
            return countryPromise;
        }

        dispatch({ type: "GET_COUNTRIES_INIT" });
        countryPromise = dispatch(API.profile.getCountries())
            .then(
                response => {
                    if (response?.status === 200) {
                        return response.json() as Promise<Country[]>;
                    } else {
                        dispatch({ type: "GET_COUNTRIES_COMPLETE", countries: null, succeeded: false, err: "Failed to get countries" });
                        return null;
                    }
                },
                (reason: any) => {
                    return null;
                }
            )
            .then(
                (countries: Country[]) => {
                    if (countries) {
                        dispatch({ type: "GET_COUNTRIES_COMPLETE", countries, succeeded: true });
                    }
                    return countries;
                },
                () => null
            );

        return countryPromise;
    },
    getAddress: (forceRefresh = false) => (dispatch, getState: () => AppState) => {
        if (addressPromise != null && !forceRefresh) {
            return addressPromise;
        }

        dispatch({ type: "GET_ADDRESS_INIT" });

        addressPromise = dispatch(actionCreators.getCountries(forceRefresh)).then(() => {
            return dispatch(API.profile.getAddress())
                .then(
                    response => {
                        if (response?.status === 200) {
                            return response.json() as Promise<Address>;
                        } else {
                            dispatch({ type: "GET_ADDRESS_COMPLETE", address: null, succeeded: false, err: "Failed to get profile address" });
                            return null;
                        }
                    },
                    (reason: any) => {
                        return null;
                    }
                )
                .then(
                    (address: Address) => {
                        if (address) {
                            dispatch({ type: "GET_ADDRESS_COMPLETE", address, succeeded: true });
                        }
                        return address;
                    },
                    () => null
                );
        });
        return addressPromise;
    },
    saveAddress: (address: Address): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const task = Promise.resolve()
            .then(() => dispatch({ type: "SAVE_ADDRESS_INIT", address }))
            .then(() => {
                return dispatch(API.profile.saveAddress(address))
                    .then(
                        response => {
                            if (response?.status === 200) {
                                return response.json() as Promise<Address>;
                            } else {
                                const message = "Sorry! Failed to update mailing address.";
                                dispatch({ type: "ADD_NOTIFICATION", message, style: NotificationStyle.Error, autohide: true, canDismiss: true });
                                dispatch({ type: "SAVE_ADDRESS_COMPLETE", address: null, succeeded: false, err: message });
                            }
                        },
                        (reason: any) => {
                            return null;
                        }
                    )
                    .then((newAddress: Address) => {
                        if (newAddress) {
                            dispatch({ type: "ADD_NOTIFICATION", message: "Mailing address updated.", style: NotificationStyle.Success, autohide: true, canDismiss: true });
                            dispatch({ type: "SAVE_ADDRESS_COMPLETE", address: newAddress, succeeded: true, err: null });

                            const personalInfoVerificationToggle = getState().features.featureToggles.PersonalInformationVerification;
                            if (FeatureToggleChecker.isOn(personalInfoVerificationToggle)) {
                                dispatch(personalInfoVerificationActionCreators.personalInfoVerified());
                            }
                                    
                            return true;
                        }
                        return false;
                    });
            });
        return task;
    }
};
