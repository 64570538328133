import * as React from "react";

import { NoEmulationButton, NoEmulationButtonProps } from "modules/common/components/forms";

interface SubmitButtonProps extends NoEmulationButtonProps {
    isSubmitting: boolean;
    submittingMessage: string;
}

// Higher order component (HOC) to add loading state to button
class SubmitButton extends React.Component<SubmitButtonProps> {
    public render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isSubmitting, submittingMessage, ...rest } = this.props;

        return (
            <NoEmulationButton id="submit-button" {...(rest as any)} disabled={this.props.disabled || this.props.isSubmitting}>
                {!this.props.isSubmitting ? <>{this.props.children}</> : <span>{this.props.submittingMessage}</span>}
            </NoEmulationButton>
        );
    }
}

export default SubmitButton;
