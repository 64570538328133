import loadable from "@loadable/component";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { KnownFeatureToggles } from "modules/features/models";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";
import LoginPage from "pages/loginPage";
import { FailedComponentMessage } from "./pages/FailedComponentMessage";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const errorMessageGenerator = (error: Error) => <FailedComponentMessage error={error} />;

const AsyncForgotPasswordPage = loadable(
    () => import(/* webpackChunkName: "registration" */ "pages/forgotPasswordPage")
);
const AsyncResetPasswordPage = loadable(() => import(/* webpackChunkName: "registration" */ "pages/resetPasswordPage"));
const AsyncRegistrationPage = loadable(() => import(/* webpackChunkName: "registration" */ "pages/registrationPage"));
const AsyncRateServiceProviderPage = loadable(
    () => import(/* webpackChunkName: "registration" */ "pages/rateServiceProviderPage")
);
const AsyncRateServiceProviderUnsubscribePage = loadable(
    () => import(/* webpackChunkName: "registration" */ "pages/rateServiceProviderUnsubscribePage")
);

const AsyncVerificationPage = loadable(() => import(/* webpackChunkName: "verification" */ "pages/verificationPage"));
const AsyncDeeplinkPage = loadable(() => import(/* webpackChunkName: "deeplink" */ "pages/deeplinkPage"));

const AsyncDashboardWrapperPage = loadable(() => import(/* webpackChunkName: "dashboard" */ "pages/dashboardPage"));

const AsyncClaimHistoryWrapperPage = loadable(() => import(/* webpackChunkName: "claims" */ "pages/claimHistoryPage"));

const AsyncClaimDetailsWrapperPage = loadable(() => import(/* webpackChunkName: "claims" */ "pages/claimDetailsPage"));

const AsyncClaimSubmissionPage = loadable(() => import(/* webpackChunkName: "claims" */ "pages/claimSubmissionPage"));

const AsyncClaimResubmissionPage = loadable(
    () => import(/* webpackChunkName: "claims" */ "pages/claimResubmissionPage")
);
const AsyncClaimEditorPage = loadable(() => import(/* webpackChunkName: "claims" */ "pages/claimEditorPage"));

const AsyncBenefitsPage = loadable(() => import(/* webpackChunkName: "benefits" */ "pages/benefitsPage"));

const AsyncBenefitUtilizationWrapperPage = loadable(
    () => import(/* webpackChunkName: "benefits" */ "pages/benefitUtilizationPage")
);

const AsyncSpendingAccountActivityWrapperPage = loadable(
    () => import(/* webpackChunkName: "benefits" */ "pages/spendingAccountActivityPage")
);

const AsyncFamilyPage = loadable(() => import(/* webpackChunkName: "benefits" */ "pages/familyPage"));
const AsyncLegacyDrugsPage = loadable(() => import(/* webpackChunkName: "benefits" */ "pages/drugsPage"));
const AsyncDrugInquiryPage = loadable(() => import(/* webpackChunkName: "benefits" */ "pages/drugInquiryPage"));
const AsyncDentalGuidePage = loadable(() => import(/* webpackChunkName: "benefits" */ "pages/dentalGuidePage"));
const AsyncDentalProcedureDetailsPage = loadable(
    () => import(/* webpackChunkName: "benefits" */ "pages/dentalProcedureDetailsPage")
);
const AsyncServiceProvidersPage = loadable(
    () => import(/* webpackChunkName: "providers" */ "pages/serviceProvidersPage")
);
const AsyncIneligibleServiceProvidersPage = loadable(
    () => import(/* webpackChunkName: "providers" */ "pages/ineligibleServiceProvidersPage")
);
const AsyncProfilePage = loadable(() => import(/* webpackChunkName: "profile" */ "pages/profilePage"));
const AsyncDocumentsPage = loadable(() => import(/* webpackChunkName: "documents" */ "pages/documentsPage"));
const AsyncResourcesPage = loadable(() => import(/* webpackChunkName: "documents" */ "pages/resourcesPage"));
const AsyncSettingsPage = loadable(() => import(/* webpackChunkName: "settings" */ "pages/settingsPage"));

const AsyncFeatureArticlesPage = loadable(() => import(/* webpackChunkName: "features" */ "pages/featureArticlesPage"));
const AsyncFeatureArticleDetailsPage = loadable(
    () => import(/* webpackChunkName: "features" */ "pages/featureArticleDetailsPage")
);
const AsyncContactPage = loadable(() => import(/* webpackChunkName: "contact" */ "pages/contactPage"));

const AsyncInboxPage = loadable(() => import(/* webpackChunkName: "inbox" */ "pages/inboxPage"));

const hideSpendingAccountsTab = (context: CoveredMemberSummary) => {
    const hasSpendingAccounts = context.allSpendingAccounts && context.allSpendingAccounts.length > 0;

    if (
        context.canAccessFeature(KnownFeatureToggles.EnableSavingsAccount) &&
        !hasSpendingAccounts &&
        context.hasSavingsAllocation
    ) {
        return false;
    }

    return !hasSpendingAccounts;
};

export interface SiteArea {
    name: string;
    pages?: SiteNode[];
    showInMainNav?: boolean;
    showInMobileNav?: boolean;
    areaPath?: string;
    rootPath?: string;
}

export interface SiteNode {
    displayName: string;
    routingPath: string; // This is the path that includes parameters
    basePath?: string; // This path is used to navigate directly to the page. Without this, the page shouldn't automatically be used in nav components.
    highlightExactMatchOnly?: boolean;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    iconName?: string;
    isHidden?: boolean;
    layout?: "default" | "public" | "blank";
}

export interface RouteDefinition {
    externalArea: SiteArea;
    dashboardArea: SiteArea;
    documentsArea: SiteArea;
    resourcesArea: SiteArea;
    claimsArea: SiteArea;
    benefitsArea: SiteArea;
    providersArea: SiteArea;
    profileArea: SiteArea;
    whatsNewArea: SiteArea;
    contactArea: SiteArea;
    inboxArea: SiteArea;
    settingsArea: SiteArea;
    areas: SiteArea[];
}

type allRoutesContext = "all_routes";

export const routeProvider = (context: CoveredMemberSummary | allRoutesContext) => {
    const routes: RouteDefinition = {
        externalArea: {
            name: "External",
            pages: [
                {
                    displayName: "Log in",
                    routingPath: "/login",
                    basePath: "/login",
                    component: LoginPage,
                    layout: "public"
                },
                {
                    displayName: "Register",
                    routingPath: "/register/:stepName?",
                    basePath: "/register",
                    component: AsyncRegistrationPage,
                    layout: "public"
                },
                {
                    displayName: "Verification",
                    routingPath: "/verification/:taskId?",
                    basePath: "/verification",
                    component: AsyncVerificationPage,
                    layout: "public"
                },
                {
                    displayName: "Open the Mobile App",
                    routingPath: "/deeplink/:src?",
                    basePath: "/deeplink",
                    component: AsyncDeeplinkPage,
                    layout: "public"
                },
                {
                    displayName: "Forgot Password",
                    routingPath: "/forgotPassword",
                    basePath: "/forgotPassword",
                    component: AsyncForgotPasswordPage,
                    layout: "public"
                },
                {
                    displayName: "Reset Password",
                    routingPath: "/resetPassword/:resetGuid?",
                    basePath: "/resetPassword",
                    component: AsyncResetPasswordPage,
                    layout: "public"
                },
                {
                    displayName: "Unsubscribe from Provider Feedback Requests",
                    routingPath: "/rateServiceProvider/unsubscribe",
                    basePath: "/rateServiceProvider/unsubscribe",
                    component: AsyncRateServiceProviderUnsubscribePage,
                    layout: "blank"
                },
                {
                    displayName: "Rate Service Provider",
                    routingPath: "/rateServiceProvider",
                    basePath: "/rateServiceProvider",
                    component: AsyncRateServiceProviderPage,
                    layout: "blank"
                }
            ]
        },
        dashboardArea: {
            name: "Home",
            rootPath: "/home",
            showInMobileNav: true,
            pages: [
                {
                    displayName: "Home",
                    routingPath: "/home",
                    basePath: "/home",
                    component: AsyncDashboardWrapperPage,
                    iconName: "home"
                }
            ]
        },
        claimsArea: {
            name: "Claims",
            showInMainNav: true,
            showInMobileNav: true,
            areaPath: "/claims",
            rootPath: "/claims",
            pages: [
                {
                    displayName: "Submit a Claim",
                    routingPath: "/claims/new",
                    basePath: "/claims/new",
                    component: AsyncClaimSubmissionPage,
                    iconName: "create",
                    isHidden:
                        context !== "all_routes" &&
                        !(context.canSubmitGeneralHealthClaims || context.canSubmitSpendingAccountClaims)
                },
                {
                    displayName: "Resubmit claim",
                    routingPath: "/claims/:referenceId/resubmit",
                    component: AsyncClaimResubmissionPage
                },
                {
                    displayName: "Fix claim",
                    routingPath: "/claims/:referenceId/fix",
                    component: AsyncClaimEditorPage
                },
                {
                    displayName: "Claims",
                    routingPath: "/claims",
                    basePath: "/claims",
                    component: AsyncClaimHistoryWrapperPage,
                    iconName: "receipt"
                },
                {
                    displayName: "Claim Details",
                    routingPath: "/claims/:referenceId",
                    component: AsyncClaimDetailsWrapperPage
                }
            ].filter((p) => !p.isHidden)
        },
        benefitsArea: {
            name: "Benefits",
            showInMainNav: true,
            showInMobileNav: true,
            areaPath: "/benefits",
            rootPath: "/benefits",
            pages: [
                {
                    displayName: "My Benefits",
                    routingPath: "/benefits",
                    basePath: "/benefits",
                    highlightExactMatchOnly: true,
                    component: AsyncBenefitsPage,
                    iconName: "playlist_add_check"
                },
                {
                    displayName: "Usage Summary",
                    routingPath: "/benefits/usage/:certificateNumber?",
                    basePath: "/benefits/usage",
                    component: AsyncBenefitUtilizationWrapperPage,
                    iconName: "account_balance_wallet",
                    isHidden: context !== "all_routes" && !context.hasActiveGeneralHealthCoverage
                },
                {
                    displayName: "Spending Account Activity",
                    routingPath: "/benefits/spending-accounts/:spendingAccountType?/:spendingAccountId?",
                    basePath: "/benefits/spending-accounts",
                    component: AsyncSpendingAccountActivityWrapperPage,
                    iconName: "history",
                    isHidden: context !== "all_routes" && hideSpendingAccountsTab(context)
                },
                {
                    displayName: "My Family",
                    routingPath: "/benefits/family",
                    basePath: "/benefits/family",
                    component: AsyncFamilyPage,
                    iconName: "group"
                },
                {
                    displayName: "Drug Inquiry",
                    routingPath: "/benefits/drugs/:name?",
                    basePath: "/benefits/drugs",
                    component: AsyncLegacyDrugsPage,
                    iconName: "local_pharmacy",
                    isHidden:
                        context !== "all_routes" &&
                        (!context.hasActiveExtendedHealthCoverage ||
                            context.canAccessFeature(KnownFeatureToggles.DrugInquiryWithDin))
                },
                {
                    displayName: "Drug Inquiry",
                    routingPath: "/benefits/drugInquiry/:name?/:din?",
                    basePath: "/benefits/drugs",
                    component: AsyncDrugInquiryPage,
                    iconName: "local_pharmacy",
                    isHidden:
                        context !== "all_routes" &&
                        (!context.hasActiveExtendedHealthCoverage ||
                            !context.canAccessFeature(KnownFeatureToggles.DrugInquiryWithDin))
                },
                {
                    displayName: "Dental Guide",
                    routingPath: "/benefits/dental-guide/:topicId?",
                    basePath: "/benefits/dental-guide",
                    component: AsyncDentalGuidePage,
                    iconName: "build",
                    isHidden:
                        context !== "all_routes" &&
                        (!context.canViewDentalGuide ||
                            !context.canAccessFeature(KnownFeatureToggles.EnableDentalGuide))
                },
                {
                    displayName: "Dental Procedure Details",
                    routingPath: "/benefits/dental-guide/procedure/:procedureCode?",
                    component: AsyncDentalProcedureDetailsPage,
                    isHidden:
                        context !== "all_routes" &&
                        (!context.canViewDentalGuide ||
                            !context.canAccessFeature(KnownFeatureToggles.EnableDentalGuide))
                }
            ].filter((p) => !p.isHidden)
        },
        documentsArea: {
            name: "Documents",
            showInMainNav: true,
            showInMobileNav: true,
            areaPath: "/documents",
            rootPath: "/documents",
            pages: [
                {
                    basePath: "/documents",
                    displayName: "Documents",
                    routingPath: "/documents/:collection?",
                    component: AsyncDocumentsPage,
                    iconName: "insert_drive_file",
                    isHidden: false
                }
            ].filter((p) => !p.isHidden)
        },
        providersArea: {
            name: "Providers",
            showInMainNav: true,
            showInMobileNav: true,
            areaPath: "/providers",
            rootPath: "/providers/service-providers",
            pages: [
                {
                    basePath: "/providers/service-providers",
                    displayName: "Service Providers",
                    routingPath: "/providers/service-providers",
                    highlightExactMatchOnly: true,
                    component: AsyncServiceProvidersPage,
                    iconName: "build",
                    isHidden:
                        context !== "all_routes" && !context.canAccessFeature(KnownFeatureToggles.ServiceProvidersPage)
                },
                {
                    basePath: "/providers/ineligibleProviders",
                    displayName: "Ineligible Providers",
                    routingPath: "/providers/ineligibleProviders",
                    highlightExactMatchOnly: true,
                    component: AsyncIneligibleServiceProvidersPage,
                    iconName: "build",
                    isHidden:
                        context !== "all_routes" &&
                        !context.canAccessFeature(KnownFeatureToggles.IneligibleServiceProviders)
                }
            ].filter((p) => !p.isHidden)
        },
        resourcesArea: {
            name: "Resources",
            showInMainNav: true,
            showInMobileNav: true,
            areaPath: "/resources",
            rootPath: "/resources",
            pages: [
                {
                    basePath: "/resources",
                    displayName: "Resources",
                    routingPath: "/resources/:collection?",
                    component: AsyncResourcesPage,
                    iconName: "insert_drive_file",
                    isHidden: context !== "all_routes" && !context.canAccessFeature(KnownFeatureToggles.ResourcesPage)
                }
            ].filter((p) => !p.isHidden)
        },
        profileArea: {
            name: "Profile",
            showInMobileNav: true,
            pages: [
                {
                    displayName: "My Profile",
                    basePath: "/profile",
                    routingPath: "/profile",
                    component: AsyncProfilePage,
                    iconName: "account_circle"
                }
            ]
        },
        whatsNewArea: {
            name: "What's New",
            showInMobileNav: true,
            pages: [
                {
                    displayName: "What's New",
                    basePath: "/whats-new",
                    routingPath: "/whats-new",
                    component: AsyncFeatureArticlesPage,
                    iconName: "notification_important"
                },
                {
                    displayName: "Feature Details",
                    routingPath: "/whats-new/:id",
                    component: AsyncFeatureArticleDetailsPage
                }
            ]
        },
        contactArea: {
            name: "Contact",
            showInMobileNav: false,
            pages: [
                {
                    displayName: "Contact Us",
                    basePath: "/contact",
                    routingPath: "/contact",
                    component: AsyncContactPage,
                    iconName: "account_circle"
                }
            ]
        },
        inboxArea: {
            name: "Inbox",
            showInMainNav: false,
            showInMobileNav: true,
            areaPath: "/inbox",
            rootPath: "/inbox",
            pages: [
                {
                    basePath: "/inbox",
                    displayName: "Inbox",
                    routingPath: "/inbox/:collection?",
                    component: AsyncInboxPage,
                    iconName: "inbox_outlined",
                    isHidden: context !== "all_routes" && !context.canAccessFeature(KnownFeatureToggles.EnableMessaging)
                }
            ].filter((p) => !p.isHidden)
        },
        settingsArea: {
            name: "Settings",
            showInMobileNav: true,
            pages: [
                {
                    displayName: "Settings",
                    basePath: "/settings",
                    routingPath: "/settings",
                    component: AsyncSettingsPage,
                    iconName: "settings"
                }
            ]
        },
        areas: []
    };

    routes.areas = [
        routes.externalArea,
        routes.dashboardArea,
        routes.claimsArea,
        routes.benefitsArea,
        routes.documentsArea,
        routes.providersArea,
        routes.resourcesArea,
        routes.profileArea,
        routes.whatsNewArea,
        routes.contactArea,
        routes.settingsArea,
        routes.inboxArea
    ].filter((a) => a.pages.some((p) => !p.isHidden));

    return routes;
};
