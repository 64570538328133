import { SpendingAccountActivity } from "./models.activity";

export interface SpendingAccountsState {
    spendingAccountList: SpendingAccountListState;
    spendingAccountActivity: SpendingAccountActivityState;
    activeTab: SpendingAccountActivityTab;
}

export interface SpendingAccountListState {
    isFetching: boolean;
    membershipSpendingAccounts: MembershipSpendingAccountList[];
    isSpendingAccountAllocationDialogOpen: boolean;
    isSavingAllocation: boolean;
    errorMessage?: string;
}

export interface SpendingAccountActivityState {
    isFetching: boolean;
    records: SpendingAccountActivityDictionary;
}

export interface SpendingAccountActivityDictionary {
    [spendingAccountId: number]: SpendingAccountActivity;
}

export interface MembershipSpendingAccountList {
    membershipId: number;
    spendingAccounts: SpendingAccountSummary[];
    paymentExclusion: PaymentExclusion;
}

export interface PaymentExclusion {
    reasonType: PaymentExclusionReasonType;
    reasonTypeName: string;
    createdDate: Date | string;
}

export interface SpendingAccountSummary {
    id: number;
    membershipId: number;
    coveragePeriodId: number;
    accountType: SpendingAccountType;
    packageName: string;

    planYear: PlanYearSummary;

    startDate: Date | string;
    lastActiveDate: Date | string;
    runoffEndDate: Date | string;
    spansEntirePlanYear: boolean;

    isPlaceholder: boolean;
    isActive: boolean;
    isInRunoff: boolean;
    isTerminated: boolean;
    isCancelled: boolean;
    isExpired: boolean;
    isUpcoming: boolean;
    hasAdjustments: boolean;

    allocationPercentage: number | null;
    isCoveragePeriodAllocated: boolean;
    allocationLockDate: Date | string;
    canChangeAllocation: boolean;

    balance: number;
    totalDepositedAmount: number;
    paidAmount: number;
    paymentExclusion: PaymentExclusion;
}

export interface PlanYearSummary {
    name: string;
    startDate: Date | string;
    endDate: Date | string;
    doesAllowWsa: boolean;
    wsaExpensePlanName: string;
    hsaExpensePlanName: string;
}

export interface GeneralHealthBenefit {
    id: number;
    name: string;
    membershipId: number;
}

export interface AllocatableCoveragePeriod {
    coveragePeriodId: number;
    membershipId: number;
    startDate: Date | string;
    lastActiveDate: Date | string;
    hsaAllocationPercentage: number;
    schoolJurisdictionName: string;
    hasSubmittedAllocation: boolean;
    allocationLockDate: Date | string;
    wsaExpensePlanName: string;
}

export enum SpendingAccountType {
    Unallocated = "Unallocated",
    HSA = "HSA",
    WSA = "WSA"
}

export enum PaymentExclusionReasonType {
    Overpayment = 1,
    Other = 2
}

export enum DepositStatus {
    DepositFailed = 2
}

export type SpendingAccountActivityTab = "claims" | "credits";
