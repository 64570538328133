import * as React from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { Button } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";

import { ApplicationState } from "store";

export interface NoEmulationButtonProps extends ButtonProps {
  component?: string | ((props: any) => JSX.Element);
}

function NoEmulationButton(props: NoEmulationButtonProps) {
  const isEmulated = useAppSelector(
    (state: ApplicationState) => state.auth.session.emulatedSession
  );

  const { title, children, disabled, ...rest } = props;

  return (
    <span title={isEmulated ? "Unavailable while emulating" : title}>
      <Button
        {...rest}
        disabled={disabled || isEmulated}
        title={isEmulated ? "Unavailable while emulating" : title}
      >
        {children}
      </Button>
    </span>
  );
}

export default NoEmulationButton;
