import { push } from "redux-first-history";

import API from "api";
import { NetworkRequest } from "api/actions";

import { ApplicationState as AppState, AppThunkAction } from "store";
import * as actions from "modules/benefits/spendingAccountsV2/actions";
import {
    MembershipCoveragePeriodSummary,
    MembershipSpendingAccountList
} from "modules/benefits/spendingAccountsV2/models";

import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import { UpdatePersonMaskedSocialInsuranceNumber } from "modules/person/actions";
import { Formatter } from "modules/utils";
import { SpendingAccountClaimCategoryActionCreators } from "modules/claims/spendingAccountClaimCategories/actionCreator";
import { RouterAction } from "routes";

export type HandledAction =
    | actions.GetCoveragePeriodsInitAction
    | actions.GetCoveragePeriodsCompleteAction
    | actions.GetSpendingAccountsInitV2Action
    | actions.GetSpendingAccountsCompleteV2Action
    | actions.OpenAllocationDialogAction
    | actions.CloseAllocationDialogAction
    | actions.SubmitAllocationInitV2Action
    | actions.SubmitAllocationCompleteV2Action;

export type SpendingAccountActionsV2 =
    | HandledAction
    | NetworkRequest
    | RouterAction
    | UpdatePersonMaskedSocialInsuranceNumber
    | AddNotificationAction;

let spendingAccountsPromise = null;

export const SpendingAccountActionCreatorsV2 = {
    getCoveragePeriods: (): AppThunkAction<SpendingAccountActionsV2> => (dispatch, getState: () => AppState) => {
        dispatch({ type: "GET_COVERAGE_PERIODS_INIT" });
        return dispatch(API.spendingAccounts.getCoveragePeriods())
            .then(
                (response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<MembershipCoveragePeriodSummary[]>;
                    } else {
                        dispatch({
                            type: "GET_COVERAGE_PERIODS_COMPLETE",
                            coveragePeriods: null,
                            succeeded: false,
                            error: "Failed to get spending accounts"
                        });
                        return null;
                    }
                },
                () => null
            )
            .then((coveragePeriods: MembershipCoveragePeriodSummary[]) => {
                if (coveragePeriods) {
                    dispatch({
                        type: "GET_COVERAGE_PERIODS_COMPLETE",
                        coveragePeriods,
                        succeeded: true
                    });

                    return coveragePeriods;
                }
                return null;
            });
    },
    getSpendingAccounts:
        (resetExistingSpendingAccountsPromise = false): AppThunkAction<SpendingAccountActionsV2> =>
        (dispatch, getState: () => AppState) => {
            if (resetExistingSpendingAccountsPromise) {
                spendingAccountsPromise = null;
            }

            if (spendingAccountsPromise == null) {
                dispatch({ type: "GET_SPENDING_ACCOUNTS_INIT_V2" });

                spendingAccountsPromise = dispatch(API.spendingAccounts.getSpendingAccounts())
                    .then(
                        (response) => {
                            if (response?.status === 200) {
                                return response.json() as Promise<MembershipSpendingAccountList[]>;
                            } else {
                                dispatch({
                                    type: "GET_SPENDING_ACCOUNTS_COMPLETE_V2",
                                    spendingAccounts: null,
                                    succeeded: false,
                                    error: "Failed to get spending accounts"
                                });
                                return null;
                            }
                        },
                        () => null
                    )
                    .then(async (spendingAccounts: MembershipSpendingAccountList[]) => {
                        if (spendingAccounts) {
                            await dispatch({
                                type: "GET_SPENDING_ACCOUNTS_COMPLETE_V2",
                                spendingAccounts,
                                succeeded: true
                            });

                            await dispatch(
                                SpendingAccountClaimCategoryActionCreators.getSpendingAccountClaimCategories(
                                    resetExistingSpendingAccountsPromise
                                )
                            );

                            return spendingAccounts;
                        }
                        return null;
                    });
            }
            return spendingAccountsPromise;
        },

    openAllocationDialog: (): AppThunkAction<SpendingAccountActionsV2> => (dispatch, getState: () => AppState) => {
        dispatch({ type: "OPEN_ALLOCATION_DIALOG" });
    },

    closeAllocationDialog: (): AppThunkAction<SpendingAccountActionsV2> => (dispatch, getState: () => AppState) => {
        dispatch({ type: "CLOSE_ALLOCATION_DIALOG" });
    },
    submitAllocation:
        (
            hsaAllocation: number,
            wsaAllocation: number,
            tfsaAllocation: number,
            rrspAllocation: number,
            coveragePeriodId: number,
            selectedSpendingAccountId: number,
            membershipId: number,
            socialInsuranceNumber?: string
        ): AppThunkAction<SpendingAccountActionsV2> =>
        (dispatch, getState: () => AppState) => {
            dispatch({ type: "SUBMIT_ALLOCATION_INIT_V2" });

            return dispatch(
                API.spendingAccounts.submitAllocation(
                    hsaAllocation,
                    wsaAllocation,
                    coveragePeriodId,
                    membershipId,
                    socialInsuranceNumber,
                    tfsaAllocation,
                    rrspAllocation
                )
            )
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<number[]>;
                    } else {
                        dispatch({
                            type: "SUBMIT_ALLOCATION_COMPLETE_V2",
                            succeeded: false,
                            error: "Failed to set allocation amount."
                        });
                        return null;
                    }
                })
                .then((spendingAccountIds: number[]) => {
                    if (spendingAccountIds) {
                        dispatch(SpendingAccountActionCreatorsV2.getCoveragePeriods());
                        dispatch(SpendingAccountActionCreatorsV2.getSpendingAccounts(true));
                        if (spendingAccountIds.indexOf(selectedSpendingAccountId) < 0) {
                            selectedSpendingAccountId = spendingAccountIds[0];
                            dispatch(push(`/benefits/spending-accounts`));
                        }
                        if (socialInsuranceNumber) {
                            dispatch({
                                type: "UPDATE_PERSON_MASKED_SOCIALINSURANCENUMBER",
                                maskedSocialInsuranceNumber: Formatter.maskSocialInsuranceNumber(socialInsuranceNumber)
                            });
                        }
                        dispatch({ type: "SUBMIT_ALLOCATION_COMPLETE_V2", succeeded: true });
                        dispatch({
                            type: "ADD_NOTIFICATION",
                            message: "Success! Your allocation is set.",
                            style: NotificationStyle.Success,
                            autohide: true,
                            canDismiss: true
                        });
                    } else {
                        dispatch({
                            type: "SUBMIT_ALLOCATION_COMPLETE_V2",
                            succeeded: false,
                            error: "Failed to set allocation amount."
                        });
                    }
                    return null;
                });
        }
};
