import { Reducer } from "redux";

import { CampaignsState } from "./models";
import { HandledAction } from "./actionCreator";

export type KnownAction = HandledAction;

export const reducer: Reducer<CampaignsState> = (state: CampaignsState, action: KnownAction) => {
    switch (action.type) {
        case "GET_CAMPAIGNS_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_CAMPAIGNS_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                campaigns: action.campaigns
            };
        }
        case "SAVE_CAMPAIGN_RESPONSE_INIT": {
            return {
                ...state,
                campaigns: state.campaigns.map(campaign => {
                    if (campaign.id === action.campaignId) {
                        return {
                            ...campaign,
                            isSaving: true
                        };
                    }
                    return campaign;
                })
            };
        }
        case "SAVE_CAMPAIGN_RESPONSE_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    campaigns: state.campaigns.map(campaign => {
                        if (campaign.id === action.campaignId) {
                            return {
                                ...campaign,
                                hasResponded: true,
                                numberOfResponses: 1,
                                isSaving: false
                            };
                        }
                        return campaign;
                    })
                };
            } else {
                return {
                    ...state,
                    campaigns: state.campaigns.map(campaign => {
                        if (campaign.id === action.campaignId) {
                            return {
                                ...campaign,
                                isSaving: false
                            };
                        }
                        return campaign;
                    })
                };
            }
        }
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return (
        state || {
            isFetching: false,
            campaigns: null
        }
    );
};
