import * as React from "react";
import { IconButton } from "@mui/material";

export interface NavDrawerToggleProps {
    hasUnreadFeatureArticles: boolean;
    toggleClicked: () => any;
}

export class NavDrawerToggle extends React.Component<NavDrawerToggleProps, {}> {
    public render() {
        const { hasUnreadFeatureArticles } = this.props;

        return (
            <IconButton
                className={`nav-drawer-toggle ${hasUnreadFeatureArticles ? "alert" : ""}`}
                onClick={this.props.toggleClicked}
                size="large">
                <i className="material-icons">menu</i>
            </IconButton>
        );
    }
}
