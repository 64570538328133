import * as React from "react";
import { CssBaseline } from "@mui/material";
import { SessionTimer } from "modules/auth/components";
import { Notifications } from "modules/notifications/components";
import App from "./App";
import Footer from "./Footer";
import GlobalActions from "./GlobalActions";
import Header from "./Header";
import { Background } from "./Background";
import PersonalInfoVerificationDialog from "modules/profile/personalInfoVerification/components/verificationDialog";
import { FeatureToggledElement } from "../misc/featureToggledElement";
import { KnownFeatureToggles } from "modules/features/models";

const LayoutBase = (props: React.PropsWithChildren<{}>) => (
  <SessionTimer>
    <CssBaseline />
    <App>
      <Background />
      <Header />
      <div className="main-content">
        <GlobalActions />
        <div>{props.children}</div>
      </div>
      <Notifications />
      <Footer />
      <FeatureToggledElement knownFeatureToggle={KnownFeatureToggles.PersonalInformationVerification}>
          <PersonalInfoVerificationDialog />
      </FeatureToggledElement>
    </App>
  </SessionTimer>
);

const PublicLayoutBase = (props: React.PropsWithChildren<{}>) => (
  <>
    <CssBaseline />
    <App>
      <Background />
      <div className="main-content">
        <div>{props.children}</div>
      </div>
    </App>
  </>
);

const BlankLayoutBase = (props: React.PropsWithChildren<{}>) => (
  <>
    <CssBaseline />
    <App>
      <div className="main-content">
        <div>{props.children}</div>
      </div>
    </App>
  </>
);

export { LayoutBase, PublicLayoutBase, BlankLayoutBase };
