import { AppThunkAction } from "store";

import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";

import API from "api";
import { NetworkRequest } from "api/actions";

import * as actions from "./actions";
import { SearchResponse } from "./models";

export type HandledAction =
    | actions.SearchCuratedSearchResultsInit
    | actions.SearchCuratedSearchResultsComplete
    | actions.TrackCuratedSearchResultSelectionInit
    | actions.TrackCuratedSearchResultSelectionComplete;

export type KnownActions = HandledAction | NetworkRequest | AddNotificationAction;

export const SearchActionCreators = {
    searchCuratedSearchResults:
        (text: string): AppThunkAction<KnownActions> =>
        (dispatch, getState) => {
            dispatch({ type: "SEARCH_CURATED_SEARCH_RESULTS_INIT", text });

            if (!text) {
                dispatch({ type: "SEARCH_CURATED_SEARCH_RESULTS_COMPLETE", text, response: null, succeeded: true });
                return null;
            }

            return dispatch(API.search.searchCuratedSearchResults(text))
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<SearchResponse>;
                    } else {
                        dispatch({
                            type: "SEARCH_CURATED_SEARCH_RESULTS_COMPLETE",
                            text,
                            response: null,
                            succeeded: false
                        });
                        dispatch({
                            type: "ADD_NOTIFICATION",
                            message: "Failed to get search results",
                            style: NotificationStyle.Error,
                            autohide: true,
                            canDismiss: true
                        });
                    }
                })
                .then((response: SearchResponse) => {
                    if (response) {
                        dispatch({ type: "SEARCH_CURATED_SEARCH_RESULTS_COMPLETE", text, response, succeeded: true });
                    }
                    return response;
                });
        },
    trackCuratedSearchResultSelection:
        (searchId: string, selectedDocumentId: string, rank: number): AppThunkAction<KnownActions> =>
        (dispatch, getState) => {
            dispatch({ type: "TRACK_CURATED_SEARCH_RESULT_SELECTION_INIT", searchId, selectedDocumentId, rank });

            return dispatch(API.search.trackCuratedSearchResultSelection(searchId, selectedDocumentId, rank)).then(
                (response) => {
                    if (response?.status === 200 || response.status === 204) {
                        dispatch({ type: "TRACK_CURATED_SEARCH_RESULT_SELECTION_COMPLETE", succeeded: true });
                        return true;
                    } else {
                        dispatch({ type: "TRACK_CURATED_SEARCH_RESULT_SELECTION_COMPLETE", succeeded: false });
                    }
                }
            );
        }
};
