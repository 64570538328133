import * as React from "react";
import { connect } from "react-redux";
import { ListItem, Icon, ListItemText, Button, ListItemIcon } from "@mui/material";

import { ApplicationState } from "store";
import { MembershipCardActionCreators } from "../actionCreator";

export interface PrintMembershipCardButtonProps {
    renderAsListItem?: boolean;
}

class PrintMembershipCardButton extends React.Component<ComponentProps> {
    public render() {
        return this.props.renderAsListItem ? (
            <ListItem button component="a" href={`/api/people/${this.props.certificateNumber}/walletCard/file`} download onClick={this.props.downloadMembershipCard}>
                <ListItemIcon>
                    <Icon>print</Icon>
                </ListItemIcon>
                <ListItemText primary="Download ID Card" />
            </ListItem>
        ) : (
            <Button href={`/api/people/${this.props.certificateNumber}/walletCard/file`} color="primary" download onClick={this.props.downloadMembershipCard}>
                Download ID Card
            </Button>
        );
    }
}

interface IStateToProps {
    certificateNumber: string;
}

interface IDispatchToProps {
    downloadMembershipCard: () => any;
}

type ComponentProps = PrintMembershipCardButtonProps & IStateToProps & IDispatchToProps;

export default connect(
    (state: ApplicationState, ownProps: PrintMembershipCardButtonProps) => {
        return {
            certificateNumber: state.auth.session.userId
        };
    },
    {
        downloadMembershipCard: MembershipCardActionCreators.downloadMembershipCard
    }
)(PrintMembershipCardButton);
