import * as React from "react";

export interface PlaceholderLineProps {
    color?: "light" | "dark";
    thickness?: "thin" | "thick";
    width?: string;
}

export class PlaceholderLine extends React.PureComponent<PlaceholderLineProps> {
    public render() {
        const color = this.props.color || "light";
        const thickness = this.props.thickness || "thin";

        const width = this.props.width || "100%";

        return <div className={`placeholder-line ${color} ${thickness}`} style={{ width }} />;
    }
}
