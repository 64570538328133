import { NetworkRequest } from "./actions";

export const verifyMemberInformation = (certificateNumber: string, fullName: string, dateOfBirth: Date): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/people/{certificateNumber}/verification/{fullName}/{dateOfBirth}",
        params: {
            certificateNumber,
            fullName,
            dateOfBirth: dateOfBirth.toJSON()
        },
        doNotEnsureToken: true
    };
};

export const verifyAccountDetails = (certificateNumber: string, fullName: string, dateOfBirth: Date, emailAddress: string, password: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/people/${certificateNumber}/account`,
        body: {
            certificateNumber,
            fullName,
            dateOfBirth,
            emailAddress,
            password
        },
        doNotEnsureToken: true
    };
};
