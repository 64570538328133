import { Relative } from "./models";
import { isBefore, isAfter } from "date-fns";

export default class RelativeComparator {
    public static compare = (prioritizedCertificateNumber?: string) => (a: Relative, b: Relative): number => {
        // pre-sort if any or all are terminated
        if (a.combinedStatus && b.combinedStatus) {
            const aTerminated = a.combinedStatus.isTerminated,
                bTerminated = b.combinedStatus.isTerminated;
            // terminated always sorts below non-terminated
            if (!aTerminated && bTerminated) {
                return -1;
            } else if (aTerminated && !bTerminated) {
                return 1;
            } else if (aTerminated && bTerminated) {
                // terminated sorted by most recently terminated first
                if (a.combinedStatus.terminationDate > b.combinedStatus.terminationDate) {
                    return -1;
                }
                if (a.combinedStatus.terminationDate < b.combinedStatus.terminationDate) {
                    return 1;
                }
            }
        }

        if (!!prioritizedCertificateNumber && a.certificateNumber === prioritizedCertificateNumber) {
            return -1;
        } else if (!!prioritizedCertificateNumber && b.certificateNumber === prioritizedCertificateNumber) {
            return 1;
        }

        // members always come first
        if (a.personStatus.isMember) {
            return -1;
        } else if (b.personStatus.isMember) {
            return 1;
        }

        // spouses always come second
        if (a.isSpouse && !b.isSpouse) {
            return -1;
        }
        if (!a.isSpouse && b.isSpouse) {
            return 1;
        }

        // sort oldest dependants first
        if (a.birthDate && b.birthDate) {
            const aBirthDate = new Date(a.birthDate);
            const bBirthDate = new Date(b.birthDate);

            if (isBefore(aBirthDate, bBirthDate)) {
                return -1;
            }
            if (isAfter(aBirthDate, bBirthDate)) {
                return 1;
            }
        }

        // twins are alphabetical
        if (a.name && b.name) {
            if (a.name.first > b.name.first) {
                return 1;
            }
            if (a.name.first < b.name.first) {
                return -1;
            }
        }

        return 0;
    };
}
