import * as React from "react";
import { Grid } from "@mui/material";

import { PlaceholderLine } from "modules/common/components/layout";

export class ProfilePhoneNumbersPlaceholder extends React.PureComponent<{}> {
    public render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <PlaceholderLine color="dark" thickness="thick" />
                    <PlaceholderLine color="light" thickness="thin" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <PlaceholderLine color="dark" thickness="thick" />
                    <PlaceholderLine color="light" thickness="thin" />
                </Grid>
            </Grid>
        );
    }
}
