import { FeatureToggleValue, ToggleableFeature } from "modules/features/models";
export default class FeatureToggleChecker {
    /**
     * If the featureToggle parameter is not null, then return the toggleValue property of the
     * featureToggle parameter, otherwise return false
     * This will return True if the FeatureToggleValue is On otherwise False
     * to find how pilot works go to FeatureToggleProvider class
     * @param {ToggleableFeature} [featureToggle] - ToggleableFeature
     * @returns The value of the toggleValue property of the featureToggle object if it is not null,
     * otherwise false.
     */
    static isOn(featureToggle?: ToggleableFeature): boolean {
        return featureToggle?.toggleValue === FeatureToggleValue.On;
    }
}
