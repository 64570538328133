import { NewConversationModel, NewMessageModel } from "modules/inbox/models";
import { NetworkRequest } from "./actions";
import { XhrUploadHandlers } from "api/xhrUpload";

export const getConversations = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/inbox/conversations?sortDescending=true"
    };
};

export const getConversation = (conversationId: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/inbox/conversations/${conversationId}`
    };
};

export const uploadAttachment = (
    file: File,
    xhrHandlers: XhrUploadHandlers
): NetworkRequest => {
    const body = new FormData();
    body.append("file", file);

    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/inbox/conversations/UploadAttachment",
        body: body,
        isXhr: true,
        xhrHandlers
    };
};

export const deleteUploadedAttachment = (uploadTokenId: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "DELETE",
        url: `/api/inbox/conversations/DeleteUploadedAttachment/${uploadTokenId}`
    };
};

export const submitNewConversation = (newConversation: NewConversationModel): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/inbox/conversations/start",
        body: newConversation
    };
};

export const getConversationMessage = (conversationId: number, messageId: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/inbox/conversations/${conversationId}/messages/${messageId}`
    };
};

export const getConversationMessages = (conversationId: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/inbox/conversations/${conversationId}/messages`
    };
};

export const submitConversationMessage = (conversationId: number, message: NewMessageModel): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/inbox/conversations/${conversationId}/messages`,
        body: message
    };
};

export const setConversationMessageViewed = (conversationId: number, messageId: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "PATCH",
        url: `/api/inbox/conversations/${conversationId}/messages/${messageId}/viewed`
    };
};

export const getCategories = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/inbox/categories"
    };
};

export const closeConversation = (conversationId: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "PATCH",
        url: `/api/inbox/conversations/${conversationId}/close`
    };
};
