import { IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import * as signalR from "@microsoft/signalr";
import * as InboxActionCreator from "modules/inbox/actionCreator";
import { IncomingConversation, IncomingMessage } from "modules/inbox/models";
import { AppInsightsLogger } from "modules/logging/appInsightsLogger";

export const signalRConnection = {
    connection: null as signalR.HubConnection,
    development: {
        log: (message: string) => {
            const environment = process.env.NODE_ENV;
            if (environment === "development") {
                console?.log(message);
            }
        }
    },
    init: (store) => {
        signalRConnection.connection = new signalR.HubConnectionBuilder()
            .withUrl("/messageHub", { transport: signalR.HttpTransportType.WebSockets })
            .withAutomaticReconnect()
            .configureLogging(
                process.env.NODE_ENV === "production" ? signalR.LogLevel.Warning : signalR.LogLevel.Information
            )
            .build();

        signalRConnection.connection.on("NotifyNewConversation", (incomingMessage: IncomingConversation) => {
            signalRConnection.development.log("Received new conversation client side");
            store.dispatch(InboxActionCreator.actionCreators.newConversationReceived(incomingMessage));
        });

        signalRConnection.connection.on("NotifyUpdatedConversation", (incomingMessage: IncomingConversation) => {
            signalRConnection.development.log("Received updated conversation client side");
            store.dispatch(InboxActionCreator.actionCreators.updatedConversationReceived(incomingMessage));
        });

        signalRConnection.connection.on("NotifyNewMessage", (incomingMessage: IncomingMessage) => {
            signalRConnection.development.log("Received new message client side");
            store.dispatch(InboxActionCreator.actionCreators.newMessageReceived(incomingMessage));
        });

        signalRConnection.connection.on("NotifyUpdatedMessage", (incomingMessage: IncomingMessage) => {
            signalRConnection.development.log("Received updated message client side");
            store.dispatch(InboxActionCreator.actionCreators.updatedMessageReceived(incomingMessage));
        });

        signalRConnection.connection.onclose((error?: Error) => {
            signalRConnection.development.log(
                "Connection closed, it should attempt to reconnect if this was unexpected."
            );
            if (!!error) {
                AppInsightsLogger.trackException({
                    exception: {
                        ...error
                    }
                } as IExceptionTelemetry);
            }
        });

        const authState = store.getState().auth;
        const isAuthenticated = authState.session && !!authState.session.userId;
        if (isAuthenticated) {
            signalRConnection.connect();
        }
    },
    connect: () => {
        if (
            signalRConnection.connection.state !== signalR.HubConnectionState.Connected &&
            signalRConnection.connection.state !== signalR.HubConnectionState.Connecting
        ) {
            signalRConnection.connection
                .start()
                .then(() => {
                    console?.log(signalRConnection.connection.state);
                })
                .catch((err) => {
                    console?.log(err);
                    AppInsightsLogger.trackException(err);
                });
        }
    },
    disconnect: () => {
        if (signalRConnection.connection) {
            signalRConnection.connection
                .stop()
                .then(() => {
                    console?.log(signalRConnection.connection.state);
                })
                .catch((err) => {
                    console?.log(err);
                    AppInsightsLogger.trackException(err);
                });
        }
    },
    isConnected: (): boolean => {
        return signalRConnection?.connection?.state === signalR.HubConnectionState.Connected;
    }
};
