import * as React from "react";

import { ValidationState } from "modules/claims/claimSubmission/models";
import { FormDropdownField, FormTextField, SelectOption, SubmitButton } from "modules/common/components/forms";
import { Address, Country } from "../models";
import { nameof } from "modules/utils";
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { ConfirmUnsavedChanges } from "modules/common/components/forms/confirmUnsavedChanges";

export interface AddressFormProps {
    profileAddress: Address;
    countries: Country[];
    isSaving: boolean;
    onSaveAddressRequested: (address: Address) => any;
    onCancel: () => any;
}

export interface AddressFormState {
    formAddress: Address;
    hasAttemptedToSubmit: boolean;
    validationState: ValidationState;
    showCancelConfirmation: boolean;
    isFormDirty: boolean;
}

export class AddressForm extends React.PureComponent<AddressFormProps, AddressFormState> {
    public state: AddressFormState = {
        formAddress: this.props.profileAddress,
        hasAttemptedToSubmit: false,
        validationState: {} as ValidationState,
        showCancelConfirmation: false,
        isFormDirty: false
    };

    public componentDidUpdate(prevProps: AddressFormProps) {
        if (this.props.profileAddress && prevProps.profileAddress !== this.props.profileAddress && this.props.profileAddress !== this.state.formAddress) {
            this.resetState();
        }
    }

    private resetState() {
        this.setState(
            s =>
                ({
                    formAddress: this.props.profileAddress,
                    hasAttemptedToSubmit: false,
                    validationState: {} as ValidationState,
                    isFormDirty: false
                } as AddressFormState)
        );
    }

    public render() {
        const hasStreetError = this.state.hasAttemptedToSubmit && !!this.state.validationState.messages[nameof<Address>("street")];
        const hasCityError = this.state.hasAttemptedToSubmit && !!this.state.validationState.messages[nameof<Address>("city")];
        const hasCountryError = this.state.hasAttemptedToSubmit && !!this.state.validationState.messages[nameof<Address>("countryCode")];
        const hasProvinceError = this.state.hasAttemptedToSubmit && !!this.state.validationState.messages[nameof<Address>("provinceCode")];
        const hasPostalCodeError = this.state.hasAttemptedToSubmit && !!this.state.validationState.messages[nameof<Address>("postalCode")];

        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={3} />
                    <Grid item xs={12} md={9}>
                        <Grid container>
                            <Grid item xs={12} sm={8} className="profile-form">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={8}>
                                        <FormTextField
                                            title="Street address"
                                            value={this.state.formAddress.street}
                                            onChanged={this.onStreetChanged}
                                            errorText={hasStreetError ? this.state.validationState.messages[nameof<Address>("street")] : null}
                                            disabled={this.props.isSaving}
                                            fullWidth
                                            id="streetAddress"
                                            className="profile-form-control"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormTextField
                                            title="Apartment, suite or unit"
                                            value={this.state.formAddress.suiteNumber ? this.state.formAddress.suiteNumber : ""}
                                            placeholder="(optional)"
                                            onChanged={this.onSuiteNumberChanged}
                                            disabled={this.props.isSaving}
                                            fullWidth
                                            id="suiteNumber"
                                            className="profile-form-control"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormTextField
                                            title="City"
                                            value={this.state.formAddress.city}
                                            onChanged={this.onCityChanged}
                                            errorText={hasCityError ? this.state.validationState.messages[nameof<Address>("city")] : null}
                                            disabled={this.props.isSaving}
                                            fullWidth
                                            id="city"
                                            className="profile-form-control"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormDropdownField
                                            title="Country"
                                            options={this.getCountriesList(this.props.countries)}
                                            value={this.state.formAddress.countryCode}
                                            onChanged={this.onCountryChanged}
                                            errorText={hasCountryError ? this.state.validationState.messages[nameof<Address>("countryCode")] : null}
                                            className="profile-form-control"
                                            disabled={this.props.isSaving}
                                            fullWidth
                                            id="countryCode"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormDropdownField
                                            title="Province/State"
                                            disabled={!this.state.formAddress.countryCode || this.props.isSaving}
                                            options={this.getProvincesList(this.state.formAddress.countryCode)}
                                            value={this.state.formAddress.provinceCode}
                                            onChanged={this.onProvinceChanged}
                                            errorText={hasProvinceError ? this.state.validationState.messages[nameof<Address>("provinceCode")] : null}
                                            className="profile-form-control"
                                            fullWidth
                                            id="provinceCode"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormTextField
                                            title={"Postal/ZIP Code"}
                                            value={this.state.formAddress.postalCode}
                                            onChanged={this.onPostalCodeChanged}
                                            errorText={hasPostalCodeError ? this.state.validationState.messages[nameof<Address>("postalCode")] : null}
                                            disabled={this.props.isSaving}
                                            fullWidth
                                            id="postalCode"
                                            className="profile-form-control"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="form-actions gutter-top">
                                        <SubmitButton
                                            submittingMessage="Updating"
                                            isSubmitting={this.props.isSaving}
                                            className="secondary"
                                            variant="contained"
                                            color="primary"
                                            onClick={this.onSaveAddressRequested}
                                            disabled={!this.state.isFormDirty}>
                                            Update Address
                                        </SubmitButton>
                                        <Button disabled={this.props.isSaving} onClick={this.onCancel}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={this.state.showCancelConfirmation} onClose={this.cancelCanceled}>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>Any changes you made will be lost.</DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelCanceled}>No, Keep It</Button>
                        <Button className="confirmation confirm-btn" onClick={this.cancelConfirmed}>
                            Yes, discard my changes
                        </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmUnsavedChanges isDirty={this.state.isFormDirty} />
            </>
        );
    }

    private getProvincesList = (countryCode: number): SelectOption[] => {
        if (countryCode && this.props.countries.filter(c => c.code === countryCode).length) {
            const selectedCountry = this.props.countries.filter(c => c.code === this.state.formAddress.countryCode)[0];
            return selectedCountry.provinces.map(
                p =>
                    ({
                        value: p.code,
                        text: p.fullName
                    } as SelectOption)
            );
        }
        return [];
    };
    private getCountriesList = (countries: Country[]): SelectOption[] => {
        return (countries || []).map(
            c =>
                ({
                    value: c.code,
                    text: c.fullName
                } as SelectOption)
        );
    };

    private onKeyPressIgnoreEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    private onSuiteNumberChanged = (evt: any) => {
        this.setAddressFieldValue(nameof<Address>("suiteNumber"), evt.target.value);
    };
    private onStreetChanged = (evt: any) => {
        this.setAddressFieldValue(nameof<Address>("street"), evt.target.value);
    };
    private onCityChanged = (evt: any) => {
        this.setAddressFieldValue(nameof<Address>("city"), evt.target.value);
    };
    private onPostalCodeChanged = (evt: any) => {
        this.setAddressFieldValue(nameof<Address>("postalCode"), evt.target.value);
    };
    private onCountryChanged = (evt: any) => {
        const value = isNaN(Number(evt.target.value)) ? 0 : evt.target.value;
        this.setAddressValue({
            ...this.state.formAddress,
            countryCode: value,
            country: null,
            provinceCode: 0,
            province: null
        } as Address);
    };
    private onProvinceChanged = (evt: any) => {
        const value = isNaN(Number(evt.target.value)) ? 0 : evt.target.value;
        this.setAddressFieldValue(nameof<Address>("provinceCode"), value);
    };

    private setAddressFieldValue(fieldName: string, value: any) {
        const updatedAddress = {
            ...this.state.formAddress,
            [fieldName]: value
        };
        this.setState(s => ({ ...s, formAddress: updatedAddress, isFormDirty: true }));
        this.validate(updatedAddress);
    }

    private setAddressValue(address: Address) {
        this.setState(s => ({ ...s, formAddress: address, isFormDirty: true }));
        this.validate(address);
    }

    private validate(address: Address) {
        const countryCodeCanada = 1;
        const countryCodeUnitedStates = 2;

        const validationResult = { isValid: true, messages: {} } as ValidationState;
        const requiredFieldMissingMessage = "This field is required";

        if (address) {
            if (!address.street) {
                validationResult.messages[nameof<Address>("street")] = requiredFieldMissingMessage;
            }
            if (!address.city) {
                validationResult.messages[nameof<Address>("city")] = requiredFieldMissingMessage;
            }
            if (!address.postalCode) {
                validationResult.messages[nameof<Address>("postalCode")] = requiredFieldMissingMessage;
            } else {
                const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(address.postalCode);
                const isValidPostalCode = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/i.test(address.postalCode);

                if (address.countryCode === countryCodeCanada && !isValidPostalCode) {
                    validationResult.messages[nameof<Address>("postalCode")] = "Use valid format (e.g. A1A 1A1)";
                }

                if (address.countryCode === countryCodeUnitedStates && !isValidZip) {
                    validationResult.messages[nameof<Address>("postalCode")] = "Use valid format (e.g. 12345)";
                }
            }

            if (!address.countryCode) {
                validationResult.messages[nameof<Address>("countryCode")] = requiredFieldMissingMessage;
            }

            if (!address.provinceCode) {
                validationResult.messages[nameof<Address>("provinceCode")] = requiredFieldMissingMessage;
            }

            validationResult.isValid = Object.keys(validationResult.messages).length === 0;
        } else {
            validationResult.isValid = false;
        }

        this.setState(s => ({ ...s, validationState: validationResult }));
    }

    private onSaveAddressRequested = () => {
        this.setState(s => ({ ...s, hasAttemptedToSubmit: true }));

        this.validate(this.state.formAddress);

        if (this.state.validationState.isValid) {
            this.props.onSaveAddressRequested(this.state.formAddress);
        }
    };
    private onCancel = () => {
        if (this.state.isFormDirty) {
            this.setState(s => ({ ...s, showCancelConfirmation: true }));
        } else {
            this.cancelConfirmed();
        }
    };

    private cancelConfirmed = () => {
        this.resetState();
        this.props.onCancel();

        this.setState(s => ({ ...s, showCancelConfirmation: false }));
    };

    private cancelCanceled = () => {
        this.setState(s => ({ ...s, showCancelConfirmation: false }));
    };
}
