import { combineReducers } from "redux";

import { reducer as addressReducer } from "modules/profile/address/reducer";
import { reducer as bankAccountReducer } from "modules/profile/bankAccount/reducer";
import { reducer as emailReducer } from "modules/profile/email/reducer";
import { reducer as phoneNumbersReducer } from "modules/profile/phoneNumbers/reducer";
import { ProfileState } from "./models";

import { reducer as passwordReducer } from "./password/reducer";
import { reducer as registrationReducer } from "./registration/reducer";
import { reducer as verificationReducer } from "./verification/reducer";
import { personalInfoVerificationReducer } from "./personalInfoVerification/reducer";

export const reducer = combineReducers<ProfileState>({
    registration: registrationReducer,
    addressInfo: addressReducer,
    phoneNumbersState: phoneNumbersReducer,
    verification: verificationReducer,
    forgotPassword: passwordReducer,
    resetPassword: passwordReducer,
    profilePassword: passwordReducer,
    bankAccountState: bankAccountReducer,
    emailState: emailReducer,
    personalInfoVerification: personalInfoVerificationReducer,
});
