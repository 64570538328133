import * as React from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { ApplicationState } from "store";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import InboxBadge from "../common/inboxBadge";
import { AppInsightsLogger } from "modules/logging/appInsightsLogger";
import { GoogleAnalyticsLogger } from "modules/logging/googleAnalyticsLogger";
import { LinkButton } from "modules/common/components/forms";
import { FeatureToggledElement } from "modules/common/components/misc/featureToggledElement";
import { KnownFeatureToggles } from "modules/features/models";

interface InboxButtonProps {
    renderWithIcon?: boolean;
}

function InboxButton({ renderWithIcon }: InboxButtonProps) {
    const isHeadOffice = useAppSelector((state: ApplicationState) => state?.person?.person?.personStatus?.isHeadOffice);

    const handleClick = () => {
        const area = "Inbox";
        const event = "ViewInbox";
        const action = `FeatureUsage-${area}-${event}-Clicked`;

        AppInsightsLogger.trackEvent({ name: action }, { name: event, area: area, action: action });
        GoogleAnalyticsLogger.trackEvent(area, event, action);
    };

    return (
        !isHeadOffice && (
        <FeatureToggledElement knownFeatureToggle={KnownFeatureToggles.EnableMessaging}>
            <div className={`${renderWithIcon ? "with-icon" : ""}`}>
                    <LinkButton to={"/Inbox"} onClick={handleClick}>
                        {renderWithIcon && (
                            <div className="icon-container">
                                <InboxBadge>
                                    <InboxOutlinedIcon />
                                </InboxBadge>
                            </div>
                        )}
                        Inbox
                    </LinkButton>
            </div>
            </FeatureToggledElement>
        )
    );
}

export default InboxButton;
