import {
    useBaseAxiosDeleteRequest,
    useBaseAxiosGetRequest,
    useBaseAxiosPostFormRequest,
    useBaseAxiosPostRequest,
    useBaseAxiosPutRequest
} from "hooks/axiosHooks";
import { ClaimAttachmentCategory } from "./enums";
import {
    OnlineClaimSubmissionRequestModel,
    OnlineClaimSubmissionResponseModel,
    OnlineClaimSubmissionAttachment,
    OnlineClaimSubmittedResponseModel
} from "./onlineClaims.models";
import { Log } from "modules/logging";
import { getSecondsSinceDate } from "modules/utils";

const controllerEndpoint = "/api/onlineClaims/v5";

export const useGetOnlineClaimRequest = (onlineClaimSubmissionId: number) =>
    useBaseAxiosGetRequest(`${controllerEndpoint}/${onlineClaimSubmissionId}`);

export const useCreateOnlineClaimDraftRequest = () =>
    useBaseAxiosPostRequest<OnlineClaimSubmissionRequestModel, OnlineClaimSubmissionResponseModel>(controllerEndpoint, {
        onRequestCompleted: (result) => {
            if (result.isSuccess) {
                if (
                    Boolean(result.response.data.validationResult) &&
                    Boolean(result.response.data.validationResult.errors)
                ) {
                    Log.createDraftClaimFailed("Failed validation");
                } else {
                    Log.createDraftClaimSucceeded();
                }
            } else {
                Log.createDraftClaimFailed("Error");
            }
        }
    });

export const useSubmitOnlineClaimRequest = (draftCreatedDate: Date, numberOfDuplicatesFound: number) =>
    useBaseAxiosPutRequest<OnlineClaimSubmissionRequestModel, OnlineClaimSubmittedResponseModel>(controllerEndpoint, {
        onRequestCompleted: (result, input) => {
            if (result.isSuccess) {
                const secondsToComplete = getSecondsSinceDate(draftCreatedDate);
                Log.submitNewClaimSucceeded(secondsToComplete, numberOfDuplicatesFound);
            } else {
                Log.submitNewClaimFailed();
            }
        }
    });

export const useAddOnlineClaimAttachmentRequest = () =>
    useBaseAxiosPostFormRequest<
        { claimSubmissionId: number; attachmentCategory: ClaimAttachmentCategory; file: File },
        OnlineClaimSubmissionAttachment
    >(
        (params) =>
            `${controllerEndpoint}/${params.claimSubmissionId}/attachments?attachmentCategory=${params.attachmentCategory}`,
        {
            onRequestCompleted: (result) => {
                if (result.isSuccess) {
                    Log.addClaimAttachmentSucceeded();
                } else {
                    Log.addClaimAttachmentFailed(result.axiosError?.message);
                }
            }
        }
    );

export const useDeleteOnlineClaimAttachmentRequest = () =>
    useBaseAxiosDeleteRequest<string>(
        (attachmentReferenceId) => `${controllerEndpoint}/attachments/${attachmentReferenceId}`,
        {
            onRequestCompleted: (result) => {
                if (result.isSuccess) {
                    Log.deleteClaimAttachmentSucceeded();
                } else {
                    Log.deleteClaimAttachmentFailed(result.axiosError?.message);
                }
            }
        }
    );
