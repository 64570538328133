import * as React from "react";
import { Formatter } from "modules/utils";

export interface CompactDateRangeProps {
    startDate: Date | string;
    endDate: Date | string;
}

export const CompactDateRange = (props: React.PropsWithChildren<CompactDateRangeProps>) => {
    const { startDate, endDate, children } = props;
    const startDateObj = Formatter.convertToDateObject(startDate);
    const endDateObj = Formatter.convertToDateObject(endDate);

    return (
        <div className="compact-date-range-wrapper">
            <div id="date-range" className="compact-date-range">
                <span className="start-date">
                    <span className="year">{startDateObj.getFullYear()}</span>
                    <span className="date">{Formatter.formatLocalDate(startDateObj, false)}</span>
                </span>
                <span className="spacer">-</span>
                <span className="end-date">
                    <span className="year">{endDateObj.getFullYear()}</span>
                    <span className="date">{Formatter.formatLocalDate(endDateObj, false)}</span>
                </span>
            </div>
            {children}
        </div>
    );
};
