import * as React from "react";
import { Grid, Card, CardContent, Typography, CardActions, Button } from "@mui/material";

import { PlaceholderLine } from "modules/common/components/layout";

export class FeatureArticleListPlaceholder extends React.PureComponent {
    public render() {
        return (
            <div className="stretched-content">
                <Grid container spacing={0} className="whats-new-list">
                    {[0, 1, 2].map(i => {
                        return (
                            <Grid key={i} item xs={12}>
                                <Card className="whats-new-list-item">
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            <PlaceholderLine />
                                        </Typography>
                                        <PlaceholderLine />
                                        <PlaceholderLine />
                                        <PlaceholderLine width="50%" />
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            <PlaceholderLine />
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}
