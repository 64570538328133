import { Reducer } from "redux";

import { HandledAction } from "./actionCreator";
import { DrugDetailState, DrugListState, DrugsState } from "./models";

export const reducer: Reducer<DrugsState> = (state: DrugsState, action: HandledAction) => {
    switch (action.type) {
        case "SEARCH_DRUGS_INIT": {
            return {
                ...state,
                list: {
                    ...state.list,
                    name: action.name,
                    isFetching: true
                }
            };
        }
        case "SEARCH_DRUGS_COMPLETE": {
            if (action.name === state.list.name) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        isFetching: false,
                        drugNames: action.matches
                    }
                };
            }
            return state;
        }
        case "GET_DRUG_DETAILS_INIT": {
            return {
                ...state,
                detail: {
                    ...state.detail,
                    name: action.name,
                    isFetching: true,
                    drug: null
                }
            };
        }
        case "GET_DRUG_DETAILS_COMPLETE": {
            if (state.detail.name === action.name) {
                return {
                    ...state,
                    detail: {
                        ...state.detail,
                        isFetching: false,
                        drug: action.drug
                    }
                };
            }
            return state;
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            list: {
                name: "",
                isFetching: false,
                drugNames: null
            } as DrugListState,
            detail: {
                isFetching: false,
                name: null,
                drug: null
            } as DrugDetailState
        } as DrugsState)
    );
};
