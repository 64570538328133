import { Reducer } from "redux";
import { KnownAction } from "./actionCreator";
import { PasswordState } from "./models";

function CreateEmptyPasswordState(): PasswordState {
    return {
        isFetching: false,
        isCheckingToken: false,
        isTokenValid: false,
        isSaving: false,
        isLastUpdateFailed: false
    } as PasswordState;
}

export const reducer: Reducer<PasswordState> = (state: PasswordState, action: KnownAction) => {
    switch (action.type) {
        case "FORGOT_PASSWORD_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "FORGOT_PASSWORD_COMPLETE": {
            return {
                ...state,
                isFetching: false
            };
        }
        case "RESET_PASSWORD_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "RESET_PASSWORD_COMPLETE": {
            return {
                ...state,
                isFetching: false
            };
        }
        case "CHECK_RESET_TOKEN_INIT": {
            return {
                ...state,
                isCheckingToken: true,
                isTokenValid: false
            };
        }
        case "CHECK_RESET_TOKEN_COMPLETE": {
            return {
                ...state,
                isCheckingToken: false,
                isTokenValid: action.isValid
            };
        }
        case "UPDATE_PASSWORD_INIT": {
            return {
                ...state,
                isSaving: true,
                isLastUpdateFailed: false
            };
        }
        case "UPDATE_PASSWORD_COMPLETE": {
            return {
                ...state,
                isSaving: false,
                isLastUpdateFailed: action.isLastUpdateFailed
            };
        }

        case "LOG_DEEP_LINK": {
            return {
                ...state
            };
        }

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return state || CreateEmptyPasswordState();
};
