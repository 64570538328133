import * as React from "react";

export interface PageContentProps {
  className?: string;
  noPadding?: boolean;
  noMargin?: boolean;
  noBackground?: boolean;
  noRadiusTop?: boolean;
  noMinHeight?: boolean;
  withOutageMessage?: boolean;
}

export const PageContent = (props: React.PropsWithChildren<PageContentProps>) => {
  const {
    className,
    noPadding,
    noMargin,
    noBackground,
    noRadiusTop,
    noMinHeight,
    withOutageMessage,
  } = props;

  return (
    <div
      className={`page-content ${className ? className : ""} ${noPadding ? "no-padding" : ""} ${noMargin ? "no-margin" : ""} ${noRadiusTop ? "no-radius-top" : ""} ${noBackground ? "no-bg" : ""} ${
        noMinHeight ? "no-min-height" : ""
      } ${withOutageMessage ? "page-content-with-outage-message" : ""}`}
    >
      {props.children}
    </div>
  );
};
