import { ApplicationInsights, IExceptionTelemetry, IEventTelemetry } from "@microsoft/applicationinsights-web";
import { ICustomProperties } from "@microsoft/applicationinsights-core-js";

export interface IAppInsightsLoggerConfig {
    instrumentationKey: string;
    authenticatedUserId: string | null;
}

interface IAppInsightsLogger {
    enabled: boolean;
    appInsights: ApplicationInsights | null;
    lastPageViewUrl: string | null;
    init: (config: IAppInsightsLoggerConfig) => any;
    setAuthenticatedUserContext: (username: string | null) => any;
    trackPageView: () => any;
    trackEvent: (event: IEventTelemetry, customProperties?: ICustomProperties) => any;
    trackTrace: (name: string, properties?: { [name: string]: string }) => any;
    trackException: (exception: IExceptionTelemetry) => any;
}

export const AppInsightsLogger = {
    enabled: false,
    appInsights: null,
    lastPageViewUrl: null,
    init: (config: IAppInsightsLoggerConfig) => {
        if (typeof window !== "undefined") {
            if ((window as any).appInsights) {
                AppInsightsLogger.appInsights = (window as any).appInsights;
            } else {
                AppInsightsLogger.appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey: config.instrumentationKey
                        /* ...Other Configuration Options... */
                    }
                });
                AppInsightsLogger.appInsights.loadAppInsights();
            }

            AppInsightsLogger.enabled = true;

            if (config.authenticatedUserId) {
                AppInsightsLogger.setAuthenticatedUserContext(config.authenticatedUserId);
            }
            AppInsightsLogger.trackPageView();
        }
    },
    setAuthenticatedUserContext: (username: string | null) => {
        if (AppInsightsLogger.enabled) {
            if (username) {
                AppInsightsLogger.appInsights!.setAuthenticatedUserContext(username);
            } else {
                AppInsightsLogger.appInsights!.clearAuthenticatedUserContext();
            }
        }
    },
    trackPageView: () => {
        // title is updated asynchronously so it may not be accurate
        // when a track is triggered
        if (AppInsightsLogger.enabled) {
            const url = window.location.pathname + window.location.search;
            if (url !== AppInsightsLogger.lastPageViewUrl) {
                AppInsightsLogger.lastPageViewUrl = url;

                AppInsightsLogger.appInsights!.trackPageView({
                    name: window.location.pathname,
                    uri: url
                });
            }
        }
    },
    trackEvent: (event: IEventTelemetry, customProperties?: ICustomProperties) => {
        if (AppInsightsLogger.enabled) {
            AppInsightsLogger.appInsights!.trackEvent(event, customProperties);
        }
    },
    trackException: (exception: IExceptionTelemetry) => {
        if (AppInsightsLogger.enabled) {
            AppInsightsLogger.appInsights!.trackException(exception);
        }
    }
} as IAppInsightsLogger;
