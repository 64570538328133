export interface DetailedDentalGuideTopicDict {
    [topicId: number]: DetailedDentalGuideTopic;
}

export interface DentalGuideState {
    isFetchingSummary: boolean;
    isFetchingDetails: boolean;
    isFetchingProcedure: boolean;
    selectedTopicId: string;
    selectedProcedureCode: string;
    dentalGuideTopics: DentalGuideTopicSummary[];
    dentalGuideTopicDetails: DetailedDentalGuideTopicDict;
    dentalProcedureDetails: DentalGuideProcedureDetails;
    errorMessage: string;
    failedToFetch: boolean;
}

export interface DentalGuideTopicSummary {
    id: number;
    endDate: Date | string;
    startDate: Date | string;
    text: string;
    title: string;
}

export interface DetailedDentalGuideTopic {
    id: number;
    title: string;
    text: string;
    serviceType: string;
    planMaximum: string;
    topicDetails: DentalGuideSubTopicDetails[];
}

export interface DentalGuideSubTopicDetails {
    detailTitle: string;
    detailText: string;
    displayOrder?: number;
    coverageStatus: DentalCoverageStatusString;
    coverageRateCost: string;
    exceptionRule: string;
    procedureCodes: DentalGuideProcedureSummary[];
}

export interface DentalGuideProcedureSummary {
    productExternalId: string;
    description: string;
    cost?: number;
    specialtyCost?: number;
}

export interface DentalGuideProcedureDetails {
    productExternalId: string;

    description: string;
    alternateDescription: string;
    coverageStatus: DentalCoverageStatusString;
    coverageRateCost: string;
    exceptionRule: string;
    serviceType: string;
    planMaximum: string;

    cost?: number;
    specialtyCost?: number;

    sameProductGroupMessageWithOccurrenceMonths: string;

    parentTopics: DentalGuideTopicSummary[];
    codesInSameProductGroup: DentalGuideProcedureDetails[];
}

export type DentalCoverageStatusString = "Covered" | "Not Covered" | "Alternative Procedure Available";

export enum DentalPlan {
    Plan1 = "1",
    Plan2 = "2",
    Plan3 = "3",
    Plan4 = "4"
}
