import * as React from "react";
import { connect } from "react-redux";

import { PersonDetails } from "modules/person/models";
import { PersonActionCreators } from "modules/person/actionCreator";
import { ApplicationState } from "store";
import { CommonActionCreators } from "modules/common/actionCreator";

class App extends React.Component<ComponentProps> {
    public componentDidMount() {
        // If the user is already authenticated but the person details are not populated
        // (user likely reloaded the page for some reason),
        // we will fetch the person details on app start
        if (typeof window !== "undefined") {
            if (this.props.isLoggedIn) {
                this.props.trackIsUserEmulated(this.props.isEmulated);

                if (!this.props.person) {
                    this.props.getPerson(this.props.certificateNumber);
                }
            }

            // This is set to help the responsive dialog
            // effectively fill the full window height
            document.body.style.minHeight = window.innerHeight + "px";
        }
    }

    public render() {
        return <div className="app">{this.props.children}</div>;
    }
}

interface IStateToProps {
    isLoggedIn: boolean;
    certificateNumber: string;
    person: PersonDetails;
    isEmulated: boolean;
}

interface IDispatchToProps {
    getPerson: (userId: string) => any;
    trackIsUserEmulated: (isEmulated: boolean) => any;
}

type ComponentProps = IStateToProps & IDispatchToProps & {
    children?: React.ReactNode;
};

export default connect(
    (state: ApplicationState) => {
        return {
            isLoggedIn: !!state.auth.session.userId,
            isEmulated: state.auth.session.emulatedSession,
            certificateNumber: state.auth.session.userId,
            person: state.person.person
        };
    },
    {
        getPerson: PersonActionCreators.getPerson,
        trackIsUserEmulated: CommonActionCreators.trackLoggedInUserIsEmulated
    }
)(App);
