import * as React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Button } from "@mui/material";
import { QuestionIcon } from "modules/common/components/icons";

export interface ContactButtonProps {
    renderWithIcon?: boolean;
    referrerUrl: string;
}

export class ContactButton extends React.Component<ContactButtonProps> {
    public render() {
        const { renderWithIcon } = this.props;

        const linkToContactPage = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
            return <RouterLink {...props} innerRef={ref as any} />;
        });

        return (
            <>
                <div className={`${renderWithIcon ? "with-icon" : ""}`}>
                    <Button component={linkToContactPage} to={"/contact?mode=question&referrerUrl=" + (this.props.referrerUrl ? this.props.referrerUrl : "")}>
                        {renderWithIcon ? (
                            <div className="icon-container">
                                <QuestionIcon />
                            </div>
                        ) : null}
                        Contact
                    </Button>
                </div>
            </>
        );
    }
}
