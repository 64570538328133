import * as React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Box, Tab, Tabs, TabProps } from "@mui/material";
import { MyAsebpIcon } from "modules/common/components/icons";
import { ContactButton } from "modules/common/components/misc";
import { FeatureHighlightButton } from "modules/features/components";
import { FeatureToggles, FeatureArticle, KnownFeatureToggles } from "modules/features/models";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";
import { routeProvider } from "routeProvider";
import { NavDrawer } from "./NavDrawer";
import { NavDrawerToggle } from "./NavDrawerToggle";
import { ProfileAvatar } from "./ProfileAvatar";
import { SearchBait, SearchDialog } from "modules/search/components";
import InboxButton from "modules/inbox/components/conversations/inboxButton";

export interface HeaderContentProps {
    isAuthenticated: boolean;
    isEmulated: boolean;
    coveredMember: CoveredMemberSummary;
    featureToggles: FeatureToggles;
    pathname: string;
    featureArticles: FeatureArticle[];
    hasUnreadFeatureArticles: boolean;
    searchText: string;
    showMembershipCard: () => any;
    updateLastViewedFeatureArticles: () => any;
    trackNavDrawer: () => any;
}

export interface HeaderContentState {
    isDrawerOpen: boolean;
    isSearchOpen: boolean;
}

export class HeaderContent extends React.Component<HeaderContentProps, HeaderContentState> {
    public state: HeaderContentState = { isDrawerOpen: false, isSearchOpen: false };

    public render() {
        const { featureToggles, featureArticles, hasUnreadFeatureArticles, coveredMember, searchText } = this.props;
        const routeDefinition = routeProvider(coveredMember); // only pass null for now because top level nav is not filtered by context

        const selectedTab = routeDefinition.areas
            .filter((a) => a.showInMainNav)
            .find((a) => this.props.pathname.indexOf(a.areaPath) >= 0);
        const selectedValue = selectedTab ? selectedTab.areaPath : false;

        const enableSettingsPage = coveredMember.canAccessFeature(KnownFeatureToggles.SettingsPage);

        return (
            <div className={`header ${this.props.isEmulated ? "emulating" : ""}`}>
                {this.props.isAuthenticated ? (
                    <>
                        <div className="bg">
                            <div className="top" />
                            <div className={`bottom secondary-color`} />
                            <SearchDialog
                                coveredMember={coveredMember}
                                open={this.state.isSearchOpen}
                                onClose={this.onSearchClose}
                            />
                        </div>
                        <div className="inner">
                            <RouterLink to="/" className="brand-link">
                                <MyAsebpIcon />
                            </RouterLink>
                            <SearchBait onClick={this.onSearchOpen} searchText={searchText} className="search-bait" />
                            <div className="tabs">
                                <Box className="nav-links" sx={{ display: { xs: "none", md: "block" } }}>
                                    <Tabs value={selectedValue} indicatorColor="primary">
                                        {routeDefinition.areas
                                            .filter((a) => a.showInMainNav)
                                            .map((a) => {
                                                const link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
                                                    (props, ref) => {
                                                        return (
                                                            <RouterLink
                                                                id={a.name.toLowerCase() + "-nav-link"}
                                                                {...props}
                                                                innerRef={ref as any}
                                                            />
                                                        );
                                                    }
                                                );
                                                return (
                                                    <Tab
                                                        key={a.rootPath}
                                                        label={a.name}
                                                        component={link as any}
                                                        to={a.rootPath}
                                                        value={a.areaPath}
                                                        sx={{
                                                            fontSize: "22px",
                                                            textTransform: "none",
                                                            minWidth: 72,
                                                            maxWidth: 264
                                                        }}
                                                        selected={
                                                            a.areaPath && this.props.pathname.indexOf(a.areaPath) >= 0
                                                        }
                                                    />
                                                );
                                            })}
                                    </Tabs>
                                </Box>
                            </div>
                            <Box sx={{ display: { xs: "none", md: "block" } }}>
                                <Box className="menu-buttons">
                                    <InboxButton renderWithIcon />
                                    <ContactButton
                                        renderWithIcon
                                        referrerUrl={this.props.pathname ? this.props.pathname : ""}
                                    />
                                    <FeatureHighlightButton
                                        renderWithIcon 
                                        articles={featureArticles}
                                        featureToggles={featureToggles}
                                        hasUnreadFeatureArticles={hasUnreadFeatureArticles}
                                        updateLastViewed={this.props.updateLastViewedFeatureArticles}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: { xs: "none", md: "block" } }}>
                                <ProfileAvatar
                                    person={coveredMember.person}
                                    certificateNumber={coveredMember.certificateNumber}
                                    showMembershipCard={this.props.showMembershipCard}
                                    hasActiveCoverage={coveredMember.hasActiveCoverage}
                                    hasT4ASlips={coveredMember.hasT4ASlips}
                                    enableSettingsPage={enableSettingsPage}
                                />
                            </Box>
                            {this.props.isEmulated ? (
                                <div className="emulate-badge">
                                    <i className="material-icons md-24">supervised_user_circle</i>
                                    <span>Emulating</span>
                                </div>
                            ) : null}
                        </div>
                        <div className="drawer-toggle">
                            <Box sx={{ display: { xs: "block", md: "none" } }}>
                                <NavDrawerToggle
                                    hasUnreadFeatureArticles={hasUnreadFeatureArticles}
                                    toggleClicked={this.toggleDrawerWithAnalytics}
                                />
                            </Box>
                        </div>
                        <div className="drawer">
                            <Box sx={{ display: { xs: "block", md: "none" } }}>
                                <NavDrawer
                                    toggleClicked={this.toggleDrawer}
                                    showMembershipCard={this.showMembershipCard}
                                    isOpen={this.state.isDrawerOpen}
                                    coveredMember={coveredMember}
                                    hasUnreadFeatureArticles={hasUnreadFeatureArticles}
                                    pathname={this.props.pathname}
                                />
                            </Box>
                        </div>
                    </>
                ) : null}
            </div>
        );
    }

    private toggleDrawer = () => {
        this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
    };

    private toggleDrawerWithAnalytics = () => {
        this.props.trackNavDrawer();
        this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
    };

    private showMembershipCard = () => {
        this.toggleDrawer();
        this.props.showMembershipCard();
    };

    private onSearchOpen = () => {
        this.setState((s) => ({ ...s, isSearchOpen: true }));
    };

    private onSearchClose = () => {
        this.setState((s) => ({ ...s, isSearchOpen: false }));
    };
}
