import { NetworkRequest } from "./actions";
import { Feedback } from "modules/contact/models";

export const post = (feedback: Feedback): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: "/api/feedback",
        body: feedback
    };
};
