import * as React from "react";
import PropTypes from "prop-types";

export interface PanelProps {
    children: React.ReactNode;
}

export const Panel = (props: PanelProps) => {
    return <div className="panel">{props.children}</div>;
};

Panel.propTypes = {
    children: PropTypes.node.isRequired
};

