import API from "api";
import { NetworkRequest } from "api/actions";
import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import { UpdatePersonPhoneNumbersAction } from "modules/person/actions";
import { ApplicationState as AppState, AppThunkAction } from "store";
import * as actions from "./actions";
import { ProfilePhoneNumbers } from "./models";
import personalInfoVerificationActionCreators, { PersonalInfoVerificationActions } from "modules/profile/personalInfoVerification/actionCreator";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";

export type HandledAction = actions.GetPhoneNumbersInitAction | actions.GetPhoneNumbersCompleteAction | actions.SavePhoneNumbersInitAction | actions.SavePhoneNumbersCompleteAction;
export type KnownAction = HandledAction | NetworkRequest | AddNotificationAction | UpdatePersonPhoneNumbersAction | PersonalInfoVerificationActions;

export const actionCreators = {
    getPhoneNumbers: () => (dispatch, getState: () => AppState) => {
        const state = getState();

        if (!state.profile.phoneNumbersState || !state.profile.phoneNumbersState.profilePhoneNumbers) {
            dispatch({ type: "GET_PHONE_NUMBERS_INIT" });

            return dispatch(API.profile.getPhoneNumbers())
                .then(
                    response => {
                        if (response?.status === 200) {
                            return response.json() as Promise<ProfilePhoneNumbers>;
                        } else {
                            dispatch({ type: "GET_PHONE_NUMBERS_COMPLETE", countries: null, succeeded: false, err: "Failed to get profile phone numbers" });
                            return null;
                        }
                    },
                    (reason: any) => {
                        return null;
                    }
                )
                .then(
                    (phoneNumbers: ProfilePhoneNumbers) => {
                        if (phoneNumbers) {
                            dispatch({ type: "GET_PHONE_NUMBERS_COMPLETE", phoneNumbers, succeeded: true });

                            return phoneNumbers;
                        }
                    },
                    () => null
                );
        } else {
            return Promise.resolve(state.profile.phoneNumbersState.profilePhoneNumbers);
        }
    },
    savePhoneNumbers: (phoneNumbers: ProfilePhoneNumbers): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const task = Promise.resolve()
            .then(() => dispatch({ type: "SAVE_PHONE_NUMBERS_INIT" }))
            .then(() => {
                return dispatch(API.profile.savePhoneNumbers(phoneNumbers))
                    .then(
                        response => {
                            if (response?.status === 200) {
                                return response.json() as Promise<ProfilePhoneNumbers>;
                            } else {
                                const message = "Oops! Something went wrong. Please try again.";
                                dispatch({ type: "ADD_NOTIFICATION", message, style: NotificationStyle.Error, autohide: true, canDismiss: true });
                                dispatch({ type: "SAVE_PHONE_NUMBERS_COMPLETE", phoneNumbers: null, succeeded: false, err: message });
                            }
                        },
                        (reason: any) => {
                            return null;
                        }
                    )
                    .then((newPhoneNumbers: ProfilePhoneNumbers) => {
                        if (newPhoneNumbers) {
                            dispatch({ type: "ADD_NOTIFICATION", message: "Phone number(s) updated.", style: NotificationStyle.Success, autohide: true, canDismiss: true });
                            dispatch({ type: "SAVE_PHONE_NUMBERS_COMPLETE", phoneNumbers: newPhoneNumbers, succeeded: true, err: null });
                            dispatch({ type: "UPDATE_PERSON_PHONE_NUMBERS", profilePhoneNumbers: newPhoneNumbers });

                            const personalInfoVerificationToggle = getState().features.featureToggles.PersonalInformationVerification;
                            if (FeatureToggleChecker.isOn(personalInfoVerificationToggle)) {
                                dispatch(personalInfoVerificationActionCreators.personalInfoVerified());
                            }

                            return true;
                        }
                        return false;
                    });
            });
        return task;
    }
};
