import { Reducer } from "redux";
import { HandledAction } from "./actionCreator";
import { ClaimDetailsState } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<ClaimDetailsState> = (state: ClaimDetailsState, action: KnownAction) => {
    switch (action.type) {
        case "GET_CLAIM_DETAILS_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_CLAIM_DETAILS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetching: false,
                    claimDetails: null,
                    errorMessage: action.err,
                    validationMessage: null
                };
            }

            return {
                ...state,
                isFetching: false,
                claimDetails: action.claimDetails,
                errorMessage: null,
                validationMessage: null
            };
        }
        case "DELETE_CLAIM_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "DELETE_CLAIM_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                errorMessage: action.err
            };
        }
        case "DELETE_CLAIM_DETAILS_ATTACHMENT_INIT": {
            const newClaimSubmissions = state.claimDetails.claimSubmissions.map(claimSubmission => {
                return {
                    ...claimSubmission,
                    attachments: claimSubmission.attachments.map(attachment => {
                        if (attachment.referenceId === action.attachmentReferenceId) {
                            return {
                                ...attachment,
                                isFetching: true
                            };
                        }
                        return attachment;
                    })
                };
            });

            return {
                ...state,
                claimDetails: {
                    ...state.claimDetails,
                    claimSubmissions: newClaimSubmissions
                }
            };
        }
        case "DELETE_CLAIM_DETAILS_ATTACHMENT_COMPLETE": {
            if (action.succeeded) {
                const newClaimSubmissions = state.claimDetails.claimSubmissions.map(claimSubmission => {
                    return {
                        ...claimSubmission,
                        attachments: claimSubmission.attachments.filter(attachment => attachment.referenceId !== action.attachmentReferenceId)
                    };
                });

                return {
                    ...state,
                    claimDetails: {
                        ...state.claimDetails,
                        claimSubmissions: newClaimSubmissions
                    }
                };
            } else {
                const newClaimSubmissions = state.claimDetails.claimSubmissions.map(claimSubmission => {
                    return {
                        ...claimSubmission,
                        attachments: claimSubmission.attachments.map(attachment => {
                            if (attachment.referenceId === action.attachmentReferenceId) {
                                return {
                                    ...attachment,
                                    isFetching: false
                                };
                            }
                            return attachment;
                        })
                    };
                });

                return {
                    ...state,
                    claimDetails: {
                        ...state.claimDetails,
                        claimSubmissions: newClaimSubmissions
                    }
                };
            }
        }
        case "REPLACE_CLAIM_ATTACHMENT_INIT": {
            const newClaimSubmissions = state.claimDetails.claimSubmissions.map(claimSubmission => {
                return {
                    ...claimSubmission,
                    attachments: claimSubmission.attachments.map(attachment => {
                        if (attachment.referenceId === action.attachmentReferenceId) {
                            return {
                                ...attachment,
                                isFetching: true,
                                localId: action.localId,
                                fileName: action.file.name,
                                file: action.file,
                                percentUploaded: 0
                            };
                        }
                        return attachment;
                    })
                };
            });

            return {
                ...state,
                claimDetails: {
                    ...state.claimDetails,
                    claimSubmissions: newClaimSubmissions
                }
            };
        }
        case "REPLACE_CLAIM_ATTACHMENT_PROGRESS": {
            const newClaimSubmissions = state.claimDetails.claimSubmissions.map(claimSubmission => {
                return {
                    ...claimSubmission,
                    attachments: claimSubmission.attachments.map(attachment => {
                        if (attachment.localId === action.localId) {
                            return {
                                ...attachment,
                                percentUploaded: action.percentUploaded
                            };
                        }
                        return attachment;
                    })
                };
            });

            return {
                ...state,
                claimDetails: {
                    ...state.claimDetails,
                    claimSubmissions: newClaimSubmissions
                }
            };
        }
        case "REPLACE_CLAIM_ATTACHMENT_COMPLETE": {
            if (action.succeeded) {
                const newClaimSubmissions = state.claimDetails.claimSubmissions.map(claimSubmission => {
                    return {
                        ...claimSubmission,
                        attachments: claimSubmission.attachments.map(attachment => {
                            if (attachment.localId === action.localId) {
                                return {
                                    ...attachment,
                                    referenceId: action.attachmentReferenceId,
                                    createdDate: action.createdDate,
                                    percentUploaded: 100,
                                    isFetching: false,
                                    status: "Uploaded",
                                    statusReason: null
                                };
                            }
                            return attachment;
                        })
                    };
                });

                return {
                    ...state,
                    claimDetails: {
                        ...state.claimDetails,
                        claimSubmissions: newClaimSubmissions
                    }
                };
            } else {
                return { ...state };
                // TODO: handle if replacement is failed

                // return {
                //     ...state,
                //     claim: {
                //         ...state.claim,
                //         attachments: state.claim.attachments.filter(attachment => attachment.localId !== action.localId)
                //     }
                // }
            }
        }
        case "VALIDATE_CLAIM_DETAILS": {
            let isValid = true;

            state.claimDetails.claimSubmissions.forEach(claimSubmission => {
                isValid = isValid && (claimSubmission.attachments.filter(attachment => attachment.status === "Rejected") || []).length === 0;
            });

            return {
                ...state,
                validationMessage: isValid ? "" : "There are still attachments that must be replaced or removed before the claim can be submitted."
            };
        }
        case "FIX_CLAIM_INIT": {
            return {
                ...state,
                isFixing: true
            };
        }
        case "FIX_CLAIM_COMPLETE": {
            if (action.succeeded) {
                const newClaimSubmissions = state.claimDetails.claimSubmissions.map(claimSubmission => {
                    if (claimSubmission.referenceId === action.referenceId) {
                        return {
                            ...claimSubmission,
                            submittedDate: action.submittedDate,
                            isFixable: false,
                            status: action.claimStatus,
                            attachments: claimSubmission.attachments.map(attachment => {
                                if (attachment.isFixable) {
                                    return {
                                        ...attachment,
                                        isFixable: false
                                    };
                                }
                                return attachment;
                            })
                        };
                    }
                    return claimSubmission;
                });

                return {
                    ...state,
                    isFixing: false,
                    claimDetails: {
                        ...state.claimDetails,
                        claimSubmissions: newClaimSubmissions
                    }
                };
            } else {
                return {
                    ...state,
                    isFixing: false
                };
            }
        }
        case "TRANSFER_CLAIM_TO_HSA_INIT": {
            return {
                ...state,
                isTransferring: true
            };
        }
        case "TRANSFER_CLAIM_TO_HSA_COMPLETE": {
            return {
                ...state,
                isTransferring: false
            };
        }
        case "SHOW_TRANSFER_TO_HSA_DIALOG_ON_DETAILS": {
            return {
                ...state
            };
        }
        case "HIDE_TRANSFER_TO_HSA_DIALOG_ON_DETAILS": {
            return {
                ...state
            };
        }
        case "GET_TRANSFER_DUPLICATE_CLAIMS_INIT": {
            return {
                ...state,
                isFetchingPossibleDuplicateClaims: true
            };
        }
        case "GET_TRANSFER_DUPLICATE_CLAIMS_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetchingPossibleDuplicateClaims: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetchingPossibleDuplicateClaims: false,
                possibleDuplicateClaims: action.claimsResponse,
                errorMessage: null
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            isFetching: false,
            isTransferring: false,
            claimDetails: null,
            isFetchingPossibleDuplicateClaims: false
        } as ClaimDetailsState)
    );
};
