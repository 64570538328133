import { DeprecatedThemeOptions } from "@mui/material/styles";

const colors = {
    hsa: "#9C27B0",
    wsa: "#00897B"
};

export const AppTheme: DeprecatedThemeOptions = {
    typography: {
        fontSize: 14,
        htmlFontSize: 14,
        fontFamily: "Lato, sans-serif"
    },
    palette: {
        primary: {
            main: "#F47D30",
            light: "#F47D30",
            dark: "#F47D30",
            contrastText: "#FFF"
        },
        secondary: {
            main: "#002939",
            contrastText: "#FFF"
        },
        error: {
            main: "#d32f2f"
        },
        text: {
            primary: "#002131"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1600
        }
    }
};
