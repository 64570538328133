import { Reducer } from "redux";

import { HandledAction } from "./actionCreator";
import { ServiceProvidersState, GeoCoordinate } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<ServiceProvidersState> = (state: ServiceProvidersState, action: KnownAction) => {
    switch (action.type) {
        case "GET_PROVIDER_CLASSES_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_PROVIDER_CLASSES_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                classGroups: action.succeeded ? action.classGroups : state.classGroups
            };
        }
        case "GET_PROVIDERS_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_PROVIDERS_COMPLETE": {
            if (action.page && action.page.items) {
                action.page.items.forEach((stack, index) => {
                    stack.id = index.toString();
                });
            }
            return {
                ...state,
                isFetching: false,
                filterState: {
                    ...state.filterState,
                    pageNumber: action.page ? action.page.pageNumber : state.filterState.pageNumber
                },
                searchResults: action.page
            };
        }

        case "GET_INELIGIBLE_PROVIDERS_INIT":
        {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_INELIGIBLE_PROVIDERS_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                ineligibleProviders: action.page
            };
        }

        case "SET_SELECTED_PROVIDER_CLASS": {
            return {
                ...state,
                filterState: {
                    ...state.filterState,
                    selectedClassId: action.providerClassId
                }
            };
        }
        case "SET_SERVICE_PROVIDERS_FILTER": {
            return {
                ...state,
                filterState: action.filter
            };
        }
        case "SET_SELECTED_PROVIDER_PAGE_NUMBER": {
            return {
                ...state,
                filterState: {
                    ...state.filterState,
                    pageNumber: action.pageNumber
                }
            };
        }
        case "GET_HOME_ADDRESS_GEO_COORDINATE_INIT": {
            return {
                ...state,
                isGeoCoding: true
            };
        }
        case "GET_HOME_ADDRESS_GEO_COORDINATE_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isGeoCoding: false,
                    errorMessage: action.err
                };
            }
            return {
                ...state,
                isGeoCoding: false,
                homeAddressGeoCoordinate: action.homeAddressGeoCoordinate,
                errorMessage: null
            };
        }
        case "GET_SERVICE_PROVIDER_RATING_REQUEST_INIT": {
            return {
                ...state,
                isFetchingRatingRequest: true
            };
        }
        case "GET_SERVICE_PROVIDER_RATING_REQUEST_COMPLETE": {
            return {
                ...state,
                ratingRequest: action.ratingRequest,
                isFetchingRatingRequest: false
            };
        }
        case "SAVE_SERVICE_PROVIDER_RATING_RESPONSE_REQUEST_INIT": {
            return {
                ...state,
                isFetchingRatingRequest: true
            };
        }
        case "SAVE_SERVICE_PROVIDER_RATING_RESPONSE_REQUEST_COMPLETE": {
            return {
                ...state,
                isFetchingRatingRequest: false
            };
        }
        case "SAVE_SERVICE_PROVIDER_RATING_COMMUNICATION_PREFERENCES_INIT": {
            return {
                ...state,
                isProcessingUnsubscribe: true
            };
        }
        case "SAVE_SERVICE_PROVIDER_RATING_COMMUNICATION_PREFERENCES_COMPLETE": {
            return {
                ...state,
                isProcessingUnsubscribe: false,
                successfulUnsubscribeProcessed: action.succeeded ? true : state.successfulUnsubscribeProcessed
            };
        }
        case "GET_SERVICE_PROVIDER_RATING_INIT": {
            return {
                ...state,
                serviceProviderRatings: {
                    ...state.serviceProviderRatings,
                    [action.providerId]: {
                        allowedToRate: false,
                        status: null,
                        isFetching: true,
                        isSubmitting: false,
                        isProviderRecommended: null,
                        lastRatingDateTime: null,
                        editableUntilDateTime: null
                    }
                }
            };
        }
        case "GET_SERVICE_PROVIDER_RATING_COMPLETE": {
            return {
                ...state,
                serviceProviderRatings: {
                    ...state.serviceProviderRatings,
                    [action.providerId]: action.succeeded
                        ? {
                              ...action.rating,
                              isFetching: false,
                              isSubmitting: false
                          }
                        : {
                              ...state.serviceProviderRatings[action.providerId],
                              isFetching: false
                          }
                }
            };
        }
        case "SUBMIT_SERVICE_PROVIDER_RATING_INIT": {
            return {
                ...state,
                serviceProviderRatings: {
                    ...state.serviceProviderRatings,
                    [action.providerId]: {
                        ...state.serviceProviderRatings[action.providerId],
                        isSubmitting: true,
                        isProviderRecommended: null
                    }
                }
            };
        }
        case "SUBMIT_SERVICE_PROVIDER_RATING_COMPLETE": {
            return {
                ...state,
                serviceProviderRatings: {
                    ...state.serviceProviderRatings,
                    [action.providerId]: action.succeeded
                        ? {
                              ...action.rating,
                              isSubmitting: false,
                              isFetching: false
                          }
                        : {
                              ...state.serviceProviderRatings[action.providerId],
                              isSubmitting: false
                          }
                }
            };
        }
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return (
        state || {
            classGroups: null,
            filterState: {
                selectedClassId: null,
                location: {
                    latitude: null,
                    longitude: null
                } as GeoCoordinate,
                selectedProviderPracticeId: null,
                selectedServiceProviderLocationStackId: null,
                pageNumber: 1
            },
            searchResults: null,
            ineligibleProviders: null,
            isFetching: false,
            isGeoCoding: false,
            homeAddressGeoCoordinate: null,

            ratingRequest: null,
            isFetchingRatingRequest: false,

            isProcessingUnsubscribe: false,
            successfulUnsubscribeProcessed: false,
            serviceProviderRatings: {}
        }
    );
};
