import * as React from "react";

import { PlaceholderLine } from "modules/common/components/layout";

export class ProfileAddressPlaceholder extends React.PureComponent<{}> {
    public render() {
        return (
            <div>
                <div>
                    <PlaceholderLine color="dark" thickness="thick" />
                </div>
                <div>
                    <PlaceholderLine color="dark" thickness="thick" />
                </div>
                <div>
                    <PlaceholderLine color="dark" thickness="thick" />
                </div>
            </div>
        );
    }
}
