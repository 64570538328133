import React from "react";
import { Dialog, DialogContent, TextField, Grid, InputAdornment, IconButton, Icon, DialogTitle } from "@mui/material";

import { ApplicationState } from "store";
import { connect } from "react-redux";
import { SearchActionCreators } from "../actionCreator";
import { KnownFilterRules, SearchResponse } from "../models";
import debounce from "lodash/debounce";
import { SearchResultList } from "./SearchResultList";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";

interface SearchDialogState {
    searchText: string;
    workingSearchText: string;
}

interface SearchDialogProps {
    open: boolean;
    coveredMember: CoveredMemberSummary;
    onClose: () => any;
}

class SearchDialog extends React.Component<ComponentProps, SearchDialogState> {
    public state: SearchDialogState = { workingSearchText: "", searchText: "" };

    public static getDerivedStateFromProps(props: ComponentProps, state: SearchDialogState) {
        return {
            ...state,
            workingSearchText: props.searchText != state.searchText ? props.searchText : state.workingSearchText,
            searchText: props.searchText
        };
    }

    public render() {
        const { coveredMember, open, searchText, response, isFetching } = this.props;
        const { workingSearchText } = this.state;

        const filteredResults = response ? (response.results || []).filter(result => this.ApplyFilterRule(result.filterRuleKey, coveredMember)) : [];

        return (
            <Dialog fullScreen open={open} className="search-dialog" onClose={this.props.onClose} style={{ margin: 8 }} PaperProps={{ style: { borderRadius: 4, background: "#FAFAFA" } }}>
                <DialogTitle style={{ padding: 0 }}>
                    <TextField
                        className="search-text"
                        color="primary"
                        autoFocus
                        fullWidth
                        placeholder="Search"
                        value={workingSearchText}
                        onChange={this.searchTextChanged}
                        variant="outlined"
                        InputProps={{
                            style: {
                                fontSize: 32
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={this.props.onClose} size="large">
                                        <Icon style={{ fontSize: 32 }}>arrow_back</Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={this.props.onClose} size="large">
                                        <Icon style={{ fontSize: 32 }}>close</Icon>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ margin: "0 auto" }}>
                        <Grid item xs={12}>
                            {!isFetching && response && filteredResults.length ? <SearchResultList coveredMember={coveredMember} results={filteredResults} onItemSelected={this.onItemSelected} /> : null}
                        </Grid>
                        {isFetching ? (
                            <Grid item xs={12}>
                                <div className="searching-text">
                                    <span>Searching...</span>
                                </div>
                            </Grid>
                        ) : null}
                        {!isFetching && !response ? (
                            <Grid item xs={12}>
                                <div className="starter-text">
                                    <span>Looking for something? We can help!</span>
                                </div>
                            </Grid>
                        ) : null}
                        {!isFetching && response && !filteredResults.length ? (
                            <Grid item xs={12}>
                                <div className="no-results-text">
                                    <span>Sorry! No results found for &quot;{searchText}&quot;</span>
                                </div>
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    private ApplyFilterRule = (filterRuleKey: KnownFilterRules | null, coveredMemberSummary: CoveredMemberSummary): boolean => {
        if (!filterRuleKey) {
            return true;
        }
        switch (filterRuleKey) {
            case KnownFilterRules.CanSubmitClaims: {
                return coveredMemberSummary.canSubmitGeneralHealthClaims || coveredMemberSummary.canSubmitSpendingAccountClaims;
            }
            case KnownFilterRules.HasAccessToDentalGuide: {
                return coveredMemberSummary.canViewDentalGuide;
            }
            case KnownFilterRules.HasActiveDentalCoverage: {
                return coveredMemberSummary.hasActiveDentalCoverage;
            }
            case KnownFilterRules.HasActiveExtendedHealthCoverage: {
                return coveredMemberSummary.hasActiveExtendedHealthCoverage;
            }
            case KnownFilterRules.HasActiveGeneralHealthCoverage: {
                return coveredMemberSummary.hasActiveGeneralHealthCoverage;
            }
            case KnownFilterRules.HasSpendingAccount: {
                return coveredMemberSummary.allSpendingAccounts.length > 0;
            }
            default:
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = filterRuleKey;
        }
        return false;
    };

    private onItemSelected = (isExternalLink: boolean, selectedDocumentId: string, rank: number) => {
        if (!isExternalLink) {
            this.props.onClose();
        }
        this.props.trackCuratedSearchResultSelection(this.props.response.searchId, selectedDocumentId, rank);
    };

    private searchTextChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const searchText = event.target.value;
        this.setState(s => ({ ...s, workingSearchText: searchText }));

        this.debouncedExecuteSearch(searchText);
    };

    private executeSearch = searchText => {
        this.props.searchCuratedSearchResults(searchText);
    };

    private debouncedExecuteSearch = debounce(this.executeSearch, 250);
}

interface IStateToProps {
    searchText: string;
    response: SearchResponse | null;
    isFetching: boolean;
}

interface IDispatchToProps {
    searchCuratedSearchResults: (text: string) => any;
    trackCuratedSearchResultSelection: (searchId: string, selectedDocumentId: string, rank: number) => any;
}

type ComponentProps = SearchDialogProps & IStateToProps & IDispatchToProps;

export default connect(
    (state: ApplicationState, ownProps: SearchDialogProps): IStateToProps => {
        return {
            searchText: state.search.searchText,
            response: state.search.response,
            isFetching: state.search.isFetching
        };
    },
    {
        searchCuratedSearchResults: SearchActionCreators.searchCuratedSearchResults,
        trackCuratedSearchResultSelection: SearchActionCreators.trackCuratedSearchResultSelection
    }
)(SearchDialog);
