import _ from "lodash";

export type SupportedQueryParamFValueTypes = undefined | null | boolean | Date | number | string;

export const appendQueryParamForEachItem = (
    params: URLSearchParams,
    values: SupportedQueryParamFValueTypes[] | undefined | null,
    queryParamName: string
) => {
    _.forEach(values ?? [], (value) => {
        appendQueryParamIfValueNotNullOrUndefined(params, value, queryParamName);
    });
};

export const appendQueryParamIfValueNotNullOrUndefined = (
    params: URLSearchParams,
    value: SupportedQueryParamFValueTypes,
    queryParamName: string
) => {
    const paramValue =
        typeof value === "string"
            ? value
            : typeof (value as Date)?.toISOString === "function"
            ? safelyConvertDateToISOString(value as Date)
            : value?.toString();
    if (!!paramValue) {
        params.append(queryParamName, paramValue);
    }
};

const safelyConvertDateToISOString = (dateValue: Date): string | null => {
    try {
        return dateValue.toISOString();
    } catch {
        return null;
    }
};
