import { ClaimDetailsState } from "./claimDetails/models";
import { ClaimListState } from "./claimList/models";
import { ClaimEditorState } from "./claimSubmission/models";
import { BenefitType } from "hooks/api/benefitsApi";
import { ClaimAttachmentCategory } from "hooks/api/claimsApi";

export interface ClaimsState {
    claimList: ClaimListState;
    claimEditor: ClaimEditorState;
    claimDetails: ClaimDetailsState;
    spendingAccountClaimCategories: SpendingAccountClaimCategoriesState;
}

export interface SpendingAccountClaimCategoriesState {
    hsaSpendingAccountClaimCategoryList: SpendingAccountClaimCategoryListState;
    wsaSpendingAccountClaimCategoryList: SpendingAccountClaimCategoryListState;
}

export interface ClaimStatus {
    status: ClaimStatusValue;
    statusDescription: ClaimStatusDescriptionValue;
    outcome: StatusOutcome;
}

export enum ClaimStatusValue {
    Submitted = "Submitted",
    Processed = "Processed"
}

export enum ClaimStatusDescriptionValue {
    PartiallyCovered = "Partially Paid",
    Covered = "Fully Paid",
    NotCovered = "Not Covered",
    Draft = "Draft",
    UnderReview = "Under Review",
    Paid = "Fully Paid",
    PartiallyPaid = "Partially Paid",
    Eligible = "Eligible",
    NotEligible = "Ineligible",
    Reversed = "Reversed",
    Reversal = "Reversal",
    RequiresAttachmentCorrections = "Requires Correction",
    IneligibleOnlineClaim = "Ineligible Online Claim",
    ReceiptRequired = "Receipt Required",
    Unknown = "Unknown Status"
}

export enum StatusDescription {
    DirectDeposit = "Direct deposit"
}

export enum MembershipType {
    MyRetireePlan = "MyRetiree Plan",
    ASEBP = "ASEBP",
    AllPlans = "All Plans"
}

export enum StatusOutcome {
    Neutral = "Neutral",
    Positive = "Positive",
    Negative = "Negative",
    Recoverable = "Recoverable"
}

export interface SpendingAccountClaimCategoryListState {
    isFetching: boolean;
    spendingAccountCategories: SpendingAccountClaimCategoryList[];
    errorMessage?: string;
}

export interface SpendingAccountClaimCategoryList {
    spendingAccountId: number;
    succeeded: boolean;
    categories: SpendingAccountClaimCategory[];
}

export interface SpendingAccountClaimCategory {
    id: number;
    name: string;
    subCategories: SpendingAccountClaimSubCategory[];
}

export interface SpendingAccountClaimSubCategory {
    id: number;
    name: string;
    receiptMinimumAmount: number;
}

export interface LocalAttachment {
    referenceId: string;
    localId?: string;
    fileName: string;
    file?: File;
    categoryId: ClaimAttachmentCategory;
    percentUploaded: number;
    isFetching: boolean;
}

export interface ClaimAttachment extends LocalAttachment {
    categoryName: string;
    fileSize: number;
    fileType: string;
    isPurged: boolean;
    contentType: string;
    status: string;
    statusReason: string;
    createdDate: Date | string;
    isFixable: boolean;
}

export enum KnownEobMessageCategoryId {
    NotSet = 0,
    IncompleteDocumentation = 1,
    Adjustments = 2,
    PreviouslyPaid = 3,
    IneligibleProvider = 4,
    InsufficientReceipt = 5
}

export enum KnownSpendingAccountClaimCategoryId {
    None = 0,
    Dental = 1,
    Drugs = 2,
    ExtendedHealth = 3,
    MedicalServices = 4,
    MedicalSupplies = 5,
    Vision = 7,
    Paramedical = 8
}

export const getSpendingAccountClaimCategoryIdsForBenefitType = (benefitType: BenefitType) => {
    switch (benefitType) {
        case BenefitType.Dental:
            return [
                KnownSpendingAccountClaimCategoryId.Dental,
                KnownSpendingAccountClaimCategoryId.MedicalSupplies,
                KnownSpendingAccountClaimCategoryId.MedicalServices
            ];
        case BenefitType.Vision:
            return [
                KnownSpendingAccountClaimCategoryId.Vision,
                KnownSpendingAccountClaimCategoryId.MedicalSupplies,
                KnownSpendingAccountClaimCategoryId.MedicalServices
            ];
        case BenefitType.ExtendedHealth:
            return [
                KnownSpendingAccountClaimCategoryId.ExtendedHealth,
                KnownSpendingAccountClaimCategoryId.MedicalServices,
                KnownSpendingAccountClaimCategoryId.MedicalSupplies,
                KnownSpendingAccountClaimCategoryId.Drugs,
                KnownSpendingAccountClaimCategoryId.Paramedical
            ];
        default:
            return [];
    }
};