import { Reducer } from "redux";
import { KnownAction } from "./actionCreator";
import { VerificationState } from "./models";

function CreateEmptyVerificationState(): VerificationState {
    return {
        isFetching: true,
        succeeded: false
    } as VerificationState;
}

export const reducer: Reducer<VerificationState> = (state: VerificationState, action: KnownAction) => {
    switch (action.type) {
        case "VERIFY_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "VERIFY_COMPLETE": {
            return {
                ...state,
                succeeded: action.succeeded,
                verificationError: action.verificationError,
                verificationType: action.verificationType,
                isFetching: false
            };
        }

        case "LOG_DEEP_LINK": {
            return {
                ...state
            };
        }

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return state || CreateEmptyVerificationState();
};
