import { NetworkRequest } from "./actions";
import { UserActivity } from "modules/features/models";

export const getToggleableFeatures = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/toggleableFeatures`,
        params: {}
    };
};

export const getArticlePreviews = (): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/featureArticles`,
        params: {}
    };
};

export const getArticle = (id: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/featureArticles/{id}`,
        params: {
            id
        }
    };
};

export const getActivity = (key: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/userActivity/{key}`,
        params: {
            key
        }
    };
};

export const saveActivity = (activity: UserActivity): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/userActivity`,
        body: activity
    };
};
