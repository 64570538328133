export interface ProfileBankAccountsState {
    banks: Bank[];
    depositBankAccountState: BankAccountState;
    withdrawalBankAccountState: BankAccountState;
    errorMessage?: string;
    isFetching: boolean;
}
export interface BankAccountState {
    bankAccount: BankAccount;
    errorMessage?: string;
    isSaving: boolean;
    isRemoving: boolean;
    isFetching: boolean;
    isValid: boolean;
}
export interface BankAccount {
    bankName: string;
    bankInstitutionNumber: string;
    branchTransitNumber: string;
    accountNumber: string;
    accountTypeId: BankAccountType;
    confirmationPassword: string;
}

export interface BankAccountRemoval {
    bankAccountTypeId: BankAccountType;
    confirmationPassword: string;
}

export enum BankAccountType {
    Deposit = 1,
    Withdrawal = 2
}

export interface Bank {
    institutionNumber: string;
    name: string;
}
