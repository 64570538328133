import { Reducer } from "redux";
import { SpendingAccountClaimCategoriesState } from "../models";
import { HandledAction } from "./actionCreator";

export type KnownAction = HandledAction;

export const reducer: Reducer<SpendingAccountClaimCategoriesState> = (state: SpendingAccountClaimCategoriesState, action: KnownAction) => {
    switch (action.type) {
        case "GET_HSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_INIT": {
            return {
                ...state,
                hsaSpendingAccountClaimCategoryList: {
                    ...state.hsaSpendingAccountClaimCategoryList,
                    isFetching: true
                }
            };
        }
        case "GET_HSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    hsaSpendingAccountClaimCategoryList: {
                        ...state.hsaSpendingAccountClaimCategoryList,
                        isFetching: false,
                        spendingAccountCategories: null,
                        errorMessage: action.error
                    }
                };
            }
            return {
                ...state,
                hsaSpendingAccountClaimCategoryList: {
                    ...state.hsaSpendingAccountClaimCategoryList,
                    isFetching: false,
                    spendingAccountCategories: action.spendingAccountCategories,
                    errorMessage: null
                }
            };
        }
        case "GET_WSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_INIT": {
            return {
                ...state,
                wsaSpendingAccountClaimCategoryList: {
                    ...state.wsaSpendingAccountClaimCategoryList,
                    isFetching: true
                }
            };
        }
        case "GET_WSA_SPENDING_ACCOUNT_CLAIM_CATEGORIES_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    wsaSpendingAccountClaimCategoryList: {
                        ...state.wsaSpendingAccountClaimCategoryList,
                        isFetching: false,
                        spendingAccountCategories: null,
                        errorMessage: action.error
                    }
                };
            }
            return {
                ...state,
                wsaSpendingAccountClaimCategoryList: {
                    ...state.wsaSpendingAccountClaimCategoryList,
                    isFetching: false,
                    spendingAccountCategories: action.spendingAccountCategories,
                    errorMessage: null
                }
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            hsaSpendingAccountClaimCategoryList: null,
            wsaSpendingAccountClaimCategoryList: null
        } as SpendingAccountClaimCategoriesState)
    );
};
