import * as React from "react";

import { AppInsightsLogger } from "modules/logging/appInsightsLogger";

interface ErrorBoundaryState {
    hasError: boolean;
    error: any;
    errorInfo: any;
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, ErrorBoundaryState> {
    private environment: string = process.env.NODE_ENV;
    public state: ErrorBoundaryState = { hasError: false, error: null, errorInfo: null };

    public componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true, error, errorInfo });
        AppInsightsLogger.trackException({ error, properties: { stackTrace: errorInfo.componentStack } });
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary-error">
                    {this.environment === "development" ? (
                        <>
                            <div>Error: {this.state.error.message}</div>
                            <pre>{this.state.errorInfo.componentStack}</pre>
                        </>
                    ) : (
                        <div>Sorry, something went wrong.</div>
                    )}
                </div>
            );
        }
        return this.props.children;
    }
}
