import * as React from "react";
import { connect } from "react-redux";
import { Link as RouterLink, RouteComponentProps, withRouter, LinkProps as RouterLinkProps } from "react-router-dom";

import { Button, Collapse } from "@mui/material";

import { ApplicationState } from "store";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";
import { getCoveredMemberSummary } from "modules/person/coveredMemberSummary";

class GlobalActions extends React.Component<ComponentProps> {
    public render() {
        const { coveredMember, isAuthenticated, pathname } = this.props;

        const newClaimLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => <RouterLink innerRef={ref as any} {...props} />);

        const canSubmitClaims = isAuthenticated && (coveredMember.canSubmitGeneralHealthClaims || coveredMember.canSubmitSpendingAccountClaims);

        const showSubmitClaimButton = canSubmitClaims && pathname.indexOf("/claims/new") < 0 && pathname.indexOf("/resubmit") < 0 && pathname.indexOf("/providers/") < 0;

        return (
            <div className="global-actions">
                {isAuthenticated ? (
                    <div className="page-actions">
                        <div className="page-buttons">
                            <Collapse in={showSubmitClaimButton}>
                                <Button id="submit-new-claim-button" component={newClaimLink} to="/claims/new" variant="contained" color="primary" className="heavy-btn">
                                    Submit a Claim
                                </Button>
                            </Collapse>
                        </div>
                        <div className="spacer" />
                    </div>
                ) : (
                    <div className="page-actions" />
                )}
            </div>
        );
    }
}

interface IStateToProps {
    coveredMember: CoveredMemberSummary;
    isAuthenticated: boolean;
    pathname: string;
}

type ComponentProps = IStateToProps & Partial<RouteComponentProps<{}, {}, { location: { pathname: string } }>>;

export default withRouter(
    connect(
        (state: ApplicationState, ownProps: RouteComponentProps<{}, {}, { location: { pathname: string } }>) => {
            const coveredMember = getCoveredMemberSummary(state);
            return {
                coveredMember,
                isAuthenticated: !!state.auth.session.userId,
                isEmulated: state.auth.session.emulatedSession,
                pathname: ownProps.location ? ownProps.location.pathname : "",
                featureToggles: state.features.featureToggles
            };
        },
        {}
    )(GlobalActions)
);
