import { Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getCoveredMemberSummary } from "modules/person/coveredMemberSummary";
import { ProfileAddressView } from "modules/profile/address/components";
import { ProfileBankAccountView } from "modules/profile/bankAccount/components";
import { ProfileEmailView } from "modules/profile/email/components";
import { ProfilePhoneNumbersView } from "modules/profile/phoneNumbers/components";
import React, { useEffect } from "react";
import { actionCreators as PhoneNumbersActionCreator } from "modules/profile/phoneNumbers/actionCreator";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router";
import { FeaturesActionCreators } from "modules/features/actionCreator";
import { UserActivityKeys } from "modules/features/models";
import PersonalInfoVerificationActionCreators from "../actionCreator";
import { useAppSelector, useAppDispatch } from "hooks/reduxHooks";
import { ResponsiveDialog } from "modules/common/components/layout";
import { KnownFeatureToggles } from "modules/features/models";

export default function PersonalInfoVerificationDialog() {
    const coveredMember = useAppSelector((state) => getCoveredMemberSummary(state));
    const profile = useAppSelector((state) => state.profile);
    const isVerificationDue = profile.personalInfoVerification.isDue;
    const isDialogOpen = profile.personalInfoVerification.openDialog;
    const dismissedActivity = useAppSelector(
        (state) => state.features.userActivities[UserActivityKeys.DismissedPersonalInfoVerification]
    );
    const isUserSessionEmulated = useAppSelector((state) => state.auth.session.emulatedSession);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const isCoveredMemberDeceased = coveredMember.person?.deathDetails?.isDeceased ?? false;

    useEffect(() => {
        if (!isCoveredMemberDeceased) {
            dispatch(PersonalInfoVerificationActionCreators.openDialog(isVerificationDue));
        }
    }, [isCoveredMemberDeceased, isVerificationDue, dispatch]);

    useEffect(() => {
        if (
            isVerificationDue &&
            !profile.phoneNumbersState.profilePhoneNumbers &&
            !profile.phoneNumbersState.isFetching
        ) {
            dispatch(PhoneNumbersActionCreator.getPhoneNumbers());
        }
    }, [
        isVerificationDue,
        profile.phoneNumbersState.profilePhoneNumbers,
        profile.phoneNumbersState.isFetching,
        dispatch
    ]);

    useEffect(() => {
        if (isVerificationDue && !dismissedActivity) {
            dispatch(FeaturesActionCreators.getUserActivity(UserActivityKeys.DismissedPersonalInfoVerification));
        }
    }, [isVerificationDue, dismissedActivity, dispatch]);

    const onDismissClick = () => {
        if (!isUserSessionEmulated) {
            dispatch(
                FeaturesActionCreators.saveUserActivity({
                    key: UserActivityKeys.DismissedPersonalInfoVerification,
                    value: "1"
                })
            );
        }

        dispatch(PersonalInfoVerificationActionCreators.openDialog(false));
    };

    const onConfirmClick = () => {
        if (!isUserSessionEmulated) {
            dispatch(PersonalInfoVerificationActionCreators.personalInfoVerified());
        }

        dispatch(PersonalInfoVerificationActionCreators.openDialog(false));
    };

    return (
        <ResponsiveDialog open={isDialogOpen} fullWidth className="personal-info-verification-dialog">
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <DialogTitle sx={{ maxWidth: 500 }}>
                    Is your information up to date? We suggest using your personal email so you can always access your
                    account.
                </DialogTitle>
                {(isUserSessionEmulated || !dismissedActivity?.value) && (
                    <IconButton aria-label="close" onClick={onDismissClick}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>
            <DialogContent>
                <Grid container rowSpacing={1}>
                    <Grid container item className="content-row">
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6">Email address</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ProfileEmailView
                                email={coveredMember?.person?.contactInfo?.memberProvidedEmailAddress}
                                isLoading={profile.emailState.isFetching}
                                emailUpdateConfirmationDetails={profile.emailState.confirmationDetails}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item className="content-row">
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6">Mailing address</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ProfileAddressView
                                address={profile.addressInfo.address}
                                countries={profile.addressInfo.countries}
                                isLoading={profile.addressInfo.isFetching}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item className="content-row">
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6">Phone numbers</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ProfilePhoneNumbersView
                                phones={profile.phoneNumbersState.profilePhoneNumbers}
                                isLoading={profile.phoneNumbersState.isFetching}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item className="content-row">
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6">Deposit bank account</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ProfileBankAccountView
                                bankAccount={profile.bankAccountState.depositBankAccountState?.bankAccount}
                                banks={profile.bankAccountState.banks}
                                isLoading={profile.bankAccountState.isFetching}
                                isReadOnly={isCoveredMemberDeceased}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item className="content-row">
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6">Withdrawal bank account</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ProfileBankAccountView
                                bankAccount={profile.bankAccountState.withdrawalBankAccountState?.bankAccount}
                                banks={profile.bankAccountState.banks}
                                isLoading={profile.bankAccountState.isFetching}
                                isReadOnly={isCoveredMemberDeceased}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onConfirmClick}>
                    Yes
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        dispatch(PersonalInfoVerificationActionCreators.openDialog(false));
                        history.push("/profile");
                    }}>
                    No, I need to update it
                </Button>
            </DialogActions>
        </ResponsiveDialog>
    );
}
