import API from "api";
import { NetworkRequest } from "api/actions";
import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import { ApplicationState as AppState, AppThunkAction } from "store";
import * as actions from "./actions";
import { AreFilterStatesEqual, AreFilterStatesEqualExceptForPaging } from "./filter/claimFilters";
import { ClaimListPage, ClaimsFilterState, ClaimStats, ClaimBenefitFilter } from "./models";

export type HandledAction =
    | actions.GetClaimStatsInitAction
    | actions.GetClaimStatsCompleteAction
    | actions.GetClaimsInitAction
    | actions.GetClaimsCompleteAction
    | actions.ClearClaimsAction
    | actions.UpdateClaimSummaryAction
    | actions.UpdateClaimsFilterAction
    | actions.DismissClaimInitAction
    | actions.DismissClaimCompleteAction
    | actions.SaveMarkingAsClaimAlreadyTransferredToHsaInitAction
    | actions.SaveMarkingAsClaimAlreadyTransferredToHsaCompleteAction
    | actions.GetClaimBenefitFiltersInitAction
    | actions.GetClaimBenefitFiltersCompleteAction;

export type ClaimListActions = HandledAction | AddNotificationAction | NetworkRequest;

export const ClaimListActionCreators = {
    getClaimStats: (): AppThunkAction<ClaimListActions> => (dispatch, getState: () => AppState) => {
        dispatch({ type: "GET_CLAIM_STATS_INIT" });
        return dispatch(API.claims.getClaimStats())
            .then((response) => {
                if (response?.status === 200) {
                    return response.json() as Promise<ClaimStats>;
                } else {
                    dispatch({ type: "GET_CLAIM_STATS_COMPLETE", claimStats: null, succeeded: false });
                }
            })
            .then((claimStats: ClaimStats) => {
                if (claimStats != null) {
                    dispatch({ type: "GET_CLAIM_STATS_COMPLETE", claimStats, succeeded: true });
                }
                return null;
            });
    },
    getAvailableClaimBenefitFilters: (): AppThunkAction<ClaimListActions> => (dispatch, getState: () => AppState) => {
        dispatch({ type: "GET_CLAIM_BENEFIT_FILTERS_INIT" });
        return dispatch(API.claims.getAvailableClaimBenefitFilters())
            .then((response) => {
                if (response?.status === 200) {
                    return response.json() as Promise<ClaimBenefitFilter[]>;
                } else {
                    dispatch({ type: "GET_CLAIM_BENEFIT_FILTERS_COMPLETE", filters: null, succeeded: false });
                }
            })
            .then((filters: ClaimBenefitFilter[]) => {
                if (filters != null) {
                    dispatch({ type: "GET_CLAIM_BENEFIT_FILTERS_COMPLETE", filters, succeeded: true });
                }
                return null;
            });
    },
    dismissClaim:
        (referenceId: string, dismissActionType: string): AppThunkAction<ClaimListActions> =>
        (dispatch, getState: () => AppState) => {
            dispatch({ type: "DISMISS_CLAIM_INIT", referenceId });

            dispatch(API.claims.dismissClaim(referenceId)).then((response) => {
                if (response && (response?.status === 200 || response?.status === 204)) {
                    dispatch({ type: "DISMISS_CLAIM_COMPLETE", referenceId, dismissActionType, succeeded: true });
                    dispatch({
                        type: "ADD_NOTIFICATION",
                        message: "Action dismissed",
                        style: NotificationStyle.Success,
                        autohide: true,
                        canDismiss: true
                    });
                    dispatch(ClaimListActionCreators.getClaimStats());
                } else {
                    dispatch({
                        type: "DISMISS_CLAIM_COMPLETE",
                        referenceId,
                        dismissActionType,
                        succeeded: false,
                        err: "Failed to dismiss claim"
                    });
                    dispatch({
                        type: "ADD_NOTIFICATION",
                        message: "Sorry, we couldn't dismiss this claim",
                        style: NotificationStyle.Error,
                        autohide: true,
                        canDismiss: true
                    });
                }
            });
        },
    setClaimsFilter:
        (filter: ClaimsFilterState, forceRefresh?: boolean): AppThunkAction<ClaimListActions> =>
        (dispatch, getState) => {
            // check if filter is different
            const currentState = getState();
            const currentFilter = currentState.claims.claimList.claimsFilterState;

            const updateFilterRequired = !currentFilter || !AreFilterStatesEqual(currentFilter, filter);

            if (updateFilterRequired) {
                if (AreFilterStatesEqualExceptForPaging(currentFilter, filter)) {
                    dispatch({ type: "UPDATE_CLAIMS_FILTER", filter });
                } else {
                    // If another filter is applied to the claim list,
                    // we should go back to page 1
                    dispatch({
                        type: "UPDATE_CLAIMS_FILTER",
                        filter: {
                            ...filter,
                            pageNumber: 1
                        }
                    });
                }
            }
            if (updateFilterRequired || forceRefresh) {
                dispatch(ClaimListActionCreators.getClaims());
            }
        },
    getClaims: () => (dispatch, getState: () => AppState) => {
        const state = getState();
        const filterState = state.claims.claimList.claimsFilterState;

        dispatch({ type: "GET_CLAIMS_INIT" });
        return dispatch(API.claims.getClaims(filterState))
            .then(
                (response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<ClaimListPage>;
                    } else {
                        dispatch({
                            type: "ADD_NOTIFICATION",
                            message: "Sorry, we ran into an issue fetching claims",
                            style: NotificationStyle.Error,
                            autohide: true,
                            canDismiss: true
                        });
                        dispatch({
                            type: "GET_CLAIMS_COMPLETE",
                            claimsResponse: null,
                            succeeded: false,
                            err: "Failed to get claims"
                        });
                        return null;
                    }
                },
                () => {
                    return null;
                }
            )
            .then((claimsResponse: ClaimListPage) => {
                if (claimsResponse) {
                    dispatch({ type: "GET_CLAIMS_COMPLETE", claimsResponse, succeeded: true });
                    return claimsResponse;
                }
            });
    }
};
