import * as React from "react";
import { FormControl, FormLabel, TextField, FormHelperText } from "@mui/material";

import { ToolTip } from "modules/common/components/misc";
import { PlaceholderLine } from "modules/common/components/layout";

export interface FormTextFieldProps {
    tombstone?: boolean;
    onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    onChanged?: (e: any) => void;
    placeholder?: string;
    errorText?: string | null;
    title?: string;
    value?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    helperText?: string;
    maxLength?: number;
    type?: string;
    className?: string;
    id?: string;
    autocomplete?: string;
    tooltipTitle?: string;
    tooltipContent?: JSX.Element;
}

export class FormTextField extends React.PureComponent<FormTextFieldProps> {
    public render() {
        const hasError = !!this.props.errorText;
        const inputProps = {
            maxLength: this.props.maxLength
        };

        return (
            <>
                {this.props.tombstone ? (
                    <div>
                        <PlaceholderLine thickness="thin" />
                        <PlaceholderLine thickness="thick" color="dark" />
                    </div>
                ) : (
                    <FormControl
                        fullWidth={this.props.fullWidth}
                        error={hasError}
                        className={"form-text-field " + this.props.className}>
                        <div className="label-tooltip">
                            <FormLabel className="control-label" htmlFor={this.props.id}>
                                {this.props.title}
                            </FormLabel>
                            {this.props.tooltipContent || this.props.tooltipTitle ? (
                                <ToolTip title={this.props.tooltipTitle}>{this.props.tooltipContent}</ToolTip>
                            ) : null}
                        </div>
                        <TextField
                            className="control-text"
                            fullWidth={this.props.fullWidth}
                            autoComplete={this.props.autocomplete || "off"}
                            onKeyPress={this.props.onKeyPress}
                            error={hasError}
                            value={this.props.value}
                            onChange={this.props.onChanged}
                            placeholder={this.props.placeholder}
                            disabled={this.props.disabled}
                            inputProps={inputProps}
                            type={this.props.type}
                            id={this.props.id}
                            name={this.props.id}
                            variant="standard"
                        />
                        {hasError ? (
                            <FormHelperText id="field-error-text" className="error-text" sx={{ marginLeft: 0 }}>
                                {this.props.errorText || this.props.helperText}
                            </FormHelperText>
                        ) : null}
                        {this.props.helperText && !hasError ? (
                            <FormHelperText sx={{ marginLeft: 0 }}>{this.props.helperText}</FormHelperText>
                        ) : null}
                    </FormControl>
                )}
            </>
        );
    }
}
