import * as React from "react";

import { AppInsightsLogger } from "modules/logging/appInsightsLogger";

export class PageViewTracker extends React.Component {
    public render() {
        if (typeof window !== "undefined") {
            AppInsightsLogger.trackPageView();
        }
        return null;
    }
}
