import React from "react";
import { Grid } from "@mui/material";

import { SearchResult } from "../models";
import { SearchResultListItem } from "./SearchResultListItem";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";

interface SearchResultListProps {
    results: SearchResult[];
    coveredMember: CoveredMemberSummary;
    onItemSelected: (isExternalLink: boolean, selectedDocumentId: string, rank: number) => any;
}

export class SearchResultList extends React.Component<SearchResultListProps> {
    public render() {
        const { results } = this.props;

        return (
            <Grid container spacing={2}>
                {results
                    ? results.map((result, index) => {
                          return (
                              <Grid key={result.id} item xs={12}>
                                  <SearchResultListItem result={result} onClick={this.onItemSelected(result, index + 1)} />
                              </Grid>
                          );
                      })
                    : null}
            </Grid>
        );
    }

    private onItemSelected = (result: SearchResult, rank: number) => () => {
        const isExternalLink = result.url.indexOf("http") >= 0;
        this.props.onItemSelected(isExternalLink, result.id, rank);
    };
}
