import * as React from "react";
import MaskedInput from "react-text-mask";
import {
    Grid,
    FormControl,
    FormLabel,
    Input,
    FormHelperText,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";

import { ValidationState } from "modules/claims/claimSubmission/models";
import { FormTextField, SubmitButton } from "modules/common/components/forms";
import { PhoneNumber, ProfilePhoneNumbers } from "modules/profile/phoneNumbers/models";
import { ConfirmUnsavedChanges } from "modules/common/components/forms/confirmUnsavedChanges";

export interface PhoneNumbersFormProps {
    profilePhoneNumbers: ProfilePhoneNumbers;
    isSaving: boolean;
    onSavePhoneNumbersRequested: (phones: ProfilePhoneNumbers) => any;
    onCancel: () => any;
}

export interface PhoneNumbersFormState {
    formPhoneNumbers: ProfilePhoneNumbers;
    hasAttemptedToSubmit: boolean;
    validationState: ValidationState;
    showCancelConfirmation: boolean;
    isFormDirty: boolean;
}

const TextMaskCustom = (props) => {
    return (
        <MaskedInput
            {...props}
            mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"\u2000"}
            showMask
        />
    );
};

export class PhoneNumbersForm extends React.PureComponent<PhoneNumbersFormProps, PhoneNumbersFormState> {
    public state: PhoneNumbersFormState = {
        formPhoneNumbers: this.ensurePhoneNumbers(this.props.profilePhoneNumbers),
        hasAttemptedToSubmit: false,
        validationState: {} as ValidationState,
        showCancelConfirmation: false,
        isFormDirty: false
    };

    public componentDidUpdate(prevProps: PhoneNumbersFormProps) {
        if (
            this.props.profilePhoneNumbers &&
            prevProps.profilePhoneNumbers !== this.props.profilePhoneNumbers &&
            this.props.profilePhoneNumbers !== this.state.formPhoneNumbers
        ) {
            this.resetState();
        }
    }

    private resetState() {
        this.setState(
            (s) =>
                ({
                    formPhoneNumbers: this.ensurePhoneNumbers(this.props.profilePhoneNumbers),
                    hasAttemptedToSubmit: false,
                    validationState: {} as ValidationState,
                    isFormDirty: false
                } as PhoneNumbersFormState)
        );
    }

    private ensurePhoneNumbers(phoneNumbers: ProfilePhoneNumbers) {
        return {
            primaryPhoneNumber:
                phoneNumbers && phoneNumbers.primaryPhoneNumber
                    ? phoneNumbers.primaryPhoneNumber
                    : ({
                          number: "",
                          extension: ""
                      } as PhoneNumber),
            secondaryPhoneNumber:
                phoneNumbers && phoneNumbers.secondaryPhoneNumber
                    ? phoneNumbers.secondaryPhoneNumber
                    : ({
                          number: "",
                          extension: ""
                      } as PhoneNumber)
        } as ProfilePhoneNumbers;
    }
    public render() {
        const hasPrimaryPhoneNumberError =
            this.state.hasAttemptedToSubmit && !!this.state.validationState.messages["primaryPhoneNumber.number"];
        const hasPrimaryPhoneExtensionError =
            this.state.hasAttemptedToSubmit && !!this.state.validationState.messages["primaryPhoneNumber.extension"];
        const hasSecondaryPhoneNumberError =
            this.state.hasAttemptedToSubmit && !!this.state.validationState.messages["secondaryPhoneNumber.number"];
        const hasSecondaryPhoneExtensionError =
            this.state.hasAttemptedToSubmit && !!this.state.validationState.messages["secondaryPhoneNumber.extension"];

        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={3} />
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={3} className="profile-form phone-numbers">
                            <Grid item xs={12} className="phone-number-container">
                                <FormControl error={hasPrimaryPhoneNumberError} className="number">
                                    <FormLabel htmlFor="primaryPhoneNumber" className="control-label">
                                        Primary
                                    </FormLabel>
                                    <Input
                                        value={this.state.formPhoneNumbers.primaryPhoneNumber.number}
                                        onChange={this.onPrimaryPhoneNumberChanged}
                                        id="primaryPhoneNumber"
                                        inputComponent={TextMaskCustom}
                                        disabled={this.props.isSaving}
                                        onKeyPress={this.onKeyPressIgnoreEnter}
                                        className="control-text"
                                    />
                                    {hasPrimaryPhoneNumberError ? (
                                        <FormHelperText id="primary-phone-change-error" className="error-text">
                                            {this.state.validationState.messages["primaryPhoneNumber.number"]}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                                <FormControl error={hasPrimaryPhoneExtensionError} className="extension">
                                    <FormTextField
                                        title="Extension"
                                        value={this.state.formPhoneNumbers.primaryPhoneNumber.extension}
                                        onKeyPress={this.onKeyPressIgnoreEnter}
                                        onChanged={this.onPrimaryPhoneExtensionChanged}
                                        errorText={
                                            hasPrimaryPhoneExtensionError
                                                ? this.state.validationState.messages["primaryPhoneNumber.extension"]
                                                : null
                                        }
                                        disabled={this.props.isSaving}
                                        maxLength={6}
                                        id="primaryPhoneExtension"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} className="phone-number-container">
                                <FormControl error={hasSecondaryPhoneNumberError} className="number">
                                    <FormLabel htmlFor="secondaryPhoneNumber" className="control-label">
                                        Secondary
                                    </FormLabel>
                                    <Input
                                        value={this.state.formPhoneNumbers.secondaryPhoneNumber.number}
                                        onChange={this.onSecondaryPhoneNumberChanged}
                                        id="secondaryPhoneNumber"
                                        inputComponent={TextMaskCustom}
                                        disabled={this.props.isSaving}
                                        onKeyPress={this.onKeyPressIgnoreEnter}
                                        className="control-text"
                                    />
                                    {hasSecondaryPhoneNumberError ? (
                                        <FormHelperText id="secondary-phone-change-error" className="error-text">
                                            {this.state.validationState.messages["secondaryPhoneNumber.number"]}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                                <FormControl error={hasSecondaryPhoneExtensionError} className="extension">
                                    <FormTextField
                                        title="Extension"
                                        value={this.state.formPhoneNumbers.secondaryPhoneNumber.extension}
                                        onKeyPress={this.onKeyPressIgnoreEnter}
                                        onChanged={this.onSecondaryPhoneExtensionChanged}
                                        errorText={
                                            hasSecondaryPhoneExtensionError
                                                ? this.state.validationState.messages["secondaryPhoneNumber.extension"]
                                                : null
                                        }
                                        disabled={this.props.isSaving}
                                        maxLength={6}
                                        id="secondaryPhoneExtension"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="form-actions gutter-top">
                                <SubmitButton
                                    submittingMessage="Updating"
                                    isSubmitting={this.props.isSaving}
                                    className="secondary"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.onSavePhoneNumbersRequested}
                                    disabled={!this.state.isFormDirty}>
                                    Update Phone Numbers
                                </SubmitButton>
                                <Button
                                    id="edit-phone-cancel-button"
                                    disabled={this.props.isSaving}
                                    onClick={this.onCancel}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={this.state.showCancelConfirmation} onClose={this.cancelCanceled}>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>Any changes you made will be lost.</DialogContent>
                    <DialogActions>
                        <Button id="phone-cancel-cancel-button" onClick={this.cancelCanceled}>
                            No, Keep It
                        </Button>
                        <Button
                            id="phone-confirm-cancel-button"
                            className="confirmation confirm-btn"
                            onClick={this.cancelConfirmed}>
                            Yes, discard my changes
                        </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmUnsavedChanges isDirty={this.state.isFormDirty} />
            </>
        );
    }

    private onKeyPressIgnoreEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    private onPrimaryPhoneNumberChanged = (evt: any) => {
        const updatedPhoneNumbers = {
            ...this.state.formPhoneNumbers,
            primaryPhoneNumber: {
                ...this.state.formPhoneNumbers.primaryPhoneNumber,
                number: this.getDigitsOnly(evt.target.value)
            }
        };

        this.setPhoneNumbersValue(updatedPhoneNumbers);
    };
    private onPrimaryPhoneExtensionChanged = (evt: any) => {
        const updatedPhoneNumbers = {
            ...this.state.formPhoneNumbers,
            primaryPhoneNumber: {
                ...this.state.formPhoneNumbers.primaryPhoneNumber,
                extension: evt.target.value
            }
        };

        this.setPhoneNumbersValue(updatedPhoneNumbers);
    };
    private onSecondaryPhoneNumberChanged = (evt: any) => {
        const updatedPhoneNumbers = {
            ...this.state.formPhoneNumbers,
            secondaryPhoneNumber: {
                ...this.state.formPhoneNumbers.secondaryPhoneNumber,
                number: this.getDigitsOnly(evt.target.value)
            }
        };

        this.setPhoneNumbersValue(updatedPhoneNumbers);
    };
    private onSecondaryPhoneExtensionChanged = (evt: any) => {
        const updatedPhoneNumbers = {
            ...this.state.formPhoneNumbers,
            secondaryPhoneNumber: {
                ...this.state.formPhoneNumbers.secondaryPhoneNumber,
                extension: evt.target.value
            }
        };

        this.setPhoneNumbersValue(updatedPhoneNumbers);
    };

    private setPhoneNumbersValue(phoneNumbers: ProfilePhoneNumbers) {
        this.setState((s) => ({ ...s, formPhoneNumbers: phoneNumbers, isFormDirty: true }));
        this.validate(phoneNumbers);
    }

    private validate(phoneNumbers: ProfilePhoneNumbers) {
        const validationResult = { isValid: true, messages: {} } as ValidationState;

        if (phoneNumbers) {
            if (
                !this.getDigitsOnly(phoneNumbers.primaryPhoneNumber.number) &&
                this.getDigitsOnly(phoneNumbers.secondaryPhoneNumber.number)
            ) {
                validationResult.messages["primaryPhoneNumber.number"] = "Please enter this number first";
            }
            if (
                !this.getDigitsOnly(phoneNumbers.primaryPhoneNumber.number) &&
                phoneNumbers.primaryPhoneNumber.extension
            ) {
                validationResult.messages["primaryPhoneNumber.number"] = "Oops, you need a phone number here";
            }
            if (
                !this.getDigitsOnly(phoneNumbers.secondaryPhoneNumber.number) &&
                phoneNumbers.secondaryPhoneNumber.extension
            ) {
                validationResult.messages["secondaryPhoneNumber.number"] = "Oops, you need a phone number here";
            }

            if (
                phoneNumbers.primaryPhoneNumber.extension &&
                !this.isValidPhoneExtension(phoneNumbers.primaryPhoneNumber.extension)
            ) {
                validationResult.messages["primaryPhoneNumber.extension"] = "Must be between 1 and 6 digits";
            }
            if (
                phoneNumbers.secondaryPhoneNumber.extension &&
                !this.isValidPhoneExtension(phoneNumbers.secondaryPhoneNumber.extension)
            ) {
                validationResult.messages["secondaryPhoneNumber.extension"] = "Must be between 1 and 6 digits";
            }

            if (
                this.getDigitsOnly(phoneNumbers.primaryPhoneNumber.number) &&
                !this.isValidPhoneNumberLength(phoneNumbers.primaryPhoneNumber.number)
            ) {
                validationResult.messages["primaryPhoneNumber.number"] = "Must be 10 digits";
            }
            if (
                this.getDigitsOnly(phoneNumbers.secondaryPhoneNumber.number) &&
                !this.isValidPhoneNumberLength(phoneNumbers.secondaryPhoneNumber.number)
            ) {
                validationResult.messages["secondaryPhoneNumber.number"] = "Must be 10 digits";
            }

            if (
                this.getDigitsOnly(phoneNumbers.primaryPhoneNumber.number) &&
                !this.isValidPhoneNumberStart(phoneNumbers.primaryPhoneNumber.number)
            ) {
                validationResult.messages["primaryPhoneNumber.number"] = "Cannot start from '0' or '1'";
            }
            if (
                this.getDigitsOnly(phoneNumbers.secondaryPhoneNumber.number) &&
                !this.isValidPhoneNumberStart(phoneNumbers.secondaryPhoneNumber.number)
            ) {
                validationResult.messages["secondaryPhoneNumber.number"] = "Cannot start from '0' or '1'";
            }

            validationResult.isValid = Object.keys(validationResult.messages).length === 0;
        }

        this.setState((s) => ({ ...s, validationState: validationResult }));
    }

    private isValidPhoneExtension = (extension: string): boolean => {
        const regex = /^\d{1,6}$/gs;
        return regex.test(extension);
    };

    private isValidPhoneNumberLength = (number: string): boolean => {
        return number && this.getDigitsOnly(number).length && this.getDigitsOnly(number).length === 10;
    };

    private isValidPhoneNumberStart = (number: string): boolean => {
        return (
            number &&
            this.getDigitsOnly(number).length &&
            this.getDigitsOnly(number).charAt(0) !== "0" &&
            this.getDigitsOnly(number).charAt(0) !== "1"
        );
    };

    private getDigitsOnly = (input: string): string => {
        return input ? input.replace(/\D/g, "") : "";
    };

    private onSavePhoneNumbersRequested = () => {
        this.setState((s) => ({ ...s, hasAttemptedToSubmit: true }));
        this.validate(this.state.formPhoneNumbers);

        if (this.state.validationState.isValid) {
            this.props.onSavePhoneNumbersRequested(this.state.formPhoneNumbers);
        }
    };
    private onCancel = () => {
        if (this.state.isFormDirty) {
            this.setState((s) => ({ ...s, showCancelConfirmation: true }));
        } else {
            this.cancelConfirmed();
        }
    };

    private cancelConfirmed = () => {
        this.resetState();
        this.props.onCancel();

        this.setState((s) => ({ ...s, showCancelConfirmation: false }));
    };

    private cancelCanceled = () => {
        this.setState((s) => ({ ...s, showCancelConfirmation: false }));
    };
}
