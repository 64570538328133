import React from "react";

interface TagProps {
    label: string;
    className?: string;
}

export class Tag extends React.Component<TagProps> {
    public render() {
        const { label } = this.props;

        return <span className={"tag " + this.props.className}>{label}</span>;
    }
}
