import * as React from "react";

export interface ContainerProps {
    highlight?: boolean;
    variant?: "default" | "warning";
    children: React.ReactNode;
}

export class Container extends React.Component<ContainerProps> {
    public render() {
        const { highlight, variant } = this.props;

        return <div className={`container ${highlight ? "highlight" : ""} ${variant || "default"}`}>{this.props.children}</div>;
    }
}
