import * as actions from "./actions";

export type CommonActions = actions.TrackMobileNavDrawerOpenedAction | actions.TrackLoggedInUserIsEmulatedAction;

export const CommonActionCreators = {
    trackNavDrawer: () => dispatch => {
        dispatch({ type: "TRACK_MOBILE_NAV_PANEL_EXPANDED" });
    },

    trackLoggedInUserIsEmulated: (isEmulated: boolean) => dispatch => {
        dispatch({ type: "TRACK_LOGGED_IN_USER_EMULATED", isEmulated });
    }
};
