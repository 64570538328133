export interface SearchState {
    searchText: string;
    isFetching: boolean;
    response: SearchResponse | null;
}

export interface SearchResponse {
    searchId: string;
    results: SearchResult[];
}

export interface SearchResult {
    id: string;
    title: string;
    description: string;
    url: string | null;
    tags: string[] | null;
    filterRuleKey: KnownFilterRules | null;
    dyamicContentKey: string | null;
    highlights: HighlightDictionary;
}

export interface HighlightDictionary {
    [key: string]: string;
}

export enum KnownFilterRules {
    CanSubmitClaims = "CanSubmitClaims",
    HasAccessToDentalGuide = "HasAccessToDentalGuide",
    HasActiveDentalCoverage = "HasActiveDentalCoverage",
    HasActiveExtendedHealthCoverage = "HasActiveExtendedHealthCoverage",
    HasActiveGeneralHealthCoverage = "HasActiveGeneralHealthCoverage",
    HasSpendingAccount = "HasSpendingAccount"
}
