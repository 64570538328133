import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { MyAsebpCardDialog } from "modules/benefits/membershipCard/components";
import { ErrorBoundary, PageContent, SubNav } from "modules/common/components/layout";
import { FeedbackDialog } from "modules/feedback/components";
import { SiteArea } from "routeProvider";
import { ApplicationState } from "store";
import BackLink from "./backLink";
import { PageTitle } from "./PageTitle";
import { PageViewTracker } from "./PageViewTracker";

class Page extends React.Component<React.PropsWithChildren<ComponentProps>> {
    public render() {
        const { title, pathname, className, withSubNav, withBackLink, area, noBackground, noPadding, noMinHeight, withOutageMessage } = this.props;

        const showSubNav = withSubNav;

        return (
            <div className={`page ${className ? className : ""}`}>
                <PageTitle title={title} />
                {withSubNav ? <SubNav currentPath={pathname} area={area} /> : null}
                <PageContent noBackground={noBackground} noPadding={noPadding} noRadiusTop={showSubNav} noMargin={showSubNav} noMinHeight={noMinHeight} withOutageMessage={withOutageMessage}>
                    <div className="back-link-wrapper">{withBackLink ? <BackLink /> : null}</div>
                    <ErrorBoundary>{this.props.children}</ErrorBoundary>
                </PageContent>
                <MyAsebpCardDialog />
                <FeedbackDialog />
                <PageViewTracker />
            </div>
        );
    }
}

interface PageProps {
    title?: string;
    className?: string;
    withSubNav?: boolean;
    withBackLink?: boolean;
    area?: SiteArea;
    noBackground?: boolean;
    noPadding?: boolean;
    noMinHeight?: boolean;
    withOutageMessage?: boolean;
}

interface IStateToProps {
    pathname: string;
}

type ComponentProps = PageProps & IStateToProps & Partial<RouteComponentProps<{}, {}, { location: { pathname: string }; history: {} }>>;

export default withRouter(
    connect((state: ApplicationState, ownProps: PageProps & RouteComponentProps<{}, {}, { location: { pathname: string }; history: {} }>) => {
        return {
            pathname: ownProps.location ? ownProps.location.pathname : ""
        };
    })(Page)
);
