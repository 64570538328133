import { configureStore, combineReducers, StateFromReducersMapObject } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import { ApplicationState, reducers } from "./";
import requestMiddleware from "api/request-middleware";
import loggingMiddleware from "modules/logging/loggingMiddleware";

const baseUrl = document.getElementsByTagName("base")[0]?.getAttribute("href") as string;

function defineContext(appHistory) {
    const { routerReducer, routerMiddleware, createReduxHistory } = createReduxHistoryContext({
        history: appHistory,
        basename: baseUrl || "/"
    });

    const rootReducer = {
        ...reducers,
        router: routerReducer
    };

    return { rootReducer, routerMiddleware, createReduxHistory };
}

function configureStoreWithMiddleware(reducersObj, initialState?: ApplicationState) {
    const { rootReducer, routerMiddleware } = reducersObj;
    const middleware = [routerMiddleware, requestMiddleware, loggingMiddleware];

    return configureStore({
        reducer: combineReducers(rootReducer),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            }).concat(middleware),
        devTools: process.env.NODE_ENV === "production" ? false : true,
        preloadedState: initialState
    });
}

export function createStoreAndHistory(initialState?: ApplicationState, appHistory = createBrowserHistory()) {
    const contextObj = defineContext(appHistory);
    const store = configureStoreWithMiddleware(contextObj, initialState);
    const history = contextObj.createReduxHistory(store);
    return { store, history };
}

export { createStoreAndHistory as configureStore };

type Store = ReturnType<typeof configureStoreWithMiddleware>;
export type AppDispatch = Store["dispatch"];
type Root = ReturnType<typeof defineContext>;
export type RootState = StateFromReducersMapObject<Root["rootReducer"]>;
