import { compareAsc, addMonths } from "date-fns";

import { BenefitCode, BenefitDisplayNames } from "../models";
import {
    BenefitCoverageLevel,
    BenefitHistoryDictionary,
    HistoricalBenefitCoverageList,
    HistoricalBenefitCoverageState,
    HistoricalMembershipBenefitCoverage
} from "modules/benefits/coverage/models";

import * as BenefitUtils from "modules/benefits/benefitUtils";

export interface IBenefitStatusCalculator {
    doesPersonHaveFutureTerminatedBenefit: (patientCertificateNumber: string, Vision: BenefitCode) => boolean;
    getTerminationDateOfBenefit: (certificateNumber: string, benefitType: BenefitCode) => Date;
    getEffectiveDateOfBenefit: (certificateNumber: string, benefitType: BenefitCode) => Date;
    doesPersonHaveActiveBenefitToday: (certificateNumber: string, benefitType: BenefitCode) => boolean;
    didPersonHaveBenefitInLast18Months: (certificateNumber: string, benefitType: BenefitCode) => boolean;
    didPersonEverHaveActiveBenefit: (certificateNumber: string, benefitType: BenefitCode) => boolean;
}

/**
 * This class is used to determine the status of a benefit for a person on a given date.
 *
 * Note for future devs: The "familyBenefitHistory" property currently only ever contains the CM's benefit history despite
 * the name due to the "BenefitCoverageActionCreators.getDependentBenefitHistory" method never being called in the app
 */
export class BenefitStatusCalculator implements IBenefitStatusCalculator {
    private familyBenefitHistory: BenefitHistoryDictionary = null;

    public constructor(familyBenefitHistory: BenefitHistoryDictionary) {
        this.familyBenefitHistory = familyBenefitHistory;
    }

    public getCurrentBenefitCoverageLevels = (certificateNumber: string): BenefitCoverageLevel[] => {
        const benefits = this.getActiveBenefitsOnDate(certificateNumber, new Date());

        return benefits
            .map((b) => ({
                benefitType: b.benefitType,
                name: BenefitDisplayNames[b.benefitType],
                coverageLevel: BenefitUtils.toCoverageDisplay(
                    b.benefitType,
                    b.historicalBenefitCoverageStates[0].calculatedStatusInputs.memberBenefitState
                        .dependantCoverageLevel
                ),
                planAbbreviation:
                    b.historicalBenefitCoverageStates[0].calculatedStatusInputs.memberBenefitState.planAbbreviation,
                planName:
                    b.benefitType === BenefitCode.HSA || b.benefitType === BenefitCode.WSA
                        ? b.historicalBenefitCoverageStates[0].calculatedStatusInputs.memberBenefitState.planName
                        : ""
            }))
            .reduce((accum, val) => {
                // only include one row (even if multiple benefits) for General Health Benefits and spending accounts
                if (
                    BenefitUtils.isGeneralHealthBenefit(val.benefitType) ||
                    BenefitUtils.isSpendingAccount(val.benefitType)
                ) {
                    if (!accum.some((b) => b.benefitType === val.benefitType)) {
                        accum = accum.concat(val);
                    }
                } else {
                    accum = accum.concat(val);
                }
                return accum;
            }, [])
            .sort((a, b) => {
                return BenefitUtils.benefitCodeSort(a.benefitType, b.benefitType);
            });
    };

    private getActiveBenefitsOnDate = (
        certificateNumber: string,
        dateToCheck: Date
    ): HistoricalBenefitCoverageList[] => {
        const personBenefitHistory = this.familyBenefitHistory[certificateNumber];
        const activeBenefits = [];

        if (!!personBenefitHistory) {
            const benefitCodes = [
                BenefitCode.Life,
                BenefitCode.AccidentalDeathAndDismemberment,
                BenefitCode.ExtendedDisability,
                BenefitCode.EHC,
                BenefitCode.Dental,
                BenefitCode.Vision,
                BenefitCode.HSA,
                BenefitCode.WSA
            ];

            for (const benefitCode of benefitCodes) {
                for (const membershipBenefitHistory of personBenefitHistory.membershipCoverageHistories) {
                    const benefitHistory = membershipBenefitHistory.benefits.find((h) => h.benefitType === benefitCode);

                    if (!!benefitHistory) {
                        const hadActiveBenefitOnDate = benefitHistory.historicalBenefitCoverageStates.some((b) => {
                            const effectiveStartingDate = new Date(b.effectiveStarting);
                            const effectiveThroughDate =
                                b.effectiveThrough !== null ? new Date(b.effectiveThrough) : null;

                            return (
                                b.calculatedStatus.wasCoverageActive &&
                                effectiveStartingDate <= dateToCheck &&
                                (effectiveThroughDate === null || effectiveThroughDate >= dateToCheck)
                            );
                        });

                        if (hadActiveBenefitOnDate) {
                            activeBenefits.push(benefitHistory);
                        }
                    }
                }
            }
        }

        return activeBenefits;
    };

    public getTerminationDateOfBenefit = (certificateNumber: string, benefitType: string): Date | null => {
        const benefitHistory = this.familyBenefitHistory[certificateNumber];

        if (!benefitHistory) {
            return new Date(1970, 1, 1);
        }

        const lastActiveDates: Date[] = [];

        for (let i = 0; i < benefitHistory.membershipCoverageHistories.length; i++) {
            const membershipBenefitHistory = benefitHistory.membershipCoverageHistories[i];
            // copy so we don't affect original when we sort
            const benefitHistoryForRequestedType = [
                // just include the active periods
                ...this.getHistoryForBenefitType(membershipBenefitHistory, benefitType).filter(
                    (b) => b.calculatedStatus.wasCoverageActive
                )
            ];
            if (benefitHistoryForRequestedType && benefitHistoryForRequestedType.length) {
                // sort in place
                benefitHistoryForRequestedType.sort((a, b) => {
                    const aEffectiveStartingDate = new Date(a.effectiveStarting);
                    const bEffectiveStartingDate = new Date(b.effectiveStarting);

                    return compareAsc(aEffectiveStartingDate, bEffectiveStartingDate);
                });

                const lastTerm = benefitHistoryForRequestedType[benefitHistoryForRequestedType.length - 1];
                const lastActiveDateInTerm =
                    lastTerm.effectiveThrough !== null ? new Date(lastTerm.effectiveThrough) : null;

                if (lastActiveDateInTerm === null) {
                    return null; // a null date means that this benefit is still active
                } else {
                    lastActiveDates.push(lastActiveDateInTerm);
                }
            }
        }

        lastActiveDates.sort(compareAsc);

        return lastActiveDates.length ? lastActiveDates[lastActiveDates.length - 1] : new Date(1970, 1, 1);
    };

    public getEffectiveDateOfBenefit = (certificateNumber: string, benefitType: string): Date => {
        const benefitHistory = this.familyBenefitHistory[certificateNumber];

        if (!benefitHistory) {
            return new Date(1970, 1, 1);
        }

        const effectiveStartingDates: Date[] = [];

        for (let i = 0; i < benefitHistory.membershipCoverageHistories.length; i++) {
            const membershipBenefitHistory = benefitHistory.membershipCoverageHistories[i];
            const benefitHistoryForRequestedType = this.getHistoryForBenefitType(
                membershipBenefitHistory,
                benefitType
            ).filter((b) => b.calculatedStatus.wasCoverageActive);
            if (benefitHistoryForRequestedType?.length) {
                // sort in place
                benefitHistoryForRequestedType.sort((a, b) => {
                    const aEffectiveStartingDate = new Date(a.effectiveStarting);
                    const bEffectiveStartingDate = new Date(b.effectiveStarting);

                    return compareAsc(aEffectiveStartingDate, bEffectiveStartingDate);
                });

                const lastTerm = benefitHistoryForRequestedType[benefitHistoryForRequestedType.length - 1];
                const effectiveDateInTerm =
                    lastTerm.effectiveStarting !== null ? new Date(lastTerm.effectiveStarting) : null;

                effectiveStartingDates.push(effectiveDateInTerm);
            }
        }

        effectiveStartingDates.sort(compareAsc);

        return effectiveStartingDates.length
            ? effectiveStartingDates[effectiveStartingDates.length - 1]
            : new Date(1970, 1, 1);
    };

    public didPersonHaveBenefitInLast18Months = (certificateNumber: string, benefitType: string): boolean => {
        const benefitHistory = this.familyBenefitHistory[certificateNumber];

        if (!benefitHistory) {
            return false;
        }

        const eighteenMonthsAgo = addMonths(new Date(), -18);
        const today = new Date();

        for (let i = 0; i < benefitHistory.membershipCoverageHistories.length; i++) {
            const membershipBenefitHistory = benefitHistory.membershipCoverageHistories[i];

            const benefitHistoryForRequestedType = this.getHistoryForBenefitType(membershipBenefitHistory, benefitType);
            const hadActiveBenefitOnDate = benefitHistoryForRequestedType.some((b) => {
                const effectiveStartingDate = new Date(b.effectiveStarting);
                const effectiveThroughDate = b.effectiveThrough !== null ? new Date(b.effectiveThrough) : null;
                return (
                    b.calculatedStatus.wasCoverageActive &&
                    effectiveStartingDate <= today &&
                    (effectiveThroughDate === null || effectiveThroughDate >= eighteenMonthsAgo)
                );
            });

            if (hadActiveBenefitOnDate) {
                return true;
            }
        }

        return false;
    };

    public doesPersonHaveFutureTerminatedBenefit = (certificateNumber: string, benefitType: string): boolean => {
        const benefitHistory = this.familyBenefitHistory[certificateNumber];

        if (!benefitHistory) {
            return false;
        }

        const today = new Date();

        for (let i = 0; i < benefitHistory.membershipCoverageHistories.length; i++) {
            const membershipBenefitHistory = benefitHistory.membershipCoverageHistories[i];
            const benefitHistoryForRequestedType = this.getHistoryForBenefitType(membershipBenefitHistory, benefitType);

            const hasActiveBenefitOnDate = benefitHistoryForRequestedType.some((b) => {
                const effectiveStartingDate = new Date(b.effectiveStarting);
                const effectiveThroughDate = b.effectiveThrough !== null ? new Date(b.effectiveThrough) : null;
                const statusEffectiveDate =
                    b.calculatedStatus.statusEffectiveDate !== null
                        ? new Date(b.calculatedStatus.statusEffectiveDate)
                        : null;
                return (
                    !b.calculatedStatus.wasCoverageActive &&
                    effectiveStartingDate <= today &&
                    effectiveThroughDate === null &&
                    statusEffectiveDate >= today
                );
            });

            if (hasActiveBenefitOnDate) {
                return true;
            }
        }

        return false;
    };

    public doesPersonHaveActiveBenefitToday = (certificateNumber: string, benefitType: string): boolean => {
        return this.didPersonHaveActiveBenefitOnDate(certificateNumber, benefitType, new Date());
    };

    public didPersonHaveActiveBenefitOnDate = (
        certificateNumber: string,
        benefitType: string,
        date: Date | string
    ): boolean => {
        const benefitHistory = this.familyBenefitHistory[certificateNumber];

        if (!benefitHistory) {
            return false;
        }

        const dateToCheck = new Date(date as any);

        for (let i = 0; i < benefitHistory.membershipCoverageHistories.length; i++) {
            const membershipBenefitHistory = benefitHistory.membershipCoverageHistories[i];

            const benefitHistoryForRequestedType = this.getHistoryForBenefitType(membershipBenefitHistory, benefitType);

            const hadActiveBenefitOnDate = benefitHistoryForRequestedType.some((b) => {
                const effectiveStartingDate = new Date(b.effectiveStarting);
                const effectiveThroughDate = b.effectiveThrough !== null ? new Date(b.effectiveThrough) : null;

                return (
                    b.calculatedStatus.wasCoverageActive &&
                    effectiveStartingDate <= dateToCheck &&
                    (effectiveThroughDate === null || effectiveThroughDate >= dateToCheck)
                );
            });

            if (hadActiveBenefitOnDate) {
                return true;
            }
        }

        return false;
    };

    public didPersonEverHaveActiveBenefit = (certificateNumber: string, benefitType: string): boolean => {
        const benefitHistory = this.familyBenefitHistory[certificateNumber];
        if (!benefitHistory) {
            return false;
        }

        for (let i = 0; i < benefitHistory.membershipCoverageHistories.length; i++) {
            const membershipBenefitHistory = benefitHistory.membershipCoverageHistories[i];

            const benefitHistoryForRequestedType = this.getHistoryForBenefitType(membershipBenefitHistory, benefitType);

            const hadActiveBenefitOnDate = benefitHistoryForRequestedType.some(
                (b) => b.calculatedStatus.wasCoverageActive
            );

            if (hadActiveBenefitOnDate) {
                return true;
            }
        }

        return false;
    };

    private getHistoryForBenefitType = (
        membershipHistory: HistoricalMembershipBenefitCoverage,
        benefitType: string
    ): HistoricalBenefitCoverageState[] => {
        for (let i = 0; i < membershipHistory.benefits.length; i++) {
            const benefitHistory = membershipHistory.benefits[i];
            if (benefitHistory.benefitType === benefitType) {
                return benefitHistory.historicalBenefitCoverageStates;
            }
        }
        return [];
    };
}
