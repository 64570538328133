import { Reducer } from "redux";
import { HandledAction } from "./actionCreator";
import { ContactState, Inquiry, Feedback } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<ContactState> = (state: ContactState, action: KnownAction) => {
    switch (action.type) {
        case "SUBMIT_INQUIRY_INIT": {
            return {
                ...state,
                submitting: true
            };
        }
        case "SUBMIT_INQUIRY_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    submitting: false,
                    isValidInquiry: false,
                    inquiry: {
                        message: "",
                        subject: "",
                        preferredContactMethod: "Email"
                    } as Inquiry
                };
            } else {
                return {
                    ...state,
                    submitting: false
                };
            }
        }
        case "UPDATE_INQUIRY": {
            const phoneNumber = action.personDetails?.contactInfo?.primaryPhoneNumber ?? null;

            const isValid =
                action.inquiry != null &&
                !!action.inquiry.subject &&
                !!action.inquiry.message &&
                !!action.inquiry.preferredContactMethod &&
                (action.inquiry.preferredContactMethod === "Email" || (action.inquiry.preferredContactMethod === "Phone" && !!phoneNumber));

            return {
                ...state,
                inquiry: action.inquiry,
                isValidInquiry: isValid
            };
        }
        case "SUBMIT_FEEDBACK_INIT": {
            return {
                ...state,
                submitting: true
            };
        }
        case "SUBMIT_FEEDBACK_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    submitting: false,
                    isValidFeedback: false,
                    feedback: { rating: null, comment: "" } as Feedback
                };
            } else {
                return {
                    ...state,
                    submitting: false
                };
            }
        }
        case "UPDATE_FEEDBACK": {
            const isValid = action.feedback != null && !!action.feedback.rating;

            return {
                ...state,
                feedback: action.feedback,
                isValidFeedback: isValid
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            submitting: false,
            inquiry: {
                message: "",
                subject: "",
                preferredContactMethod: "Email"
            } as Inquiry,
            isValidInquiry: null,
            feedback: {
                rating: null,
                comment: ""
            } as Feedback,
            isValidFeedback: null
        } as ContactState)
    );
};
