import * as React from "react";
import { Input, InputAdornment, InputBaseComponentProps } from "@mui/material";

import { Formatter } from "modules/utils";

export interface NumberFieldProps {
    id?: string;
    className?: string;
    fullWidth?: boolean;
    value: number | null;
    error?: boolean;
    adornmentIcon?: string;
    onChange: (value: number | null) => any;
    disabled?: boolean;
    inputProps?: InputBaseComponentProps;
}
export interface NumberFieldState {
    workingValue: string;
    isFocused: boolean;
}

export class NumberField extends React.Component<NumberFieldProps, NumberFieldState> {
    private inputElement: HTMLInputElement;

    public state: NumberFieldState = { workingValue: "", isFocused: false };

    public componentDidMount() {
        this.setState(s => ({
            ...s,
            workingValue: this.props.value != null ? Formatter.formatNumber(this.props.value) : ""
        }));
    }

    public shouldComponentUpdate(nextProps: NumberFieldProps, nextState: { workingValue: string }) {
        return (
            this.props.className !== nextProps.className || this.props.fullWidth !== nextProps.fullWidth || this.props.error !== nextProps.error || this.props.value !== nextProps.value || this.state.workingValue !== nextState.workingValue
        );
    }

    public componentDidUpdate(prevProps: NumberFieldProps) {
        if (prevProps.value !== this.props.value && !this.state.isFocused) {
            this.setState(s => ({
                ...s,
                workingValue: this.props.value != null ? Formatter.formatNumber(this.props.value) : ""
            }));
        }
    }

    public render() {
        return (
            <Input
                id={this.props.id}
                autoComplete="off"
                inputRef={el => {
                    this.inputElement = el;
                }}
                onClick={this.onClick}
                onKeyPress={this.onKeyPressIgnoreEnter}
                className={this.props.className}
                fullWidth={this.props.fullWidth}
                value={this.state.workingValue}
                onChange={this.onChange}
                error={isNaN(Number(this.state.workingValue.replace(",", ""))) || this.props.error}
                startAdornment={
                    this.props.adornmentIcon ? (
                        <InputAdornment className="number-adornment" position="start">
                            <i className="material-icons">{this.props.adornmentIcon}</i>
                        </InputAdornment>
                    ) : null
                }
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                disabled={this.props.disabled}
                inputProps={this.props.inputProps}
            />
        );
    }

    private onKeyPressIgnoreEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    private onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        this.inputElement.focus();
    };

    private onChange = (event: any) => {
        const newWorkingValue = event.target.value as string;
        const numberValue = newWorkingValue.trim() !== "" ? Number(newWorkingValue.replace(",", "")) : null;
        /*
            If it is indeed a number and a number different than the current props, call onChange.
            This prevents the editor from changing the value when we enter a decimal.
        */
        if ((numberValue == null || !isNaN(numberValue)) && numberValue !== this.props.value) {
            this.props.onChange(numberValue);
        }

        this.setState(s => ({
            ...s,
            workingValue: newWorkingValue
        }));
    };

    private onFocus = () => {
        this.setState(s => ({
            ...s,
            workingValue: this.props.value != null ? Formatter.formatNumber(this.props.value) : "",
            isFocused: true
        }));
    };

    private onBlur = () => {
        /* Reset state to match the props value to ensure field value matches stored value */
        this.setState(s => ({
            ...s,
            workingValue: this.props.value != null ? Formatter.formatNumber(this.props.value) : "",
            isFocused: false
        }));
    };
}
