import { push } from "redux-first-history";

import API from "api";
import { NetworkRequest } from "api/actions";
import { SpendingAccountActivity } from "modules/benefits/spendingAccounts/models.activity";

import { ApplicationState as AppState, AppThunkAction } from "store";
import * as actions from "./actions";
import { MembershipSpendingAccountList, SpendingAccountActivityTab } from "./models";

import { ClaimListPage, ClaimsFilterState } from "modules/claims/claimList/models";
import { AddNotificationAction } from "modules/notifications/actions";
import { NotificationStyle } from "modules/notifications/models";
import { UpdatePersonMaskedSocialInsuranceNumber } from "modules/person/actions";
import { Formatter } from "modules/utils";
import { SpendingAccountClaimCategoryActionCreators } from "modules/claims/spendingAccountClaimCategories/actionCreator";
import { RouterAction } from "routes";

export type HandledAction =
    | actions.GetSpendingAccountsInitAction
    | actions.GetSpendingAccountsCompleteAction
    | actions.GetSpendingAccountActivityInitAction
    | actions.GetSpendingAccountActivityCompleteAction
    | actions.SubmitAllocationInitAction
    | actions.SubmitAllocationCompleteAction
    | actions.EditAllocationInitAction
    | actions.CancelEditAllocationInitAction
    | actions.SetActiveSpendingAccountActivityTabAction
    | actions.GetSpendingAccountClaimsInitAction
    | actions.GetSpendingAccountClaimsCompleteAction;

export type SpendingAccountActions =
    | HandledAction
    | NetworkRequest
    | RouterAction
    | UpdatePersonMaskedSocialInsuranceNumber
    | AddNotificationAction;

let spendingAccountsPromise = null;

export const SpendingAccountActionCreators = {
    getSpendingAccounts:
        (resetExistingSpendingAccountsPromise = false): AppThunkAction<SpendingAccountActions> =>
        (dispatch, getState: () => AppState) => {
            if (resetExistingSpendingAccountsPromise) {
                spendingAccountsPromise = null;
            }

            if (spendingAccountsPromise == null) {
                dispatch({ type: "GET_SPENDING_ACCOUNTS_INIT" });

                spendingAccountsPromise = dispatch(API.spendingAccounts.getSpendingAccounts())
                    .then(
                        (response) => {
                            if (response?.status === 200) {
                                return response.json() as Promise<MembershipSpendingAccountList[]>;
                            } else {
                                dispatch({
                                    type: "GET_SPENDING_ACCOUNTS_COMPLETE",
                                    spendingAccounts: null,
                                    succeeded: false,
                                    err: "Failed to get spending accounts"
                                });
                                return null;
                            }
                        },
                        () => null
                    )
                    .then(async (spendingAccounts: MembershipSpendingAccountList[]) => {
                        if (spendingAccounts) {
                            await dispatch({
                                type: "GET_SPENDING_ACCOUNTS_COMPLETE",
                                spendingAccounts,
                                succeeded: true
                            });

                            await dispatch(
                                SpendingAccountClaimCategoryActionCreators.getSpendingAccountClaimCategories(
                                    resetExistingSpendingAccountsPromise
                                )
                            );

                            return spendingAccounts;
                        }
                        return null;
                    });
            }
            return spendingAccountsPromise;
        },
    getSpendingAccountActivity:
        (spendingAccountId: number): AppThunkAction<SpendingAccountActions> =>
        (dispatch, getState: () => AppState) => {
            dispatch({ type: "GET_SPENDING_ACCOUNT_ACTIVITY_INIT" });

            return dispatch(API.spendingAccounts.getSpendingAccountActivity(spendingAccountId))
                .then(
                    (response) => {
                        if (response?.status === 200) {
                            return response.json() as Promise<SpendingAccountActivity>;
                        } else {
                            dispatch({
                                type: "GET_SPENDING_ACCOUNT_ACTIVITY_COMPLETE",
                                spendingAccountActivity: null,
                                succeeded: false,
                                err: "Failed to get spending account activity"
                            });
                            return null;
                        }
                    },
                    () => null
                )
                .then((spendingAccountActivity: SpendingAccountActivity) => {
                    if (spendingAccountActivity) {
                        dispatch({
                            type: "GET_SPENDING_ACCOUNT_ACTIVITY_COMPLETE",
                            spendingAccountActivity,
                            succeeded: true
                        });
                        dispatch(SpendingAccountActionCreators.getSpendingAccountClaims(spendingAccountId));
                    }
                    return null;
                });
        },
    getSpendingAccountClaims:
        (spendingAccountId: number): AppThunkAction<SpendingAccountActions> =>
        (dispatch, getState: () => AppState) => {
            dispatch({ type: "GET_SPENDING_ACCOUNT_CLAIMS_INIT" });
            const spendingAccountClaimsFilter = {
                spendingAccountId,
                view: "All",
                pageNumber: 1,
                format: "Flat",
                pageSize: 100000
            } as ClaimsFilterState;

            return dispatch(API.claims.getClaims(spendingAccountClaimsFilter))
                .then(
                    (response) => {
                        if (response?.status === 200) {
                            return response.json() as Promise<ClaimListPage>;
                        } else {
                            dispatch({
                                type: "GET_SPENDING_ACCOUNT_CLAIMS_COMPLETE",
                                spendingAccountId,
                                claimsResponse: null,
                                succeeded: false,
                                err: "Failed to get spending account claims"
                            });
                            return null;
                        }
                    },
                    () => null
                )
                .then((claimsResponse: ClaimListPage) => {
                    if (claimsResponse) {
                        dispatch({
                            type: "GET_SPENDING_ACCOUNT_CLAIMS_COMPLETE",
                            spendingAccountId,
                            claimsResponse,
                            succeeded: true
                        });
                    }
                    return null;
                });
        },
    editAllocation: (): AppThunkAction<SpendingAccountActions> => (dispatch, getState: () => AppState) => {
        dispatch({ type: "START_EDIT_ALLOCATION_INIT" });
    },

    cancelEditAlloction: (): AppThunkAction<SpendingAccountActions> => (dispatch, getState: () => AppState) => {
        dispatch({ type: "CANCEL_EDIT_ALLOCATION_INIT" });
    },

    submitAllocation:
        (
            hsaAllocationPercentage: number,
            wsaAllocationPercentage: number,
            coveragePeriodId: number,
            selectedSpendingAccountId: number,
            membershipId: number,
            socialInsuranceNumber?: string
        ): AppThunkAction<SpendingAccountActions> =>
        (dispatch, getState: () => AppState) => {
            dispatch({ type: "SUBMIT_ALLOCATION_INIT" });

            return dispatch(
                API.spendingAccounts.submitAllocation(
                    hsaAllocationPercentage,
                    wsaAllocationPercentage,
                    coveragePeriodId,
                    membershipId,
                    socialInsuranceNumber
                )
            )
                .then((response) => {
                    if (response?.status === 200) {
                        return response.json() as Promise<number[]>;
                    } else {
                        dispatch({
                            type: "SUBMIT_ALLOCATION_COMPLETE",
                            succeeded: false,
                            error: "Failed to set allocation amount."
                        });
                        return null;
                    }
                })
                .then((spendingAccountIds: number[]) => {
                    if (spendingAccountIds) {
                        dispatch(SpendingAccountActionCreators.getSpendingAccounts(true));
                        if (spendingAccountIds.indexOf(selectedSpendingAccountId) < 0) {
                            selectedSpendingAccountId = spendingAccountIds[0];
                            dispatch(push(`/benefits/spending-accounts`));
                        }
                        dispatch(SpendingAccountActionCreators.getSpendingAccountActivity(selectedSpendingAccountId));
                        if (socialInsuranceNumber) {
                            dispatch({
                                type: "UPDATE_PERSON_MASKED_SOCIALINSURANCENUMBER",
                                maskedSocialInsuranceNumber: Formatter.maskSocialInsuranceNumber(socialInsuranceNumber)
                            });
                        }
                        dispatch({
                            type: "ADD_NOTIFICATION",
                            message: "Success! Your allocation is set.",
                            style: NotificationStyle.Success,
                            autohide: true,
                            canDismiss: true
                        });
                        dispatch({ type: "SUBMIT_ALLOCATION_COMPLETE", succeeded: true });
                    } else {
                        dispatch({
                            type: "SUBMIT_ALLOCATION_COMPLETE",
                            succeeded: false,
                            error: "Failed to set allocation amount."
                        });
                    }
                    return null;
                });
        },
    setActiveSpendingAccountActivityTab:
        (tab: SpendingAccountActivityTab): AppThunkAction<SpendingAccountActions> =>
        (dispatch, getState: () => AppState) => {
            dispatch({ type: "SET_SPENDING_ACCOUNT_ACTIVITY_TAB", tab });
        }
};
