import { Reducer } from "redux";
import { HandledAction } from "./actionCreator";
import { DentalGuideState, DentalGuideTopicSummary, DetailedDentalGuideTopicDict } from "./models";

export type KnownAction = HandledAction;

export const reducer: Reducer<DentalGuideState> = (state: DentalGuideState, action: KnownAction) => {
    switch (action.type) {
        case "GET_DENTAL_GUIDE_TOPIC_SUMMARY_INIT": {
            return {
                ...state,
                isFetchingSummary: true
            };
        }
        case "GET_DENTAL_GUIDE_TOPIC_SUMMARY_COMPLETE": {
            if (!action.succeeded) {
                return {
                    ...state,
                    isFetchingSummary: false,
                    errorMessage: action.err
                };
            }

            return {
                ...state,
                isFetchingSummary: false,
                dentalGuideTopics: action.dentalGuideTopicSummaries,
                errorMessage: null
            };
        }
        case "GET_DENTAL_GUIDE_TOPIC_DETAILS_INIT": {
            return {
                ...state,
                isFetchingDetails: true,
                selectedTopicId: action.topicId
            };
        }
        case "GET_DENTAL_GUIDE_TOPIC_DETAILS_COMPLETE": {
            if (action.succeeded && action.dentalGuideTopicDetails.id.toString() === state.selectedTopicId) {
                return {
                    ...state,
                    isFetchingDetails: false,
                    dentalGuideTopicDetails: {
                        ...state.dentalGuideTopicDetails,
                        [action.dentalGuideTopicDetails.id]: action.dentalGuideTopicDetails
                    }
                };
            }

            return {
                ...state,
                isFetchingDetails: false,
                errorMessage: action.err
            };
        }
        case "GET_DENTAL_PROCEDURE_DETAILS_INIT_ACTION": {
            return {
                ...state,
                isFetchingProcedure: true,
                selectedProcedureCode: action.procedureCode
            };
        }
        case "GET_DENTAL_PROCEDURE_DETAILS_COMPLETE_ACTION": {
            if (action.succeeded && action.dentalProcedureDetails.productExternalId === state.selectedProcedureCode) {
                return {
                    ...state,
                    isFetchingProcedure: false,
                    dentalProcedureDetails: action.dentalProcedureDetails,
                    failedToFetch: false
                };
            }

            return {
                ...state,
                isFetchingProcedure: false,
                dentalProcedureDetails: {
                    ...state.dentalProcedureDetails,
                    productExternalId: state.selectedProcedureCode
                },
                errorMessage: action.err,
                failedToFetch: true
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return (
        state ||
        ({
            isFetchingSummary: false,
            isFetchingDetails: false,
            isFetchingProcedure: false,
            failedToFetch: false,
            dentalProcedureDetails: null,
            selectedTopicId: null,
            selectedProcedureCode: null,
            dentalGuideTopics: [] as DentalGuideTopicSummary[],
            dentalGuideTopicDetails: {} as DetailedDentalGuideTopicDict
        } as DentalGuideState)
    );
};
