import { isBefore } from "date-fns";
import { FeatureArticle, FeatureToggles, UserActivity, FeatureToggleValue, ToggleableFeature } from "./models";

export const UnreadFeatureArticleCalculator = {
    calculateHasUnreadFeatureArticles: (articles: FeatureArticle[], featureToggles: FeatureToggles, lastViewedFeatureArticles: UserActivity) => {
        const lastViewedDate = lastViewedFeatureArticles && lastViewedFeatureArticles.value ? new Date(lastViewedFeatureArticles.value) : null;
        const hasUnreadFeatureArticles =
            lastViewedDate &&
            articles &&
            articles.length &&
            articles.some(a => {
                if (a.isPublished) {
                    if (a.lastPublishedDateTime && isBefore(lastViewedDate, new Date(a.lastPublishedDateTime))) {
                        return true;
                    } else if (a.toggleableFeatureKey) {
                        const featureToggle = featureToggles[a.toggleableFeatureKey] as ToggleableFeature;
                        if (featureToggle.toggleValue === FeatureToggleValue.On && featureToggle.lastActivatedDateTime && isBefore(lastViewedDate, new Date(featureToggle.lastActivatedDateTime))) {
                            return true;
                        }
                    }
                }
                return false;
            });

        return hasUnreadFeatureArticles;
    }
};
