import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router";
import { Location } from "history";

export default function ConfirmUnsavedChanges({ isDirty }: { isDirty: boolean }) {
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [promiseResolveCallback, setPromiseResolveCallback] = useState<{ resolve: (value: any) => void }>(null);
    const [nextLocation, setNextLocation] = useState<Location>();
    const [navigateAway, setNavigateAway] = useState(false);

    useEffect(() => {
        if (nextLocation && navigateAway) {
            history.push(nextLocation);
            setNavigateAway(false); // reset to false
        }
    }, [nextLocation, navigateAway, history]);

    // To block navigation outside of application routes (external links or new page requests from the server e.g. /logout),
    // subscribe to the window beforeunload event. This will trigger the browser's own confirmation dialog. 
    useEffect(() => {
        const onBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = true;
        }
        
        if (isDirty) {
            window.addEventListener("beforeunload", onBeforeUnload);
            return () => window.removeEventListener("beforeunload", onBeforeUnload);
        }
    }, [isDirty]);

    const confirmNavigateAway = () => {
        return new Promise((resolve) => {
            setPromiseResolveCallback({ resolve });
            setOpenDialog(true);
        });
    };

    const onConfirmDiscard = () => {
        promiseResolveCallback.resolve(true);
        setOpenDialog(false);
    };

    const onCancel = () => {
        promiseResolveCallback.resolve(false);
        setOpenDialog(false);
    };

    return (
        <>
            <Prompt
                when={isDirty}
                message={(location: Location) => {
                    if (!navigateAway) {
                        setNextLocation(location);
                        (async () => {
                            const confirmation = await confirmNavigateAway();
                            if (confirmation) {
                                setNavigateAway(true);
                            }
                        })();
                        return false;
                    } 
                    return true; // allow user to navigate away
                }}
            />
            <Dialog open={openDialog}>
                <DialogContent>You haven't saved. Are you sure you want to discard your changes?</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onConfirmDiscard}>Discard Changes</Button>
                    <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
