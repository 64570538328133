import * as React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { createPopper, Instance as PopperInstance } from "@popperjs/core";
import { ClickAwayListener, Button, Icon, Popper, Fade, Paper, Grid, Typography, List, ListItem } from "@mui/material";
import { Markdown } from "modules/common/components/misc";
import { FeatureArticlePreview, FeatureToggles, FeatureToggleValue } from "../models";
import { PilotChip } from "./pilotChip";

export interface FeatureHighlightButtonProps {
    renderWithIcon?: boolean;
    className?: string;
    articles: FeatureArticlePreview[];
    featureToggles: FeatureToggles;
    updateLastViewed: () => any;
    hasUnreadFeatureArticles: boolean;
}

export interface FeatureHighlightButtonState {
    anchorEl: HTMLElement;
    arrowRef: HTMLElement;
}

export class FeatureHighlightButton extends React.Component<FeatureHighlightButtonProps, FeatureHighlightButtonState> {
    public state: FeatureHighlightButtonState = { anchorEl: null, arrowRef: null };
    public popperInstance: PopperInstance | null = null;

    private handlePopperRef = (popperNode: HTMLElement | null) => {
        if (popperNode) {
          this.popperInstance = createPopper(this.state.anchorEl, popperNode, {
            modifiers: [
              {
                name: "flip",
                options: {
                  enabled: false,
                },
              },
              {
                name: "preventOverflow",
                options: {
                  enabled: true,
                  boundary: "scrollParent",
                },
              },
              {
                name: "arrow",
                options: {
                  enabled: true,
                  element: this.state.arrowRef,
                },
              },
            ],
          });
        } else {
          if (this.popperInstance) {
            this.popperInstance.destroy();
            this.popperInstance = null;
          }
        }
      };

    public render() {
        const { className = "", featureToggles, articles, hasUnreadFeatureArticles } = this.props;
        const { anchorEl, arrowRef } = this.state;

        const availableFeatureArticles =
            articles && articles.length ? articles.filter(article => article.isPublished && (!featureToggles[article.toggleableFeatureKey] || featureToggles[article.toggleableFeatureKey].toggleValue === FeatureToggleValue.On)) : [];

        const allLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
            return <RouterLink {...props} innerRef={ref as any} />;
        });

        return (
            <ClickAwayListener onClickAway={this.handleClose}>
                <div className={`${ this.props.renderWithIcon ? "with-icon" : ""}`}>
                    <div className={`feature-highlight-btn ${className} ${hasUnreadFeatureArticles ? "unread" : ""}`}>
                        <Button onClick={this.toggleOpen}>
                            <div className="icon-container">
                                <Icon className={hasUnreadFeatureArticles ? "ring" : ""}>notification_important</Icon>
                            </div>
                            What&apos;s new
                        </Button>
                    </div>
                    <Popper
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        placement="bottom"
                        transition
                        ref={this.handlePopperRef}
                        className="feature-highlight-popover">
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <div>
                                    <span className="feature-highlight-popover-arrow" ref={this.handleArrowRef} />
                                    <Paper>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="feature-highlight-popover-header">
                                                    <Typography variant="h6">Woo hoo! New feature alert!</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div>
                                                    {availableFeatureArticles && availableFeatureArticles.length ? (
                                                        <List className="feature-highlight-preview-list">
                                                            {availableFeatureArticles.slice(0, 3).map(a => {
                                                                const link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
                                                                    return <RouterLink {...props} innerRef={ref as any} />;
                                                                });
                                                                const featureToggle = a.toggleableFeatureKey ? featureToggles[a.toggleableFeatureKey] : null;

                                                                return (
                                                                    <ListItem key={a.id} divider>
                                                                        <Grid container>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="h6">
                                                                                    {a.title}
                                                                                    {featureToggle && featureToggle.isPilotOnly ? <PilotChip /> : null}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Markdown source={a.preview} />
                                                                            </Grid>
                                                                            <Grid item xs={12} className="feature-highlight-preview-list-item-actions">
                                                                                <Button component={link} to={`/whats-new/${a.id}`} color="primary" onClick={this.handleClose}>
                                                                                    Read more
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    ) : (
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <div className="caught-up-msg">
                                                                    <p>
                                                                        You&apos;re all caught up 👌
                                                                        <br />
                                                                        <br /> Check back here for new features &amp; more information on improvements to My ASBEP
                                                                    </p>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </div>
                                            </Grid>
                                            {availableFeatureArticles && availableFeatureArticles.length ? (
                                                <Grid item xs={12}>
                                                    <div className="feature-highlight-popover-actions">
                                                        <Button component={allLink} to={`/whats-new`} color="secondary" onClick={this.handleClose}>
                                                            View more updates
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </Paper>
                                </div>
                            </Fade>
                        )}
                    </Popper>
                </div>
            </ClickAwayListener>
        );
    }

    private toggleOpen = event => {
        const isOpen = Boolean(this.state.anchorEl);
        const { currentTarget } = event;

        this.props.updateLastViewed();

        this.setState(s => ({ ...s, anchorEl: isOpen ? null : currentTarget }));
    };

    private handleArrowRef = node => {
        this.setState(s => ({
            ...s,
            arrowRef: node
        }));
    };

    private handleClose = () => {
        setTimeout(() => {
            this.setState(s => ({ ...s, anchorEl: null }));
        }, 100);
    };
}
