import * as React from "react";
import { FormControl, Grid, FormLabel, FormHelperText } from "@mui/material";

import { PlaceholderLine } from "modules/common/components/layout";
import { NumberField } from "./numberField";

export interface FormNumberFieldProps {
    tombstone?: boolean;
    errorText?: string | null;
    title?: string;
    disabled?: boolean;
    helperText?: string;
    id?: string;
    className?: string;
    fullWidth?: boolean;
    value: number | null;
    adornmentIcon?: string;
    onChanged: (value: number | null) => any;
}
export class FormNumberField extends React.PureComponent<FormNumberFieldProps> {
    public render() {
        const hasError = !!this.props.errorText;
        return (
            <Grid item xs={12}>
                {this.props.tombstone ? (
                    <div>
                        <PlaceholderLine thickness="thin" />
                        <PlaceholderLine thickness="thick" color="dark" />
                    </div>
                ) : (
                    <FormControl fullWidth={this.props.fullWidth} error={hasError}>
                        <FormLabel className="control-label">{this.props.title}</FormLabel>
                        <NumberField
                            id={this.props.id}
                            error={hasError}
                            value={this.props.value}
                            onChange={this.props.onChanged}
                            adornmentIcon="attach_money"
                            disabled={this.props.disabled}
                            fullWidth={this.props.fullWidth}
                            className="control-text"
                        />
                        {hasError ? <FormHelperText className="error-text">{this.props.errorText}</FormHelperText> : null}
                        {this.props.helperText ? <FormHelperText>{this.props.helperText}</FormHelperText> : null}
                    </FormControl>
                )}
            </Grid>
        );
    }
}
