import { Reducer } from "redux";

import { HandledAction } from "./actionCreator";
import { FeaturesState, FeatureToggles, UserActivities, UserActivityKeys, FeatureArticle } from "./models";
import { UnreadFeatureArticleCalculator } from "./unreadFeatureArticleCalculator";
import cloneDeep from 'clone-deep';

export type KnownAction = HandledAction;

export const reducer: Reducer<FeaturesState> = (state: FeaturesState, action: KnownAction) => {
    switch (action.type) {
        case "GET_TOGGLEABLE_FEATURES_INIT": {
            return { ...state
            };
        }
        case "GET_TOGGLEABLE_FEATURES_COMPLETE": {
            const newState = cloneDeep(state);
            
            if (action.succeeded) {
                for (const toggle of action.toggleableFeatures) {
                    newState.featureToggles[toggle.key] = toggle;
                }
            }
            return newState;
        }
        case "GET_FEATURE_ARTICLE_PREVIEWS_INIT": {
            return {
                ...state,
                featureArticleList: {
                    ...state.featureArticleList,
                    isFetching: true
                }
            };
        }
        case "GET_FEATURE_ARTICLE_PREVIEWS_COMPLETE": {
            const articles = action.succeeded ? action.articles : state.featureArticleList.articles;
            if (action.succeeded && state.featureArticleList.articles) {
                for (const article of state.featureArticleList.articles) {
                    const newVersion = articles.find(a => a.id === article.id) as FeatureArticle;
                    if (newVersion) {
                        if (article.content) {
                            newVersion.content = article.content;
                        }
                    }
                }
            }
            const newState = {
                ...state,
                featureArticleList: {
                    ...state.featureArticleList,
                    articles,
                    isFetching: false
                }
            };
            newState.hasUnreadFeatureArticles = UnreadFeatureArticleCalculator.calculateHasUnreadFeatureArticles(
                newState.featureArticleList.articles || [],
                newState.featureToggles,
                newState.userActivities[UserActivityKeys.LastViewedFeatureArticles]
            );

            return newState;
        }
        case "GET_FEATURE_ARTICLE_INIT": {
            const newState = {
                ...state,
                featureArticleList: {
                    ...state.featureArticleList,
                    articles:
                        state.featureArticleList.articles && state.featureArticleList.articles.length
                            ? state.featureArticleList.articles.map(article => {
                                  return {
                                      ...article,
                                      isFetchingContent: true
                                  };
                              })
                            : null
                }
            };
            newState.hasUnreadFeatureArticles = UnreadFeatureArticleCalculator.calculateHasUnreadFeatureArticles(
                newState.featureArticleList.articles || [],
                newState.featureToggles,
                newState.userActivities[UserActivityKeys.LastViewedFeatureArticles]
            );
            return newState;
        }
        case "GET_FEATURE_ARTICLE_COMPLETE": {
            const newState = {
                ...state,
                featureArticleList: {
                    ...state.featureArticleList,
                    articles:
                        state.featureArticleList.articles && state.featureArticleList.articles.length
                            ? state.featureArticleList.articles.map(article => {
                                  if (article.id === action.id) {
                                      return {
                                          ...action.article,
                                          isFetchingContent: false
                                      };
                                  }
                                  return {
                                      ...article,
                                      isFetchingContent: false
                                  };
                              })
                            : [
                                  {
                                      ...action.article,
                                      isFetchingContent: false
                                  }
                              ]
                }
            };
            newState.hasUnreadFeatureArticles = UnreadFeatureArticleCalculator.calculateHasUnreadFeatureArticles(
                newState.featureArticleList.articles || [],
                newState.featureToggles,
                newState.userActivities[UserActivityKeys.LastViewedFeatureArticles]
            );
            return newState;
        }
        case "GET_USER_ACTIVITY_INIT": {
            return state;
        }
        case "GET_USER_ACTIVITY_COMPLETE": {
            const newState = {
                ...state,
                userActivities: {
                    ...state.userActivities,
                    [action.key]: action.succeeded ? action.activity : state.userActivities[action.key]
                }
            };
            newState.hasUnreadFeatureArticles = UnreadFeatureArticleCalculator.calculateHasUnreadFeatureArticles(
                newState.featureArticleList.articles || [],
                newState.featureToggles,
                newState.userActivities[UserActivityKeys.LastViewedFeatureArticles]
            );
            return newState;
        }
        case "SAVE_USER_ACTIVITY_INIT": {
            return {...state};
        }
        case "SAVE_USER_ACTIVITY_COMPLETE": {
            const newState = {
                ...state,
                userActivities: {
                    ...state.userActivities,
                    [action.key]: action.succeeded ? action.activity : state.userActivities[action.key]
                }
            };

            newState.hasUnreadFeatureArticles = UnreadFeatureArticleCalculator.calculateHasUnreadFeatureArticles(
                newState.featureArticleList.articles || [],
                newState.featureToggles,
                newState.userActivities[UserActivityKeys.LastViewedFeatureArticles]
            );
            return newState;
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return (
        state || {
            featureToggles: {} as FeatureToggles,
            featureArticleList: {
                articles: null,
                isFetching: false
            },
            userActivities: {} as UserActivities,
            hasUnreadFeatureArticles: false
        }
    );
};
