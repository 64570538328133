export class UrlBuilder {
    private url: string;

    public constructor(baseUri: string) {
        this.url = baseUri;
    }

    public addPath = (path: string): UrlBuilder => {
        if (!path.startsWith("/")) {
            throw new Error("Invalid Request URL; path must start with /");
        }

        this.url += path;
        return this;
    };

    public mapParams = (params: any) => {
        const keys = Object.keys(params);
        for (let i = 0; i < keys.length; i++) {
            if (this.url.indexOf(keys[i]) < 0) {
                throw new Error("Invalid Path Parameter");
            }

            this.url = this.url.replace(`{${keys[i]}}`, params[keys[i]]);
        }

        return this;
    };

    public addQueryParams = (queryParams: { [key: string]: string }): UrlBuilder => {
        if (Object.keys(queryParams).length === 0) {
            return this;
        }

        const queryString = Object.keys(queryParams)
            .map((key) => {
                const val = queryParams[key];
                if (Array.isArray(val)) {
                    return val
                        .map((i) => {
                            return key + "=" + i;
                        })
                        .join("&");
                } else {
                    return key + "=" + val;
                }
            })
            .reduce((a, b) => {
                return a && a.length > 0 ? a + "&" + b : b;
            }, "");

        if (this.url.indexOf("?") < 0) {
            this.url += "?" + queryString;
        } else {
            this.url += "&" + queryString;
        }

        return this;
    };

    public getUrl = (skipEncodeURI?: boolean): string => {
        return skipEncodeURI ? this.url : encodeURI(this.url);
    };
}

// Some info can be found here: https://thisthat.dev/encode-uri-vs-encode-uri-component/
export function fullEncodeURI(str: string): string {
    return str
        ? encodeURIComponent(str)
              .replace(/\-/g, "%2D")
              .replace(/\_/g, "%5F")
              .replace(/\./g, "%2E")
              .replace(/\!/g, "%21")
              .replace(/\~/g, "%7E")
              .replace(/\*/g, "%2A")
              .replace(/\'/g, "%27")
              .replace(/\(/g, "%28")
              .replace(/\)/g, "%29")
              .replace(/\//g, "%2F")
        : str;
}

export function fullDecodeURI(str): string {
    return str
        ? decodeURIComponent(
              str
                  .replace(/\\%2D/g, "-")
                  .replace(/\\%5F/g, "_")
                  .replace(/\\%2E/g, ".")
                  .replace(/\\%21/g, "!")
                  .replace(/\\%7E/g, "~")
                  .replace(/\\%2A/g, "*")
                  .replace(/\\%27/g, "'")
                  .replace(/\\%28/g, "(")
                  .replace(/\\%29/g, ")")
                  .replace(/\\%2F/g, "/")
          )
        : str;
}
