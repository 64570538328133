import { NetworkRequest } from "./actions";

export const searchCuratedSearchResults = (text: string): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: `/api/search/curatedSearchResults/query?text=${text}`,
        params: {
            text
        }
    };
};

export const trackCuratedSearchResultSelection = (searchId: string, selectedDocumentId: string, rank: number): NetworkRequest => {
    return {
        type: "NETWORK_REQUEST",
        method: "POST",
        url: `/api/search/curatedSearchResults/track`,
        body: {
            searchId,
            selectedDocumentId,
            rank
        }
    };
};
