import { ApplicationState } from "store";
import { ConversationModel, ConversationsState, MessageModel } from "./models";

/* This class is used to get the conversation and message models for the current conversation and
message */
export class ConversationUtils {
    private _conversationId: number;
    private _messageId?: number;
    private _getState: () => ApplicationState;

    constructor(getState: () => ApplicationState, conversationId: number, messageId?: number) {
        this._getState = getState;
        this._conversationId = conversationId;
        this._messageId = messageId;
    }

    /**
     * It creates a new instance of the class.
     * @param getState - A function that returns the current state of the application.
     * @param {number} conversationId - The ID of the conversation.
     * @param {number} [messageId] - The optional ID of the message related to the conversation.
     * @returns The new instance of ConversationUtils.
     */
    public static newInstance(
        getState: () => ApplicationState,
        conversationId: number,
        messageId?: number
    ): ConversationUtils {
        return new this(getState, conversationId, messageId);
    }

    private get getConversationState(): ConversationsState {
        return this._getState()?.inbox?.conversationsState;
    }

    private shouldUpdate = (versionToCompare: number, currentStateVersion: number): boolean => {
        if (currentStateVersion > versionToCompare) {
            return false;
        }
        return true;
    };

    /**
     * Get the version of the conversation
     * @returns The conversation version.
     */
    get conversationVersion(): number {
        return this.conversation?.timestamp;
    }

    /**
     * `getConversation` returns the conversation with the given id
     * @returns The conversation model for the current conversation.
     */
    get conversation(): ConversationModel {
        return this.getConversationState?.conversations?.find((c) => c.id === this._conversationId);
    }

    /**
     * `getConversationMessage` returns the message that is associated with the current messageId
     * @returns The message that was in the conversation.
     */
    get conversationMessage(): MessageModel {
        return this.conversation?.messages?.find((m) => m.id === this._messageId);
    }

    /**
     * Get the version of the conversation message
     * @returns The conversation message.
     */
    get conversationMessageVersion(): number {
        return this.conversationMessage?.timestamp;
    }

    /* A helper function to determine if the conversation should be updated. */
    public shouldUpdateConversation = (versionToCompare: number): boolean => {
        return this.shouldUpdate(versionToCompare, this.conversationVersion);
    };

    /* A helper function that determines if the message should be updated. */
    public shouldUpdateMessage = (versionToCompare: number): boolean => {
        return this.shouldUpdate(versionToCompare, this.conversationMessageVersion);
    };
}
