import * as React from "react";
import {
    FormControl,
    FormLabel,
    TextField,
    InputAdornment,
    Tooltip,
    ButtonBase,
    Icon,
    FormHelperText
} from "@mui/material";

import { PlaceholderLine } from "modules/common/components/layout";

export interface FormPasswordFieldProps {
    tombstone?: boolean;
    onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    onChanged?: (e: any) => void;
    placeholder?: string;
    errorText?: string | null;
    title?: string;
    value?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    helperText?: string;
    maxLength?: number;
    className?: string;
    id?: string;
    autocomplete?: string;
    inputRef?: React.Ref<any> | React.RefObject<any>;
    extraHelperTextComponent?: JSX.Element;
    showHidePasswordButtonClassName?: string;
}

interface FormPasswordFieldState {
    isPasswordMasked: boolean;
}

export class FormPasswordField extends React.PureComponent<FormPasswordFieldProps, FormPasswordFieldState> {
    public state: FormPasswordFieldState = { isPasswordMasked: true };

    public render() {
        const hasError = !!this.props.errorText;

        return (
            <>
                {this.props.tombstone ? (
                    <div>
                        <PlaceholderLine thickness="thin" />
                        <PlaceholderLine thickness="thick" color="dark" />
                    </div>
                ) : (
                    <FormControl
                        fullWidth={this.props.fullWidth}
                        error={hasError}
                        className={"form-password-field " + this.props.className}>
                        <FormLabel htmlFor={this.props.id}>{this.props.title}</FormLabel>
                        <TextField
                            variant="standard"
                            className="control-text"
                            fullWidth={this.props.fullWidth}
                            autoComplete={this.props.autocomplete || "off"}
                            onKeyPress={this.props.onKeyPress}
                            error={hasError}
                            value={this.props.value}
                            onChange={this.props.onChanged}
                            placeholder={this.props.placeholder}
                            disabled={this.props.disabled}
                            inputRef={this.props.inputRef}
                            id={this.props.id}
                            name={this.props.id}
                            type={this.state.isPasswordMasked ? "password" : "text"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={
                                            "show-hide-password-btn " +
                                            (this.props.showHidePasswordButtonClassName
                                                ? this.props.showHidePasswordButtonClassName
                                                : null)
                                        }>
                                        <Tooltip
                                            title={(this.state.isPasswordMasked ? "Show" : "Hide") + " password"}
                                            enterDelay={100}>
                                            <ButtonBase
                                                id="password-visibility-button"
                                                className="visibility-indicator"
                                                aria-label="Toggle password visibility"
                                                onClick={this.togglePasswordMask}>
                                                <Icon>
                                                    {this.state.isPasswordMasked ? "visibility" : "visibility_off"}
                                                </Icon>
                                            </ButtonBase>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {this.props.extraHelperTextComponent ? (
                            <FormHelperText sx={{ marginLeft: 0 }}>
                                {this.props.extraHelperTextComponent}
                            </FormHelperText>
                        ) : null}
                        {hasError ? (
                            <FormHelperText sx={{ marginLeft: 0 }} id="edit-password-error-text" className="error-text">
                                {this.props.errorText}
                            </FormHelperText>
                        ) : null}
                        {this.props.helperText ? (
                            <FormHelperText sx={{ marginLeft: 0 }}>{this.props.helperText}</FormHelperText>
                        ) : null}
                    </FormControl>
                )}
            </>
        );
    }

    private togglePasswordMask = () => {
        this.setState((s) => ({ ...s, isPasswordMasked: !s.isPasswordMasked }));
    };
}
