import * as React from "react";
import { Divider } from "@mui/material";
import { AsebpLogo } from "modules/common/components/icons";

import { VoStatusEnum } from "modules/person/models";
import { CoveredMemberSummary } from "modules/person/coveredMemberSummary.models";

export interface MyAsebpCardFaceProps {
    isFetching: boolean;
    coveredMember: CoveredMemberSummary;
}

export class MyAsebpCardFace extends React.Component<MyAsebpCardFaceProps> {
    public render() {
        const { coveredMember } = this.props;

        return (
            <div className="card-holder">
                <div className="card-face">
                    <table className="card-body member">
                        <thead>
                            <tr>
                                <th className="title">ID NO.</th>
                                <th className="title">Covered Member</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span>{coveredMember.certificateNumber}</span>
                                </td>
                                <td>
                                    <span className="name">{coveredMember.person.name.fullName} </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {coveredMember.hasActiveCoverage ? (
                        coveredMember.hasActiveFamilyGeneralHealthCoverage &&
                        coveredMember.activeDependants.length > 0 ? (
                            <table className="card-body dependant">
                                <thead>
                                    <tr>
                                        <th className="title" />
                                        <th className="title">Dependant</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(coveredMember.activeDependants || []).map((person) => {
                                        if (
                                            person.personStatus.isDependant &&
                                            person.combinedStatus &&
                                            (person.combinedStatus.statusCode === VoStatusEnum.Active ||
                                                person.combinedStatus.hasUpcomingTermination)
                                        ) {
                                            return (
                                                <tr key={person.certificateNumber}>
                                                    <td>
                                                        <span>{person.certificateNumber}</span>
                                                    </td>
                                                    <td>
                                                        <span className="name">{person.name.fullName} </span>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                        ) : null
                    ) : (
                        <div className="no-active-benefits">
                            <Divider />
                            {!this.props.isFetching ? <span>No active benefits</span> : <span />}
                            <Divider />
                        </div>
                    )}
                    <div className="card-footer">
                        <div className="section-id">
                            <span className="title">Group </span>
                            <span>{coveredMember.group} </span>
                            <span className="title section">Section </span>
                            <span>{coveredMember.sectionCode}</span>
                        </div>
                        <div className="logo">
                            <AsebpLogo />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
