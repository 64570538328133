import { BenefitCode, BenefitCoverageLevel } from "./models";

export function isIncomeReplacement(bt: string): boolean {
    switch (bt.toUpperCase()) {
        case BenefitCode.Life:
        case BenefitCode.AccidentalDeathAndDismemberment:
        case BenefitCode.ExtendedDisability:
            return true;

        default:
            return false;
    }
}

export function isSpendingAccount(bt: string): boolean {
    switch (bt.toUpperCase()) {
        case BenefitCode.WSA:
        case BenefitCode.HSA:
            return true;

        default:
            return false;
    }
}

export function isGeneralHealthBenefit(bt: string): boolean {
    switch (bt.toUpperCase()) {
        case BenefitCode.EHC:
        case BenefitCode.Vision:
        case BenefitCode.Dental:
            return true;

        default:
            return false;
    }
}

export function toCoverageDisplay(benefitType: string, cov: string) {
    if (isIncomeReplacement(benefitType)) {
        return BenefitCoverageLevel.Single;
    }
    if (isSpendingAccount(benefitType)) {
        return BenefitCoverageLevel.Family;
    }
    if (cov.toUpperCase() !== BenefitCoverageLevel.Couple.toUpperCase() 
        && cov.toUpperCase() !== BenefitCoverageLevel.Family.toUpperCase() 
        && cov.toUpperCase() !== BenefitCoverageLevel.Couple.toUpperCase()) {
        return BenefitCoverageLevel.Single;
    }
    return cov;
}

export function benefitCodeSort(a: BenefitCode, b: BenefitCode): number {
    function GetBenefitSortingValue(benefit: string): number {
        switch (benefit.toUpperCase()) {
            case BenefitCode.Life:
                return 0;
            case BenefitCode.AccidentalDeathAndDismemberment:
                return 1;
            case BenefitCode.ExtendedDisability:
                return 2;
            case BenefitCode.EHC:
                return 3;
            case BenefitCode.Dental:
                return 4;
            case BenefitCode.Vision:
                return 5;
            case BenefitCode.HSA:
                return 6;
            case BenefitCode.WSA:
                return 7;
            default:
                return 8;
        }
    }
    const aSortValue = GetBenefitSortingValue(a);
    const bSortValue = GetBenefitSortingValue(b);

    if (aSortValue < bSortValue) {
        return -1;
    }
    if (aSortValue > bSortValue) {
        return 1;
    }
    return 0;
}
