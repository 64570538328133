import * as React from "react";
import { useAppSelector } from "hooks/reduxHooks";
import { Button } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { ApplicationState } from "store";

export interface AttachFileButtonProps extends ButtonProps {
    label: string;
    onSelectedFilesChange: (file: File) => void;
    isPrimaryColor?: boolean;
}

const AttachFileButton = (props: AttachFileButtonProps) => {
    const isEmulated = useAppSelector((state: ApplicationState) => state.auth.session.emulatedSession);

    const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        if (!!file) {
            props.onSelectedFilesChange(file);
            e.target.value = ""; // reset input field file path
        }
    };

    // These props are removed so they don't get applied to the underlying <Button/>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { label, onSelectedFilesChange, isPrimaryColor, ...rest } = props as any;

    return (
        <span title={isEmulated ? "Unavailable while emulating" : props.title}>
            <Button
                {...rest}
                className={`attach-file-btn-${isPrimaryColor ? "primary" : "secondary"}`}
                variant="contained"
                component="label"
                disabled={props.disabled || isEmulated}
                title={isEmulated ? "Unavailable while emulating" : props.title}>
                <i className="material-icons">file_upload</i>
                <span>{label}</span>
                <input
                    id={label.split(" ").join("-").toLowerCase()}
                    onChange={onFileSelected}
                    style={{ display: "none" }}
                    type="file"
                />
            </Button>
        </span>
    );
};

export default AttachFileButton;
