import * as React from "react";
import { Typography } from "@mui/material";

export interface PageHeaderProps {
    title: string;
    /** subtitle1 rendered below the h1 */
    subheading?: JSX.Element | string;
    /** Like the subheading, but rendered above the h1 */
    superheading?: JSX.Element | string;
    action?: JSX.Element;
    className?: string;
}

export class PageHeader extends React.PureComponent<PageHeaderProps> {
    public render() {
        return (
            <header className={`page-header ${this.props.className ?? ""}`}>
                {!!this.props.superheading && (
                    <Typography variant="subtitle1" className="page-superheading">
                        {this.props.superheading}
                    </Typography>
                )}
                <div className="page-header-main">
                    <Typography id="page-title" variant="h1" color="secondary" gutterBottom={false}>
                        {this.props.title}
                    </Typography>
                    {this.props.action ? <div className="page-header-action">{this.props.action}</div> : null}
                </div>
                {!!this.props.subheading && (
                    <Typography variant="subtitle1" className="page-subheading">
                        <span className="header-subtext">{this.props.subheading}</span>
                    </Typography>
                )}
            </header>
        );
    }
}
