import * as React from "react";
import { DialogProps } from "@mui/material/Dialog";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";

type ComponentProps = DialogProps;

export const ResponsiveDialog = (props: ComponentProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Dialog open={props.open} scroll={fullScreen ? "paper" : "body"} {...props} fullScreen={fullScreen}>
            {props.children}
        </Dialog>
    );
};
