import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, useTheme } from "@mui/material";

interface TermsAndConditionsProps {
  open: boolean;
  close: () => any;
}

const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
            <Dialog onClose={props.close} open={props.open} scroll="paper" fullScreen={fullScreen} aria-labelledby="scroll-dialog-title">
                <DialogTitle id="scroll-dialog-title">TERMS AND CONDITIONS</DialogTitle>
                <DialogContent>
                    <p>
                        My ASEBP, The Alberta School Employee Benefit Plan’s (“ASEBP”) member portal (“Application”) is designed and intended to provide you with general information regarding your benefit coverage as well as specific
                        information regarding your general health benefit (Extended Health Care, Dental Care, Vision Care) claims and Spending Account (“HSA and WSA”) expenses.
                    </p>
                    <h3>By using the Application, you agree:</h3>
                    <ul>
                        <li>You have read, understood and agree to the Terms and Conditions herein.</li>
                        <li>ASEBP has the right to disable any user name, password or account at any time in our sole discretion for any or no reason if, in ASEBP’s opinion, you have violated any of the Terms and Conditions.</li>
                        <li>All information provided by you on the Application is correct, current and complete.</li>
                        <li>
                            You must maintain an email address and ensure that ASEBP is provided with it as it is necessary for the functionality of the Application. To ensure that you receive all notifications promptly, and to further
                            protect your privacy rights, we recommend that a personal email address be used rather than the email address provided by your employer.
                        </li>
                    </ul>
                    <h3> ASEBP may use your registered email address for the following purposes:</h3>
                    <ul>
                        <li>to notify you of transactions on your account</li>
                        <li>to notify you of changes or information related to ASEBP and its various benefit plans and services</li>
                        <li>to provide information specifically related to your benefit coverage/utilization/experience</li>
                        <li>to conduct surveys regarding your experience with ASEBP</li>
                    </ul>
                    <h3>By submitting personal information, including general health benefit claims and HSA and WSA expenses, through your use of the Application, you agree that:</h3>
                    <ul>
                        <li>All information provided by you through the Application is accurate and complete.</li>
                        <li>All claims and expenses have been lawfully incurred by you or your dependants.</li>
                        <li>
                            ASEBP may collect, use and disclose the personal information submitted by you, on behalf of yourself or your dependants, for the purposes of determining eligibility for coverage, verifying , assessing and paying
                            claims, conducting audits and generally administering the provisions of the benefit plan.
                        </li>
                        <li>
                            In case of a disagreement in interpretation, the language of the plan documents and group policies are legally binding. The information on the Application should not be relied upon in determining your legal
                            rights under any ASEBP plan. If you are concerned about your rights to make a claim under any coverage, you should obtain a copy of the plan document for review. Copies of the individual plan documents are
                            available from your employer or ASEBP.
                        </li>
                    </ul>
                    <h3>Other information relevant to the Application:</h3>
                    <ul>
                        <li>
                            ASEBP’s benefit plans and services are only available to eligible employers entitled to enter into a Participation Agreement, as set out in the ASEBP Deed of Trust. All ASEBP benefit plans and services shall be
                            subject to the terms and conditions of the ASEBP Plan Documents and applicable Participation Agreements.
                        </li>
                        <li>
                            ASEBP makes every effort to ensure that personal information (including general health benefit claims and HSA and WSA expense history) accessible through the Application is current and accurate. If the
                            information presented on the Application differs from official ASEBP records, the latter will prevail. If you think that your general health benefit claims or HSA and WSA expense information are inaccurate or not
                            up-to-date, please contact a Benefit Specialist before acting on the information. If any of your personal information appears to be missing or incorrect or if you have any questions about what you are viewing,
                            please contact an ASEBP Benefit Specialist. If your address information is incorrect and you are actively employed, please contact your employer. If you are enrolled in Substitute Teacher and Casual Staff or
                            Early Retiree benefits, please contact a Benefit Specialist.
                        </li>
                        <li>
                            The general health benefit claims and HSA expense history shown in the Application may not be current. If you have recently submitted claims/expenses, please ensure you consider those submissions when calculating
                            amounts available pursuant to the appropriate maximum amount allowed.
                        </li>
                        <li>
                            All personal information collected and used will be managed in accordance with ASEBP’s{" "}
                            <a href="https://www.asebp.ca/privacy" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>
                            .
                        </li>
                        <li>
                            Personal information we collect from you may be transferred to and processed by third party service providers located outside Canada , and will be subject to the laws of the relevant jurisdiction, which may be
                            different from Canada’s laws. See the{" "}
                            <a href="https://www.asebp.ca/privacy" target="_blank" rel="noopener noreferrer">
                                Outside Canada Service Provider Statement
                            </a>{" "}
                            for more information.
                        </li>
                        <li>
                            Your use of the Application, including all content and claim/expense history, shall be on an &quot;as is&quot; and &quot;as available&quot; basis entirely at your own risk. Neither ASEBP, nor any of its Trustees,
                            officers, directors or employees, nor any other person associated with the creation of the Application or its contents, including third party service providers, shall be liable or responsible to you or any person
                            for any harm, loss or damage that may arise in any connection with your use of the Application, including without limitation any direct, indirect, special, or consequential damages.
                        </li>
                        <li>In order to continue using My ASEBP, you are required to agree to the Terms and Conditions every 90 days, or whenever material changes are made.</li>
                        <li>The Application and these Terms and Conditions shall be governed by the laws of the Province of Alberta, Canada.</li>
                    </ul>
                    <p>
                        ASEBP reserves the right to amend these Terms and Conditions at any time by posting amendments to the Application. You will be deemed to have read and agreed to these amendments by signing onto the Application. All
                        material amendments will be presented when you sign into the Application. If you choose not to agree to the revised Terms and Conditions you will not be able to use the Application. Minor revisions will not be
                        presented to you, but will be posted on the Application.
                    </p>
                    <h3>Drug Search Disclaimer:</h3>
                    <ol>
                        <li>
                            No Medical Advice: THE APPLICATION IS PROVIDED FOR GENERAL INFORMATIONAL PURPOSES ONLY. WE OFFER NO MEDICAL, PHARMACEUTICAL, THERAPEUTIC OR OTHER ADVICE OR MEDICAL OR DIAGNOSTIC SERVICES IN CONNECTION WITH THE
                            APPLICATION. You are responsible for your use of the Application. A licensed medical professional is responsible for independently reaching any medical judgment, and for any resulting diagnosis and treatments,
                            notwithstanding any use of the Application by such medical professional. You should never disregard medical advice or delay in seeking it because of something you have read or viewed on the Application. Nothing
                            on the Application is suggesting you choose a particular therapy or medication. You acknowledge and agree that the Application may contain inaccuracies and/or marketing statements.
                        </li>
                        <li>
                            No Endorsements: In no way is ASEBP claiming that any medication, therapy or any other specific thing related to or referenced in the Application is in any way superior to any other medication, therapy or
                            specific thing related to or referenced in the Application. No endorsement of any medication, therapy or any other specific thing related to or referenced in the Application is expressed or implied. Links to
                            websites outside the Application are provided only for your convenience. No endorsement of any third-party content is expressed or implied by anything included on the Application or linked from or to the
                            Application.
                        </li>
                        <li>
                            Disclaimer: YOU EXPRESSLY AGREE THAT THE USE OF THE APPLICATION IS AT YOUR SOLE RISK AND THAT YOU HAVE SOLE RESPONSIBILITY FOR ANY DECISIONS YOU MAKE BASED ON INFORMATION CONTAINED IN THE APPLICATION. The
                            Application is provided on an &quot;as is&quot; and &quot;as available&quot; basis for your use, without warranties of any kind, either express or implied. Without limiting the generality of the foregoing, we do
                            not make any warranty or guarantee: that the Application satisfies government regulations requiring disclosure of information on prescription drug products; that the codes, prices, therapeutic content, formulary
                            content, coverage levels, or other data of any nature contained on, or directly or indirectly connected with, the Application is accurate or complete; that the Application covers all possible uses, directions,
                            precautions, drug interactions, or adverse effects that may be associated with any therapeutic treatment; that personal information and/or transactions connected with the Application or the Internet will be
                            maintained confidential and secure, although we will make commercially reasonable efforts to do so; that the Application or any site to which it is linked or any relevant server is free of computer viruses or
                            other harmful applications; or that you will be able to receive uninterrupted or error free operation, availability, reliability, timeliness, suitability, privacy, security, merchantability, quality, title,
                            non-infringement or fitness for a particular purpose from the Application or any website to which it is linked or any relevant server.
                        </li>
                        <li>
                            Limitation of Liability. WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR DAMAGE OR INJURY (INCLUDING DEATH) TO YOU, OTHER PERSONS, OR PROPERTY ARISING FROM ANY DIRECT OR INDIRECT USE OF THE APPLICATION. Under no
                            circumstances shall we or any of our employees, customers, directors, shareholders, officers, agents, affiliates, licensors or suppliers be liable to you for any damages whatsoever arising out of or related to
                            access to or use of the Application, whether or not any such damages might be foreseeable and regardless of whether we are informed of their possibility, including without limitation, liability for loss of
                            profit, business, contracts or revenues or direct, indirect, special, punitive, incidental or consequential losses or damages. To the maximum extent permitted by applicable law, YOU ACKNOWLEDGE AND AGREE THAT
                            YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH ASEBP IS TO STOP USING THE APPLICATION. To the extent permitted by law, all implied terms are excluded. This exclusion or limitation of liability applies
                            regardless of the form of action or proceeding, whether in contract, tort, strict liability, breach of statutory duty or otherwise. IF YOU USE THIS APPLICATION, YOU ARE AGREEING THAT THIS LIMITATION OF LIABILITY
                            IS REASONABLE AND IN KEEPING WITH THE NATURE OF THIS APPLICATION
                        </li>
                        <li>
                            Indemnification: You agree to defend, indemnify and hold harmless ASEBP, its affiliates and their respective directors, officers, employees and agents from and against any and all claims, actions, suits or
                            proceedings, as well as any and all losses, liabilities, damages, costs and expenses (including attorneys fees and costs) arising out of or accruing from: (a) any non-compliance by you with the terms and
                            conditions of this Agreement; and/or (b) any liability, loss, claim and/or expense arising from or related to your access and/or use of the Application. Notwithstanding the foregoing, ASEBP reserves the
                            right to assume at its expense the exclusive defense and control of any matter subject to indemnification by you, in which event you will fully cooperate with ASEBP in asserting any available defenses.
                        </li>
                        <li>
                            Intellectual Property. The Application is protected by intellectual property, copyright, trademark, patent and other proprietary rights and/or laws and the Application may contain certain licensed materials in
                            which ASEBP and/or its licensors may enforce their rights in the event of any violation of this Agreement. Your use of the Application cannot in any way harm or prejudice the image or perception of the
                            Application or ASEBP to others receiving the information on this Application. You shall not do anything to harm the functioning or content of the Application. You will not attempt to upload, insert, or
                            change any information or image to or on the Application or directly or indirectly alter or tamper with the Application in any way. You will not permit, allow or do anything that would infringe or otherwise
                            prejudice our proprietary rights. We retain all right, title and interest in and to the Application, including all intellectual property rights. You agree that you will use the Application for your personal use
                            only, and not for any commercial purpose or use whatsoever.
                        </li>
                        <li>
                            Modifications to the Application. We reserve the right to modify or discontinue, temporarily or permanently, the Application, or any part thereof (including without limitation information related to formulary and
                            coverage levels), with or without notice to you, at any time and from time to time. You agree that we will not be liable to you or to any third-party for any modification, discontinuance, or suspension of this
                            Application, or any part thereof. We may also impose limits on certain features and services or restrict your access and use to parts or all of the Application without notice or liability.
                        </li>
                    </ol>
                </DialogContent>
                <DialogActions>
                    <Button id="close-terms-and-conditions-button" onClick={props.close} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );   
}
  
  export default TermsAndConditions;
