// these imports are for ie11 support and sadly have to be in this index tsx file for them to work
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "promise-polyfill/src/polyfill";
import "abortcontroller-polyfill";

import "polyfills";

import * as React from "react";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { configureStore } from "./store/configureStore";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import * as RoutesModule from "./routes";
import "css.escape";
import "./styles/index.scss";

import { AppTheme } from "./theme";
import { handleNetworkRequest } from "./api/request-middleware";
import { ApplicationState } from "./store";
import { UserActivities } from "./modules/features/models";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material";
import { AppInsightsLogger } from "modules/logging/appInsightsLogger";
import { GoogleAnalyticsLogger } from "modules/logging/googleAnalyticsLogger";
import TagManager from "react-gtm-module";
import { signalRConnection } from "signalr";
import FeatureToggleChecker from "modules/utils/featureToggleChecker";

const getRoutes = RoutesModule.getRoutes;

const dummyStore = {
    getState: () => {
        return {
            config: {},
            auth: {
                session: {}
            }
        };
    }
};

// Immediately get config from the server
const initialConfigPromise = handleNetworkRequest(
    dummyStore,
    {},
    {
        type: "NETWORK_REQUEST",
        method: "GET",
        url: "/api/config"
    }
);

initialConfigPromise
    .then((rawResponse: any) => rawResponse.json())
    .then((configJson) => {
        // Initialize the application-wide store instance, prepopulating with state from the server where available.
        const initialReduxState = {
            config: configJson.config,
            features: {
                featureToggles: configJson.features.featureToggles,
                featureArticleList: {
                    articles: null,
                    isFetching: false
                },
                userActivities: {} as UserActivities,
                hasUnreadFeatureArticles: false
            },
            auth: {
                // We need to provide this so the initial reducer state isn't missing props..
                // Need to find a better method than this eventually.
                login: {
                    isFetching: false,
                    message: null,
                    nonPilotUserLoggedIn: false
                },
                session: configJson.session,
                lastActivity: null
            }
        } as ApplicationState;

        const { store, history } = configureStore(initialReduxState);

        // Initialize logging
        AppInsightsLogger.init({
            instrumentationKey: initialReduxState.config.appInsightsKey,
            authenticatedUserId:
                initialReduxState.auth && initialReduxState.auth.session ? initialReduxState.auth.session.userId : null
        });

        GoogleAnalyticsLogger.init({
            trackingId: initialReduxState.config.googleAnalyticsTrackingId,
            googleAnalyticsFourTrackingID: initialReduxState.config.googleAnalyticsFourTrackingID
        });

        TagManager.initialize({ gtmId: initialReduxState.config.googleAnalyticsTagID });

        const theme = createTheme(AppTheme);

        if (FeatureToggleChecker.isOn(initialReduxState.features.featureToggles.EnableMessaging)) {
            signalRConnection.init(store);
        }

        function renderApp() {
            // This code starts up the React app when it runs in a browser. It sets up the routing configuration
            // and injects the app into a DOM element.
            const state = store.getState();
            const container = document.getElementById("root");
            const root = createRoot(container!);

            root.render(
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StyledEngineProvider>
                        <ThemeProvider theme={theme}>
                            <Provider store={store}>
                                <Router history={history}>{getRoutes(state)}</Router>
                            </Provider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </LocalizationProvider>
            );
        }

        renderApp();
    });
