import { push, RouterActions } from "redux-first-history";
import { AppThunkAction } from "store";

import API from "api";
import { NetworkRequest } from "api/actions";

import * as actions from "./actions";

export type KnownAction =
    | actions.VerifyNewMemberInitAction
    | actions.VerifyNewMemberCompleteAction
    | actions.SetNewMemberInfoAction
    | actions.SetAccountDetailsAction
    | actions.CancelNewMemberRegistrationAction
    | actions.InitializeNewMemberRegistrationAction
    | actions.FinalizeMemberRegistrationInitAction
    | actions.BackToMemberVerficiationFormAction
    | actions.FinalizeMemberRegistrationCompleteAction;

export type HandledAction = KnownAction | RouterActions | NetworkRequest;

export const actionCreators = {
    verifyNewMember: (): AppThunkAction<HandledAction> => (dispatch, getState) => {
        dispatch({ type: "VERIFY_NEW_MEMBER_INIT" });

        const newMemberInfo = getState().profile.registration.newMemberInfo;

        const task = Promise.resolve().then(() => {
            return dispatch(API.registration.verifyMemberInformation(newMemberInfo.certificateNumber, newMemberInfo.fullName, newMemberInfo.dateOfBirth))
                .then(
                    response => {
                        if (response?.status === 200) {
                            return response.json() as Promise<any>;
                        } else {
                            dispatch({
                                type: "VERIFY_NEW_MEMBER_COMPLETE",
                                succeeded: false,
                                memberVerificationErrors: ["Oops! Something went wrong. Please try again. If the problem continues, please contact us at 1-877-431-4786 or benefits@asebp.ca."]
                            });
                            return null;
                        }
                    },
                    () => {
                        return null;
                    }
                )
                .then((response: any) => {
                    if (response !== null && response.succeeded !== undefined && response.succeeded) {
                        dispatch({ type: "VERIFY_NEW_MEMBER_COMPLETE", succeeded: true, memberVerificationErrors: null });

                        dispatch(push("/register/accountDetails"));

                        return null;
                    }

                    dispatch({ type: "VERIFY_NEW_MEMBER_COMPLETE", succeeded: false, memberVerificationErrors: response.messages });
                    return null;
                });
        });

        return task;
    },

    setMemberInfo: (certificateNumber: string, dateOfBirth: Date, fullName: string, hasAgreedToTermsAndConditions: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "SET_NEW_MEMBER_INFO", certificateNumber, dateOfBirth, fullName, hasAgreedToTermsAndConditions });
    },

    setAccountDetails: (emailAddress: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "SET_ACCOUNT_DETAILS", emailAddress, password });
    },

    cancelNewMemberRegistration: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "CANCEL_NEW_MEMBER_REGISTRATION" });
    },

    initializeNewMemberRegistration: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "INITIALIZE_NEW_MEMBER_REGISTRATION" });
    },

    backToMemberVerficiationForm: (): AppThunkAction<HandledAction> => (dispatch, getState) => {
        dispatch({ type: "BACK_TO_MEMBER_VERIFICATION" });

        dispatch(push("/register"));
    },

    finalizeMemberRegistration: (): AppThunkAction<HandledAction> => (dispatch, getState) => {
        dispatch({ type: "FINALIZE_MEMBER_REGISTRATION_INIT" });
        const currentState = getState().profile;

        const newMemberInfo = currentState.registration.newMemberInfo;
        const newMemberAccountDetailsInfo = currentState.registration.newMemberAccountDetailsInfo;

        const task = Promise.resolve().then(() => {
            return dispatch(API.registration.verifyAccountDetails(newMemberInfo.certificateNumber, newMemberInfo.fullName, newMemberInfo.dateOfBirth, newMemberAccountDetailsInfo.emailAddress, newMemberAccountDetailsInfo.password))
                .then(
                    response => {
                        if (response?.status === 200) {
                            return response.json() as Promise<any>;
                        } else {
                            dispatch({
                                type: "FINALIZE_MEMBER_REGISTRATION_COMPLETE",
                                succeeded: false,
                                accountDetailErrors: ["Oops! Something went wrong. Please try again. If the problem continues, please contact us at 1-877-431-4786 or benefits@asebp.ca."]
                            });
                            return null;
                        }
                    },
                    () => {
                        return null;
                    }
                )
                .then((response: any) => {
                    if (response !== null && response.succeeded !== undefined && response.succeeded) {
                        dispatch({
                            type: "FINALIZE_MEMBER_REGISTRATION_COMPLETE",
                            succeeded: true,
                            accountDetailErrors: null,
                            isPasswordStrongEnough: response.isPasswordStrongEnough,
                            isEmailAddressAvailable: response.isEmailAddressAvailable
                        });

                        dispatch(push("/register/success"));

                        return null;
                    }

                    dispatch({
                        type: "FINALIZE_MEMBER_REGISTRATION_COMPLETE",
                        succeeded: false,
                        accountDetailErrors: response.messages,
                        isPasswordStrongEnough: response.isPasswordStrongEnough,
                        isEmailAddressAvailable: response.isEmailAddressAvailable
                    });
                    return null;
                });
        });

        return task;
    }
};
