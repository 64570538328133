import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { LayoutBase, BlankLayoutBase, PublicLayoutBase } from "modules/common/components/layout";

import { routeProvider } from "routeProvider";
import { ApplicationState } from "store";
import { Action, Location } from "history";

export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
export const CALL_HISTORY_METHOD = "@@router/CALL_HISTORY_METHOD";

export interface LocationActionPayload<A = any[]> {
    method: string;
    args?: A;
}

export interface CallHistoryMethodAction<A = any[]> {
    type: typeof CALL_HISTORY_METHOD;
    payload: LocationActionPayload<A>;
}

export interface RouterState {
    location: RouterLocation;
    action: RouterActionType;
}
export interface RouterLocation extends Location {
    query: Record<string, string>;
}

export type RouterActionType = Action;

export type RouterAction = LocationChangeAction | CallHistoryMethodAction;

export interface LocationChangeAction {
    type: typeof LOCATION_CHANGE;
    payload: LocationChangePayload;
}

export interface LocationChangePayload extends RouterState {
    isFirstRendering: boolean;
}

export const getRoutes = (state: ApplicationState) => {
    const allPages = routeProvider("all_routes")
        .areas.map((a) => a.pages || [])
        .reduce((a, b) => a.concat(b), []);

    const homeRedirect = (props) => <Redirect to="/home" {...props} />;

    const defaultLayoutPages = allPages.filter((p) => !p.layout || p.layout === "default");
    const publicLayoutPages = allPages.filter((p) => p.layout === "public");
    const blankLayoutPages = allPages.filter((p) => p.layout === "blank");

    return (
        <>
            <Switch>
                {/* public pages render with no nav, header, footer, etc. but do have a background image */}
                <Route path={publicLayoutPages.map((p) => p.routingPath)}>
                    <PublicLayoutBase>
                        <Switch>
                            {publicLayoutPages.map((page) => {
                                return (
                                    <Route
                                        exact
                                        key={page.routingPath}
                                        path={page.routingPath}
                                        render={(props) => <page.component {...props} />}
                                    />
                                );
                            })}
                        </Switch>
                    </PublicLayoutBase>
                </Route>
                {/* blank layout pages render on a white background */}
                <Route path={blankLayoutPages.map((p) => p.routingPath)}>
                    <BlankLayoutBase>
                        <Switch>
                            {blankLayoutPages.map((page) => {
                                return (
                                    <Route
                                        exact
                                        key={page.routingPath}
                                        path={page.routingPath}
                                        render={(props) => <page.component {...props} />}
                                    />
                                );
                            })}
                        </Switch>
                    </BlankLayoutBase>
                </Route>
                <Route path={defaultLayoutPages.map((p) => p.routingPath).concat("/")}>
                    <LayoutBase>
                        <Switch>
                            {defaultLayoutPages.map((page) => {
                                return (
                                    <Route
                                        exact
                                        key={page.routingPath}
                                        path={page.routingPath}
                                        render={(props) => <page.component {...props} />}
                                    />
                                );
                            })}
                            <Route exact path="/" render={homeRedirect} />
                        </Switch>
                    </LayoutBase>
                </Route>
            </Switch>
        </>
    );
};
