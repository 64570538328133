import * as React from "react";
import { Accordion, AccordionSummary, Grid, Typography, Button, AccordionDetails } from "@mui/material";

import { ProfilePhoneNumbers } from "modules/profile/phoneNumbers/models";
import { PhoneNumbersForm } from "./phoneNumbersForm";
import { ProfilePhoneNumbersView } from "./profilePhoneNumbersView";

export interface ProfilePhoneNumbersProps {
    phoneNumbers: ProfilePhoneNumbers;
    isSaving: boolean;
    savePhoneNumbers: (phones: ProfilePhoneNumbers) => any;
    isLoading: boolean;
}

export interface ProfilePhoneNumbersState {
    isExpanded: boolean;
}

export class ProfilePhoneNumbersSection extends React.Component<ProfilePhoneNumbersProps, ProfilePhoneNumbersState> {
    public state: ProfilePhoneNumbersState = {
        isExpanded: false
    };

    public componentDidUpdate(prevProps: ProfilePhoneNumbersProps) {
        if (!this.props.isSaving && prevProps.isSaving !== this.props.isSaving && this.state.isExpanded) {
            this.collapsePanel();
        }
    }

    public render() {
        return (
            <Accordion expanded={this.state.isExpanded} className="profile-line">
                <AccordionSummary classes={{ disabled: "panel-summary disabled", root: "panel-summary" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6">Phone numbers</Typography>
                        </Grid>
                        <Grid item xs={12} md={this.state.isExpanded ? 9 : 8}>
                            <ProfilePhoneNumbersView phones={this.props.phoneNumbers} isLoading={this.props.isLoading} />
                        </Grid>
                        {!this.state.isExpanded ? (
                            <Grid item xs={12} md={1} className="panel-summary-action">
                                <Button id="phone-edit-button" variant="outlined" onClick={this.expandPanel} disabled={this.props.isLoading}>
                                    {this.props.phoneNumbers && this.props.phoneNumbers.primaryPhoneNumber ? "Edit" : "Add"}
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {this.props.phoneNumbers && this.state.isExpanded ? (
                        <PhoneNumbersForm profilePhoneNumbers={this.props.phoneNumbers} onSavePhoneNumbersRequested={this.props.savePhoneNumbers} onCancel={this.collapsePanel} isSaving={this.props.isSaving} />
                    ) : null}
                    <></>
                </AccordionDetails>
            </Accordion>
        );
    }

    private expandPanel = () => {
        this.setState(s => ({ ...s, isExpanded: true }));
    };

    private collapsePanel = () => {
        this.setState(s => ({ ...s, isExpanded: false }));
    };
}
