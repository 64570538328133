import { Reducer } from "redux";

import { TaxSlipsPreferenceState } from "modules/preferences/taxSlips/models";
import { HandledAction } from "modules/preferences/taxSlips/actionCreator";

export type KnownAction = HandledAction;

export const reducer: Reducer<TaxSlipsPreferenceState> = (state: TaxSlipsPreferenceState, action: KnownAction): TaxSlipsPreferenceState => {
    switch (action.type) {
        case "GET_PREFERENCE_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "GET_PREFERENCE_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                preference: action.preference,
                showCondensedView: action.preference ? !action.preference.isEnabled : true,
                errorMessage: null
            };
        }
        case "SAVE_PREFERENCE_INIT": {
            return {
                ...state,
                isSaving: true
            };
        }
        case "SAVE_PREFERENCE_COMPLETE": {
            if (action.succeeded) {
                return {
                    ...state,
                    isSaving: false,
                    preference: {
                        ...action.preference,
                        isEnabled: action.isEnabled,
                        memberPreferenceName: action.preferenceName
                    },
                    errorMessage: null
                };
            } else {
                return {
                    ...state,
                    isSaving: false,
                    errorMessage: action.err
                };
            }
        }
        case "VALIDATE_TAX_DATE_INIT": {
            return {
                ...state,
                isFetching: true
            };
        }
        case "VALIDATE_TAX_DATE_COMPLETE": {
            return {
                ...state,
                isFetching: false,
                isWithinDaysOfTaxDate: action.isWithinDaysOfTaxDate
            };
        }
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return (
        state || {
            isFetching: false,
            isSaving: false,
            isWithinDaysOfTaxDate: false,
            preference: null,
            showCondensedView: true
        }
    );
};
