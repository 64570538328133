import * as React from "react";
import {
    Grid,
    Checkbox,
    FormHelperText,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip
} from "@mui/material";

import { ValidationState } from "modules/claims/claimSubmission/models";
import {
    FormDropdownField,
    FormPasswordField,
    FormTextField,
    SelectOption,
    SubmitButton
} from "modules/common/components/forms";
import PreAuthorizedDebitsAgreement from "./preAuthorizedDebitsAgreement";
import { Bank, BankAccount, BankAccountRemoval, BankAccountType } from "modules/profile/bankAccount/models";
import { nameof } from "modules/utils";
import { ConfirmUnsavedChanges } from "modules/common/components/forms/confirmUnsavedChanges";

export interface BankAccountFormProps {
    banks: Bank[];
    profileBankAccount: BankAccount;
    bankAccountTypeId: BankAccountType;
    isSaving: boolean;
    isRemoving: boolean;
    isReadOnly: boolean;
    onSaveBankAccountRequested: (bankAccount: BankAccount) => any;
    onRemoveBankAccountRequested: (bankAccountRemoval: BankAccountRemoval) => any;
    onCancel: () => any;
}

export interface BankAccountFormState {
    formBankAccount: BankAccount;
    hasAttemptedToSubmit: boolean;
    hasAttemptedToDelete: boolean;
    validationState: ValidationState;
    showUpdateCancelConfirmation: boolean;
    showRemoveConfirmation: boolean;
    isFormDirty: boolean;
    isFormTouched: boolean;
    hasAgreedToPreAuthorizedDebitsAgreement: boolean;
    isPreAuthorizedDebitsAgreementModalOpen: boolean;
}

export class BankAccountForm extends React.PureComponent<BankAccountFormProps, BankAccountFormState> {
    public state: BankAccountFormState = {
        formBankAccount: this.ensureBankAccount(this.props.profileBankAccount, this.props.bankAccountTypeId),
        hasAttemptedToSubmit: false,
        hasAttemptedToDelete: false,
        validationState: {} as ValidationState,
        showUpdateCancelConfirmation: false,
        showRemoveConfirmation: false,
        isFormDirty: false,
        isFormTouched: false,
        hasAgreedToPreAuthorizedDebitsAgreement: false,
        isPreAuthorizedDebitsAgreementModalOpen: false
    };

    public componentDidUpdate(prevProps: BankAccountFormProps) {
        if (
            this.props.profileBankAccount &&
            prevProps.profileBankAccount !== this.props.profileBankAccount &&
            this.props.profileBankAccount !== this.state.formBankAccount
        ) {
            this.resetState();
        }
    }

    private resetState() {
        this.setState(
            (s) =>
                ({
                    formBankAccount: this.ensureBankAccount(
                        this.props.profileBankAccount,
                        this.props.bankAccountTypeId
                    ),
                    hasAttemptedToSubmit: false,
                    hasAttemptedToDelete: false,
                    validationState: {} as ValidationState,
                    isFormDirty: false,
                    isFormTouched: false
                } as BankAccountFormState)
        );
    }

    private ensureBankAccount(bankAccount: BankAccount, bankAccountTypeId: BankAccountType) {
        return {
            accountNumber: bankAccount ? bankAccount.accountNumber : "",
            accountTypeId: bankAccount ? bankAccount.accountTypeId : bankAccountTypeId,
            bankInstitutionNumber: bankAccount ? bankAccount.bankInstitutionNumber : 0,
            branchTransitNumber: bankAccount ? bankAccount.branchTransitNumber : "",
            confirmationPassword: ""
        } as BankAccount;
    }

    public render() {
        const hasTransitNumberError =
            this.state.hasAttemptedToSubmit &&
            !!this.state.validationState.messages[nameof<BankAccount>("branchTransitNumber")];
        const hasBankInstitutionNumberError =
            this.state.hasAttemptedToSubmit &&
            !!this.state.validationState.messages[nameof<BankAccount>("bankInstitutionNumber")];
        const hasAccountNumberError =
            this.state.hasAttemptedToSubmit &&
            !!this.state.validationState.messages[nameof<BankAccount>("accountNumber")];
        const hasPasswordError =
            (this.state.hasAttemptedToSubmit || this.state.hasAttemptedToDelete) &&
            !!this.state.validationState.messages[nameof<BankAccount>("confirmationPassword")];
        const hasAgreementError = this.state.hasAttemptedToSubmit && !!this.state.validationState.messages["agreement"];

        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={3} />
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2} className="profile-form">
                            {this.props.bankAccountTypeId === BankAccountType.Deposit ? (
                                <Grid item xs={12}>
                                    <p>
                                        ASEBP must collect, use and disclose to your financial institution the banking
                                        information provided by you below in order to set up direct deposit services
                                        with your financial institution. By providing the information below you consent
                                        to the collection, use and disclosure of your personal information for this
                                        purpose and confirm that you understand why the information is required and that
                                        you are aware of the risks and benefits of doing so.
                                    </p>
                                    <p>
                                        You may revoke your consent at any time by deleting the banking information;
                                        however, doing so may affect your ability to receive payments from ASEBP.
                                    </p>
                                </Grid>
                            ) : null}
                            <Grid item xs={12} md={3}>
                                <FormTextField
                                    title="Transit/Branch Number"
                                    id="transit-number-field"
                                    value={this.state.formBankAccount.branchTransitNumber}
                                    className="profile-form-control"
                                    maxLength={5}
                                    onChanged={this.onBranchTransitNumberChanged}
                                    errorText={
                                        hasTransitNumberError
                                            ? this.state.validationState.messages[
                                                  nameof<BankAccount>("branchTransitNumber")
                                              ]
                                            : null
                                    }
                                    disabled={this.props.isSaving || this.props.isReadOnly}
                                    helperText={!hasTransitNumberError ? "Five-digit number" : null}
                                    fullWidth
                                    tooltipTitle="Transit/Branch number"
                                    tooltipContent={
                                        <div className="tooltip-cheque">
                                            <img
                                                className="tooltip-cheque-img"
                                                src="/img/cheque_transit_number.png"
                                                alt="cheque transit number"
                                            />
                                        </div>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormDropdownField
                                    data-testid="bank-number-select"
                                    title={"Bank/Institution Number"}
                                    id="institution-number-field"
                                    options={this.getBanksList(this.props.banks)}
                                    value={this.state.formBankAccount.bankInstitutionNumber}
                                    onChanged={this.onBankInstitutionNumberChanged}
                                    errorText={
                                        hasBankInstitutionNumberError
                                            ? this.state.validationState.messages[
                                                  nameof<BankAccount>("bankInstitutionNumber")
                                              ]
                                            : null
                                    }
                                    className="profile-form-control"
                                    disabled={this.props.isSaving || this.props.isReadOnly}
                                    fullWidth
                                    tooltipTitle="Bank/Institution number"
                                    tooltipContent={
                                        <div className="tooltip-cheque">
                                            <img
                                                className="tooltip-cheque-img"
                                                src="/img/cheque_bank_number.png"
                                                alt="cheque bank number"
                                            />
                                        </div>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormTextField
                                    title="Account Number"
                                    id="account-number-field"
                                    value={this.state.formBankAccount.accountNumber}
                                    className="profile-form-control"
                                    maxLength={12}
                                    onChanged={this.onAccountNumberChanged}
                                    errorText={
                                        hasAccountNumberError
                                            ? this.state.validationState.messages[nameof<BankAccount>("accountNumber")]
                                            : null
                                    }
                                    disabled={this.props.isSaving || this.props.isReadOnly}
                                    helperText={!hasAccountNumberError ? "Seven to 12 digits without spaces" : null}
                                    fullWidth
                                    tooltipTitle="Account Number"
                                    tooltipContent={
                                        <div className="tooltip-cheque">
                                            <img
                                                className="tooltip-cheque-img"
                                                src="/img/cheque_account_number.png"
                                                alt="cheque account number"
                                            />
                                        </div>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormPasswordField
                                    id="bank-account-password-field"
                                    title="My ASEBP Password"
                                    className="profile-form-control"
                                    value={this.state.formBankAccount.confirmationPassword}
                                    onChanged={this.onPasswordChanged}
                                    errorText={
                                        hasPasswordError
                                            ? this.state.validationState.messages["confirmationPassword"]
                                            : null
                                    }
                                    disabled={this.props.isSaving || this.props.isReadOnly}
                                    helperText={
                                        !hasPasswordError
                                            ? "For security reasons, enter your My ASEBP password to change your bank account information."
                                            : null
                                    }
                                />
                            </Grid>
                            {this.props.bankAccountTypeId === BankAccountType.Withdrawal ? (
                                <Grid item xs={12}>
                                    <div className="agreement-container">
                                        <Checkbox
                                            checked={this.state.hasAgreedToPreAuthorizedDebitsAgreement}
                                            id={"agreeAgreementCheckbox"}
                                            onChange={this.onPreAuthorizedDebitsAgreementChanged}
                                        />
                                        <span>
                                            <label htmlFor={"agreeAgreementCheckbox"} className="agreement-label">
                                                I agree to the{" "}
                                            </label>
                                            <span
                                                className="agreement-link"
                                                onClick={this.togglePreAuthorizedDebitsAgreementModal}>
                                                Personal Pre-Authorized Debits Agreement
                                            </span>
                                        </span>
                                    </div>
                                    <PreAuthorizedDebitsAgreement
                                        open={this.state.isPreAuthorizedDebitsAgreementModalOpen}
                                        close={this.togglePreAuthorizedDebitsAgreementModal}
                                    />
                                    {hasAgreementError ? (
                                        <FormHelperText className="error-text">This field is required</FormHelperText>
                                    ) : (
                                        <div />
                                    )}
                                </Grid>
                            ) : null}
                            <Grid
                                item
                                xs={12}
                                className={
                                    "form-actions gutter-top " +
                                    (this.props.bankAccountTypeId !== BankAccountType.Deposit ? "wrap" : "")
                                }>
                                {!this.props.isReadOnly ? (
                                    <Tooltip
                                        title={
                                            !this.state.isFormDirty && this.state.isFormTouched
                                                ? "Banking information has not been changed"
                                                : ""
                                        }
                                        placement="top">
                                        <div>
                                            <SubmitButton
                                                submittingMessage={
                                                    this.props.profileBankAccount ? "Updating" : "Adding"
                                                }
                                                isSubmitting={this.props.isSaving}
                                                className="secondary"
                                                variant="contained"
                                                color="primary"
                                                onClick={this.onSaveBankAccountRequested}
                                                disabled={!this.state.isFormDirty}>
                                                {this.props.profileBankAccount ? "Update " : "Add "}
                                                {this.props.bankAccountTypeId === BankAccountType.Deposit
                                                    ? "Deposit "
                                                    : "Withdrawal "}
                                                account
                                            </SubmitButton>
                                        </div>
                                    </Tooltip>
                                ) : null}
                                {this.props.profileBankAccount && !this.props.isReadOnly ? (
                                    <SubmitButton
                                        id="remove-account-button"
                                        submittingMessage="Removing"
                                        isSubmitting={this.props.isRemoving}
                                        variant="outlined"
                                        color="primary"
                                        onClick={this.onRemoveBankAccountRequested}
                                        disabled={this.props.isSaving || this.props.isRemoving}>
                                        Remove{" "}
                                        {this.props.bankAccountTypeId === BankAccountType.Deposit
                                            ? "Deposit"
                                            : "Withdrawal"}
                                        &nbsp; account
                                    </SubmitButton>
                                ) : null}
                                <Button
                                    id="edit-account-cancel-button"
                                    variant="text"
                                    color="secondary"
                                    disabled={this.props.isSaving || this.props.isRemoving}
                                    onClick={this.onCancel}>
                                    <span>Cancel</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={this.state.showUpdateCancelConfirmation} onClose={this.cancelCanceled}>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>Any changes you made will be lost.</DialogContent>
                    <DialogActions>
                        <Button id="edit-account-cancel-cancel-button" onClick={this.cancelCanceled}>
                            No, Keep It
                        </Button>
                        <Button
                            id="edit-account-confirm-cancel-button"
                            className="confirmation confirm-btn"
                            onClick={this.cancelConfirmed}>
                            Yes, discard my changes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showRemoveConfirmation} onClose={this.removeCanceled}>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        The {this.props.bankAccountTypeId === BankAccountType.Deposit ? "Deposit" : "Withdrawal"}{" "}
                        account will be removed
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.removeCanceled}>No, Keep it</Button>
                        <Button className="confirmation confirm-btn" onClick={this.removeConfirmed}>
                            Yes, Remove it
                        </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmUnsavedChanges isDirty={this.state.isFormDirty} />
            </>
        );
    }

    private onBranchTransitNumberChanged = (evt: any) => {
        this.setBankAccountValue({
            ...this.state.formBankAccount,
            branchTransitNumber: evt.target.value
        });
    };
    private onBankInstitutionNumberChanged = (evt: any) => {
        const value = isNaN(Number(evt.target.value)) ? 0 : evt.target.value;

        this.setBankAccountValue({
            ...this.state.formBankAccount,
            bankInstitutionNumber: value
        });
    };
    private onAccountNumberChanged = (evt: any) => {
        this.setBankAccountValue({
            ...this.state.formBankAccount,
            accountNumber: evt.target.value
        });
    };
    private onPasswordChanged = (evt: any) => {
        this.setBankAccountValue({
            ...this.state.formBankAccount,
            confirmationPassword: evt.target.value
        });
    };

    private IsBankAccountModified = (bankAccountInput: BankAccount) => {
        return (
            !this.props.profileBankAccount ||
            bankAccountInput.accountNumber !== this.props.profileBankAccount.accountNumber ||
            bankAccountInput.bankInstitutionNumber !== this.props.profileBankAccount.bankInstitutionNumber ||
            bankAccountInput.branchTransitNumber !== this.props.profileBankAccount.branchTransitNumber
        );
    };

    private setBankAccountValue(bankAccount: BankAccount) {
        let isFormDirty = true;
        if (!this.IsBankAccountModified(bankAccount)) {
            isFormDirty = false;
        }

        this.setState((s) => ({
            ...s,
            formBankAccount: bankAccount,
            isFormDirty,
            isFormTouched: true,
            hasAttemptedToSubmit: !isFormDirty ? false : s.hasAttemptedToSubmit
        }));
        this.validate(bankAccount, this.state.hasAgreedToPreAuthorizedDebitsAgreement);
    }

    private togglePreAuthorizedDebitsAgreementModal = () => {
        this.setState({ isPreAuthorizedDebitsAgreementModalOpen: !this.state.isPreAuthorizedDebitsAgreementModalOpen });
    };

    private onPreAuthorizedDebitsAgreementChanged = () => {
        this.setState({ hasAgreedToPreAuthorizedDebitsAgreement: !this.state.hasAgreedToPreAuthorizedDebitsAgreement });
        this.validate(this.state.formBankAccount, !this.state.hasAgreedToPreAuthorizedDebitsAgreement);
    };

    private validate(
        bankAccount: BankAccount,
        hasAgreedToPreAuthorizedDebitsAgreement: boolean,
        isOnlyRemovingBankAccount = false
    ) {
        const validationResult = { isValid: true, messages: {} } as ValidationState;
        const requiredFieldMissingMessage = "This field is required";

        if (bankAccount) {
            if (!bankAccount.confirmationPassword) {
                validationResult.messages[nameof<BankAccount>("confirmationPassword")] =
                    "For security reasons, please enter your My ASEBP password";
            }

            if (!isOnlyRemovingBankAccount) {
                if (!bankAccount.accountNumber) {
                    validationResult.messages[nameof<BankAccount>("accountNumber")] = requiredFieldMissingMessage;
                } else if (!this.isValidAccountNumber(bankAccount.accountNumber)) {
                    validationResult.messages[nameof<BankAccount>("accountNumber")] =
                        "Must be between seven and 12 digits";
                }

                if (!bankAccount.bankInstitutionNumber) {
                    validationResult.messages[nameof<BankAccount>("bankInstitutionNumber")] =
                        requiredFieldMissingMessage;
                }

                if (!bankAccount.branchTransitNumber) {
                    validationResult.messages[nameof<BankAccount>("branchTransitNumber")] = requiredFieldMissingMessage;
                } else if (!this.isValidBranchTransitNumber(bankAccount.branchTransitNumber)) {
                    validationResult.messages[nameof<BankAccount>("branchTransitNumber")] = "Must be five digits";
                }

                if (
                    bankAccount.accountTypeId === BankAccountType.Withdrawal &&
                    !hasAgreedToPreAuthorizedDebitsAgreement
                ) {
                    validationResult.messages["agreement"] = requiredFieldMissingMessage;
                }
            }

            validationResult.isValid = Object.keys(validationResult.messages).length === 0;
        }

        this.setState((s) => ({ ...s, validationState: validationResult }));

        return validationResult.isValid;
    }

    private isValidAccountNumber = (accountNumber: string): boolean => {
        const regex = /^\d{7,12}$/gs;
        return regex.test(accountNumber);
    };

    private isValidBranchTransitNumber = (branchTransitNumber: string): boolean => {
        const regex = /^\d{5}$/gs;
        return regex.test(branchTransitNumber);
    };

    private onSaveBankAccountRequested = () => {
        this.setState((s) => ({ ...s, hasAttemptedToSubmit: true }));
        const isValid = this.validate(this.state.formBankAccount, this.state.hasAgreedToPreAuthorizedDebitsAgreement);

        if (isValid) {
            this.props.onSaveBankAccountRequested(this.state.formBankAccount);
        }
    };

    private onRemoveBankAccountRequested = () => {
        this.setState((s) => ({ ...s, hasAttemptedToDelete: true }));
        const isValid = this.validate(
            this.state.formBankAccount,
            this.state.hasAgreedToPreAuthorizedDebitsAgreement,
            true
        );

        if (isValid) {
            this.setState((s) => ({ ...s, showRemoveConfirmation: true }));
        }
    };

    private onCancel = () => {
        if (this.state.isFormDirty) {
            this.setState((s) => ({ ...s, showUpdateCancelConfirmation: true }));
        } else {
            this.cancelConfirmed();
        }
    };

    private cancelConfirmed = () => {
        this.resetState();
        this.props.onCancel();

        this.setState((s) => ({ ...s, showUpdateCancelConfirmation: false }));
    };

    private cancelCanceled = () => {
        this.setState((s) => ({ ...s, showUpdateCancelConfirmation: false }));
    };

    private removeConfirmed = () => {
        this.setState((s) => ({ ...s, showRemoveConfirmation: false }));
        const bankAccountRemoval: BankAccountRemoval = {
            bankAccountTypeId: this.props.bankAccountTypeId,
            confirmationPassword: this.state.formBankAccount.confirmationPassword
        };
        this.props.onRemoveBankAccountRequested(bankAccountRemoval);
    };

    private removeCanceled = () => {
        this.setState((s) => ({ ...s, showRemoveConfirmation: false }));
    };

    private getBanksList = (banks: Bank[]): SelectOption[] => {
        return (banks || []).map(
            (c) =>
                ({
                    value: c.institutionNumber,
                    text: c.name + " (" + c.institutionNumber + ")"
                } as SelectOption)
        );
    };
}
