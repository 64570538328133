export interface AddressState {
    countries: Country[];
    address: Address;
    errorMessage?: string;
    isSaving: boolean;
    isFetching: boolean;
    isValid: boolean;
}
export interface Address {
    suiteNumber: string | null;
    street: string;
    city: string;
    postalCode: string;
    country: string;
    province: string;
    countryCode: number;
    provinceCode: number;
}
export interface Province {
    code: number;
    fullName: string;
    shortName: string;
}
export interface Country {
    code: number;
    fullName: string;
    provinces: Province[];
}

export const getCountryName = (countries: Country[], countryCode: number): string => {
    if (countryCode) {
        if ((countries || []).find((c) => c.code === countryCode)) {
            return (countries || []).find((c) => c.code === countryCode).fullName;
        }
    }
};

export const getProvinceName = (countries: Country[], countryCode: number, provinceCode: number): string => {
    if (countryCode && provinceCode) {
        if ((countries || []).find((c) => c.code === countryCode)) {
            const province = (countries || [])
                .find((c) => c.code === countryCode)
                .provinces.find((p) => p.code === provinceCode);
            if (province) {
                return province.fullName;
            }
            return "";
        }
    }
};

export function getAddressAsString(address: Address, excludeSuite: boolean, countries: Country[]): string {
    let addressString = "";

    if (address) {
        if (!excludeSuite && address.suiteNumber) {
            addressString = address.suiteNumber;
        }

        if (address.street) {
            addressString = addressString + " " + address.street;
        }
        if (address.city) {
            if (addressString) {
                addressString = addressString + ", ";
            }
            addressString = addressString + address.city;
        }

        if (address.provinceCode) {
            const provinceName = getProvinceName(countries, address.countryCode, address.provinceCode);

            addressString = addressString + ", " + provinceName;
        }

        if (address.postalCode) {
            addressString = addressString + " " + address.postalCode.toUpperCase();
        }

        if (address.countryCode) {
            const countryName = getCountryName(countries, address.countryCode);
            addressString = addressString + " " + countryName;
        }
    }

    return addressString.trim();
}

export function CreateQueryStringFromAddress(address: Address, countries: Country[]): string {
    if (address) {
        const addressString = getAddressAsString(address, true, countries);
        const q = addressString.replace(/\r?\n/, ",").replace(/\s+/g, " ");

        return encodeURIComponent(q);
    }

    return null;
}
