import * as React from "react";

export interface ClipProps {
    className?: string;
}

export const Clip = (props: React.PropsWithChildren<ClipProps>) => {
    const { className = "" } = props;

    return (<div className={"clip " + className}>{props.children}</div>);
};

