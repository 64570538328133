import { AppThunkAction } from "store";

import API from "api";
import { NetworkRequest } from "api/actions";
import { BenefitCoverageActionCreators, BenefitCoverageActions } from "modules/benefits/coverage/actionCreator";
import { SpendingAccountActionCreators, SpendingAccountActions } from "modules/benefits/spendingAccounts/actionCreator";
import {
    SpendingAccountActionCreatorsV2,
    SpendingAccountActionsV2
} from "modules/benefits/spendingAccountsV2/actionCreator";
import { SpendingAccountClaimCategoryActions } from "modules/claims/spendingAccountClaimCategories/actionCreator";
import { ClaimListActionCreators, ClaimListActions } from "modules/claims/claimList/actionCreator";
import {
    actionCreators as BankAccountsActionCreator,
    KnownAction as BankAccountActions
} from "modules/profile/bankAccount/actionCreator";
import {
    actionCreators as AddressActionCreator,
    KnownAction as AddressActions
} from "modules/profile/address/actionCreator";
import { FeaturesActionCreators, FeaturesActions } from "modules/features/actionCreator";
import { DocumentsActionCreators, DocumentsActions } from "modules/documents/actionCreator";
import PersonalInfoVerificationActionCreator, {
    PersonalInfoVerificationActions
} from "modules/profile/personalInfoVerification/actionCreator";

import * as actions from "./actions";
import { PersonDetails } from "./models";
import { UserActivityKeys, ToggleableFeature, KnownFeatureToggles } from "../features/models";

export type HandledAction =
    | actions.GetPersonInitAction
    | actions.GetPersonCompleteAction
    | actions.UpdatePersonPhoneNumbersAction
    | actions.UpdatePersonMaskedSocialInsuranceNumber;
export type DownstreamActions =
    | BenefitCoverageActions
    | ClaimListActions
    | SpendingAccountActions
    | SpendingAccountActionsV2
    | BankAccountActions
    | AddressActions
    | FeaturesActions
    | SpendingAccountClaimCategoryActions
    | DocumentsActions
    | PersonalInfoVerificationActions;
export type PersonActions = HandledAction | DownstreamActions | NetworkRequest;

const personPromise = {} as { [certificateNumber: string]: Promise<PersonDetails> };

export const PersonActionCreators = {
    getPerson:
        (certificateNumber: string): AppThunkAction<PersonActions> =>
        (dispatch, getState): Promise<PersonDetails> => {
            if (!!personPromise[certificateNumber]) {
                return personPromise[certificateNumber];
            } else {
                dispatch({ type: "GET_PERSON_INIT", certificateNumber });
                personPromise[certificateNumber] = dispatch(API.people.getPerson(certificateNumber))
                    .then(
                        (response) => {
                            if (response?.status === 200) {
                                return response.json() as Promise<PersonDetails>;
                            } else if (response?.status === 403) {
                                dispatch({
                                    type: "GET_PERSON_COMPLETE",
                                    certificateNumber,
                                    person: null,
                                    succeeded: false,
                                    statusCode: response.status
                                });
                            } else {
                                dispatch({
                                    type: "GET_PERSON_COMPLETE",
                                    certificateNumber,
                                    person: null,
                                    succeeded: false
                                });
                            }
                        },
                        () => null
                    )
                    .then(
                        async (person: PersonDetails) => {
                            if (person) {
                                await dispatch({
                                    type: "GET_PERSON_COMPLETE",
                                    certificateNumber,
                                    person,
                                    succeeded: true
                                });

                                // TODO:    User Story created: 21016 Web - Api service error handling
                                //                             ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
                                //          i.) Need to address error handling to integrate with a
                                //          applicationState Hooks API
                                //
                                //          ii.) At same time can investigate running below queries in parallel
                                //
                                await dispatch(BenefitCoverageActionCreators.getMemberBenefits(certificateNumber));
                                await dispatch(BenefitCoverageActionCreators.getBenefitHistory(certificateNumber));
                                await dispatch(SpendingAccountActionCreators.getSpendingAccounts());
                                await dispatch(BenefitCoverageActionCreators.getOverAgeDependants(certificateNumber));
                                await dispatch(BankAccountsActionCreator.getBankAccounts());
                                await dispatch(AddressActionCreator.getAddress());
                                await dispatch(DocumentsActionCreators.getDocumentStats());

                                dispatch(PersonalInfoVerificationActionCreator.checkIsPersonalInfoVerificationDue());

                                return person;
                            }
                            return null;
                        },
                        () => null
                    );

                dispatch(FeaturesActionCreators.getToggleableFeatures()).then(
                    async (toggleableFeatures: ToggleableFeature[]) => {
                        await dispatch(FeaturesActionCreators.getArticlePreviews());
                        await dispatch(
                            FeaturesActionCreators.getUserActivity(UserActivityKeys.LastViewedFeatureArticles)
                        );

                        const updateToClaimsApiV5OnWeb =
                            toggleableFeatures?.find(
                                (toggle) => toggle.key === KnownFeatureToggles.UpdateToClaimsApiV5OnWeb
                            )?.toggleValue ?? 0;

                        if (updateToClaimsApiV5OnWeb === 0) {
                            await dispatch(ClaimListActionCreators.getClaimStats());
                        } else {
                            await dispatch(SpendingAccountActionCreatorsV2.getSpendingAccounts());
                            const enableSavingsAccount =
                                toggleableFeatures?.find(
                                    (toggle) => toggle.key === KnownFeatureToggles.EnableSavingsAccount
                                )?.toggleValue ?? 0;
                            if (enableSavingsAccount === 1) {
                                await dispatch(SpendingAccountActionCreatorsV2.getCoveragePeriods());
                            }
                        }
                    }
                );

                return personPromise[certificateNumber];
            }
        }
};
