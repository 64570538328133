import * as React from "react";
import { Accordion, AccordionSummary, Grid, Typography, Button, AccordionDetails } from "@mui/material";

import { EmailUpdateConfirmationDetails, UpdateEmailRequest } from "../models";
import  EmailForm from "./emailForm";
import { ProfileEmailView } from "./profileEmailView";

export interface ProfileEmailProps {
    profileEmail: string;
    isSaving: boolean;
    updateProfileEmail: (updateEmailRequest: UpdateEmailRequest) => any;
    isLoading: boolean;
    emailUpdateConfirmationDetails: EmailUpdateConfirmationDetails;
}

export interface ProfileEmailState {
    isExpanded: boolean;
}

export class ProfileEmailSection extends React.Component<ProfileEmailProps, ProfileEmailState> {
    public state: ProfileEmailState = {
        isExpanded: false
    };

    public componentDidUpdate(prevProps: ProfileEmailProps) {
        if (!this.props.isSaving && prevProps.emailUpdateConfirmationDetails !== this.props.emailUpdateConfirmationDetails && this.state.isExpanded) {
            this.collapsePanel();
        }
    }

    public render() {
        return (
            <Accordion expanded={this.state.isExpanded} className="profile-line">
                <AccordionSummary classes={{ disabled: "panel-summary disabled", root: "panel-summary" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6">Email address</Typography>
                        </Grid>
                        <Grid item xs={12} md={this.state.isExpanded ? 9 : 8}>
                            <ProfileEmailView email={this.props.profileEmail} isLoading={this.props.isLoading} emailUpdateConfirmationDetails={this.props.emailUpdateConfirmationDetails} />
                        </Grid>
                        {!this.state.isExpanded ? (
                            <Grid item xs={12} md={1} className="panel-summary-action">
                                <Button id="email-edit-button" variant="outlined" onClick={this.expandPanel} disabled={this.props.isLoading}>
                                    Edit
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {this.state.isExpanded ? <EmailForm profileEmail={this.props.profileEmail} onSaveEmailRequested={this.props.updateProfileEmail} onCancel={this.collapsePanel} isSaving={this.props.isSaving} /> : null}
                    <></>
                </AccordionDetails>
            </Accordion>
        );
    }

    private expandPanel = () => {
        this.setState(s => ({ ...s, isExpanded: true }));
    };

    private collapsePanel = () => {
        this.setState(s => ({ ...s, isExpanded: false }));
    };
}
